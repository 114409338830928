import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvService } from '../GENERAL/env.service';
import { ClassroomService } from './classroom.service';
import { ClassRoomInterface } from './classRoomInterface';

@Injectable({
  providedIn: 'root',
})
export class GoToClassroomService implements ClassRoomInterface {
  baseUrl: string;
  baseUrlGoTo: string;

  code = '';
  redirectUri = '';

  constructor(private http: HttpClient, private env: EnvService) {
    this.baseUrl = this.env.apiUrl;
    this.baseUrlGoTo = this.baseUrl + '/go_to_webinar';
  }
  getListaAule(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_classroom');
  }
  getListaAlunni(id: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_alunni/?id=' + id);
  }
  createAula(aula: any, wantFirma?: any): Observable<any> {
    let endpoint =
      wantFirma != '-1' ? '/crea_classroom' : '/crea_classroom?noTemplate=true';
    return this.http.post<any>(this.baseUrlGoTo + endpoint, aula);
  }
  editAula(aula: any, wantFirma?: any): Observable<any> {
    let endpoint =
      wantFirma != '-1'
        ? '/modifica_classroom'
        : '/modifica_classroom?noTemplate=true';
    return this.http.post<any>(this.baseUrlGoTo + endpoint, aula);
  }
  deleteAula(id: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrlGoTo + '/elimina_classroom/?id=' + id,
      null
    );
  }



  lista_classroom_leggera(
    page?: any,
    size?: any,
    ricerca?: any,
    campo?: any,
    cardinalita?: any
  ): Observable<any> {
    if ((page || page == 0) && size && ricerca && !campo) {
      return this.http.get<any>(
        this.baseUrlGoTo +
          '/lista_classroom_leggera?page=' +
          page +
          '&size=' +
          size +
          '&ricerca=' +
          ricerca
      );
    } else if ((page || page == 0) && size && !ricerca && !campo) {
      return this.http.get<any>(
        this.baseUrlGoTo +
          '/lista_classroom_leggera?page=' +
          page +
          '&size=' +
          size
      );
    } else if (
      (page || page == 0) &&
      size &&
      !ricerca &&
      campo &&
      cardinalita
    ) {
      return this.http.get<any>(
        this.baseUrlGoTo +
          '/lista_classroom_leggera?page=' +
          page +
          '&size=' +
          size +
          '&campo=' +
          campo +
          '&cardinalita=' +
          cardinalita
      );
    } else if ((page || page == 0) && size && !ricerca && campo) {
      return this.http.get<any>(
        this.baseUrlGoTo +
          '/lista_classroom_leggera?page=' +
          page +
          '&size=' +
          size +
          '&campo=' +
          campo
      );
    } else {
      return this.http.get<any>(this.baseUrlGoTo + '/lista_classroom_leggera');
    }
  }

  joinAula(id: any, utente: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  confermaPresenzaStudente(id: any, utente: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  confermaAssenzaStudente(
    id: any,
    nomeAula: any,
    utente: any
  ): Observable<any> {
    throw new Error('Method not implemented.');
  }
  listaStudentiPresenti(idAula: any, nomeAula: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  listaRelatori(idAula: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  editRelatori(
    idAula: any,
    idUtente: any,
    stato: any,
    aulaId: any
  ): Observable<any> {

    throw new Error('Method not implemented.');
  }
  recuperaConnessioni(idAula: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  createLezione(idAula: any, lezione: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getLezioni(idAula: any): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/recupera_schedule/?idAula=' + idAula
    );
  }
  getLezioneOggi(idAula: any): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/recupera_schedule_oggi/?idAula=' + idAula
    );
  }
  eliminaLezione(idLezione: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  contatorePresenza(idAula: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  GraficoGruppi(aula): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/GraficoGruppi", aula)
  }
  GraficoEntrate(aula: any): Observable<any> {
    return this.http.post<any>(this.baseUrlGoTo + "/GraficoEntrate", aula)
  }
  GraficoDurata(aula: any): Observable<any> {
    return this.http.post<any>(this.baseUrlGoTo + "/GraficoDurata", aula)
  }
  GraficoDownloadEsercitazioni(aula: any): Observable<any> {
    return this.http.post<any>(this.baseUrlGoTo + "/GraficoDownloadEsercitazioni", aula)
  }
  GraficoEsercitazioniNrRisposte(aula: any): Observable<any> {
    return this.http.post<any>(this.baseUrlGoTo + "/GraficoEsercitazioniNrRisposte", aula)
  }
  graficoRelatori(aula: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/graficoRelatori", aula)
  }

  sendNotification(body) {
    return this.http.post<any>(this.baseUrl + '/invio_notifica_email', body)
  }

  lista_dettagli_classroom(idAula: any, flag?: any): Observable<any> {
    if (flag) {
      return this.http.get<any>(
        this.baseUrlGoTo +
          '/info_classroom_leggera/?token=' +
          idAula +
          '&flag=' +
          flag
      );
    } else {
      return this.http.get<any>(
        this.baseUrlGoTo + '/info_classroom_leggera/?token=' + idAula
      );
    }
  }
  toggle_Registrazione(endpoint: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getListaFile(id: any): Observable<any> {
    return this.http.get<any>(this.baseUrlGoTo + '/registrazione/' + id);
  }
  deleteFile(id: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  update_mani_alzate(idAula: any, studente: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  update_tempoVideoPassato(
    idUtente: any,
    idRegistrazione: any,
    idAula: any
  ): Observable<any> {
    throw new Error('Method not implemented.');
  }
  graficoVideo(idRegistrazione: any, aula: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getSondaggio(idAula: any): Observable<any> {
    return this.http.get<any>(this.baseUrlGoTo + '/getSondaggio/?idAula=' + idAula)
  }
  grafico_risposte_sondaggio_aula(titolo, RequestStartEndIdForm): Observable<any> {
    return this.http.post<any>(this.baseUrlGoTo + '/grafico_risposte_sondaggio_aula?titolo=' + titolo, RequestStartEndIdForm)
  }
  grafico_report_valutazione(data1: any, data2: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getVideo(id: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  lista_insegnanti(id: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  sendPool(
    idAula,
    message,
  ): Observable<any> {
        return this.http.post<any>(
          this.baseUrlGoTo + `/invia_sondaggio?classroom=${idAula}`,
          {
            type: 'singleChoice',
            questionText: message,
            choices: [{ answerText: 'Si' }, { answerText: 'No' }],
          }
        );

  }
  answerPool(
    classroom: number,
    messaggio: string,
    sondaggio: string,
    tempoInattivita: number
  ): Observable<any> {
    throw new Error('Method not implemented.');
  }
  saveDBPool(classroom: number): Observable<any> {
    throw new Error('Method not implemented.');
  }
  upload_afi(idAula: any, file: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  modifica_img_afi(idAula: any, file: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  conta_eventi_seguiti(): Observable<any> {
    return this.http.get<any>(this.baseUrlGoTo + '/conta_eventi_seguiti');
  }
  conta_eventi_programmati(): Observable<any> {
    return this.http.get<any>(this.baseUrlGoTo + '/conta_eventi_programmati');
  }
  lista_attestati(): Observable<any> {
    return this.http.get<any>(this.baseUrlGoTo + '/lista_attestati');
  }
  modifica_schedule(idAula: any, idGruppo: any, lezione: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  recupera_schedule_gruppo(idGruppo: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  elimina_schedule_gruppo(idGruppo: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  checkRec(idAula: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  salaAttesa(idAula: any, idStudente: any, nomeAula: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  impostaStato(idAula: any, stato: any, body: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getStatoStudenteAttesa(idAula: any, idStudente: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  entraInClassroom(idAula: string, utente: any): Observable<any> {
    return this.http.post<any>(
      this.baseUrlGoTo + '/entra_in_classroom',
      utente,
      {
        params: new HttpParams()
          .append('idAula', idAula)
          .append('utenteRelatore', 'true'),
      }
    );
  }

  setGoToToken(): void {
    //Chiamata a gotowebinar per il recupero del code.
    //1 Passo
    let callBEByGOTO = `http://` + location.host + `/access_token_go_to`;
    callBEByGOTO =  this.baseUrlGoTo + '/access_token_oauth'
    const redirectUri =
      `https://authentication.logmeininc.com/oauth/authorize?client_id=${environment.go_to_client_id}&response_type=code&redirect_uri=${callBEByGOTO}`;
     // console.log(redirectUri);
    //Apertura della pagina di gotowebinar con callback allegata
    window.open(
      redirectUri
    );
  }

  setToken(code: string, uri: string) {
    //4 passo, eseguo un post verso il be
    let obj = {
      code:code,
      redirectURI:uri
    }
    this.code=code
    this.redirectUri = uri
    //Invio al be il code è il redirectURI che corrisponde sempre a quello del FE
    return this.http.post<any>(this.baseUrlGoTo + '/access_token_oauth',obj);
  }

  /*
  log(log:string,path:string){
    let obj = {
      log:log,
      path:path
    }
    this.http.post<any>(this.baseUrlGoTo + '/log',obj).subscribe(()=>{})
  }
  */


}
