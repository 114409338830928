import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '././COMPONENTS/dashboard/dashboard.component';
import { ClassroomListaAuleComponent } from './COMPONENTS/classroom/listaAule/classroom-lista-aule/classroom-lista-aule.component';
import { HomeControlRoomComponent } from './COMPONENTS/controlroom/home-control-room/home-control-room.component';
import { HomeControlRoomLightComponent } from './COMPONENTS/geolocalizzazione/home-control-room/home-control-roomLight.component';
import { CambiaPasswordComponent } from './COMPONENTS/navbar/cambia-password/cambia-password.component';
import { GuidaComponent } from './COMPONENTS/navbar/guida/guida.component';
import { LogoutComponent } from './COMPONENTS/navbar/logout';
import { ProfiloComponent } from './COMPONENTS/navbar/profilo/profilo.component';
import { VideochiamataComponent } from './COMPONENTS/videochiamata/videochiamata.component';
import { EmbedControlComponent } from './COMPONENTS/embed-control/telemetria/embed-control.component';
import { AuthGuard } from './HELPERS/auth.guard';
import { CanDeactivateGuard } from './HELPERS/candeactivate-guard.service';
import { ListaFileComponent } from './COMPONENTS/Dms/lista-file/lista-file.component';
import { ClassroomHomepageComponent } from './COMPONENTS/classroom/homepageClassroom/classroom-homepage/classroom-homepage.component';
import { IotLayoutComponent } from './COMPONENTS/embed-control/iot-layout/iot-layout.component';
import { LavagnaComponent } from './COMPONENTS/classroom/lavagna/lavagna.component';
import { ChatbotComponent } from './COMPONENTS/chatbot/chatbot.component';
import { AppComponent } from './app.component';
import { IotModularComponent } from './COMPONENTS/iot/iot-modular/iot-modular.component';
import { PrenotazioneTecnicoComponent } from './COMPONENTS/prenotazione-tecnico/prenotazione-tecnico.component';
import { DownloadReportImageComponent } from './COMPONENTS/pannello-controllo/report/report-badge/download-report-image/download-report-image.component';
import { AssistitiComponent } from './COMPONENTS/assistiti/assistiti.component';
import { AccessTokenGoToComponent } from './COMPONENTS/classroom/access-token-go-to/access-token-go-to.component';
//import { DashboardMonitoraggioComponent } from './components/monitoraggio-ambiente/dashboard-monitoraggio/dashboard-monitoraggio.component';

const accountModule = () => import('././COMPONENTS/account/account.module').then(x => x.AccountModule);
const pannelloModule = () => import('././COMPONENTS/pannello-controllo/pannello-controllo.module').then(x => x.PannelloControlloModule);
const planimetrieModule = () => import('./COMPONENTS/planimetria/planimetria.module').then(x => x.PlanimetriaModule);
const newsLetter = () => import('././COMPONENTS/news-letter/news-letter.module').then(x => x.NewsLetterModule);
const farmacie = () => import('././COMPONENTS/farmacie-call-center/farmacie-call-center.module').then(x => x.FarmacieCallCenterModule);
const campagne = () => import('././COMPONENTS/campagne/campagne.module').then(x => x.CampagneModule);

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'pannellocontrollo', loadChildren: pannelloModule, canActivate: [AuthGuard] },
  { path: 'account', loadChildren: accountModule },
  { path: 'newsLetter', loadChildren: newsLetter, canActivate: [AuthGuard]},
  { path: 'farmacie', loadChildren: farmacie, canActivate: [AuthGuard]},
  { path: 'campagne', loadChildren: campagne, canActivate: [AuthGuard]},
  { path: 'cambiaPassword', component: CambiaPasswordComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: 'access_token_go_to/:code', component: AccessTokenGoToComponent  },
  { path: 'controlroom', component: HomeControlRoomComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'videochiamata', component: VideochiamataComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'geolocalizzazione', component: HomeControlRoomLightComponent, canActivate: [AuthGuard] },
  { path: 'guida', component: GuidaComponent, canActivate: [AuthGuard] },
  { path: 'profilo', component: ProfiloComponent, canActivate: [AuthGuard] },
  { path: 'eventi', component: PrenotazioneTecnicoComponent, canActivate: [AuthGuard] },
  { path: 'education', component: ClassroomListaAuleComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'webinar/:token', component: ClassroomHomepageComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'remoteControl', component: IotLayoutComponent, canActivate: [AuthGuard] },
  { path: 'iot', component: IotModularComponent},
  { path: 'assistiti', component:AssistitiComponent},
  { path: 'dms', component: ListaFileComponent, canActivate: [AuthGuard] },
  { path: 'lavagna', component: LavagnaComponent, canActivate: [AuthGuard] },
  { path: 'planimetrie', loadChildren: planimetrieModule, canActivate: [AuthGuard] },
  { path: 'chatBot', component: ChatbotComponent, canActivate: [AuthGuard] },
  { path: 'login/:token', component: AppComponent},
  { path: 'badge/report/image/:id', component: DownloadReportImageComponent, canActivate: [AuthGuard]},
 // { path: "monitoraggioAmbientale", component: DashboardMonitoraggioComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
