<div class="card_container vh90">
  <div class="card shadow">
    <div class="card-header">
      <div
        class="map2"
        id="map"
        (leafletMapReady)="onMapReady($event)"
        [ngClass]="{ notMapFullScreen: !fullScreenMap }"
      ></div>
      <leaflet-fullscreen-control [map]="map"></leaflet-fullscreen-control>

      <form [formGroup]="searchform">
        <div
          class="input-group input-group-sm mb-3"
          style="width: 100% !important; margin-left: 3px"
        >
          <input
            type="text"
            formControlName="search"
            class="form-control"
            placeholder="Cerca..."
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            maxlength="30"
            (input)="hideUser()"
          />
        </div>
      </form>
      <div *ngIf="searchInUser()"></div>
      <div>
        <h5>Utenti online</h5>
        <span *ngIf="spinner"
          ><p-progressSpinner
            [style]="{ width: '10px', height: '10px' }"
            strokeWidth="8"
            styleClass="custom-spinner"
            animationDuration=".5s"
          ></p-progressSpinner
        ></span>
        <div
          *ngIf="
            usersStatic &&
            usersStatic.length < 1 &&
            !isSearchingOn &&
            !searchform.value.search
          "
          class="mat-elevation-z8 mb-5 mt-5"
          style="background-color: #eee"
        >
          <div class="rowGrid m-o bg-color h-30">
            <div class="arrowFirstGrid iconCenter">
              <i class="material-icons pointer notranslate">more_vert</i>
            </div>
            <div class="nameSecondGrid centered">
              <div>Nessun utente online</div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let user of usersStatic; let j = index"
          class="mat-elevation-z8 mb-5 mt-5"
          style="background-color: #eee"
        >
          <div class="rowGrid m-0 bg-color h-30">
            <div class="arrowFirstGrid iconCenter">
              <i
                [ngStyle]="{ color: colorArray[j] }"
                class="material-icons pointer notranslate"
                (click)="rotate('stateOnline', j, stateOnline[j], user.id)"
                [@rotatedState]="stateOnline[j]"
                >arrow_right</i
              >
            </div>
            <div class="nameSecondGrid">
              <div
                class="mb-10"
                [ngClass]="{ bold: stateOnline[j] === 'rotated' }"
              >
                {{ user.nome }} {{ user.cognome }}
              </div>
              <div>
                <p *ngIf="user.capcomune && user.capcomune.length === 1">
                  {{ user.capcomune[0] }}
                </p>
                <p
                  *ngIf="user.capcomune && user.capcomune.length > 1"
                  data-mdb-toggle="tooltip"
                  data-bs-html="true"
                  [title]="user.capcomune[1]"
                >
                  {{ user.capcomune[0] }}...
                </p>
                <p style="background-color: transparent !important">
                  <span *ngFor="let gruppo of user.gruppos; let i = index">
                    <span *ngIf="i <= user.gruppos.length - 2">{{
                      gruppo?.nomeGruppo + ", "
                    }}</span>
                    <span *ngIf="i === user.gruppos.length - 1">{{
                      gruppo?.nomeGruppo
                    }}</span>
                  </span>
                </p>
              </div>
            </div>
            <div class="actionThirdGrid">
              <div class="row m-0 iconCenter">
                <a class="geo" (click)="zoom(user.latitudine, user.longitudine)"
                  ><i class="material-icons notranslate">place</i></a
                >

                <a
                  *ngIf="user.attivo === 1 && user.userNonInCr === true"
                  class="call"
                  (click)="call(user.id)"
                  ><i class="material-icons notranslate">phone</i></a
                >
                <a
                  *ngIf="user.attivo === 0 && user.userNonInCr === true"
                  class="blink"
                  (click)="call(user.id)"
                  ><i class="material-icons notranslate">phone</i></a
                >
              </div>
            </div>
          </div>
          <ng-container *ngIf="stateOnline[j] === 'rotated'">
            <div *ngIf="spinnerDispositivi">
              <p-progressSpinner
                [style]="{ width: '10px', height: '10px' }"
                strokeWidth="8"
                styleClass="custom-spinner"
                animationDuration=".5s"
              ></p-progressSpinner>
            </div>
            <div
              *ngIf="listaDispositiviUser && listaDispositiviUser.length < 1"
            >
              <div class="rowGrid m-0 bg-color h-30">
                <div class="arrowFirstGrid iconCenter">
                  <i class="material-icons notranslate"
                    >more_vert</i
                  >
                </div>
                <div class="nameSecondGrid centered">
                  <div>Nessun dispositivo disponibile</div>
                </div>
              </div>
            </div>
            <div *ngFor="let dispositivo of listaDispositiviUser">
              <div class="rowGrid m-0 bg-color h-30">
                <div class="arrowFirstGrid iconCenter">
                  <i
                    class="material-icons notranslate"
                    [ngStyle]="{ color: colorArray[user.token] }"
                    >more_vert</i
                  >
                </div>
                <div class="nameSecondGrid">
                  <div class="textLabel">{{ dispositivo.dispositivo }}</div>
                </div>
                <div class="actionThirdGrid">
                  <div class="row m-0 iconCenter">
                    <a
                      class="call"
                      (click)="call(user.id, dispositivo.dispositivo)"
                      ><i class="material-icons notranslate">phone</i></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Utenti Offline -->
      <h5>Utenti offline</h5>
      <span *ngIf="spinnerOffline"
        ><p-progressSpinner
          [style]="{ width: '10px', height: '10px' }"
          strokeWidth="8"
          styleClass="custom-spinner"
          animationDuration=".5s"
        ></p-progressSpinner
      ></span>
      <div *ngIf="!spinnerOffline">
        <div
          *ngIf="
            usersOffline &&
            usersOffline.length < 1 &&
            !isSearchingOn &&
            !searchform.value.search
          "
          class="mat-elevation-z8 mb-5 mt-5"
          style="background-color: #eee"
        >
          <div class="rowGrid m-o bg-color h-30">
            <div class="arrowFirstGrid iconCenter">
              <i class="material-icons pointer notranslate">more_vert</i>
            </div>
            <div class="nameSecondGrid centered">
              <div>Nessun utente offline</div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let userOffline of usersOffline; let k = index"
          class="mat-elevation-z8 mb-5 mt-5"
          style="background-color: #eee"
        >
          <div class="rowGrid m-0 bg-color h-30">
            <div class="arrowFirstGrid iconCenter">
              <i
                class="material-icons pointer notranslate"
                [ngStyle]="{ color: colorArray[k] }"
                (click)="
                  rotate('stateOffline', k, stateOffline[k], userOffline.id)
                "
                [@rotatedState]="stateOffline[k]"
                >arrow_right</i
              >
            </div>
            <div class="nameSecondGrid">
              <div
                class="textLabel"
                [ngClass]="{ bold: stateOffline[k] === 'rotated' }"
              >
                {{ userOffline.nome }} {{ userOffline.cognome }}
              </div>
              <p style="background-color: transparent !important">
                <span *ngFor="let gruppo of userOffline.gruppos; let i = index">
                  <span *ngIf="i <= userOffline.gruppos.length - 2">{{
                    gruppo?.nomeGruppo + ", "
                  }}</span>
                  <span *ngIf="i === userOffline.gruppos.length - 1">{{
                    gruppo?.nomeGruppo
                  }}</span>
                </span>
              </p>
            </div>
            <div class="actionThirdGrid">
              <div class="row m-0 iconCenter">
                <a class="call" (click)="call(userOffline.id)"
                  ><i class="material-icons notranslate">phone</i></a
                >
              </div>
            </div>
          </div>
          <ng-container *ngIf="stateOffline[k] === 'rotated'">
            <div *ngIf="spinnerDispositivi">
              <p-progressSpinner
                [style]="{ width: '10px', height: '10px' }"
                strokeWidth="8"
                styleClass="custom-spinner"
                animationDuration=".5s"
              ></p-progressSpinner>
            </div>
            <div
              *ngIf="listaDispositiviUser && listaDispositiviUser.length < 1"
            >
              <div class="rowGrid m-0 bg-color h-30">
                <div class="arrowFirstGrid iconCenter">
                  <i class="material-icons notranslate"
                    >more_vert</i
                  >
                </div>
                <div class="nameSecondGrid centered">
                  <div>Nessun dispositivo disponibile</div>
                </div>
              </div>
            </div>
            <div *ngFor="let dispositivo of listaDispositiviUser">
              <div class="rowGrid m-0 bg-color h-30">
                <div class="arrowFirstGrid iconCenter">
                  <i class="material-icons notranslate"
                    >more_vert</i
                  >
                </div>
                <div class="nameSecondGrid">
                  <div class="textLabel">{{ dispositivo.dispositivo }}</div>
                </div>
                <div class="actionThirdGrid">
                  <div class="row m-0 iconCenter">
                    <a
                      class="call"
                      (click)="call(userOffline.id, dispositivo.token)"
                      ><i class="material-icons notranslate">phone</i></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- vecchie table-->
      <!--  <div class="table-responsive scroll">

  <table class="table table-striped">
      <thead>
        <tr style="display: none;">
          <th style="width: 3%;"></th>
          <th style="width: 67%"></th>
          <th style="width: 30%"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="usersStatic?.length < 1">
          <td style="opacity: 50%;">Nessun utente connesso</td>
        </tr>
        <tr *ngFor="let user of usersStatic ; let j= index">
          
          <td style="width:3%"><i class="material-icons notranslate" [ngStyle]="{'color' : colorArray[j]}">more_vert</i></td>
          <td style="width:75%">
        <tr style="background-color: transparent!important;">{{user.nome}} {{user.cognome}}</tr>
        <tr style="background-color: transparent!important;">
          
            <p *ngIf="user.capcomune.length === 1">{{user.capcomune[0]}}</p>
            <p *ngIf="user.capcomune.length > 1"  data-mdb-toggle="tooltip" data-bs-html="true" [title]="user.capcomune[1]">{{user.capcomune[0]}}...</p>
        </tr>
        <tr style="background-color: transparent!important;"><span *ngFor="let gruppo of user.gruppos; let i= index">
            <span *ngIf="i <= user.gruppos.length-2">{{gruppo?.nomeGruppo + ', '}}</span>
            <span *ngIf=" i === user.gruppos.length-1">{{gruppo?.nomeGruppo}}</span>
          </span></tr>
        </td>


        <td style="white-space: nowrap">
          <div class="row">
            <a class=" geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                class="material-icons notranslate">place</i></a>
                <a *ngIf="user.attivo === 1 && user.userNonInCr === true" class="call" (click)="call(user.id)"><i class="material-icons notranslate">phone</i></a>
                <a *ngIf="user.attivo === 0 && user.userNonInCr === true" class="blink" (click)="call(user.id)"><i class="material-icons notranslate">phone</i></a>
          </div>
        </td>

        </tr>
       
       

      </tbody>
    </table>
    <table class="table table-striped">
      <thead>
        <tr style="display: none;">
          <th style="width: 3%;"></th>
          <th style="width: 67%"></th>
          <th style="width: 30%"></th>
        </tr>
      </thead>
      <tbody>    

        <hr class="hr-text" data-content="Utenti Offline">
        <tr *ngFor="let userOffline of usersOffline ; let k= index">
          
          <td>
        <tr style="background-color: transparent!important;">{{userOffline.nome}} {{userOffline.cognome}}</tr>
        <tr style="background-color: transparent!important;"><span *ngFor="let gruppo of userOffline.gruppos; let y= index">
            <span *ngIf="y <= userOffline.gruppos.length-2">{{gruppo?.nomeGruppo + ', '}}</span>
            <span *ngIf=" y === userOffline.gruppos.length-1">{{gruppo?.nomeGruppo}}</span>
          </span></tr>
        </td>


        <td style="white-space: nowrap">
          <div class="row">
            <a  class="call" (click)="call(userOffline.id)"><i class="material-icons notranslate">phone</i></a>
          </div>
        </td>

        </tr>

      </tbody>
    </table>
  
  -->
    </div>
  </div>
</div>
