import {
  Component,
  ViewChild,
  OnDestroy,
  HostListener,
  Output,
  EventEmitter,
  DoCheck,
} from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  OpenviduSessionComponent,
  StreamEvent,
  Session,
  UserModel,
  OpenViduLayout,
  OvSettings,
  OpenViduLayoutOptions,
  SessionDisconnectedEvent,
  Publisher,
  OpenVidu,
  OpenviduSessionModule,
  SignalOptions,
  SignalEvent,
} from 'openvidu-angular';
import { Router } from '@angular/router';
import { Utente } from 'src/app/MODELS/USER/utente';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { OperatorService } from 'src/app/SERVICES/ASSISTENZA/operator.service';
import { AccountService, EnvService, ShareService } from 'src/app/SERVICES/index';
import { ruolo } from 'src/app/MODELS/USER/ruolo';
import { UserStream } from 'src/app/MODELS/ASSISTENZA/user_stream.model';
import { Event } from '@angular/router';

@Component({
  selector: 'app-open-vidu',
  templateUrl: './open-vidu.component.html',
  styleUrls: ['./open-vidu.component.less'],
})
export class OpenViduComponent implements OnDestroy {
  @Output() userIn = new EventEmitter<boolean>();
  @Output() access = new EventEmitter<boolean>();
  // Join form
  openviduSessionModule: OpenviduSessionModule;
  mediaDevice: OpenVidu;
  mySessionId = '';
  interval;
  myUserName = '';
  user: Utente = new Utente();
  tokens = [];
  session = false;
  ovSession: Session;
  ovLocalUsers: UserModel[];
  ovLayout: OpenViduLayout;
  ovLayoutOptions: OpenViduLayoutOptions;
  ovSettings: OvSettings;
  publisher: Publisher;
  chiudi = false;
  url;
  urlEnv;
  @ViewChild('ovSessionComponent')
  public ovSessionComponent: OpenviduSessionComponent;
  //AR+Screenshoot-Array inviato al padre per AR e screenchoot, invia un UserStream che contiene al sui interno, l'oggetto UserStream
  @Output() localUser = new EventEmitter<UserStream[]>();
  userStreams: UserStream[] = []; //aggiorno qui array e poi lo invio a home-control
  constructor(
    private httpClient: HttpClient,
    private operatorService: OperatorService,
    private accountService: AccountService,
    private envService : EnvService,
    private alertService: AlertService,
    private router: Router,
    private shareService: ShareService
  ) {
    this.urlEnv = this.envService.apiUrl;
    this.router.events.subscribe((event: Event) => {
      this.url = event['url'];
    });
    this.ovSettings = {
      chat: false,
      autopublish: false,
      toolbarButtons: {
        audio: true,
        video: true,
        screenShare: true,
        fullscreen: false,
        layoutSpeaking: false,
        exit: false,
      },
    };
    this.joinSession();
    this.interval = setInterval(() => {
      setTimeout(() => {
        let el = document.getElementById('navSwitchCameraButton');
        if (el) {
          el.style.display = 'none';
          clearInterval(this.interval);
        }
      }, 100);
    }, 1000);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.operatorService.leaveSession(this.user).subscribe(
      (res) => {},
      (error) => {}
    );
    if (!this.chiudi) {
      this.router.navigate(['']);
    }

    localStorage.removeItem('openviduCallNickname');
  }

  joinSession() {
    let token1;
    let token2;

    let ruoloUser: ruolo = new ruolo();
    ruoloUser.id = this.accountService.ruolo;
    ruoloUser.nome_ruolo = this.accountService.userValue.data.ruolo.nome_ruolo;
    this.user.id = this.accountService.userValue.data.id;
    this.user.nome = this.accountService.userValue.data.nome;
    this.user.cognome = this.accountService.userValue.data.cognome;
    this.user.ruolo = ruoloUser;
    this.user.email = this.accountService.userValue.data.email;
    this.mySessionId = this.user.nome + ' ' + this.user.cognome;
    this.myUserName = this.accountService.userValue.data.email;
    this.operatorService.createSession(this.user).subscribe(
      (res) => {
        token1 = res.data[0];
        token2 = res.data[1];
        this.tokens.push(token1);
        this.tokens.push(token2);

        this.session = true;
        if (this.ovLayout) this.ovLayout.updateLayout();
      },
      (error) => {
        this.alertService.error('Errore creazione sessione lato server');
        this.router.navigate(['']);
      }
    );
  }
  /**
   * Metodo richiamato ogni volta che c'è un evento in CR, gli eventi sono:
   * 1. session.on('streamCreated'): entrata di un utente
   * 2. session.on('streamDestroyed'): uscita di un utente
   * @param session contiene le info dell'utente entrato nella CR
   */
  handlerSessionCreatedEvent(session: Session): void {
    session.on('streamCreated', (event: StreamEvent) => {
      this.ovSession = session;
      this.userIn.emit(true);

      //screenshot e in futuro anche per AR
      //mi salvo id del tag che contiene il video
      let idStream = '#container-' + event.stream.streamId;
      //mi salvo email di quel utente, contenuto in un JSON: "{clientData:email}"
      let utente = JSON.parse(event.stream.connection.data);
      let email = utente.clientData;
      //salvo le info dentro array
      this.userStreams.push(new UserStream(idStream, email));
      this.localUser.emit(this.userStreams); //invio al padre l'evento, che al suo interno contiene ID univico
    });

    session.on('streamDestroyed', (event: StreamEvent) => {
      //Cancello nell'array l'utente che esce dalla sessione, per farlo mi prendo email
      let utente = JSON.parse(event.stream.connection.data);
      let email = utente.clientData;
      //scorro array e cerco quell'utente
      let i = 0;
      for (; i < this.userStreams.length; i++) {
        if (email === this.userStreams[i].email) {
          //trovato l'utente lo cancello
          let a = this.userStreams.splice(i, 1);
        }
      }
      //invio al padre array aggiornato
      this.localUser.emit(this.userStreams);
    });

    session.on('sessionDisconnected', (event: SessionDisconnectedEvent) => {
      this.chiudi = true;
      this.session = false;
      this.tokens = [];
      this.userIn.emit(false);
      if (!this.url) {
        this.router.navigate(['']);
      }
    });

    this.myMethod();
  }

  handlerPublisherCreatedEvent(publisher: Publisher) {
    this.shareService.changeCloseLateralMenu(new Date());

    publisher.on('streamCreated', (e) => {});
  }

  handlerErrorEvent(event): void {}

  myMethod() {
    this.ovLocalUsers = this.ovSessionComponent.getLocalUsers();
    this.ovLayout = this.ovSessionComponent.getOpenviduLayout();
    this.ovLayoutOptions = this.ovSessionComponent.getOpenviduLayoutOptions();
  }

  cambiaEvidenza(event) {
    let signal: SignalOptions = {};
    signal.data = event;
    signal.type = 'changeEvidence';
    this.ovSession.signal(signal);
  }

  close(event) {
    if (!event)
      this.alertService.error(
        'Per continuare, è necessario fornire i permessi'
      );
    this.chiudi = true;
    this.session = false;
    this.tokens = [];
    if (!event) this.access.emit();
  }
}
