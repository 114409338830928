<div class="container-fluid">
  <p-confirmDialog key="uscita" #cd icon="pi pi-exclamation-triangle" [style]="{width: responsiveWidth}"
    [acceptLabel]="'Si'" [baseZIndex]="5">
    <p-footer>
      <button type="button" pButton icon="pi pi-check" label="Sì" (click)="cd.accept()"></button>
      <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
    </p-footer>
  </p-confirmDialog>
  <div class="content">
    <div class="row">
      <div class="col-md-12 ">
        <div class="card">
          <div class="card-content">

            <div class="card-title">
              <button (click)="indietro()" id="back-button" class="btn btn-round  btn-just-icon"
                style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
                <i class="material-icons notranslate">arrow_back</i>
              </button>
              <h4 style="display: inline;">{{aulaInfo?.nomeAula | titlecase}}</h4>
            </div>

            <ul class="nav nav-pills nav-pills-primary row mobileView">



              <li class="active" [ngClass]="{'col-xs-12': !isDesktop}">
                <a href="#pill1" (click)="cambioPill(1)" data-toggle="tab">{{ambiente.LEZIONIINCORSO}}</a>
              </li>
              <li [ngClass]="{'col-xs-12': !isDesktop}">
                <a href="#pill2" (click)="cambioPill(2)" data-toggle="tab">{{ambiente.CALENDARIOLEZIONI}}</a>
              </li>
              <li [ngClass]="{'col-xs-12': !isDesktop}">
                <a href="#pill3" (click)="cambioPill(3)" data-toggle="tab">{{ambiente.ESERCITAZIONI}}</a>
              </li>
              <li [ngClass]="{'col-xs-12': !isDesktop}">
                <a href="#pill4" (click)="cambioPill(4)" data-toggle="tab"
                  *ngIf="recording">{{ambiente.LEZIONIREGISTRATE}}</a>
              </li>
              <li [ngClass]="{'col-xs-12': !isDesktop}" *ngIf="!envService.goTo">
                <a href="#pill5" (click)="cambioPill(5)" data-toggle="tab" *ngIf="envScorm">{{ambiente.CORSI}}</a>
              </li>
            </ul>
            <hr>
            <div class="tab-content">

              <div class="tab-pane active" id="pill1">
                <ng-container *ngIf="!lezione && !salaAttesa">
                  <table class="table" *ngIf="flagSchedule === 'siSchedule'">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 30%;">Orario</th>
                        <th scope="col" style="width: 30%;">Lezione</th>
                        <th scope="col" style="width: 30%;">Docente</th>
                        <th scope="col" style="width: 30%;"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let lezione of schedule">
                        <th scope="row">{{lezione.start}} - {{lezione.end}}</th>
                        <td>{{lezione.nome}}</td>
                        <td>{{lezione.docente}}</td>
                        <td><button [disabled]="!caricato" style="margin:0; padding: 7px 7px 7px 7px;"
                            *ngIf="lezione.inCorso" class="btn btn-primary" (click)="avviaLezione()">
                            <i class="material-icons notranslate">videocam</i>
                            Partecipa
                          </button>
                          <!-- Pulsante Sondaggio START-->
                          <div *ngIf="this.envService.goTo  && this.isWebinarActive" style="margin-top: 8px">
                            <!-- icona graafica che se premuta apre il popup -->
                            <i data-toggle="tooltip" title="Verifica inattività" *ngIf="(ruoloUser==4||ruoloUser==1)"
                              class="material-icons notranslate" (click)="apriPopUpSondaggio()" style="cursor: pointer;">poll</i>
                          </div>
                          <!-- Pulsante Sondaggio END-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="flex">
                    <button [disabled]="!caricato" *ngIf="flagSchedule === 'noSchedule'" class="btn btn-primary"
                      (click)="avviaLezione()">
                      <i class="material-icons notranslate">videocam</i>
                      Partecipa
                    </button>
                    <!-- Pulsante Sondaggio START-->
                    <div *ngIf="this.envService.goTo && this.isWebinarActive" style="margin-top: 8px">
                      <!-- icona graafica che se premuta apre il popup -->
                      <i data-toggle="tooltip" title="Verifica inattività" *ngIf="(ruoloUser==4||ruoloUser==1)"
                        class="material-icons notranslate" (click)="apriPopUpSondaggio()" style="cursor: pointer;">poll</i>
                    </div>
                    <!-- Pulsante Sondaggio END-->
                  </div>

                </ng-container>
                <div class="centered">
                  <ng-container *ngIf="salaAttesa && !lezione">
                    <img src="../../../../../assets/img/waitingRoom" style="width: 100px;height: 100px;" width="40"
                      height="40" alt="">
                    <!-- <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                                styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner> -->
                  </ng-container>

                <div class="loader chat-message-right" *ngIf="salaAttesa && !lezione">
                  <div class="bar1"></div>
                  <div class="bar2"></div>
                  <div class="bar3"></div>
                  <div class="bar4"></div>
                  <div class="bar5"></div>
                  <div class="bar6"></div>
                </div>
                <h3 *ngIf="salaAttesa && !lezione">Richiesta di partecipazione in corso...</h3>
                  <h5 *ngIf="salaAttesa && !lezione">Parteciperai alla chiamata quando</h5>
                  <h5 *ngIf="salaAttesa && !lezione">qualcuno te lo permetterà</h5>
                </div>
                <app-classroom-lezione-in-corso (indietro)="indietroLezione()" *ngIf="lezione"
                  (feedbackDialog)="openFeedbackDialog()"></app-classroom-lezione-in-corso>
              </div>


              <div class="tab-pane" id="pill2">


                <app-calendario *ngIf="aulaInfo"></app-calendario>
              </div>
              <div class="tab-pane" id="pill3" *ngIf="clickEsercitazioni">
                <app-esercitazioni *ngIf="aulaInfo"></app-esercitazioni>

              </div>
              <div class="tab-pane" id="pill4">
                <app-lezioni-passate *ngIf="pill4Var"></app-lezioni-passate>
              </div>
              <div class="tab-pane" id="pill5">
                <app-corsi *ngIf="aulaInfo"></app-corsi>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [draggable]="false" [resizable]="false" [(visible)]="selectedFeedback" [closeOnEscape]="true" modal="true"
  [dismissableMask]="true" (onHide)="rateCall(0)">
  <h5 class="centered">Come valuti la tua esperienza sul sito?</h5>

  <div class="centered">
    <i class="material-icons unsatisfied" (click)="rateCall(1)" data-dismiss="modal">
      sentiment_dissatisfied
    </i>
    <i class="material-icons neutral" (click)="rateCall(2)" data-dismiss="modal">
      sentiment_neutral
    </i>
    <i class="material-icons satisfied" (click)="rateCall(3)" data-dismiss="modal">
      sentiment_satisfied_alt
    </i>
  </div>
</p-dialog>

<!-- <button type="button" style="display: none;" #modale class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  </button>

<div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
    <div class="modal-dialog" role="document" >
      <div class="modal-content">
        <div class="modal-header">
            <button #closeModal style="display: none;" type="button" class="close" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
            </button>
          <h5 class="modal-title centered" id="exampleModalLabel">Come valuti la tua esperienza sul sito?</h5>
        </div>
        <div class="modal-body centered">
                <i class="material-icons unsatisfied" (click)="rateCall(1)">
                sentiment_dissatisfied
                </i>
                <i class="material-icons neutral" (click)="rateCall(2)">
                sentiment_neutral
                </i>
                <i class="material-icons satisfied" (click)="rateCall(3)">
                sentiment_satisfied
                </i>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div> -->
<!-- START Sondaggio -->
<!-- Docente con p-dialog Start in p-dialog (onHide) viene richiamato quando il popup si chiude -->
<p-dialog [modal]="true" [(visible)]="popupInsegnanteSondaggio" [style]="{width: '50vw'}" [draggable]="false"
  [resizable]="false">
    <h4 style="text-align: left;">Inserisci sondaggio</h4>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <input type="text" class="form-control" placeholder="Questo è il sondaggio" [formControl]="sondaggioQuestionControl"
            maxlength="45">
        </div>
      </div>
    </div>
  <div style="text-align: right;">
    <button type="button" class="btn btn-secondary" (click)="inviaSondaggio(false)">Chiudi</button>
    <button type="button" class="btn btn-primary" (click)="inviaSondaggio(true)">Invia</button>
  </div>
</p-dialog>
<!-- Docente con p-dialog end -->
