import { ThrowStmt } from '@angular/compiler';
import {Injectable} from "@angular/core";
import { environment } from 'src/environments/environment';
import { EnvService } from '../GENERAL/env.service';


var SockJs = require("sockjs-client");
var Stomp = require("stompjs");

@Injectable()
export class WebSocketService {
constructor(private env : EnvService){}
url = this.env.apiUrl;
//stompUrl = 'wss://api-collaborative.protom.com/collaborative/socket';
//stompUrl = 'wss://api-collaborative.protom.com/collaborative/socket/websocket';
stompUrl = this.env.wssUrl;
stompClient;
public attivo = false;
webSocket : WebSocket;
    // Open connection with the back-end socket
    public connect() {
        this.webSocket = new WebSocket(this.stompUrl)
        this.stompClient = Stomp.over(this.webSocket);
        this.attivo = true;
        return this.stompClient;
    }

   public disconnect(){
    this.webSocket.close();
    this.attivo = false;
   }

   

    
}



