<div class="filters-container mb-3" [ngClass]="{ show: filtersVisible }">
  <form [formGroup]="doctorsFiltersForm" class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label" for="nome">Nome</label>
          <input class="form-control" formControlName="nome" id="nome" type="text" />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label" for="cognome">Cognome</label>
          <input class="form-control" formControlName="cognome" id="cognome" type="text" />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3 d-flex align-items-end">
        <div class="form-group">
          <label class="form-label">Data di nascita</label>
          <input class="form-control" type="text"
                 formControlName="data_di_nascita" id="data_di_nascita"
                 (input)="onInputDate($event)" placeholder="gg/mm/aaaa" maxlength="10" />
        </div>
        <!--<label class="form-label">Data di nascita</label>
        <mat-form-field appearance="fill" class="datepicker-input-filter">
          <input matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>-->
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label">Micro Area</label>
          <select formControlName="micro_area" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaMicroArea" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-4">
        <div class="form-group">
          <label class="form-label" for="indirizzo_princ_di_visita">Indirizzo</label>
          <input class="form-control" formControlName="indirizzo_princ_di_visita" id="indirizzo_princ_di_visita" type="text" />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <div class="form-group">
          <label class="form-label" for="cap">Cap</label>
          <input class="form-control" formControlName="cap" id="cap" type="text" />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label">Provincia</label>
          <select formControlName="provincia" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaProvincia" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label">Località</label>
          <select formControlName="localita" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaLocalita" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label">ISF</label>
          <select formControlName="isf" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaIsf" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label">Carica</label>
          <select formControlName="carica" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaCarica" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label">Attività</label>
          <select formControlName="attivita" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaAttivita" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label">Profilo Pubblico/Privato</label>
          <select formControlName="flag_pubblico_privato" class="form-control">
            <option selected  value="">-</option>
            <option value="true">Pubblico</option>
            <option value="false">Privato</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12 col-md-5">
        <div class="form-group">
          <label class="form-label" for="ragione_sociale_struttura">Ragione sociale</label>
          <input class="form-control" formControlName="ragione_sociale_struttura" id="ragione_sociale_struttura" type="text" />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <div class="form-group">
          <label class="form-label" for="codice_fiscale">Cod. Fiscale</label>
          <input class="form-control" formControlName="codice_fiscale" id="codice_fiscale" type="text" />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label" for="partita_iva">P. Iva</label>
          <input class="form-control" formControlName="partita_iva" id="partita_iva" type="text" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label" for="numero_iscrizione_albo">Numero Iscr. Albo</label>
          <input class="form-control" formControlName="numero_iscrizione_albo" id="numero_iscrizione_albo" type="text" />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label" for="email_pubblica">Emai pubblica</label>
          <input class="form-control" formControlName="email_pubblica" id="email_pubblica" type="text" />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label" for="email_privata">Email privata</label>
          <input class="form-control" formControlName="email_privata" id="email_privata" type="text" />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label class="form-label" for="email_amministrazione">Email amministraz.</label>
          <input class="form-control" formControlName="email_amministrazione" id="email_amministrazione" type="text" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label class="form-label" for="email_ufficio_formazione">Email ufficio formazione</label>
          <input class="form-control" formControlName="email_ufficio_formazione" id="email_ufficio_formazione" type="text" />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label class="form-label" for="email_primario_reparto">Email primario reparto</label>
          <input class="form-control" formControlName="email_primario_reparto" id="email_primario_reparto" type="text" />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label class="form-label">Profilo</label>
          <select formControlName="profilo" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaProfilo" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-2">
        <div class="form-group">
          <label class="form-label">Target A</label>
          <select formControlName="target_a" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaTargetA" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <div class="form-group">
          <label class="form-label">Target B</label>
          <select formControlName="target_b" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaTargetB" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-4">
        <div class="form-group">
          <label class="form-label">Specialità</label>
          <select formControlName="specialita" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaSpecialita" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-1">
        <div class="form-group">
          <label class="form-label">Cons. A</label>
          <select formControlName="consenso_privacy_a" class="form-control">
            <option selected  value="">-</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-1">
        <div class="form-group">
          <label class="form-label">Cons. B</label>
          <select formControlName="consenso_privacy_b" class="form-control">
            <option selected  value="">-</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-1">
        <div class="form-group">
          <label class="form-label">Cons. C</label>
          <select formControlName="consenso_privacy_c" class="form-control">
            <option selected  value="">-</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-1">
        <div class="form-group">
          <label class="form-label">Cons. D</label>
          <select formControlName="consenso_privacy_d" class="form-control">
            <option selected  value="">-</option>
            <option value="si">SI</option>
            <option value="no">NO</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-4">
        <div class="form-group">
          <label class="form-label">Gruppi</label>
          <select formControlName="gruppoId" class="form-control">
            <option selected  value="">-</option>
            <option *ngFor="let item of listaGruppi" [value]="item.id">{{ item.descrizioneGruppo }}</option>
          </select>
        </div>
      </div>
    </div>

  </form>
</div>
