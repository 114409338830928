
import { Component, ViewChild, OnDestroy, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OpenviduSessionComponent, StreamEvent, Session, UserModel, OpenViduLayout, OvSettings, OpenViduLayoutOptions, SessionDisconnectedEvent, Publisher } from 'openvidu-angular';
import { Router } from '@angular/router';
import { ruolo } from 'src/app/MODELS/USER/ruolo';
import { Utente } from 'src/app/MODELS/USER/utente';
import { OperatorService } from 'src/app/SERVICES/ASSISTENZA/operator.service';
import { Event } from '@angular/router';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { AccountService, EnvService, ShareService } from 'src/app/SERVICES/index';

@Component({
  selector: 'app-videochiamata-openvidu',
  templateUrl: './videochiamata-openvidu.component.html',
  styleUrls: ['./videochiamata-openvidu.component.less']
})
export class VideochiamataOpenviduComponent implements OnDestroy {
  @Input() motivo;
  @Output() access = new EventEmitter<boolean>();
  // Join form
  mySessionId = '';
  myUserName = '';
  user: Utente = new Utente;
  tokens = [];
  session = false;
  ovSession: Session;
  ovSettings: OvSettings;
  ovLocalUsers: UserModel[];
  ovLayout: OpenViduLayout;
  ovLayoutOptions: OpenViduLayoutOptions;
  lat;
  urlEnv;
  lng;
  interval;
  url;
  @ViewChild('ovSessionComponent')
  publisher;
  public ovSessionComponent: OpenviduSessionComponent;

  constructor(
    private service: ShareService,private httpClient: HttpClient, private operatorService: OperatorService,
    private accountService: AccountService,
    private envService : EnvService,
    private alertService: AlertService,
    private shareService: ShareService,
    private router: Router) {
      this.urlEnv = this.envService.apiUrl;
    this.router.events.subscribe((event : Event)=>{
        this.url = event["url"];
      })
    navigator.geolocation.getCurrentPosition(res => {
      this.lat = res.coords.latitude;
      this.lng = res.coords.longitude;
      this.joinSession();
    },
    error => {
      this.alertService.error('Per continuare, è necessario fornire i permessi di geolocalizzazione')
      this.access.emit(true)
  })

  this.interval = setInterval(()=>{
    setTimeout(() => {
      let el = document.getElementById('navSwitchCameraButton');
      if (el) {
        el.style.display = 'none';
        clearInterval(this.interval);
      }
    }, 100);
  },1000)
    this.ovSettings = {
      chat: false,
      autopublish: false,
      toolbarButtons: {
        audio: true,
        video: true,
        screenShare: true,
        fullscreen: true,
        layoutSpeaking: false,
        exit: false,
      }
    }
  }
  @HostListener('window:resize',["$event"])
  onResize(event){
    this.ovLayout.updateLayout();
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    if (!this.ovSession) {
      if(!this.url){
        this.access.emit(true)
      }
    }
    else if (this.ovSession) {
      this.operatorService.leaveVideochiamata(this.accountService.userValue.data.id).subscribe((res) => {
        this.service.navigateAwaySelection$.subscribe((resAccept)=>{
          if (resAccept) {
            if (res && res.data && this.showError) {
            }
          }
        })
      });
      this.ovSession.disconnect();

    }
  }

  joinSession() {
    let token1;
    let token2;


    let ruoloUser: ruolo = new ruolo;
    ruoloUser.id = this.accountService.ruolo;
    ruoloUser.nome_ruolo = this.accountService.userValue.data.ruolo.nome_ruolo;
    this.user.id = this.accountService.userValue.data.id;
    this.user.nome = this.accountService.userValue.data.nome;
    this.user.cognome = this.accountService.userValue.data.cognome;
    this.user.ruolo = ruoloUser;
    this.user.email = this.accountService.userValue.data.email;
    this.mySessionId = this.user.nome + ' ' + this.user.cognome;
    this.myUserName = this.accountService.userValue.data.email;
    let risultato;
    this.motivo = encodeURI(this.motivo);
    this.operatorService.joinSession(this.user, this.motivo).subscribe((res) => {


      token1 = res.data[0];
      token2 = res.data[1];

      this.tokens.push(token1);
      this.tokens.push(token2);

      this.session = true;
      this.operatorService.confermaAccesso(this.accountService.userValue.data.id, this.lat, this.lng, this.motivo).subscribe((res) => {

      },
      (error)=>{
        this.access.emit(true)
      });
      if (!token1) {
        this.alertService.error('Errore creazione sessione lato server');
      }

    }, (error) => {
      this.access.emit(true)
   });

  }

  handlerSessionCreatedEvent(session: Session): void {


    session.on('connectionCreated', (event: StreamEvent) => {
      // let subscriber;
      // subscriber = session.subscribe(event.stream, null);
      // this.ovSession = session;
    //  session.unpublish(this.publisher)
    });


    session.on('streamCreated', (event: StreamEvent) => {
      // let subscriber;
      // subscriber = session.subscribe(event.stream, null);
      this.ovSession = session;
    //  session.unpublish(this.publisher)
    });

    session.on('streamDestroyed', (event: StreamEvent) => {


    });

    session.on('sessionDisconnected', (event: SessionDisconnectedEvent) => {
      if (event.reason === "forceDisconnectByServer")
        this.alertService.error("L'operatore ha terminato la videoconferenza. Verrai reindrizzato alla dashboard");
      this.session = false;
      this.tokens = [];
      if(!this.url){
        this.access.emit(true);
      }
    });

    this.myMethod();

    session.on('streamDestroyed', (event: StreamEvent) => {

    });

  }

  handlerPublisherCreatedEvent(publisher: Publisher) {
    this.publisher = publisher;
    this.shareService.changeCloseLateralMenu(new Date())

    publisher.on('streamCreated', (e) => {

    });

  }


  handlerErrorEvent(event): void {

  }



  myMethod() {
    this.ovLocalUsers = this.ovSessionComponent.getLocalUsers();
    this.ovLayout = this.ovSessionComponent.getOpenviduLayout();
    this.ovLayoutOptions = this.ovSessionComponent.getOpenviduLayoutOptions();
  }


  chiudi() {
    this.ovSession.disconnect();
  }
  showError = true;
  close(event) {
    if(event)
      this.showError = false
    if(!event)
    //   this.access.emit(false);
    this.alertService.error("Per continuare, è necessario fornire i permessi")
    this.session = false;
    this.tokens = [];
    this.router.navigate(['']);
  }
}

