<div id="live-chat" *ngIf="stepPage">

  <header class="clearfix"
          [ngClass]="{cloudRed: colore==='rgb(255, 0, 0)',cloudGreen: colore==='rgb(53, 167, 49)',cloudYellow: colore==='rgb(255, 230, 0)',cloudOrange: colore==='rgb(255, 153, 0)',cloudPurple: colore==='rgb(172,47,163)'}">
    <a (click)="chiudi()">
      <a class="chat-close" (click)="close()"><i class="material-icons notranslate"
                                                 style="color: white; font-size: medium; margin-right: 3px; margin-left: 3px;">close</i></a>
      <a class="chat-close" (click)="chiudi()"><i class="material-icons notranslate"
                                                  style="color: white; font-size: medium; margin-right: 3px; margin-left: 3px;">horizontal_rule</i></a>


      <h4 style="display: inline; color: #fff;">Chatbot</h4>
    </a>
    <div *ngIf="isVoiceSupported" style="display: inline; margin-left: 95px;" [ngClass]="{'opacity': disableSwitch}">
      <i class="fa fa-microphone" aria-hidden="true" style="margin-right: 5px;"></i>
      <label class="switch">
        <input type="checkbox" (click)="changeMode()" [(ngModel)]="voiceMode" [disabled]="disableSwitch">
        <span class="slider round"></span>
      </label>
    </div>

    <a (click)="chiudi()">
      <span class="chat-message-counter">3</span>

    </a>
  </header>

  <div class="chat">

    <div class="chat-history" ngx-auto-scroll lock-y-offset="10" observe-attributes #scroll
         [scrollTop]="scroll.scrollHeight">
      <div *ngFor="let messaggio of listaMessaggi;let i = index">
        <div class="chat-message clearfix">
          <div class="clearfix" *ngIf="messaggio.idUtente === 'chatbot'">
            <div class="chat-message-frame-right"
                 [ngClass]="{cloudRed: colore==='rgb(255, 0, 0)',cloudGreen: colore==='rgb(53, 167, 49)',cloudYellow: colore==='rgb(255, 230, 0)',cloudOrange: colore==='rgb(255, 153, 0)',cloudPurple: colore==='rgb(172,47,163)'}">
              <div class="map-container" *ngIf="messaggio.mappa">
                <div class="map-frame">
                  <div id="mapChatbot"></div>
                </div>
              </div>
              <img *ngIf="messaggio.immagine" [src]="messaggio.immagine" class="immagine">
              <p class="chat-message-right">{{messaggio.messaggio}}</p>

              <p class="chat-time"
                 *ngIf="(messaggio && messaggio.dataMessaggio) && ((messaggio.dataMessaggio.getDate().toString() + messaggio.dataMessaggio.getMonth().toString() + messaggio.dataMessaggio.getFullYear().toString()) !== (ora.getDate().toString() + ora.getMonth().toString() + ora.getFullYear().toString()))">
                {{messaggio.dataMessaggio | date: 'dd/MM HH:mm'}}</p>
              <p class="chat-time"
                 *ngIf="(messaggio && messaggio.dataMessaggio) && ((messaggio.dataMessaggio.getDate().toString() + messaggio.dataMessaggio.getMonth().toString() + messaggio.dataMessaggio.getFullYear().toString()) === (ora.getDate().toString() + ora.getMonth().toString() + ora.getFullYear().toString()))">
                {{messaggio.dataMessaggio | date: 'HH:mm'}}</p>

            </div>
            <div *ngIf="messaggio.listaButtons" class="btn-group-vertical btn-group-xs" role="group">
              <button *ngFor="let button of messaggio.listaButtons, let i = index" type="button"
                      [ngClass]="{'btn-primary': colore === 'rgba(35,80,153,1)','btn-danger': colore==='rgb(255, 0, 0)','btn-success': colore==='rgb(53, 167, 49)',cloudYellow: colore==='rgb(255, 230, 0)',cloudOrange: colore==='rgb(255, 153, 0)',cloudPurple: colore==='rgb(172,47,163)'}"
                      class="btn" (click)="submitButton(messaggio.listaButtons, button.link)"
                      [disabled]="(button.type === '1' && !button.attiva) || (button.type === '2' && elaborazioneInCorso) || isRiconoscendo">{{button.text}}</button>
            </div>
            <!-- <div *ngFor="let button of messaggio.listaButtons">
                <button (click)="submitButton(button.link)" type="button" class="btn btn-sm btn-primary">{{button.text}}</button>
            </div> -->
          </div> <!-- end chat-message-content -->


          <div class="chat-message-content clearfix" *ngIf="messaggio.idUtente === 'tu'">
            <div class="chat-message-frame-left">
              <p class="chat-message-left">{{messaggio.messaggio}}</p>

              <p class="chat-time"
                 *ngIf="(messaggio && messaggio.dataMessaggio) && ((messaggio.dataMessaggio.getDate().toString() + messaggio.dataMessaggio.getMonth().toString() + messaggio.dataMessaggio.getFullYear().toString()) !== (ora.getDate().toString() + ora.getMonth().toString() + ora.getFullYear().toString()))">
                {{messaggio.dataMessaggio | date: 'dd/MM HH:mm'}}</p>
              <p class="chat-time"
                 *ngIf="(messaggio && messaggio.dataMessaggio) && ((messaggio.dataMessaggio.getDate().toString() + messaggio.dataMessaggio.getMonth().toString() + messaggio.dataMessaggio.getFullYear().toString()) === (ora.getDate().toString() + ora.getMonth().toString() + ora.getFullYear().toString()))">
                {{messaggio.dataMessaggio | date: 'HH:mm'}}</p>

            </div>
          </div> <!-- end chat-message-content -->


        </div> <!-- end chat-message -->
      </div>
      <div class="clearfix" *ngIf="listaMessaggi[listaMessaggi.length-1].idUtente === 'tu' && !primaVolta">
        <div>
          <div class="loader chat-message-right">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
            <div class="bar4"></div>
            <div class="bar5"></div>
            <div class="bar6"></div>
          </div>
        </div>
      </div>
      <!-- <div class="chat-float-button" id="float-buttons" #chatMessageBot></div> -->
    </div> <!-- end chat-history -->


    <form class="pd-bt-0" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitForm()" id="form">
      <div class="form-group">
        <div class="pd-0">
          <div class="row">
            <input *ngIf="((!isData || isMobile) && !voiceMode) || (isMobile && isData)" autofocus type="search"
                   autocomplete="off"
                   formControlName="Domanda" class="form-control" id="Domanda" name="Domanda"
                   placeholder="Scrivi la tua domanda..." [pKeyFilter]="noSpecial" #search maxlength="300">
            <!--<p-calendar styleClass="form-control" dataType="string" showButtonBar="true" [maxDate]="max"
                        [firstDayOfWeek]="1" dateFormat="dd/mm/yy" formControlName="Data" id="Data" [showIcon]="true"
                        [touchUI]="isMobile" [readonlyInput]="true" (onSelect)="submitForm()" *ngIf="isData"></p-calendar>-->
            <input *ngIf="isData && !isMobile" type="date" [max]="ora | date: 'yyyy-MM-dd'" formControlName="Data"
                   class="form-control" id="Data"
                   name="Data" #search>
            <div *ngIf="(isVoiceSupported && voiceMode) && (!isData || (isData && isMobile))"
                 style="display: flex; justify-content: center;">

              <button style="text-align: center;" id="speech" class="btn"
                      *ngIf="isVoiceSupported && !isData" type="button" (click)="voiceRecognition()"
                      [disabled]="disableMic">
                <i [ngClass]="disableMic ? 'fa fa-microphone-slash' : 'fa fa-microphone'"
                   aria-hidden="true" style="font-size:18px;left: 0;"></i>
                <div class="pulse-ring" *ngIf="isRiconoscendo"></div>
              </button>
            </div>
          </div>

          <!-- <button *ngIf="!isData" type="button" (click)="voiceRecognition()"
              style="padding: 0; background: none; border: none;"><i
                  class="material-icons notranslate pointer" style="margin: 0;">mic_none</i></button> -->

          <input type="submit" hidden>
        </div>
      </div>
    </form>

  </div> <!-- end chat -->

</div> <!-- end live-chat -->

<div id="live-chat" *ngIf="!stepPage">

  <a (click)="chiudi()">
    <header class="clearfix"
            [ngClass]="{cloudRed: colore==='rgb(255, 0, 0)',cloudGreen: colore==='rgb(53, 167, 49)',cloudYellow: colore==='rgb(255, 230, 0)',cloudOrange: colore==='rgb(255, 153, 0)',cloudPurple: colore==='rgb(172,47,163)'}">
      <a class="chat-close" (click)="close()"><i class="material-icons notranslate"
                                                 style="color: white; font-size: medium; margin-right: 3px; margin-left: 3px;">close</i></a>
      <a class="chat-close" (click)="chiudi()"><i class="material-icons notranslate"
                                                  style="color: white; font-size: medium; margin-right: 3px; margin-left: 3px;">expand_less</i></a>

      <h4>Chatbot</h4>


    </header>
  </a>
</div>
