import { Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { registerLocaleData } from '@angular/common';
import {ConfirmationService, PrimeNGConfig} from 'primeng/api';
import localeIt from '@angular/common/locales/it';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { AccountService } from 'src/app/SERVICES/index';

registerLocaleData(localeIt);
const colors: any = {

  0: {
    primary:'#b3b3b3',
    secondary:'#DAE3D9',

  },
  1: {
    primary:'#E0BBE4',
    secondary:'#e7dfe8',
  },
  2: {
    primary: '#FFCE56',
    secondary: '#FDF1BA',
  },
  3: {
    primary: '#FF6384',
    secondary: '#ffc2cf',
  },
  4:{
    primary: '#bce08c',
    secondary: '#e3ffbd'
  },
  5:{
    primary: '#AAC5E2',
    secondary: '#dbedff'

  },
  6:{
    primary: '#6891C3',
    secondary: '#D1E8FF',
  }
};

@Component({
  selector: 'app-calendario',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./calendario.component.scss'],
  templateUrl: './calendario.component.html',
  providers: [ConfirmationService]
})
export class CalendarioComponent implements OnInit{
  ita = {
    firstDayOfWeek: 1,
    dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do","Lu","Ma","Me","Gi","Ve","Sa"],
    monthNames: [ "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre" ],
    monthNamesShort: [ "Gen", "Feb", "Mar", "Apr", "Mag", "Giu","Lug", "Ago", "Set", "Ott", "Nov", "Dic" ],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk'
  };
  aulaInfo: any;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  costante=0;
  costanteInizio=1;
  schedule=false;
  view: CalendarView = CalendarView.Month;
  lezioneId;
  loading;
  cancellaLezione = false;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  cancella=false;
  idLezioneGruppo
  statoCancellazione
  firstView=true;
  scheduleMod=false;
  idGruppoLezioni

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        // this.handleEvent('Deleted', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        // this.handleEvent('Edited', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [
  //   {start: new Date(2011, 4 - 1, 11, 11, 51, 0),
  //   end: new Date('2020-23-12T11:20:30Z'),
  //   title: 'Matematica'
  // },
  //   {
  //     start: subDays(startOfDay(new Date()), 1),
  //     end: addDays(new Date(), 1),
  //     title: 'A 3 day event',
  //     color: colors.red,
  //     actions: this.actions,
  //     allDay: true,
  //     resizable: {
  //       beforeStart: false,
  //       afterEnd: false,
  //     },
  //     draggable: false,
  //   },
  //   {
  //     start: startOfDay(new Date()),
  //     title: 'An event with no end date',
  //     color: colors.yellow,
  //     actions: this.actions,
  //   },
  //   {
  //     start: subDays(endOfMonth(new Date()), 3),
  //     end: addDays(endOfMonth(new Date()), 3),
  //     title: 'A long event that spans 2 months',
  //     color: colors.blue,
  //     allDay: true,
  //   },
  //   {
  //     start: addHours(startOfDay(new Date()), 2),
  //     end: addHours(new Date(), 2),
  //     title: 'A draggable and resizable event',
  //     color: colors.yellow,
  //     actions: this.actions,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true,
  //     },
  //     draggable: true,
  //   },
  ];

  activeDayIsOpen: boolean = true;
  ruolo;
  isDesktop = true;
constructor(
  private deviceService: DeviceDetectorService,
  private classroomService : ClassroomService, private alert : AlertService,
  private service : ShareService,
  private primengConfig: PrimeNGConfig,
  private confirmationService: ConfirmationService,
  private accountService: AccountService){
    this.isDesktop = this.deviceService.isDesktop();
    this.ruolo = this.accountService.ruolo;
    this.service.scegliAula$.subscribe((res) => {
      this.aulaInfo = res;
    });
  }

  ngOnInit(): void {
    if(this.ruolo === 1 || this.ruolo ==4){
      this.inizializzaEventiAdmin();
    }
    else {
      this.inizializzaEventi();
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    // this.handleEvent('Dropped or resized', event);
  }

  // handleEvent(action: string, event: CalendarEvent): void {
  //   this.modalData = { event, action };
  //   this.modal.open(this.modalContent, { size: 'lg' });
  // }
  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  inizializzaEventiAdmin(){
    this.events=[];
    let i = 0;
    this.classroomService.getLezioni(this.aulaInfo.idAula).subscribe((res) => {
      res.data.giorni.forEach(giorno => {
        let provaData = giorno.data.split(' ');
        let newData = provaData[0]+'T'+provaData[1];
        let date = new Date(newData);
        let year = date.getFullYear();
        let month = date.getMonth();
        let day = date.getDate();
        giorno.ore.forEach(lezione => {
          let arrInizio = lezione.start.split(':')
          let oraInizio;
          let minutiInizio;
          if(arrInizio[0].length < 2){
            oraInizio = '0' + arrInizio[0];
          }
          else {
            oraInizio = arrInizio[0];
          }
          if(arrInizio[1].length < 2){
            minutiInizio = '0' + arrInizio[1];
          }
          else {
            minutiInizio = arrInizio[1];
          }
          let arrFine= lezione.end.split(':')
          let oraFine;
          let minutiFine;
          if(arrFine[0].length < 2){
            oraFine = '0' + arrFine[0];
          }
          else {
            oraFine = arrFine[0];
          }
          if(arrFine[1].length < 2){
            minutiFine = '0' + arrFine[1];
          }
          else {
            minutiFine = arrFine[1];
          }
          let evento : CalendarEvent = {
            start: new Date(year, month , day, oraInizio, minutiInizio, 0),
            end: new Date(year, month , day, oraFine, minutiFine, 0),
            title: lezione.nome + ', Docente: ' + lezione.docente + ', dalle: ' + oraInizio + ':' + minutiInizio + ' alle: ' + oraFine + ':' + minutiFine,
            color: colors[i],
            actions: [
              {
                label: '<i class="material-icons notranslate" style="color: yellow;">edit</i>',
                cssClass: 'iconaMod',
                onClick: ({ event }: { event: CalendarEvent }): void => {
                    this.vaiModifica(lezione.idGruppo);
                },
              },{
                label: '<i class="material-icons notranslate">clear</i>',
                cssClass: 'icona',
                onClick: ({ event }: { event: CalendarEvent }): void => {
                    this.elimina(lezione.id,lezione.idGruppo);
                },
              },
            ],
          }
          if(this.events.length < 1){
            i++
          }
          let continua = true;
          this.events.forEach(eventoTrascorso => {
            if(continua){
            if(eventoTrascorso.title === evento.title){
              evento.color = eventoTrascorso.color;
              continua=false;
            }
          }})
          if(continua){
              if(i<7){
              i++
            }
            else{
              i=0
            }
          }
          this.events.push(evento);
        });
      });
      this.costante = this.events[0]?.start.getHours();
      this.costanteInizio = this.events[0]?.start.getHours();
      this.events.forEach(evento => {
        if(evento.start.getHours() < this.costante){
          this.costante = evento.start.getHours()
          this.costanteInizio = evento.start.getHours();
        }
      })
      this.events = this.events.sort((n1,n2) => {
        if (n1.start.getHours() > n2.start.getHours()) {
            return 1;
        }
        if (n1.start.getHours() < n2.start.getHours()) {
            return -1;
        }
        return 0;
    });
      this.refresh.next();
    })
  }

  inizializzaEventi(){
    this.events=[];
    let i = 0;
    this.classroomService.getLezioni(this.aulaInfo.idAula).subscribe((res) => {
      res.data.giorni.forEach(giorno => {
        let provaData = giorno.data.split(' ');
        let newData = provaData[0]+'T'+provaData[1];
        let date = new Date(newData);
        let year = date.getFullYear();
        let month = date.getMonth();
        let day = date.getDate();
        giorno.ore.forEach(lezione => {
          let arrInizio = lezione.start.split(':')
          let oraInizio;
          let minutiInizio;
          if(arrInizio[0].length < 2){
            oraInizio = '0' + arrInizio[0];
          }
          else {
            oraInizio = arrInizio[0];
          }
          if(arrInizio[1].length < 2){
            minutiInizio = '0' + arrInizio[1];
          }
          else {
            minutiInizio = arrInizio[1];
          }
          let arrFine= lezione.end.split(':')
          let oraFine;
          let minutiFine;
          if(arrFine[0].length < 2){
            oraFine = '0' + arrFine[0];
          }
          else {
            oraFine = arrFine[0];
          }
          if(arrFine[1].length < 2){
            minutiFine = '0' + arrFine[1];
          }
          else {
            minutiFine = arrFine[1];
          }
          let evento : CalendarEvent = {
            start: new Date(year, month , day, oraInizio, minutiInizio, 0),
            end: new Date(year, month , day, oraFine, minutiFine, 0),
            title: lezione.nome + ', Docente: ' + lezione.docente + ', dalle: ' + oraInizio + ':' + minutiInizio + ' alle: ' + oraFine + ':' + minutiFine,
            color: colors[i],
          }
          if(this.events.length < 1){
            i++
          }
          let continua = true;
          this.events.forEach(eventoTrascorso => {
            if(continua){
            if(eventoTrascorso.title === evento.title){
              evento.color = eventoTrascorso.color;
              continua=false;
            }
          }})
          if(continua){
              if(i<7){
              i++
            }
            else{
              i=0
            }
          }
          this.events.push(evento);
        });
      });
      this.costante = this.events[0]?.start.getHours();
      this.costanteInizio = this.events[0]?.start.getHours();
      this.events.forEach(evento => {
        if(evento.start.getHours() < this.costante){
          this.costante = evento.start.getHours()
          this.costanteInizio = evento.start.getHours();
        }
      })
      this.events = this.events.sort((n1,n2) => {
        if (n1.start.getHours() > n2.start.getHours()) {
            return 1;
        }
        if (n1.start.getHours() < n2.start.getHours()) {
            return -1;
        }
        return 0;
    });
      this.refresh.next();
    })
  }

  creaSchedule(){
    this.schedule=true;
    this.firstView =false;
  }

  indietroSchedule(){
    if(this.ruolo === 1 || this.ruolo == 4){
      this.inizializzaEventiAdmin();
    }
    else {
      this.inizializzaEventi();
    }
    this.schedule=false;
    this.scheduleMod=false;
    this.firstView =true;
  }

  elimina(lezioneId,lezioniGruppo){
    this.cancella=true
    this.lezioneId=lezioneId
    this.idLezioneGruppo=lezioniGruppo
  }

richiestaCancellazione(stato){
    //stato = 1 cancella singola
  //stato = 2 cancella tutto il gruppo
  //stato = 3 chiudi
if(stato==0){
  this.cancella=false;
}else{
  this.cancella=false;
  this.statoCancellazione=stato
    this.cancellaLezione=true;
    this.confirmationService.confirm({
      message: 'Sicuro di voler continuare? '+ '<br>' +' I dati verranno persi!',

      icon: 'pi pi-exclamation-triangle',

      reject: () => {
        this.cancellaLezione=false;
        this.loading=false;
        this.confirmationService.close();

      }
    });
}
}

accettaCancella(){
  this.loading=true;
  //stato = 1 cancella singola
  //stato = 2 cancella tutto il gruppo
  //stato = 3 chiudi
if( this.statoCancellazione==1){
  this.classroomService.eliminaLezione(this.lezioneId).subscribe((res)=>{
    this.inizializzaEventiAdmin();
    this.service.changeSchedule(true);
      this.cancellaLezione=false;
      this.loading=false;
      this.confirmationService.close();
      this.alert.success(res.status.description)
    })
}else if( this.statoCancellazione==2){
  this.classroomService.elimina_schedule_gruppo(this.idLezioneGruppo).subscribe((res)=>{
    this.inizializzaEventiAdmin();
    this.service.changeSchedule(true);
      this.cancellaLezione=false;
      this.loading=false;
      this.confirmationService.close();
      this.alert.success(res.status.description)
    })
}
}

vaiModifica(evt){
  this.idGruppoLezioni=evt
  this.scheduleMod = true;
  this.firstView =false;

}
responsiveWidth() {    }

}
