import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Sessione } from 'src/app/MODELS/ASSISTENZA/sessione';
import { Studente } from 'src/app/MODELS/CLASSROOM/studente';
import { Utente } from 'src/app/MODELS/USER/utente';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { DatePipe } from '@angular/common';
import { UIChart } from 'primeng/chart';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { AccountService, EnvService } from 'src/app/SERVICES/index';
import { lineBreak } from 'html2canvas/dist/types/css/property-descriptors/line-break';
import { environment } from 'src/environments/environment';
import { PrimeNGConfig } from 'primeng/api';
import { GoToClassroomService } from 'src/app/SERVICES/CLASSROOM/go-to-classroom.service';
import { env } from 'process';


@Component({
  selector: 'app-report-aula',
  templateUrl: './report-aula.component.html',
  styleUrls: ['./report-aula.component.scss']
})
export class ReportAulaComponent implements OnInit {
@ViewChild('chart') chart: UIChart;
@ViewChildren("gruppi") chartsGruppi: QueryList<ElementRef>;
@ViewChildren("entrate") chartsEntrate: QueryList<ElementRef>;
@ViewChildren("durata") chartsDurata: QueryList<ElementRef>;
@ViewChildren("nrDownload") chartsNrDownload: QueryList<ElementRef>;
@ViewChildren("risposte") chartsRisposte: QueryList<ElementRef>;
@ViewChildren("video") chartsVideo: QueryList<ElementRef>;
@ViewChildren("sondaggi") chartsSondaggi: QueryList<ElementRef>;

  hoveredDate: Date | null = null;
  tutti;
  fromDate;
  newDate: Date;
  toDate;
  dayDate: Date;
  cols: any[];
  aule: any[];
  allAule: any[];
  searchform: FormGroup;
  selectedAule: any[] = [];
  selezionaAula: boolean = true;
  auleSelezionate: any[];
  idAuleSelezionate: any[];
  reportAula;
  today = new Date();
  oggi;
  dd = String(this.today.getDate()).padStart(2, '0');
  mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  yyyy = this.today.getFullYear();
  todayDay = this.dd;
  todayMonth = this.mm;
  todayYear = this.yyyy;
  calendar: FormGroup;
  toDay;
  toMonth;
  toYear;
  fromDay;
  fromMonth;
  fromYear;
  dataOK: boolean = true;
  value1: Date;
  value2: Date;
  dates: Date[];
  rangeDates: Date[];
  date1: string;
  report: boolean = false;
  loading = false;
  ita;
  listaVuota = true;
  
  optionsEntrate2:any
optionsEntrate:any
optionsDurata2:any
optionsDurata:any
optionsNrRisposte2:any
optionsNrRisposte:any
optionsDownload:any
optionsDownload2:any
// --------------aggiunte-------------
caricato=false;
sceltaDate = false;
inizio;
fine;
inizioPipe;
finePipe;
arrayColori = ["#FFCE56", "#bce08c", "#6891C3", "#AAC5E2", "#FF6384", "#36A2EB", "#E0BBE4", "#DAE3D9", "#F4ADC6", "#FDFD95"]
listaAuleSelezionate;
date: false;
GraficoObject;
grafici = false;
dataGroup: any;
arrayLableGroup;
arrayDataGroup;
// ------------------
dataGraficoEntrate: any;
dataGraficoEntrate2: any;
graficoEntrateLable= [];
graficoEntrateData = [];
graficoDate=[];
// --------------------
dataGraficoDurata: any;
graficoDurataLable = [];
graficoDurataData = [];
//---------------------
dataDownloadEs: any;
graficoDownloadEsLable = [];
graficoDownloadEsData = [];
//---------------------
dataNrrisposteEs: any;
graficoNrrisposteEsLable = [];
graficoNrrisposteEsData = [];
//---------------------
dataRelatori: any;
graficoRelatoriLable = [];
graficoRelatoriData = [];
//---------------------
dataSondaggio:any;
graficoSondaggioLable = [];
graficoSondaggioData = [];
//---------------------
dataVideoAsincroni:any;
graficoVideoAsincorniLable=[]
graficoVideoAsincorniLables=[]
graficoVideoAsincorniData=[]
nomeVideo
//---------------------
mostraChartGruppi =false;
graficoEntrate =false;
graficoDurata =false;
graficoDownloadEsercitazioni =false;
graficoEsercitazioniNrRisposte =false;
graficoRelatori =false;
graficoSondaggio=false;
graficoVideoAsincorno=false;
//--------------------
nomeMostraChartGruppi;
nomeGraficoEntrate;
NomeGraficoDurata;
NomeGraficoDwEs;
NomeGraficoEsNrRisposte;
NomeGraficoRelatori;
nomeGraficoSondaggio;
nomeGraficoVideoAsincorni;
//--------------------
graficoLezioniAsincroneWidth;
optionsVideo:any;
optionsVideo2:any;
optionsDwEs:any;
optionsDwEs2:any;
listaVideo=[]
idAula;
listaSondaggio=[]
accendi=false;
ambiente;
alfa=false;
usedClassroomService;

gruppiBianco =false;
entrateBianco=false;
durataBianco =false;
downloadBianco =false;
risposteBianco =false;
videoBianco =false;
sondaggiBianco =false;
videoOn
heightListaAule 
  constructor(private classroomService: ClassroomService, private formBuilder: FormBuilder,
    private gotoClassroomService: GoToClassroomService,
    private accountService: AccountService,
    private router: Router,
    private alertService: AlertService,
    private fileService: FileService,
    private excelService: ExportExcelService,
    private datePipe : DatePipe,
    private envService : EnvService,
    private config: PrimeNGConfig
    ) {
      this.ambiente = environment;
      this.alfa=envService.alfaintes;
      this.videoOn = envService.RECORDING
   
  }

  ngOnInit() {

  
    this.config.setTranslation({
      accept: 'Accept',
        dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
        dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
        dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
        monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
        monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
        today: 'Today',
        clear: 'Clear',
        // dateFormat: 'dd/mm/yy',
        weekHeader: 'Wk'
      
      //translations
      
  });
 

    this.buildForm();
    if(this.envService.goTo)
    {
      
      this.gotoClassroomService.getListaAule().subscribe((res) => {
        if(res.data.content) {
          if (res.data.content.length > 0) {
            this.listaVuota = false;
          }
          this.aule = res.data.content;
        } else {
          if (res.data.length > 0) {
            this.listaVuota = false;
          }
          this.aule = res.data;
        }
      
        this.allAule = this.aule;
        this.caricato=true;
        this.report = true;
      }, (error) => {
      });
    }
    else
    {
      
      this.classroomService.lista_classroom_leggera().subscribe((res) => {
        if(res.data.content) {
          if (res.data.content.length > 0) {
            this.listaVuota = false;
          }
          this.aule = res.data.content;
        } else {
          if (res.data.length > 0) {
            this.listaVuota = false;
          }
          this.aule = res.data;
        }
      
        this.allAule = this.aule;
        this.caricato=true;
        this.report = true;
      }, (error) => {
      });
    }


    this.cols = [
      { field: 'nomeAula', header: 'Titolo' },
      { field: 'descrizioneAula', header: 'Descrizione' }
    ];

    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })

  }
  buildForm(): void {
    this.calendar = this.formBuilder.group({

      calendario: new FormControl('', Validators.required),

    });
  }
  selectAll() { }

  searchInAula() {
    let valore: string = this.searchform.value.search;
    this.aule = this.allAule;
    if (valore) {
      let aule = []
      this.aule.forEach(aula => {
        if (aula.nomeAula.toLowerCase().includes(valore.toLowerCase()) || aula.descrizioneAula.toLowerCase().includes(valore.toLowerCase())) {
          if (!(aule.find(x => x === aula))) {
            aule.push(aula);
          }
        }
      });
      this.aule = aule;
    } else if (this.aule !== this.allAule) {
      this.aule = this.allAule;
    }
  }

  avanti() {
    this.alertService.clear();
    if (this.selectedAule.length > 0) {
      this.auleSelezionate = this.selectedAule;
      this.sceltaDate =true;
      this.selezionaAula = false;
    }
    else {
      this.alertService.error("Seleziona uno o più webinar");
    }
  }

  indietro() {
    this.selezionaAula = true;
    this.sceltaDate =false;
    this.grafici=false;
    this.selectedAule = [];
  }
  selezionaData() {


    if (this.rangeDates !== undefined && this.rangeDates[0] !== null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.fromDay = dd;
      this.fromMonth = mm;
      this.fromYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] !== null) {
      var dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[1].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] === null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates === undefined) {
      this.fromDay = "01";
      this.fromMonth = "01";
      this.fromYear = "1990";

      this.toDay = this.todayDay;
      this.toMonth = this.todayMonth;
      this.toYear = this.todayYear;
    }


  }

  exportExcelAula() {

    let excelscaricato = false;
    this.reportAula.data.forEach(aula => {
      let title;
      let usersAula = new Array;
      aula.forEach(studente => {
        title = studente.classroom.nomeAula;
        let utente: Studente = new Studente;
        utente.nome = studente.studente.nome;
        utente.cognome = studente.studente.cognome;
        utente.entrata = this.datePipe.transform(studente.entrata, 'dd/MM/yy HH:mm:ss') ;
        utente.uscita = this.datePipe.transform(studente.uscita, 'dd/MM/yy HH:mm:ss') ;
        utente.durata = studente.ore;
        utente.maniAlzate = studente.maniAlzate;
        utente.inattivita = studente.inattivita;
        if(this.alfa){
        utente.Informatore = studente.studente.afi?.isf;
        utente.Struttura = studente.studente.afi?.struttura;
      }
        usersAula.push(utente)
      });
      if (usersAula.length > 0) {
        excelscaricato = true;
        this.exportToExcel(usersAula, title);
        usersAula = new Array;
      }
    });
    this.loading = false;
    if (!excelscaricato) {
      this.alertService.info('Nessun report presente per le date selezionate');
    }
    else{
      if(this.accountService.ruolo === 1 || this.accountService.ruolo === 4){
      this.chiamaServizi()
      this.opzioniGrafici()
      this.sceltaDate=false;
      this.grafici=true;
      }
    }
  }


  inviaDati() {
    this.loading = true;
    this.selezionaData();
    this.idAuleSelezionate = [];
    this.selectedAule.forEach(aula =>{
      this.idAuleSelezionate.push(aula.id)
    })
    this.fileService.inviaDatiReportAula(this.idAuleSelezionate, this.fromDay, this.fromMonth, this.fromYear, this.toDay, this.toMonth, this.toYear).subscribe((res) => {
      this.reportAula = res;
      this.exportExcelAula();
    },
      error => this.loading = false);
  }

  dataForExcel = [];


  exportToExcel(usersAula, title) {
    this.dataForExcel = [];
    usersAula.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: 'Report '+ this.ambiente.NOMEAULA +': ' + title,
      data: this.dataForExcel,
      headers: Object.keys(usersAula[0])
    }

    this.excelService.exportExcel(reportData);
  }



  
// 1.	Partecipazione Gruppi Fatto
// Grafico a torta che mostra quali gruppi hanno partecipato di più ad una determinata aula
// in un determinato lasso di tempo

// 2.	Report Entrate Fatto
// Grafico a linee che mostra il numero di entrate degli utenti in una determinata aula
// in un determinato lasso di tempo

// 3.	Durata partecipazione  Fatto
// Grafico a barre che mostra la durata di partecipazione di ogni singolo utente 
// in una determinata aula in un determinato lasso di tempo

// 4.	Download Esercitazioni Fatto
// Grafico a linee che mostra la quantità di materiale scaricato da una determinata aula 
// in un determinato lasso di tempo

// 5.	Risposte Esercitazioni Fatto
// Grafico a linee che mostra la quantità di materiale uploadato in risposta 
// in una determinata aula in un determinato lasso di tempo

// 6.	Visualizzazione lezioni asincrone
// Grafico a barre che mostra la durata di visualizzazione delle lezioni registrate di ogni singolo utente 
// in una determinata aula in un determinato lasso di tempo

// 7.	Risposte ai sondaggi 
// Grafico a barre che mostra la quantità di “sì” e di “no” ottenuti 
// in risposta ad un determinato sondaggio, in una determinata aula in un determinato lasso di tempo

 async chiamaServizi(){

  this.scegliServizioClassroom();

  this.listaAuleSelezionate=this.selectedAule;

  // this.idAuleSelezionate = new Array;

  this.inizio = this.fromYear+":"+this.fromMonth+":"+this.fromDay
  this.fine = this.toYear+":"+this.toMonth+":"+this.toDay
  this.inizioPipe = new Date(this.fromYear,this.fromMonth-1,this.fromDay)
  this.finePipe=  new Date(this.toYear,this.toMonth-1,this.toDay)
  let id=this.selectedAule[0].id
  this.creaGraficogruppi(id,this.selectedAule[0].nomeAula);
  this.creaGraficoEntrate(id,this.selectedAule[0].nomeAula);
  this.creaGraficoDurata(id,this.selectedAule[0].nomeAula);
  this.creaGraficoDwEsercitazioni(id,this.selectedAule[0].nomeAula);
  this.creaGraficoNrRisposte(id,this.selectedAule[0].nomeAula);
  //this.creaGraficoRelatori(id,this.selectedAule[0].nomeAula);


    await this.getListaSondaggio(id,this.selectedAule[0].nomeAula)
    if(this.listaSondaggio.length>0){
    this.graficoSondaggi(this.listaSondaggio[0].idAula,this.listaSondaggio[0].titolo)
  }else{
    this.graficoSondaggiNo()
    this.nomeGraficoSondaggio="Nessun Risultato";
  }
  await this.getListaVideo(id,this.selectedAule[0].nomeAula)
  if(this.listaVideo.length>0) {
    this.graficoLezioniAsincrone(this.listaVideo[0]);
  }else{
    this.nomeVideo="Nessun Risultato";
    this.graficoLezioniAsincroneNo()
  }
  setTimeout(() => {
     this.accendi=true;
  }, 3000);
   
}

  creaGraficogruppi(id,nomeAula){
    this.nomeMostraChartGruppi=nomeAula;
    let graficoObject = {
      inizio:  this.inizio,
      fine:  this.fine ,
      id:id
    }

    if(this.envService.goTo)
      {

        this.gotoClassroomService.GraficoGruppi(graficoObject).subscribe((res) => {
          
          this.arrayDataGroup=[];
          this.arrayLableGroup=[];
      
      if(res.data.length>0){
      res.data.forEach(element => {
        this.arrayDataGroup.push(element.valore);
          this.arrayLableGroup.push(element.nome);
      });
        this.graficoGroup(); 
        
      }else{
        this.graficoNoGroup()
      }
      
        }) 
      
      }
    else
    {
      this.classroomService.GraficoGruppi(graficoObject).subscribe((res) => {
          
        this.arrayDataGroup=[];
        this.arrayLableGroup=[];
    
    if(res.data.length>0){
    res.data.forEach(element => {
      this.arrayDataGroup.push(element.valore);
        this.arrayLableGroup.push(element.nome);
    });
      this.graficoGroup(); 
      
    }else{
      this.graficoNoGroup()
    }
    
      }) 
    }
  }

  widthGraficoEntrate;
 creaGraficoEntrate(id,nomeAula){
   this.nomeGraficoEntrate=nomeAula;
  let graficoObject = {
    inizio:  this.inizio,
    fine:  this.fine ,
    id:id
  }


  if(this.envService.goTo)
  {
    this.gotoClassroomService.GraficoEntrate(graficoObject).subscribe((res) => {
      this.graficoDate=[];
      this.graficoEntrateData=[];
      this.graficoEntrateLable=[];
      if(res.data.length>0){
    res.data.forEach(element => {
     this.graficoEntrateLable.push(element.giorno);
     this.graficoEntrateData.push(element.totEntrate)
    })
  
  
  this.widthGraficoEntrate=this.changeWidth(this.arrayDataGroup)
  this.graficoEntrateSi();
       }else{
        this.graficoEntrateNo();
       }
  
    })
  }
  else
  {
    this.classroomService.GraficoEntrate(graficoObject).subscribe((res) => {
      this.graficoDate=[];
      this.graficoEntrateData=[];
      this.graficoEntrateLable=[];
      if(res.data.length>0){
    res.data.forEach(element => {
     this.graficoEntrateLable.push(element.giorno);
     this.graficoEntrateData.push(element.totEntrate)
    })
  
  
    this.widthGraficoEntrate=this.changeWidth(this.arrayDataGroup)
    this.graficoEntrateSi();
        }else{
          this.graficoEntrateNo();
        }
    
      })
  }
}
graficoDurataWeight
creaGraficoDurata(id,nomeAula){
  this.NomeGraficoDurata=nomeAula;
  this.graficoDurataLable=[];
  this.graficoDurataData=[];
  let arrayAppoggio=[]
  let graficoObject = {
    inizio:  this.inizio,
    fine:  this.fine ,
    id:id
  }

  if(this.envService.goTo)
  {
      this.gotoClassroomService.GraficoDurata(graficoObject).subscribe((res) => {
          
          if(res.data){
            arrayAppoggio =res.data
        arrayAppoggio= arrayAppoggio.sort((a, b) => a.nome.length - b.nome.length);
        arrayAppoggio.forEach(element => {
            this.graficoDurataLable.push(element.nome)
            this.graficoDurataData.push(this.transformatoreMilli(element.tempo));
          })  
    
      

      this.GraficoDurataSi();
      this.graficoDurataWeight  = this.changeWidth(this.graficoDurataData)
      }else{
        this.GraficoDurataNo();
      }
      })
  }
  else
  {
    this.classroomService.GraficoDurata(graficoObject).subscribe((res) => {
          
      if(res.data){
        arrayAppoggio =res.data
    arrayAppoggio= arrayAppoggio.sort((a, b) => a.nome.length - b.nome.length);
    arrayAppoggio.forEach(element => {
        this.graficoDurataLable.push(element.nome)
        this.graficoDurataData.push(this.transformatoreMilli(element.tempo));
      })  

      

    this.GraficoDurataSi();
    this.graficoDurataWeight  = this.changeWidth(this.graficoDurataData)
    }else{
      this.GraficoDurataNo();
    }
    })
  }
}
graficoDwEsercitazioniWeight
  creaGraficoDwEsercitazioni(id,nomeAula){
    this.NomeGraficoDwEs=nomeAula;
    this.graficoDownloadEsLable=[];
    this.graficoDownloadEsData=[];
    let graficoObject = {
      inizio:  this.inizio,
      fine:  this.fine ,
      id:id
    }
  this.usedClassroomService.GraficoDownloadEsercitazioni(graficoObject).subscribe((res) => {
    if(res.data.length>0){
res.data.forEach(element => {
  let nome:string=element.nome.toString();
if(nome.length>10 ){
  if(nome.split(" ")){
    let arry=nome.split(" ")
    if(arry[3]){
      this.graficoDownloadEsLable.push(arry[0]+" "+arry[3])
    }else{
  this.graficoDownloadEsLable.push(arry[0]);
}}
}else{
    this.graficoDownloadEsLable.push(element.nome)
}


  this.graficoDownloadEsData.push(element.valore)
});
this.GraficoDownloadEsercitazioniSi()
this.graficoDwEsercitazioniWeight  = this.changeWidth(this.graficoDownloadEsData)
     }else{
      this.GraficoDownloadEsercitazioniNo()
     }
 
  })}

  graficoEsNrRisposteWeight  
  creaGraficoNrRisposte(id,NomeAula){
    this.NomeGraficoEsNrRisposte=NomeAula;
    this.graficoNrrisposteEsLable=[];
    this.graficoNrrisposteEsData=[];
    let arrayAppoggio = []
    let graficoObject = {
      inizio:  this.inizio,
      fine:  this.fine ,
      id:id
    }
    this.usedClassroomService.GraficoEsercitazioniNrRisposte(graficoObject).subscribe((res) => {
      this.graficoNrrisposteEsData=[];
      this.graficoDownloadEsLable=[];
      if(res.data.length>0){
        arrayAppoggio=res.data
        arrayAppoggio= arrayAppoggio.sort((a, b) => a.nome.length - b.nome.length);
        arrayAppoggio.forEach(element => {
        this.graficoNrrisposteEsLable.push(element.nome)
        this.graficoNrrisposteEsData.push(element.valore)
      });
  
      this.GraficoNrRisposteEsSi();
      this.graficoEsNrRisposteWeight  = this.changeWidth(this.graficoNrrisposteEsData)
       }else{
        this.GraficoNrRisposteEsNo();
       }
  
    })
 
}
creaGraficoRelatori(id,NomeAula){ //TODO: Grafico inutilizzato nell'HTML.
  this.NomeGraficoRelatori=NomeAula;
  this.graficoRelatoriLable=[];
this.graficoRelatoriData=[];
  let graficoObject = {
    inizio:  this.inizio,
    fine:  this.fine ,
    id:id
  }
  this.classroomService.graficoRelatori(graficoObject).subscribe((res) => {
    if(res.data.length>0){
     res.data.forEach(element => {
this.graficoRelatoriLable.push(element.nome);
this.graficoRelatoriData.push(element.numeroOre);
     });
 this.GraficoRelatoriSi();
     }else{
     this.GraficoRelatoriNo();
     }

  })
 }
// ---------------------GraficoGruppi
  graficoGroup(){
    this.dataGroup = {
      labels: this.arrayLableGroup,
      datasets: [
          {
              data: this.arrayDataGroup,
              backgroundColor: this.arrayColori,
              hoverBackgroundColor: this.arrayColori
          }]    
      };
      this.mostraChartGruppi=true;
}

  graficoNoGroup(){
    this.dataGroup = {
      labels: ["nessun Dato Trovato"],
      datasets: [
          {
              data: [1],
              backgroundColor: this.arrayColori[0],
              hoverBackgroundColor: this.arrayColori[0]
          }]    
      };
      this.mostraChartGruppi=true;
}
// -----------------------Grafico entrata
 graficoEntrateSi(){
    this.dataGraficoEntrate = {
        labels: this.graficoEntrateLable,
        datasets: [
            {
                label: "Grafiche Entrate",
                data: this.graficoEntrateData,
                fill: false,
                borderColor: '#bce08c'
            },
         
        ]
    }
    this.dataGraficoEntrate2 = JSON.parse(JSON.stringify(this.dataGraficoEntrate));
    setTimeout(() => {
      for (const dataset of this.dataGraficoEntrate2.datasets) {
        dataset.hidden = true;
      }
    }, 100);
 
this.graficoEntrate=true;
  }

  graficoEntrateNo(){
    this.dataGraficoEntrate = {
      labels: "Nessun risultato",
      datasets: [
          {
              label: 'Nessun risultato',
              data: [1],
              fill: false,
              borderColor: '#bce08c'
          }
      ]
  }
    this.graficoEntrate=true;
  }

  //------------------------Grafico Durata
  GraficoDurataSi(){
    this.dataGraficoDurata = {
      labels:  this.graficoDurataLable,
      datasets: [
         
          {
              label: 'Partecipazioni in Aula',
              backgroundColor: '#6891C3',
              data: this.graficoDurataData
          }
      ]
  };
  this.graficoDurata=true;
  }
 GraficoDurataNo(){
  this.dataGraficoDurata = {
    labels:  "Nessun risultato",
    datasets: [
       
        {
            label: 'Nessun risultato ',
            backgroundColor: '#6891C3',
            data: 0
        }
    ]
};
this.graficoDurata=true;
  }
//------------------Grafico Esercitazioni
  GraficoDownloadEsercitazioniSi(){
    this.dataDownloadEs = {
      labels:  this.graficoDownloadEsLable,
      datasets: [
          {
              label: 'Quantità Download per Esercitazione',
              backgroundColor: '#36A2EB',
              data: this.graficoDownloadEsData
          }
      ]
  };
  this.graficoDownloadEsercitazioni=true;
  }

  GraficoDownloadEsercitazioniNo(){
  this.dataDownloadEs = {
    labels:  ['Nessun risultato'],
    datasets: [
       
        {
            label: 'Nessun risultato',
            backgroundColor: '#36A2EB',
            data: 0
        }
    ]
};
this.graficoDownloadEsercitazioni=true;
  }
//------GraficoNrRisposte
  GraficoNrRisposteEsSi(){
    this.dataNrrisposteEs = {
      labels: this.graficoNrrisposteEsLable,
      datasets: [
         
          {
              label: 'Quantità di Risposte per Esercitazione',
              backgroundColor: '#AAC5E2',
              data: this.graficoNrrisposteEsData
          }
      ]
  };
  this.graficoEsercitazioniNrRisposte=true;
  }
  GraficoNrRisposteEsNo(){
  this.dataNrrisposteEs = {
    labels:  ['Nessun risultato'],
    datasets: [
       
        {
            label: 'Report Presenza Ore in classe',
            backgroundColor: '#AAC5E2',
            data: 0
        }
    ]
};
this.graficoEsercitazioniNrRisposte=true;
  }
//------GraficoNrRisposte
GraficoRelatoriSi(){
  this.dataRelatori = {
    labels:  this.graficoRelatoriLable,
    datasets: [
       
        {
            label: 'Report Relatori',
            backgroundColor: '#E0BBE4',
            data: this.graficoNrrisposteEsData
        }
    ]
};
this.graficoRelatori=true;
}
GraficoRelatoriNo(){
this.dataRelatori = {
  labels:  ['Nessun risultato'],
  datasets: [
     
      {
          label: 'Report Presenza Ore in classe',
          backgroundColor: '#E0BBE4',
          data: 0
      }
  ]
};
this.graficoRelatori=true;
}


indietroGrafici(){
  this.grafici=false;
  this.sceltaDate=true;
}

graficoLezioniAsincrone(video){
  this.graficoVideoAsincorniData = [];
   this.graficoVideoAsincorniLable =[];
   this.nomeVideo=video.title;
  let array=[]
  let graficoObject = {
    inizio:  this.inizio,
    fine:  this.fine,
    id:this.idAula
  }
  this.classroomService.graficoVideo(video.id,graficoObject).subscribe((res) => {
      if(res.data.listaUtenti.length > 0){
    
        array=res.data.listaUtenti
        this.graficoLezioniAsincroneWidth = this.changeWidth(array);
     
        array= array.sort((a, b) => (a.nome.length +a.cognome.lenght) - (b.nome.length + b.nome.lenght));

        array.forEach(element => { 
   
  
   this.graficoVideoAsincorniData.push(element.tempo);
   this.graficoVideoAsincorniLable.push(element.nome+" "+element.cognome);
        });

    this.graficoLezioniAsincroneSi();
        }else{
        this.graficoLezioniAsincroneNo();
        }
    });
  }

graficoLezioniAsincroneSi(){
  this.dataVideoAsincroni = {
    labels:  this.graficoVideoAsincorniLable,
    datasets: [
        {
            label:"Visualizzazione espressa in Minuti",
            backgroundColor: '#E0BBE4',
            data: this.graficoVideoAsincorniData
        }
    ]
};
this.graficoVideoAsincorno=true;
}

graficoLezioniAsincroneNo(){

  this.dataVideoAsincroni = {
    labels:  ['Nessun risultato'],
    datasets: [
        {
            label: 'Report Sondaggi in classe',
            backgroundColor: '#E0BBE4',
            data: 0
        }
    ]
  };
  this.graficoVideoAsincorno=true;
  }


scegliServizioClassroom()
{
  if(this.envService.goTo)
  {
    this.usedClassroomService = this.gotoClassroomService;
  }
  else
  {
    this.usedClassroomService = this.classroomService;
  }
}

graficoSondaggi(idAula,nomeSondaggio){
  this.nomeGraficoSondaggio=nomeSondaggio;
  this.graficoSondaggioLable=[];
  this.graficoSondaggioData=[];
  let graficoObject = {
    inizio:  this.inizio,
    fine:  this.fine,
    id:idAula
  }
  this.usedClassroomService.grafico_risposte_sondaggio_aula(nomeSondaggio,graficoObject).subscribe((res) => {
    if(res.data){ 
      this.graficoSondaggioLable.push("si");
      this.graficoSondaggioLable.push("no");
      this.graficoSondaggioData.push(res.data.totaleSi)
      this.graficoSondaggioData.push(res.data.totaleNo);
      this.graficoSondaggiSi();
     }else{
     this.graficoSondaggiNo();
     }
  })
}

graficoSondaggiSi(){
  this.dataSondaggio = {
    labels:  this.graficoSondaggioLable,
    datasets: [
        {
            label: 'Report Sondaggi',
            backgroundColor: '#E0BBE4',
            data: this.graficoSondaggioData
        }
    ]
};
this.graficoSondaggio=true;
}

graficoSondaggiNo(){

  this.dataSondaggio = {
  labels:  ['Nessun risultato'],
  datasets: [
      {
          label: 'Report Sondaggi',
          backgroundColor: '#E0BBE4',
          data: 0
      }
  ]
};
this.graficoSondaggio=true;
}


changeWidth(list: any[] = []): number {
  const startWidth = 2000;
  return (list && list.length > 48) ? startWidth * Math.trunc(list.length  % 48 !== 0 ? list.length / 48 + 1 : list.length / 48) : null;
}


reloadOptionsEntrate(){
  this.optionsEntrate={
    scales: {
      yAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
          stepSize: 30
        }
      }],
      xAxes: [{
        ticks: {
        stepSize: '60',
        callback: function(value, index, values) {
          if(value.length > 10){
            return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
          }else{
            return value
          }
        }
        },
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
     
      }
    }
  }
}
reloadOptionsDurata(){
  this.optionsDurata={
    scales: {
      yAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
        }
      }],
      xAxes: [{
        ticks: {
      callback: function(value, index, values) {
         
          if(value.length > 10){
  
            return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
          }else{
            return value
          }
        }
        },
  
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
     
      callbacks: {
  afterLabel:function(tooltipItems,data){
  
  let r:string=data.datasets[0].data;
  return " minuti";
  }
      }
    }
  }
}
reloadOptionsNrRisposte(){
  this.optionsNrRisposte = {
    scales: {
      yAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
        }
      }],
      xAxes: [{
        ticks: {
  
        stepSize: '60',
        callback: function(value, index, values) {
         
          if(value.length > 10){
  
            return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
          }else{
            return value
          }
        }
         
        },
  
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
      callbacks: {
     
      }
    }
  
  }
}
reloadOptionsNrDownoald(){
  this.optionsDownload={
    scales: {
      yAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
        }
      }],
      xAxes: [{
        ticks: {
      callback: function(value, index, values) {
         
          if(value.length > 10){
  
            return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
          }else{
            return value
          }
        }
        },
  
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
     
      callbacks: {
  afterLabel:function(tooltipItems,data){
  
  let r:string=data.datasets[0].data;
  return "";
  }
      }
    }
  }
}
reloadOptionsNrVideo(){
  this.optionsVideo={
    scales: {
      
      yAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
          stepSize: 30

        }
  
      }],
      xAxes: [{
        ticks: {
      callback: function(value, index, values) {
         
          if(value.length > 10){
  
            return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
          }else{
            return value
          }
        }
        },
  
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
     
      callbacks: {
  afterLabel:function(tooltipItems,data){
  
  let r:string=data.datasets[0].data;
  return " minuti";
  }
      }
    }
  }
}
opzioniGrafici(){
  this.optionsDownload={
    scales: {
      yAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
        }
      }],
      xAxes: [{
        ticks: {
      callback: function(value, index, values) {
         
          if(value.length > 10){
  
            return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
          }else{
            return value
          }
        }
        },
  
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
     
      callbacks: {
  afterLabel:function(tooltipItems,data){
  
  let r:string=data.datasets[0].data;
  return " ";
  }
      }
    }
  }
  this.optionsDownload2={
    scales: {
      fontColor:'transparent',
      yAxes: [{
      
      }],
      xAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
        },
        gridLines: {
          drawBorder: false,
          display: true
        }
      }]
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  }

  this.optionsDurata={
    scales: {
      yAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
        }
      }],
      xAxes: [{
        ticks: {
      callback: function(value, index, values) {
         
          if(value.length > 10){
  
            return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
          }else{
            return value
          }
        }
        },
  
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
     
      callbacks: {
  afterLabel:function(tooltipItems,data){
  
  let r:string=data.datasets[0].data;
  return " minuti";
  }
      }
    }
  }
  this.optionsDurata2={
    scales: {
      fontColor:'transparent',
      yAxes: [{
      
      }],
      xAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
        },
        gridLines: {
          drawBorder: false,
          display: true
        }
      }]
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  }
  this.optionsEntrate={
    scales: {
      yAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
       
        }
      }],
      xAxes: [{
        ticks: {
        callback: function(value, index, values) {
          if(value.length > 10){
            return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
          }else{
            return value
          }
        }
        },
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
     
      }
    }
  }
  this.optionsEntrate2={
    scales: {
      fontColor:'transparent',
      yAxes: [{
      }],
      xAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
            // Include a dollar sign in the ticks
         
        },
        gridLines: { 
          drawBorder: false,
          display: true
        }
      }]
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  }

  this.optionsNrRisposte2 = {
    scales: {
      fontColor:'transparent',
      yAxes: [{
      }],
      xAxes: [{
        fontColor: 'transparent',
        ticks: {
          fontColor: 'transparent',
        },
        gridLines: { 
          drawBorder: false,
          display: true
        }
      }]
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  }

this.optionsNrRisposte = {
  scales: {
    yAxes: [{
      fontColor: 'transparent',
      ticks: {
        fontColor: 'transparent',
      }
    }],
    xAxes: [{
      ticks: {
      stepSize: '60',
      callback: function(value, index, values) {
       
        if(value.length > 10){

          return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
        }else{
          return value
        }
      }
       
      },

    }],
  },
  legend: {
    display: false
  },
  tooltips: {
    enabled: true,
    callbacks: {
   
    }
  }

}

this.optionsVideo={
  scales: {
    
    yAxes: [{
      fontColor: 'transparent',
      ticks: {
        fontColor: 'transparent',
      }

    }],
    xAxes: [{
      ticks: {
    callback: function(value, index, values) {
       
        if(value.length > 10){

          return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
        }else{
          return value
        }
      }
      },

    }],
  },
  legend: {
    display: false
  },
  tooltips: {
    enabled: true,
   
    callbacks: {
afterLabel:function(tooltipItems,data){

let r:string=data.datasets[0].data;
return " minuti";
}
    }
  }
}
this.optionsVideo2={
  scales: {
    fontColor:'transparent',
    yAxes: [{
    
    }],
    xAxes: [{
      fontColor: 'transparent',
      ticks: {
        fontColor: 'transparent',
      },
      gridLines: {
        drawBorder: false,
        display: true
      }
    }]
  },
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  }
}
//------------------------
this.optionsDwEs={
  scales: {
    
    yAxes: [{
      fontColor: 'transparent',
      ticks: {
        fontColor: 'transparent',

    //     callback: function(label:number,index,labels){
    //       let r :any = label
    //       let labelString:string =label.toString();
    //       if(labelString.split(".")){
    //   let arrayString= labelString.split(".")
    //   if(arrayString[0] && arrayString[1]){
    //   r=arrayString[0]+":"+arrayString[1]+"0"
    //   }}
    //  return r;
    //     }
      }
        // scaleLabel:{
        // display:false,
        // labelString: this.graficoVideoAsincorniData
        // }
    }],
    xAxes: [{
      ticks: {
    callback: function(value, index, values) {
       
        if(value.length > 10){

          return value[0]+value[1]+value[2]+value[3]+value[4]+value[5]+value[6]+value[7]+value[8]+value[9]+"...";
        }else{
          return value
        }
      }
      },

    }],
  },
  legend: {
    display: false
  },
  tooltips: {
    enabled: true,
   
    callbacks: {
afterLabel:function(tooltipItems,data){

let r:string=data.datasets[0].data;
return " minuti";
}
    }
  }
}
this.optionsDwEs2={
  scales: {
    fontColor:'transparent',
    yAxes: [{
    
    }],
    xAxes: [{
      fontColor: 'transparent',
      ticks: {
        fontColor: 'transparent',
      },
      gridLines: {
        drawBorder: false,
        display: true
      }
    }]
  },
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  }
}
  }

transformatoreMilli(value){
  let risultato
  // if (value) {
    risultato=value/1000/60
  //     var ore = '' + Math.floor(value / 3600);
  //     var minuti = '' + Math.floor(value % 3600 / 60);
  //     var secondi = '' + Math.floor(value % 3600 % 60);
     
  //     if (Number(ore) < 10) {
  //       ore = '0' + ore;
  //     } else {
  //       ore = '' + ore;
  //     }
  //     if (Number(minuti) < 10) {
  //       minuti = '0' + minuti;
  //     } else {
  //       minuti = '' + minuti;
  //     }
  //     if (Number(secondi) < 10) {
  //       secondi = '0' + secondi;
  //     } else {
  //       secondi = '' + secondi;
  //     }
    
  //     risultato = ore + '.' + minuti;
  // }
  return risultato;
}


//TODO Riabilitare
async getListaVideo(id,nomeAula){
  return new Promise((resolve, reject) => {
  this.idAula=id;
  this.nomeGraficoVideoAsincorni=nomeAula;
//   this.classroomService.getListaFile(id).subscribe((res)=>{
// this.listaVideo=res.data.risultati
// if(this.listaVideo.length <1){
//   this.nomeVideo="Nessun Risultato"
// }
// resolve(this.listaVideo)  
// },error=>{reject(error)})
})
}


async getListaSondaggio(id,nomeAula){
  return new Promise((resolve, reject) => {
  this.idAula=id;

  this.classroomService.getSondaggio(id).subscribe((res)=>{
this.listaSondaggio=res.data
if(this.listaSondaggio.length <1){
this.nomeGraficoSondaggio ="NessunRisultato"
}
resolve(this.listaSondaggio)  
},error=>{reject(error)})
})
}

scegliDownload(valore) {
  if(valore === 'gruppi'){
    this.gruppiBianco=true;
    setTimeout(() => {  
      this.chartsGruppi.forEach(element =>{
        this.downloadIMG(element['chart'].canvas, 'gruppi')
      })
      
      }, 1000);
       
      setTimeout(() => {  
        this.opzioniGrafici()  
        this.gruppiBianco=false;
      }, 1000);
  }
  if(valore === 'entrate'){
    this.entrateBianco=true;
    this.optionsEntrate = {
      scales: {
        yAxes: [{
          ticks: {
            stepSize: 30
          }
        }],
        xAxes: [{
          ticks: {
          stepSize: '60',
          },
        }],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
        }
      }
    }
    setTimeout(() => {  
      this.chartsEntrate.forEach(element =>{
        this.downloadIMG(element['chart'].canvas, 'entrate')
      })
      
      }, 1000);
       
      setTimeout(() => {  
        this.reloadOptionsEntrate()  
        this.entrateBianco=false;
      }, 1000);
  }
  if(valore === 'durata'){
   
    this.accendi=false;
    this.durataBianco=true;
    this.optionsDurata={
      scales: {
        yAxes: [{
          ticks: {
          }
        }],
        xAxes: [{
          ticks: {
          },
        }],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
      }
    }
    setTimeout(() => {  
      this.chartsDurata.forEach(element =>{
        this.downloadIMG(element['chart'].canvas, 'durata')
      })
      
      }, 1000);
       
      setTimeout(() => {  
        this.reloadOptionsDurata()  
        this.durataBianco=false;
        setTimeout(() => {
         this.accendi=true; 
        }, );
        
      }, 1000);
  }
  if(valore === 'download'){
    this.downloadBianco=true;
    this.optionsDownload = {
      scales: {
        yAxes: [{
          ticks: {
            stepSize: 30
          }
        }],
        xAxes: [{
          ticks: {
          stepSize: '60',
          },
        }],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
        }
      }
    }
    setTimeout(() => {  
      this.chartsNrDownload.forEach(element =>{
        this.downloadIMG(element['chart'].canvas, 'download')
      })
      
      }, 1000);
       
      setTimeout(() => {  
        this.reloadOptionsNrDownoald()   
        this.downloadBianco=false;
      }, 1000);
  }
  if(valore === 'risposte'){
    this.risposteBianco=true;
    this.optionsNrRisposte = {
      scales: {
        yAxes: [{
          ticks: {
            stepSize: 30
          }
        }],
        xAxes: [{
          ticks: {
          stepSize: '60',
          },
        }],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
        }
      }
    }
    setTimeout(() => {  
      this.chartsRisposte.forEach(element =>{
        this.downloadIMG(element['chart'].canvas, 'risposte')
      })
      
      }, 1000);
       
      setTimeout(() => {  
        this.reloadOptionsNrRisposte()   
        this.risposteBianco=false;
      }, 1000);
    
  }
  if(valore === 'video'){
    this.videoBianco=true;
    this.accendi=false;
    this.optionsVideo={
      scales: {
        yAxes: [{
          ticks: {
          }
        }],
        xAxes: [{
          ticks: {
          },
        }],
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
      }
    }
    setTimeout(() => {  
      this.chartsVideo.forEach(element =>{
        this.downloadIMG(element['chart'].canvas, 'video')
      })
      }, 1000);
      setTimeout(() => {  
        this.reloadOptionsNrVideo()  
        this.videoBianco=false;
        setTimeout(() => {
          this.accendi=true;
        }, );
      }, 1000);
  }
  if(valore === 'sondaggi'){
    this.sondaggiBianco=true;

    setTimeout(() => {  
      this.chartsSondaggi.forEach(element =>{
      this.downloadIMG(element['chart'].canvas, 'sondaggi')
    })

      }, 1000);
       
      setTimeout(() => {  
        this.sondaggiBianco=false;
      }, 1000);
    
   
  }
}

downloadIMG(canvas,nome){
  let ctx = canvas.getContext('2d');
  // set the ctx to draw beneath your current content
  ctx.globalCompositeOperation = 'destination-over';
  // // set the fill color to white
  ctx.fillStyle = 'white';
  // apply fill starting from point (0,0) to point (canvas.width,canvas.height)
  // these two points are the top left and the bottom right of the canvas
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  let url_base64jp = canvas.toDataURL("image/jpeg");
  var a = document.createElement("a"); //Create <a>
  a.href = url_base64jp //Image Base64 Goes here
  a.download = nome; //File name Here
  a.click(); //Downloaded file
 
  //refresh dei grafici

  if(nome === 'gruppi'){
  this.mostraChartGruppi=false;
  setTimeout(() => {  
    this.mostraChartGruppi= true;     
  }, 1);
  }
  if(nome === 'entrate'){
    this.graficoEntrate=false;
    setTimeout(() => {  
      this.graficoEntrate= true;     
    }, 1);
  }
  if(nome === 'durata'){
    this.graficoDurata=false;
    setTimeout(() => {  
      this.graficoDurata= true;     
    }, 1);
  }
  if(nome === 'download'){
    this.graficoDownloadEsercitazioni =false;
    setTimeout(() => {  
      this.graficoDownloadEsercitazioni= true;     
    }, 1);
  }
  if(nome === 'risposte'){
   this.graficoEsercitazioniNrRisposte=false;
   setTimeout(() => {  
    this.graficoEsercitazioniNrRisposte= true;     
  }, 1);
  }
  if(nome === 'video'){
   this.graficoVideoAsincorno=false;
   setTimeout(() => {  
    this.graficoVideoAsincorno= true;     
  }, 1);
  }
  if(nome === 'sondaggi'){

   this.graficoSondaggio=false;
   setTimeout(() => {  
    this.graficoSondaggio= true;     
  }, 1);
  }
}

}

 