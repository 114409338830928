import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  EnvService,
  AccountService,
  ShareService,
  AlertService,
} from 'src/app/SERVICES';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-classroom-lista-aule-go-to',
  templateUrl: './classroom-lista-aule-go-to.component.html',
  styleUrls: ['./classroom-lista-aule-go-to.component.scss'],
})
export class ClassroomListaAuleGoToComponent implements OnInit {
  listPage = 0;
  listSize = 10;
  searchControl = new FormControl('');
  webinarsList = [];
  isLoading = false;
  statoOrder: 'ASC' | 'DESC' = 'ASC';
  ambiente = environment;
  ruolo = this.accountService.ruolo;
  defaultColor = '#2196F3';
  numeroMaxPagine = 0;
  maxWebinars = 0;
  scegliAula = false;

  constructor(
    private classroomService: ClassroomService,
    private env: EnvService,
    private router: Router,
    private accountService: AccountService,
    private shareService: ShareService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getWebinarsGoTo(this.listPage, this.listSize);
    this.searchControl.valueChanges
      .pipe(debounceTime(900), distinctUntilChanged())
      .subscribe((value) => {
        this.getWebinarsGoTo(this.listPage, this.listSize, {
          ricerca: value,
        });
      });
  }

  getWebinarsGoTo(
    page: number,
    size: number,
    options?: {
      ricerca: string;
    }
  ) {
    this.classroomService
      .lista_classroom_leggera(page, size, options?.ricerca, null, null)
      .subscribe((res) => {
        if (res.data.content.length > 0) {
          this.webinarsList = res.data.content;
        } else {
          this.webinarsList = [];
        }
        this.numeroMaxPagine = Number(res.data.totalPages);
        this.maxWebinars = res.data.totalElements;

        this.scegliAula = true;
      });
  }
  changeListSize() {
    this.listPage = 0;
    this.getWebinarsGoTo(this.listPage, this.listSize);
  }

  changeStatusOrder() {
    this.statoOrder = this.statoOrder === 'ASC' ? 'DESC' : 'ASC';
    this.webinarsList = [...this.webinarsList].sort((a, b) =>
      this.statoOrder === 'ASC'
        ? a.suspended - b.suspended
        : b.suspended - a.suspended
    );
  }

  view(aula) {
    //TMS 6-10-2021 GAP-442
    //start
    let sospeso = (aula.suspended as number) || null;
    if (sospeso && sospeso == 1) {
      //esempio di dati
      // end: "2021-09-30 00:00:00"
      // start: "2021-08-02 00:00:00"
      let start = aula.start as string;
      let dataStart = start.split(' ')[0];
      let annoInizio = dataStart.split('-')[0];
      let meseInizio = dataStart.split('-')[1];
      let giornoInizio = dataStart.split('-')[2];
      let end = aula.end as string;
      let dataEnd = end.split(' ')[0];
      let annoFine = dataEnd.split('-')[0];
      let meseFine = dataEnd.split('-')[1];
      let giornoFine = dataEnd.split('-')[2];
      dataStart = giornoInizio + '/' + meseInizio + '/' + annoInizio;
      dataEnd = giornoFine + '/' + meseFine + '/' + annoFine;
      this.alertService.error('Attiva dal ' + dataStart + ' al ' + dataEnd);
      return;
    } //end
    this.scegliAula = false;
    this.shareService.changeAula(aula);
    this.router.navigate(['webinar/',aula.token]);
  }

  chiuso() {
    if (this.ambiente.NOMEAULA == 'Webinar') {
      return 'Webinar chiuso';
    } else {
      return 'Aula chiusa';
    }
  }

  cambiaPagina(page) {
    this.listPage = page;
    if (this.searchControl.value.length > 0) {
      this.getWebinarsGoTo(this.listPage, this.listSize, {
        ricerca: this.searchControl.value,
      });
    } else {
      this.getWebinarsGoTo(this.listPage, this.listSize);
    }
  }
}
