<div *ngIf="firstView" >
  <p-confirmDialog #cd  icon="pi pi-exclamation-triangle" [style]="{width:responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
    <p-footer>

        <button [disabled]="loading"  *ngIf="cancellaLezione" type="button" pButton icon="pi pi-check" label="Sì" (click)="accettaCancella()">
            <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
        </button>
        <button *ngIf="!cancellaLezione" type="button" pButton icon="pi pi-check" label="Sì" (click)="cd.accept()"></button>
        <button [disabled]="loading && cancellaLezione" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>

    </p-footer>
</p-confirmDialog>

<p-dialog [draggable]="false" [resizable]="false" [(visible)]="cancella" [closeOnEscape]="true" modal="true" [dismissableMask]="true">
  <p>Vuoi eliminare solo questa occorrenza o tutta la serie?</p>
  <div class="flex destra">
    <button type="button" class="btn btn-primary" (click)="richiestaCancellazione(1)">Occorenza</button>
  <button type="button" class="btn btn-primary" (click)="richiestaCancellazione(2)">Serie</button>
  <button type="button" class="btn btn-secondary" (click)="richiestaCancellazione(0)">Annulla</button>
</div>
</p-dialog>
<div class="row text-center" >
    <div class="flex" [ngClass]="{'col-xs-4': isDesktop, 'col-xs-12': !isDesktop }">
        <div *ngIf="ruolo === 1 || ruolo === 4" class="btn btn-primary btn-plus flex" (click)="creaSchedule()"   id="btn-responsive">
            <!-- <i class="material-icons notranslate"  style="font-size: 16px;">add</i> -->
            <span  class="arrows spanCenter"> + </span>
        </div>
      <div class="flex">
        <div
          class="btn btn-primary btn-arrow-left"
          id="btn-responsive"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        ><span  class="arrows"> < </span>
          <!-- <i class="material-icons notranslate"  style="font-size: 16px;">navigate_before</i> -->
        </div>
        <div
        id="btn-responsive"
          class="btn  btn-primary btn-oggi"
          mwlCalendarToday
          [(viewDate)]="viewDate"

        >
          Oggi
        </div>
        <div
        id="btn-responsive"
          class="btn btn-primary btn-arrow-right"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        ><span class="arrows">></span>
        <!-- <i class="material-icons notranslate" style="font-size: 15px;">navigate_next</i> -->
        </div>
      </div>
    </div>
    <div [ngClass]="{'col-xs-4': isDesktop, 'col-xs-12': !isDesktop }">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'it' }}</h3>
    </div>
    <div [ngClass]="{'col-xs-4': isDesktop, 'col-xs-12': !isDesktop }">
      <div class="btn-group">
        <div
          class="btn btn-primary "
          id="btn-responsive"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          Mese
        </div>
        <div
          class="btn btn-primary "
          id="btn-responsive"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          Settimana
        </div>
        <div
          class="btn btn-primary "
          id="btn-responsive"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          Giorno
        </div>
      </div>
    </div>
  </div>
  <br />
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"

      (eventTimesChanged)="eventTimesChanged($event)"
    locale="it"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      locale="it"
      (eventTimesChanged)="eventTimesChanged($event)"
      [dayStartHour]="costanteInizio"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      locale="it"
      (eventTimesChanged)="eventTimesChanged($event)"
      [dayStartHour]="costanteInizio"

    >
    </mwl-calendar-day-view>
</div>
</div>

<app-schedule-v2 *ngIf="schedule" (indietro)="indietroSchedule()" ></app-schedule-v2>
<app-schedule-v2 *ngIf="scheduleMod" [idGruppoLezioni]="idGruppoLezioni" (indietro)="indietroSchedule()" ></app-schedule-v2>
