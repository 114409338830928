import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ShareService } from 'src/app/SERVICES';
import { IOTService } from '../serviceIOT.service';
import * as L from 'leaflet';
import { Map } from 'leaflet';

const ELEMENT_DATA: any[] = [
  { dispositivo: 'nome dispositivo 1', id: 1 },
  { dispositivo: 'nome dispositivo 2', id: 2 },
  { dispositivo: 'nome dispositivo 3', id: 3 },
  { dispositivo: 'nome dispositivo 4', id: 4 },
  { dispositivo: 'nome dispositivo 5', id: 5 },
  { dispositivo: 'nome dispositivo 6', id: 6 },
  { dispositivo: 'nome dispositivo 7', id: 7 },
  { dispositivo: 'nome dispositivo 8', id: 8 },
  { dispositivo: 'nome dispositivo 9', id: 9 },
  { dispositivo: 'nome dispositivo 10', id: 10 },
  { dispositivo: 'nome dispositivo 11', id: 11 },
  { dispositivo: 'nome dispositivo 12', id: 12 },
  { dispositivo: 'nome dispositivo 13', id: 13 },
  { dispositivo: 'nome dispositivo 14', id: 14 },
  { dispositivo: 'nome dispositivo 15', id: 15 },
  { dispositivo: 'nome dispositivo 16', id: 16 },
  { dispositivo: 'nome dispositivo 17', id: 17 },
  { dispositivo: 'nome dispositivo 18', id: 18 },
  { dispositivo: 'nome dispositivo 19', id: 19 },
  { dispositivo: 'nome dispositivo 20', id: 20 },
  { dispositivo: 'nome dispositivo 21', id: 21 },
];

const telemetrie: any[] = [
  {
    allarme: false,
    stato: 'A letto',
    batteria: '87%',
    temporizzazione: '20 min',
    HeartRate: '87',
    breathingRate: '24',
    tipoDispositivo: 'Wearable',
    movimento: '1',
    id: 1,
  },
  {
    allarme: false,
    stato: 'A letto',
    batteria: '87%',
    temporizzazione: '20 min',
    HeartRate: '87',
    breathingRate: '24',
    tipoDispositivo: 'smartbed',
    movimento: '2',
    id: 2,
  },
  {
    allarme: false,
    stato: 'A letto',
    batteria: '87%',
    temporizzazione: '20 min',
    HeartRate: '87',
    breathingRate: '24',
    tipoDispositivo: 'Wearable',
    movimento: '3',
    id: 3,
  },
  {
    allarme: false,
    stato: 'A letto',
    batteria: '87%',
    temporizzazione: '20 min',
    HeartRate: '87',
    breathingRate: '24',
    tipoDispositivo: 'smartbed',
    movimento: '4',
    id: 4,
  },
  {
    allarme: false,
    stato: 'A letto',
    batteria: '87%',
    temporizzazione: '20 min',
    HeartRate: '87',
    breathingRate: '24',
    tipoDispositivo: 'Wearable',
    movimento: '5',
    id: 5,
  },
  {
    allarme: false,
    stato: 'A letto',
    batteria: '87%',
    temporizzazione: '20 min',
    HeartRate: '87',
    breathingRate: '24',
    tipoDispositivo: 'smartbed',
    movimento: '0',
    id: 6,
  },
  {
    allarme: false,
    stato: 'A letto',
    batteria: '87%',
    temporizzazione: '20 min',
    HeartRate: '87',
    breathingRate: '24',
    tipoDispositivo: 'Wearable',
    movimento: null,
    id: 7,
  },
  {
    allarme: false,
    stato: 'A letto',
    batteria: '87%',
    temporizzazione: '20 min',
    HeartRate: '87',
    breathingRate: '24',
    tipoDispositivo: 'smartbed',
    movimento: '1',
    id: 8,
  },
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 9},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 10},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 11},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 12},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 13},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 14},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 15},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 16},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 17},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 18},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 19},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 20},
  // {allarme: false,stato: 'A letto', temporizzazione: '20 min', HeartRate: '87', breathingRate: '24',movimento:'1', id: 21}
];

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
})
export class DeviceListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isClickable = true;
  mainLayer;
  pageSize = 13;
  pagina = 1;
  currentId = 0;
  tooltipTextMovement =
    'L’indicatore del movimento calcola il benessere del paziente basandosi su uno studio della Qilu University of Technology secondo il quale se in un arco temporale di 5 secondi il movimento raggiunge una certa ampiezza, questo indica una situazione di disagio o agitazione. Jiyong Xu et al 2020 J. Phys.: Conf. Ser. 1633 012137';
  displayedColumnsSmartbed: string[] = [
    'allarme',
    'dispositivo',
    'stato',
    'temporizzazione',
    'HeartRate',
    'breathingRate',
    'movimento',
    'mondo',
  ];
  displayedColumnsSmartwatch: string[] = [
    'allarme',
    'dispositivo',
    'stato',
    'batteria',
    'mondo',
  ];
  dataSource = new MatTableDataSource<any>();

  optionsBPM = {
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          fontColor: 'transparent',
          ticks: {
            fontColor: 'transparent',
            max: 210,
            min: 30,
            stepSize: 30,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            max: '23:59',
            min: '00:00',
            stepSize: '60',
          },
          type: 'time',
          time: {
            format: 'HH:mm',
            unit: 'hour',
            unitStepSize: 2,
            displayFormats: {
              minute: 'HH:mm',
              hour: 'HH:mm',
              min: '00:00',
              max: '23:59',
            },
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      mode: 'single',
    },
  };

  dataSourceGrafici = {
    labels: [
      '00:00',
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00',
      '23:59',
    ],
    datasets: [
      {
        label: 'Company1',
        data: [
          50, 60, 40, 80, 90, 70, 60, 50, 80, 90, 100, 80, 50, 40, 60, 60, 60,
          90, 80, 60, 30, 60, 50, 80, 75,
        ],
        borderColor: '#ea4c89',
        fill: false,
        lineTension: 0,
      },
    ],
  };
  listToShow = [];
  graficoBPMWidth = 300
  showModalHeartrate = false;
  showModalBreathRate = false;
  showModalMovements = false;
  showModalGeo = false;
  graficoCO2Width = 300;
  private map;
  constructor(
    private iotService: IOTService,
    private shareService: ShareService
  ) {}

  ngOnInit(): void {
    this.shareService.changeCloseLateralMenu(new Date());
    let newTelemetrie = [];
    ELEMENT_DATA.forEach((dispositivo) => {
      telemetrie.forEach((telemetria) => {
        if (dispositivo.id === telemetria.id) {
          telemetria.dispositivo = dispositivo.dispositivo;
          newTelemetrie.push(telemetria);
        }
      });
    });
    this.listToShow = newTelemetrie;
    this.dataSource = new MatTableDataSource<any>(newTelemetrie);
  }

  cambiaPagina(event) {}

  changeIndex(event) {
    if (event === 0) {
      this.iotService.tipoDispositivo = 'Wearable';
    }
    if (event === 1) {
      this.iotService.tipoDispositivo = 'smartbed';
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  changeId(id, mappa?) {
    if (this.currentId !== id) {
      this.showModalBreathRate = false;
      this.showModalHeartrate = false;
      this.showModalMovements = false;
      this.showModalGeo = false;
      this.currentId = id;
      let newList = [];
      this.listToShow.forEach((element) => {
        newList.push(element.id);
      });
      this.iotService.dispositivoSelezionato =
        this.listToShow[newList.indexOf(id)];
    }
    if (mappa === 'mappa') {
      setTimeout(() => {
        this.initMap();
      }, 100);
    }
  }
  getNomeDispositivoById(id) {
    let newList = [];
    this.listToShow.forEach((element) => {
      newList.push(element.id);
    });
    if (newList.indexOf(id) !== -1)
      return this.listToShow[newList.indexOf(id)].dispositivo;
    else {
      return this.listToShow[0].dispositivo;
    }
  }
  getTextTooltip(movimento) {
    if (movimento == 0) {
      return 'Immobile';
    } else if (movimento == 1) {
      return 'Lieve';
    } else if (movimento == 2) {
      return 'Basso';
    } else if (movimento == 3) {
      return 'Medio';
    } else if (movimento == 4) {
      return 'Alto';
    } else if (movimento == 5) {
      return 'Critico';
    } else {
      return '';
    }
  }

  private initMap(): void {
    navigator.geolocation.getCurrentPosition((res) => {
      this.map = L.map('map', {
        center: [res.coords.latitude, res.coords.longitude],
        zoom: 14,
      });
      const self = this;

      this.map.on('load', function () {
        setTimeout(() => {
          self.map.invalidateSize();
        }, 1);
      });

      const tiles = L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          maxZoom: 19,
          minZoom: 6,
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }
      );

      tiles.addTo(this.map);
      setTimeout(function () {
        this.map.invalidateSize();
      }, 100);
    });
  }
  onMapReady(map: L.Map) {
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }
  zoomBPM(event) {}
  zoomCO2(event) {
    this.graficoCO2Width = (300*event)/100;
  }
}
