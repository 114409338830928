<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="!infoNota" class="modal-title pull-left" id="exampleModalLabel">Inserisci Nota</h5>
        <h5 *ngIf="infoNota" class="modal-title pull-left" id="exampleModalLabel"> Nota</h5>
      </div>
      <div class="modal-body">

        <input *ngIf="!infoNota" type="textarea" class="form-control" [(ngModel)]="errorTxt" placeholder="Descrizione"
          maxlength="60">
        <p *ngIf="infoNota" class="form-control">{{errorTxt}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
        <button *ngIf="!infoNota" type="button" class="btn btn-primary" (click)="onSubmit()" data-dismiss="modal"
          [disabled]="!errorTxt">{{noteExists ? 'Modifica' : 'Aggiungi'}}</button>
        <button *ngIf="graficoClicked && noteExists" type="button" class="btn btn-danger" (click)="onNoteDelete()"
          data-dismiss="modal">Elimina</button>
      </div>
    </div>
  </div>
</div>

<!-- <button (click)="$('#exampleModal').modal('show');">Test Info Nota</button> -->

<div *ngIf="!caricato">
  <div class="container-fluid">
    <div class="content">
      <div class="row">
        <div class="col-md-12 ">
          <div class="card">
            <div class="card-content">
              <h4 class="card-title">Dispositivi IoT</h4>

              <div class="text-center">
                <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                  styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="caricato && filtroTipoDispositivo.length < 1 && listaVuota">
  <div class="container-fluid">
    <div class="content">
      <div class="row">
        <div class="col-md-12 ">
          <div class="card">
            <div class="card-content">
              <h4 class="card-title">Dispositivi IoT</h4>
              <div class="content">
                <div class="container-fluid">
                  <div class="row">
                    <h5>Nessun dispositivo associato</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="caricato && !listaVuota">

  <div class="container-fluid">
    <div class="content">
      <div class="row">
        <div class="col-md-12 ">
          <div class="card">
            <div class="card-content">
              <h4 class="card-title">Dispositivi IoT</h4>
              <div class="row">
                <div class="col-12 col-sm-4 col-sm-push-8"
                  [ngClass]="{'col-sm-4': filtroScelto !== 'smartbed', 'col-sm-push-8': filtroScelto !== 'smartbed','col-sm-3': filtroScelto == 'smartbed', 'col-sm-push-9': filtroScelto == 'smartbed'}">
                  <div class="card bg-color-f1f1f1 h-61-r">
                    <div class="card-header card-header-text bg-color-192 w-100 h-40 centra">
                      <span class="card-title c-white">
                        <span class="fw-bold">Allarmi</span>
                      </span>
                      <span class="cursor-pointer">
                        <i [ngClass]="{'siAllarme' : listaSOS.length>0, 'noAllarme' : listaSOS.length==0}"
                          class="material-icons notranslate spostaDestra iconAlarm"
                          (click)="openModalEliminateAllAllarms()">error_outline</i>
                      </span>
                    </div>
                    <div class="card-content centered of-auto maxh-55-r">
                      <!-- <i class="material-icons notranslate c-192 fs-100">error_outline</i> -->
                      <div> <strong><i class="material-icons notranslate c-red fs-xl">report_problem</i></strong>
                        <strong class="fs-xl"> ALLARMI: </strong>
                        <strong class="c-red fs-28">{{listaSOS.length}}</strong>
                        <div *ngFor="let sos of listaSOS.slice().reverse()" class="alert alert-danger">
                          <div>
                            <button type="button" aria-hidden="true" class="close float-left"
                              (click)="cleanSOS(sos); getConfermaAllarme(sos.id, 1)">
                              <i class="material-icons notranslate">close</i>
                            </button>
                            <button type="button" aria-hidden="true" class="close float-right"
                              (click)="cleanSOS(sos); getConfermaAllarme(sos.id, 2)">
                              <i class="material-icons notranslate">check</i>
                            </button>
                          </div>
                          <button *ngIf="sos.nota && ruolo == 1" (click)="salvaAllarme(sos)" type="button"
                            data-toggle="modal" data-target="#exampleModal" aria-hidden="true"
                            class="close fs-20 bottom-10 absolute right-10">
                            <i class="material-icons notranslate">edit</i>
                          </button>
                          <button *ngIf="!sos.nota && ruolo == 1" (click)="salvaAllarme(sos)" type="button"
                            data-toggle="modal" data-target="#exampleModal" aria-hidden="true"
                            class="close close fs-20 bottom-10 absolute right-10">
                            <i class="material-icons notranslate">post_add</i>
                          </button>
                          <span class="mw-100"><strong>{{sos.time | date:'HH:mm dd/MM/yyyy'}}</strong> <br />
                            <strong>{{sos.tipologia | uppercase}}</strong> <br /> {{sos.messaggio}}</span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12"
                  [ngClass]="{'col-sm-8': filtroScelto !== 'smartbed', 'col-sm-pull-4': filtroScelto !== 'smartbed','col-sm-9': filtroScelto == 'smartbed', 'col-sm-pull-3': filtroScelto == 'smartbed'}">
                  <div class="row" id="listaDispositivi">
                    <div class="col-sm-12">
                      <div class="card bg-color-f1f1f1">
                        <!-- Pulsanti Raggruppamento START-->
                        <!-- <ul (click)="mostraTipoDispositivo($event)" class="nav nav-pills nav-pills-primary mt-10 ml-10 mb-10">
                                <li *ngFor="let gruppo of gruppiDispositivi" [ngClass]="{'active': gruppo==filtroScelto}">
                                  <a data-toggle="tab">{{gruppo}}</a>
                                </li>
                              </ul> -->
                        <!-- Pulsanti Raggruppamento END-->
                        <ul class="nav nav-tabs">
                          <li class="nav-item" *ngFor="let gruppo of gruppiDispositivi">
                            <!-- <i class="material-icons no-translate nav-link" *ngIf="gruppo=='wearable'">watch</i>
                            <i class="material-icons no-translate nav-link" *ngIf="gruppo=='smartbed'">king_bed</i> -->
                            <a *ngIf="gruppo!='sos_trigger' && gruppo!='sos_handler' && gruppo!='tracciamento_asset'" class="nav-link"
                              (click)="mostraTipoDispositivo(gruppo)"
                              [ngClass]="{'active': gruppo==filtroScelto, 'notActive': gruppo!==filtroScelto}">{{gruppo}}</a>
                          </li>
                          <!-- <li class="nav-item">
                            <a class="nav-link">
                              <i class="material-icons no-translate">king_bed</i>
                              Smartbed</a>
                          </li> -->
                        </ul>
                        <!-- <div class="card-header card-header-text bg-color-2196F3 w-100 h-40"> -->
                        <!--
                        <p-tabView>
                          <p-tabPanel header="smartwatch">
                             Content 1
                          </p-tabPanel>
                          <p-tabPanel header="smartband">
                              Content 2
                          </p-tabPanel>
                        </p-tabView>
                        -->
                        <!--
                        <div class="btn-toolbar" role="toolbar">
                                <div class="btn-group btn-group-seperator">
                                  <button type="button" class="btn btn-primary"><i class="zmdi zmdi-home f-20"></i></button>
                                  <button type="button" class="btn btn-primary"><i class="zmdi zmdi-zoom-in f-20"></i></button>
                                  <button type="button" class="btn btn-primary"><i class="zmdi zmdi-zoom-out f-20"></i></button>
                                </div>
                              </div>
                          -->
                        <!-- <span class="card-title c-white">
                            <span class="fw-bold">Dispositivi</span>
                            <small class="text-muted c-white"> seleziona un dispositivo</small>
                          </span> -->
                        <!-- </div> -->

                        <div id="datatables_filter" class="dataTables_filter">
                          <form [formGroup]="searchform" class="form-group m-0-20 pd-0">
                            <input id="clearText" type="search" formControlName="search" class="form-control"
                              placeholder="Cerca..." maxlength="30">
                          </form>
                          <div *ngIf="searchDispositivo()"></div>
                        </div>
                        <div class="card-content table-responsive of-auto maxh-220"
                          [ngClass]="{'maxh-53-r': filtroScelto == 'smartbed','minh-53-r': filtroScelto == 'smartbed'}">
                          <!-- ======================= -->
                          <!-- = Descrizione -->
                          <!-- ======================= -->
                          <table class="table table-hover" id="tableDropDown" (click)="choseDownUp($event)"
                            *ngIf="showTable">
                            <thead class="text-primary sticky theadSticky">
                              <tr>
                                <th class="fs-medium cursor-pointer" (click)="ordinaArray('allarme')">
                                  <i class="material-icons notranslate fs-15">error_outline</i>
                                </th>
                                <th class="fs-medium cursor-pointer" (click)="ordinaArray('nome')">
                                  Dispositivo<i class="material-icons notranslate fs-15">unfold_more</i></th>
                                <th class="fs-medium cursor-pointer" *ngIf="mostraGrafico('WearState')"
                                  (click)="ordinaArray('stato')">
                                  Stato<i class="material-icons notranslate fs-15">unfold_more</i></th>
                                <th class="fs-medium cursor-pointer" *ngIf="mostraGrafico('Power')"
                                  (click)="ordinaArray('power')">
                                  Batteria<i class="material-icons notranslate fs-15">unfold_more</i>
                                </th>
                                <th class="fs-medium cursor-pointer">
                                Comandi
                              </th>
                                <!-- <th class="fs-medium cursor-pointer" *ngIf="mostraGrafico('Power')">
                              </th> -->
                                <th class="fs-medium cursor-pointer" title="Temporizzazione"
                                  *ngIf="mostraGrafico('Movements')" (click)="ordinaArray('schedule')">
                                  <i class="material-icons notranslate fs-15">schedule</i>
                                </th>
                                <th class="fs-medium cursor-pointer" *ngIf="mostraGrafico('Movements')"
                                  (click)="ordinaArray('heartRate')">
                                  <span class="spanNoNewLine" title="Frequenza Cardiaca">
                                    <i class="material-icons notranslate fs-15">favorite_border</i><i
                                      class="material-icons notranslate fs-15">unfold_more</i>
                                  </span>
                                </th>
                                <th class="fs-medium cursor-pointer" *ngIf="mostraGrafico('Movements')"
                                  (click)="ordinaArray('co2')">
                                  <span class="spanNoNewLine" title="Frequenza Respiratoria">
                                    <span class="material-symbols-outlined" style="
                                    transform: translate(1.5px, 2px);font-size: 18px;">
                                      pulmonology
                                    </span><i style="
                                      transform: translate(1px, 0px);"
                                      class="material-icons notranslate fs-15">unfold_more</i>
                                  </span>
                                </th>
                                <th class="fs-medium cursor-pointer" *ngIf="mostraGrafico('Movements')"
                                  (click)="ordinaArray('movementsIntensity')">
                                  <span class="spanNoNewLine" title="Indicatore Movimento">
                                    Indicatore Movimento<i style="
                                    transform: translate(0px, 3px);"
                                      class="material-icons notranslate fs-15">unfold_more</i>
                                  </span>
                                </th>
                                <th *ngIf="mostraGrafico('Movements')" class="centered"> <span
                                    [matTooltip]="tooltipTextMovement"><i
                                      class="material-icons notranslate fs-15">info</i></span></th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr *ngFor="let dispositivo of filtroTipoDispositivo, let i = index"
                                [ngClass]="{'selected': arraySelezionato[i], 'wait' : delay}"
                                style="border-top: 1px solid #ddd;" (click)="selezionaDispositivo(dispositivo)"
                                class="cursor-pointer">
                                <!-- 1 colonna Allarme Start -->
                                <td *ngIf="dispositivo.allarme"><i class="material-icons notranslate c-red fs-large"
                                    [ngClass]="{'siAllarme' : dispositivo.allarme, 'noAllarme' : !dispositivo.allarme}">report_problem</i>
                                </td>
                                <td *ngIf="!dispositivo.allarme"><i
                                    class="material-icons notranslate c-green fs-large">check_circle_outline</i></td>
                                <!-- 1 colonna Allarme End -->

                                <!-- 2 colonna Nome Start -->
                                <td class="one-line"><span>{{dispositivo.deviceName}}</span></td>
                                <!-- 2 colonna Nome Start -->

                                <!-- 3 colonna No smartbed Stato Start -->
                                <td *ngIf="dispositivo.wearState == 1">
                                  <span *ngIf="dispositivo.deviceType.tipoDevice !== 'J2032'">
                                    Indossato
                                  </span>
                                  <span *ngIf="dispositivo.deviceType.tipoDevice === 'J2032'">
                                    Online
                                  </span>
                                </td>
                                <td *ngIf="dispositivo.wearState == 4">A letto</td>
                                <td class="c-red" *ngIf="dispositivo.wearState == 5"><span class="spanNoNewLine">Non a
                                    letto</span></td>
                                <td *ngIf="dispositivo.wearState == 2" class="c-red">Spento</td>
                                <td *ngIf="dispositivo.wearState == 0 && filtroScelto!=='smartbed'">
                                  Non Indossato</td>
                                <td
                                  *ngIf="(dispositivo.wearState != 2 && dispositivo.wearState != 1 && dispositivo.wearState != 0 && dispositivo.wearState != 4 && dispositivo.wearState != 5) || dispositivo.wearState == 3 || dispositivo.wearState=='--'"
                                  class="c-red">Offline</td>
                                <!-- 3 colonna No smartbed Stato END -->

                                <!-- 2 colonna Nome Start -->
                                <td
                                  *ngIf="dispositivo.date && (dispositivo.wearState == 4 || dispositivo.wearState == 5) && filtroScelto == 'smartbed'">
                                  <span class="spanNoNewLine">{{dispositivo.date | dateAgoIOT}}</span> </td>
                                <td
                                  *ngIf="!(dispositivo.wearState == 4 || dispositivo.wearState == 5) && filtroScelto == 'smartbed'">
                                  --</td>
                                <!-- 2 colonna Nome Start -->
                                <!-- 2 colonna Nome Start -->
                                <td
                                  *ngIf="((dispositivo.heartRate !== -1 && filtroScelto == 'smartbed') && dispositivo.wearState != 5 && dispositivo.wearState != 2 && dispositivo.wearState != 3 && dispositivo.wearState !== '--')&& showTelemetry">
                                  {{dispositivo.heartRate}}</td>
                                <td
                                  *ngIf="(dispositivo.heartRate == -1 && filtroScelto == 'smartbed')  || ((dispositivo.wearState == 5 || dispositivo.wearState == 2 || dispositivo.wearState == 3 || dispositivo.wearState == '--')) && filtroScelto == 'smartbed'">
                                  --</td>
                                <!-- 2 colonna Nome Start -->
                                <!-- 2 colonna Nome Start -->
                                <td
                                  *ngIf="((dispositivo.breathingRate != -1 && filtroScelto == 'smartbed') && dispositivo.wearState != 5 && dispositivo.wearState != 2 && dispositivo.wearState != 3 && dispositivo.wearState !== '--') && showTelemetry">
                                  {{dispositivo.breathingRate}}</td>
                                <td
                                  *ngIf="(dispositivo.breathingRate == -1 && filtroScelto == 'smartbed')  || ((dispositivo.wearState == 5 || dispositivo.wearState == 2 || dispositivo.wearState == 3 || dispositivo.wearState == '--') && filtroScelto == 'smartbed')">
                                  --</td>
                                <!-- 2 colonna Nome Start -->

                                <!-- 4 colonno Stato Movumento smartbed START-->
                                <td *ngIf="mostraGrafico('Movements') && filtroScelto == 'smartbed'"
                                  class="cursor-initial">
                                  <!-- <div class="category"><strong class="flex mb-10">
                                            Indicatore di movimento:&nbsp;
                                            <div *ngIf="dispositivo.wearState == 1">Indossato</div>
                                            <div *ngIf="dispositivo.wearState == 4">A letto</div>
                                            <div class="c-red" *ngIf="dispositivo.wearState == 5">Non a letto</div>
                                            <div *ngIf="dispositivo.wearState == 2" class="c-red">Spento</div>
                                            <div
                                              *ngIf="dispositivo.wearState != 1 && dispositivo.wearState != 2 && dispositivo.wearState != 3 && filtroScelto!=='smartbed'">
                                              Non Indossato</div>
                                            <div *ngIf="dispositivo.wearState == 3" class="c-red">Offline</div>
                                          </strong><div class="nascosto">a</div></div> -->
                                  <div class="flex"
                                    *ngIf="dispositivo.movementsIntensity !='--' && dispositivo.movementsIntensity !== -1 && dispositivo.wearState == 4">
                                    <div class="progress initial-border">
                                      <div class="selettore" *ngIf="dispositivo.movementsIntensity==0"></div>
                                      <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                                        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                      </div>
                                    </div>
                                    <div class="progress progressMid mid-border">
                                      <div class="selettore" *ngIf="dispositivo.movementsIntensity==1"></div>
                                      <div class="progress-bar progress-bar-striped bg-color-198754" role="progressbar"
                                        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                      </div>
                                    </div>
                                    <div class="progress progressMid mid-border">
                                      <div class="selettore" *ngIf="dispositivo.movementsIntensity==2"></div>
                                      <div class="progress-bar progress-bar-striped bg-color-198754" role="progressbar"
                                        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                      </div>
                                    </div>
                                    <div class="progress progressMid mid-border">
                                      <div class="progress-bar progress-bar-striped bg-color-FFC107" role="progressbar"
                                        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                      </div>
                                    </div>
                                    <div class="progress progressMid mid-border">
                                      <div class="selettore" *ngIf="dispositivo.movementsIntensity==3"></div>
                                      <div class="progress-bar progress-bar-striped bg-color-FFC107" role="progressbar"
                                        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                      </div>
                                    </div>
                                    <div class="progress end-border">
                                      <div class="selettore" *ngIf="dispositivo.movementsIntensity==4"></div>
                                      <div class="progress-bar progress-bar-striped bg-color-DC3454" role="progressbar"
                                        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                      </div>
                                    </div>
                                    <div class="ml--10">
                                      <div class="selettore" *ngIf="dispositivo.movementsIntensity==5"></div>
                                    </div>
                                  </div>

                                  <div class="flex"
                                    *ngIf="dispositivo.movementsIntensity =='--' || dispositivo.movementsIntensity == -1 || dispositivo.wearState == 3 || dispositivo.wearState == 5">
                                    <div class="progress progressGray all-border">
                                      <div class="progress-bar progress-bar-striped bg-gray" role="progressbar"
                                        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                      </div>
                                    </div>
                                  </div>
                                  <!-- && dispositivo.MovementsStats!==undefined
                                          && dispositivo.MovementsStats==0
                                          && dispositivo.wearState!=5">Immobile</td>
                                <td *ngIf="mostraGrafico('Movements')
                                          && dispositivo.MovementsStats!==undefined
                                          && (dispositivo.MovementsStats>0 && dispositivo.MovementsStats<=2)
                                          && dispositivo.wearState!=5">Lieve</td>
                                <td *ngIf="mostraGrafico('Movements')
                                           && dispositivo.MovementsStats!==undefined
                                           && (dispositivo.MovementsStats>=3 && dispositivo.MovementsStats<=4)
                                           && dispositivo.wearState!=5">Agitato</td>
                                <td *ngIf="mostraGrafico('Movements')
                                           && dispositivo.MovementsStats!==undefined
                                           && dispositivo.MovementsStats==5
                                           && dispositivo.wearState!=5">Anomalo -->
                                </td>
                                <td *ngIf="filtroScelto == 'smartbed'">
                                  <div *ngIf="dispositivo.movementsIntensity==0 && dispositivo.wearState == 4">Immobile
                                  </div>
                                  <div *ngIf="dispositivo.movementsIntensity==1 && dispositivo.wearState == 4">Lieve
                                  </div>
                                  <div *ngIf="dispositivo.movementsIntensity==2 && dispositivo.wearState == 4">Basso
                                  </div>
                                  <div *ngIf="dispositivo.movementsIntensity==3 && dispositivo.wearState == 4">Medio
                                  </div>
                                  <div *ngIf="dispositivo.movementsIntensity==4 && dispositivo.wearState == 4">Alto
                                  </div>
                                  <div *ngIf="dispositivo.movementsIntensity==5 && dispositivo.wearState == 4">Critico
                                  </div>
                                </td>
                                <!-- 4 colonno smartbed START-->

                                <!-- 4 colonna Batteria no smartbed Start-->
                                <td *ngIf="mostraGrafico('Power')">
                                  <span *ngIf="dispositivo.power && dispositivo.wearState != 3 && dispositivo.wearState !='--' && dispositivo.wearState != 2 && filtroScelto !== 'smartbed'"
                                  [ngStyle]="{'color': dispositivo.power <= 20 ? 'red' : 'unset'}">{{dispositivo.power}}%</span>

                                  <span *ngIf="(dispositivo.wearState == 3 || dispositivo.wearState == 2 || dispositivo.wearState=='--') && filtroScelto !== 'smartbed'"
                                  [ngStyle]="{'color': dispositivo.power <= 20 ? 'red' : 'unset'}">--</span>
                                </td>
                                <!-- 4 colonna Batteria no smartbed End-->

                                <!-- Sezione azioni START -->
                                <td>

                                  <div *ngIf="dispositivo.deviceType.tipoDevice === 'J2032' && dispositivo.wearState!=3" [ngClass]="{'dropdown': dropdDownUp, 'dropup': !dropdDownUp}" style="position: inherit">
                                    <span class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" (click)="openJ2032ActionMenu(dispositivo.id)"
                                      aria-haspopup="true" aria-expanded="false">
                                      <i class="material-icons notranslate">
                                        more_horiz
                                      </i>
                                    </span>
                                    <ng-container *ngTemplateOutlet="tooltipChoicesUp">
                                    </ng-container>
                                  </div>
                                </td>
                                <td *ngIf="dispositivo.deviceType.tipoDevice !== 'J2032'">
                                  <span> </span>
                                </td>
                                <!-- Sezione azioni END -->
                                <!-- 4 colonna null start-->
                                <!-- <td
                                  *ngIf="((!dispositivo.power && filtroScelto!='smartbed') || dispositivo.wearState == null || dispositivo.wearState == 3 ||
                                          dispositivo.wearState == 2 || dispositivo.wearState == 5 || (filtroScelto=='smartbed'&&(dispositivo.MovementsStats==undefined||dispositivo.MovementsStats==null)))">
                                  --</td> -->
                                <!-- 4 colonna null start-->

                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ======================= -->
                  <!-- = Monitoring -->
                  <!-- ======================= -->
                  <div class="row" id="titolo" *ngIf="filtroScelto !== 'smartbed'">
                    <div class="col-sm-12">
                      <span class="c-black">
                        <span><i class="material-icons notranslate">
                            query_stats
                          </i></span>
                        <span class="fw-bold fs-medium"> Monitoring</span>
                        <span><small class="text-muted"> {{dispositivo.utente?.nome}} {{dispositivo.utente?.cognome}} -
                            {{dispositivo.deviceName}} </small></span>
                      </span>
                    </div>
                  </div>

                  <!-- ======================= -->
                  <!-- = 1 Riga Smartwatch -->
                  <!-- ======================= -->
                  <div class="row" id="monitoring" *ngIf="filtroScelto!=='smartbed'">
                    <ng-container>
                      <!-- Stato dispositivo WearState no smartbed -->
                      <div class="col-sm-3"
                        *ngIf="wearState !== null && mostraGrafico('WearState') && filtroScelto!=='smartbed'">
                        <div class="card card-stats bg-color-f1f1f1 text-align-justify-important">
                          <div class="card-content text-align-justify-important">
                            <p class="category doubledRows"><strong>Stato Dispositivo</strong></p>
                            <span class="icon icon-info">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': wearStateAllarme ? 'red' : 'DodgerBlue'}">watch</i></span>
                            <span class="card-title pull-right" *ngIf="wearState == 1 && dispositivo.deviceType.tipoDevice != 'J2032'">Indossato</span>
                            <span class="card-title pull-right" *ngIf="wearState != 3 && dispositivo.deviceType.tipoDevice === 'J2032'">Online</span>
                            <span class="card-title pull-right"
                              *ngIf="wearState !== 1 && wearState !== 2 && wearState !== 3">Non Indossato</span>
                            <span class="card-title pull-right" *ngIf="wearState == 2">Spento</span>
                            <span class="card-title pull-right" *ngIf="wearState == 3">Offline</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="wearStateSoglia">
                            <small class="text-muted c-red">Soglia: {{wearStateSoglia}}</small>
                          </div>
                          <!-- <div class="card-footer" style="opacity: 0;">
                            <div class="stats">
                              <small class="text-muted">{{wearStateTempo | dateAgo}}</small>
                            </div>
                          </div> -->

                          <div class="card-footer nascosto">

                            <div class="stats">
                              <small class="text-muted">{{wearStateTempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Batteria Power-->
                      <div class="col-sm-3" *ngIf="mostraGrafico('Power')">
                        <div class="card card-stats text-align-justify-important bg-color-f1f1f1">
                          <div class="card-content text-align-justify-important">
                            <div class="category"><strong>Batteria</strong>
                              <div class="nascosto">a</div>
                            </div>
                            <span class="icon">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': power <= 20 ? 'red' : 'DodgerBlue'}">battery_charging_full</i></span>
                            <span class="card-title pull-right"
                              *ngIf="wearState !== 3 && wearState !== 2">{{power}}%</span>
                            <span class="card-title pull-right" *ngIf="wearState == 3 || wearState == 2">--</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="powerSoglia">
                            <small class="text-muted c-red">Soglia: {{powerSoglia}}</small>
                          </div>

                          <div class="card-footer nascosto">

                            <div class="stats">
                              <small class="text-muted">{{powerTempo | dateAgo}}</small>
                            </div>
                          </div>
                          <!-- <div class="card-footer"> -->

                          <!-- <div class="stats" style="opacity: 0;">
                                <small class="text-muted">{{powerTempo | dateAgo}}</small>
                              </div> -->
                          <!-- </div> -->
                        </div>
                      </div>

                      <!-- Battiti HeartRate -->
                      <div class="col-sm-3" *ngIf="bpm !== null && mostraGrafico('HeartRate')">
                        <div class="card card-stats bg-color-f1f1f1 text-align-justify-important">
                          <div class="card-content text-align-justify-important">
                            <div class="category"><strong>Battiti</strong>
                              <div class="nascosto">a</div>
                            </div>
                            <span class="icon icon-info">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': bpmAllarme ? 'red' : 'DodgerBlue'}">favorite_border</i></span>
                            <span class="card-title pull-right"
                              *ngIf="wearState !== 3 && wearState !== 2 && wearState!=5">{{bpm}}</span>
                            <span class="card-title pull-right"
                              *ngIf="wearState == 3 || wearState == 2 || wearState==5">--</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="bpmSoglia">
                            <small data-toggle="tooltip" title="{{bpmSoglia}}" class="text-muted c-red">Soglia:
                              {{bpmSoglia | soglia:'-':5}}</small>
                          </div>
                          <div class="card-footer" [ngStyle]="{'opacity:0': wearState !== 3 && wearState !== 2}">
                            <div class="stats">
                              <small class="text-muted">{{bpmTempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Passi StepCounter -->
                      <div class="col-sm-3" *ngIf="passi !== null && mostraGrafico('StepCounter')">
                        <div class="card card-stats text-align-justify-important bg-color-f1f1f1">
                          <div class="card-content text-align-justify-important">
                            <div class="category"><strong>Passi</strong>
                              <div class="nascosto">a</div>
                            </div>
                            <span class="icon icon-info">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': passiAllarme ? 'red' : 'DodgerBlue'}">directions_walk</i></span>
                            <span class="card-title pull-right"
                              *ngIf="wearState !== 3 && wearState !== 2">{{passi}}</span>
                            <span class="card-title pull-right" *ngIf="wearState == 3 || wearState == 2">--</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="passiSoglia">
                            <small data-toggle="tooltip" title="{{passiSoglia}}" class="text-muted c-red">Soglia:
                              {{passiSoglia | soglia:'-':5}}</small>
                          </div>
                          <div class="card-footer" [ngStyle]="{'opacity:0': wearState !== 3 && wearState !== 2}">

                            <div class="stats">
                              <small class="text-muted">{{passiTempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div>

                    </ng-container>
                  </div>

                  <!-- ======================= -->
                  <!-- = 2 Riga Smartwatch -->
                  <!-- ======================= -->
                  <div class="row" id="monitoring" *ngIf="filtroScelto!=='smartbed'">
                    <ng-container>
                      <!-- Temperatura BodyTemperature -->
                      <div class="col-sm-3" *ngIf="temp !== null && mostraGrafico('BodyTemperature')">
                        <div class="card card-stats text-align-justify-important bg-color-f1f1f1">
                          <div class="card-content text-align-justify-important">
                            <div class="category"><strong>Temperatura</strong>
                              <div class="nascosto">a</div>
                            </div>
                            <span class="icon icon-info">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': tempAllarme ? 'red' : 'DodgerBlue'}">thermostat</i></span>
                            <span class="card-title pull-right"
                              *ngIf="wearState !== 3 && wearState !== 2">{{temp}}</span>
                            <span class="card-title pull-right" *ngIf="wearState == 3 || wearState == 2">--</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="tempSoglia">
                            <small data-toggle="tooltip" title="{{tempSoglia}}" class="text-muted c-red">Soglia:
                              {{tempSoglia | soglia:'-':5}}</small>
                          </div>
                          <div class="card-footer" [ngStyle]="{'opacity:0': wearState !== 3 && wearState !== 2}">
                            <div class="stats">
                              <small class="text-muted">{{tempTempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Pressione max SBP-->
                      <div class="col-sm-3" *ngIf="pmax !== null && mostraGrafico('SBP')">
                        <div class="card card-stats text-align-justify-important bg-color-f1f1f1">
                          <div class="card-content text-align-justify-important">
                            <p class="category doubledRows"><strong>Pressione max</strong></p>
                            <span class="icon icon-info">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': pmaxAllarme ? 'red' : 'DodgerBlue'}">bloodtype</i></span>
                            <span class="card-title pull-right"
                              *ngIf="wearState !== 3 && wearState !== 2">{{pmax}}</span>
                            <span class="card-title pull-right" *ngIf="wearState == 3 || wearState == 2">--</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="pmaxSoglia">
                            <small data-toggle="tooltip" title="{{pmaxSoglia}}" class="text-muted c-red">Soglia:
                              {{pmaxSoglia | soglia:'-':5}}</small>
                          </div>
                          <div class="card-footer" [ngStyle]="{'opacity:0': wearState !== 3 && wearState !== 2}">

                            <div class="stats">
                              <small class="text-muted">{{pmaxTempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Pressione min DBP-->
                      <div class="col-sm-3" *ngIf="pmin !== null && mostraGrafico('DBP')">
                        <div class="card card-stats text-align-justify-important bg-color-f1f1f1">
                          <div class="card-content text-align-justify-important">
                            <p class="category doubledRows"><strong>Pressione min</strong></p>
                            <span class="icon icon-info">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': pminAllarme ? 'red' : 'DodgerBlue'}">bloodtype</i></span>
                            <span class="card-title pull-right"
                              *ngIf="wearState !== 3 && wearState !== 2">{{pmin}}</span>
                            <span class="card-title pull-right" *ngIf="wearState == 3 || wearState == 2">--</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="pminSoglia">
                            <small data-toggle="tooltip" title="{{pminSoglia}}" class="text-muted c-red">Soglia:
                              {{pminSoglia | soglia:'-':5}}</small>
                          </div>
                          <div class="card-footer" [ngStyle]="{'opacity:0': wearState !== 3 && wearState !== 2}">

                            <div class="stats">
                              <small class="text-muted">{{pminTempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Ossigenazione  BloodOxygen-->
                      <div class="col-sm-3" *ngIf="spo2 !== null && mostraGrafico('BloodOxygen')">
                        <div class="card card-stats text-align-justify-important bg-color-f1f1f1">
                          <div class="card-content text-align-justify-important">
                            <p class="category doubledRows"><strong>SpO2</strong></p>
                            <span class="icon icon-info">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': spo2Allarme ? 'red' : 'DodgerBlue'}">bloodtype</i></span>
                            <span class="card-title pull-right"
                              *ngIf="wearState !== 3 && wearState !== 2">{{spo2}}</span>
                            <span class="card-title pull-right" *ngIf="wearState == 3 || wearState == 2">--</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="spo2Soglia">
                            <small data-toggle="tooltip" title="{{spo2Soglia}}" class="text-muted c-red">Soglia:
                              {{spo2Soglia | soglia:'-':5}}</small>
                          </div>
                          <div class="card-footer" [ngStyle]="{'opacity:0': wearState !== 3 && wearState !== 2}">

                            <div class="stats">
                              <small class="text-muted">{{spo2Tempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>


                  <!-- ======================= -->
                  <!-- = Monitoraggio Smartbed -->
                  <!-- ======================= -->
                  <!-- <div class="row" id="monitoring" *ngIf="filtroScelto=='smartbed'">
                    <ng-container> -->
                  <!-- ======================= -->
                  <!-- = 1 Riga SmartBED -->
                  <!-- ======================= -->
                  <!-- Stato a Letto smartbed -->
                  <!-- <div class="col-sm-3" *ngIf="mostraGrafico('WearState')">
                        <div class="card card-stats text-align-justify-important bg-color-f1f1f1" >
                          <div class="card-content text-align-justify-important" [ngStyle]="{'height': wearStateTempo == undefined ? '138px' : null}">
                            <div class="category"><strong>A letto</strong><div class="nascosto">a</div></div>
                            <span class="icon">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': movementsStats >= 3 ? 'red' : 'DodgerBlue'}">hotel</i></span>
                            <span class="card-title pull-right"
                              *ngIf="wearState == 4">A letto</span>
                              <span class="card-title pull-right"
                              *ngIf="wearState == 5" [ngStyle]="{'color': wearState == 5 ? 'red' : null}">Non a letto</span>
                              <span class="card-title pull-right"
                              *ngIf="wearState == 3">Offline</span>
                            <span class="card-title pull-right" *ngIf="movementsStats == null">--</span>
                          </div>
                          <div class="card-footer" *ngIf="wearStateTempo !== undefined && wearStateTempo !== null">
                            <div class="stats" >
                              <small class="text-muted">{{wearStateTempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div> -->

                  <!-- Battiti HeartRate -->
                  <!-- <div class="col-sm-3" *ngIf="bpm !== null && mostraGrafico('HeartRate')&&bpm!=null">
                        <div class="card card-stats bg-color-f1f1f1 text-align-justify-important">
                          <div class="card-content text-align-justify-important" >
                            <div class="category"><strong>Battiti</strong> <div class="nascosto">a</div></div>
                            <span class="icon icon-info">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': bpmAllarme ? 'red' : 'DodgerBlue'}">favorite_border</i></span>
                            <span class="card-title pull-right"
                              *ngIf="wearState !== 3 && wearState !== 2 && wearState!=5">{{bpm}}</span>
                            <span class="card-title pull-right" *ngIf="wearState == 3 || wearState == 2 || wearState==5">--</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="bpmSoglia">
                            <small data-toggle="tooltip" title="{{bpmSoglia}}" class="text-muted c-red">Soglia: {{bpmSoglia | soglia:'-':5}}</small>
                          </div>
                          <div class="card-footer" [ngStyle]="{'opacity:0': wearState !== 3 && wearState !== 2}">
                            <div class="stats">
                              <small class="text-muted">{{bpmTempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div> -->

                  <!--Respirazione-->
                  <!-- <div class="col-sm-3" *ngIf="mostraGrafico('BreathingRate')&&breathingRate!=null">
                        <div class="card card-stats text-align-justify-important bg-color-f1f1f1" >
                          <div class="card-content text-align-justify-important">
                            <div class="category"><strong>Respirazione</strong><div class="nascosto">a</div></div>
                            <span class="icon">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': false ? 'red' : 'DodgerBlue'}">air</i></span>
                            <span class="card-title pull-right"
                              *ngIf="(breathingRate!=null)&& wearState == 4">{{breathingRate}}</span>
                            <span class="card-title pull-right" *ngIf="breathingRate == null || wearState == 5">--</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="respirazioneSoglia">
                            <small class="text-muted c-red">Soglia: {{respirazioneSoglia}}</small>
                          </div>
                          <div class="card-footer" >
                            <div class="stats" *ngIf="breathingRateTempo !== undefined && breathingRateTempo !== null">
                              <small class="text-muted">{{breathingRateTempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div> -->

                  <!-- Movimento -->
                  <!-- <div class="col-sm-3" *ngIf="mostraGrafico('Movements')">
                        <div class="card card-stats text-align-justify-important bg-color-f1f1f1" [ngStyle]="{'height': movementsStatsTempo == undefined ? '138px' : null}">
                          <div class="card-content text-align-justify-important">
                            <div class="category"><strong>Movimenti</strong><div class="nascosto">a</div></div>
                            <span class="icon">
                              <i class="material-icons fs-25"
                                [ngStyle]="{'color': movementsStats >= 3 ? 'red' : 'DodgerBlue'}">radar</i></span>
                            <span class="card-title pull-right"
                              *ngIf="(movementsStats!=null)&&(wearState == 4)&&movementsStats==0">Immobile</span>
                              <span class="card-title pull-right"
                              *ngIf="(movementsStats!=null)&&(wearState == 4)
                                     && (movementsStats>0&&movementsStats<=2)">Lieve</span>
                              <span class="card-title pull-right"
                              *ngIf="(movementsStats!=null)&&(wearState == 4)
                              && (movementsStats>2&&movementsStats<=4)">Agitato</span>
                              <span class="card-title pull-right"
                              *ngIf="(movementsStats!=null)&&(wearState == 4)&&movementsStats==5">Anomalo</span>
                            <span class="card-title pull-right" *ngIf="movementsStats == null || wearState == 5||wearState == 3">--</span>
                          </div>
                          <div class="stats absolute ml-20 mt--5" *ngIf="false">
                            <small class="text-muted c-red">Soglia: {{powerSoglia}}</small>
                          </div>
                          <div class="card-footer" *ngIf="movementsStatsTempo !== undefined && movementsStatsTempo !== null">
                            <div class="stats">
                              <small class="text-muted">{{movementsStatsTempo | dateAgo}}</small>
                            </div>
                          </div>
                        </div>
                      </div>

                    </ng-container>
                  </div> -->
                  <!-- Barra Movimento -->
                  <!-- <div class="col-12">
                  <div class="card card-stats text-align-justify-important bg-color-f1f1f1">
                    <div class="card-content text-align-justify-important">
                        <div class="category"><strong class="flex mb-10">
                          Indicatore di movimento:&nbsp;
                          <div *ngIf="dispositivo.wearState == 1">Indossato</div>
                          <div *ngIf="dispositivo.wearState == 4">A letto</div>
                          <div class="c-red" *ngIf="dispositivo.wearState == 5">Non a letto</div>
                          <div *ngIf="dispositivo.wearState == 2" class="c-red">Spento</div>
                          <div
                            *ngIf="dispositivo.wearState != 1 && dispositivo.wearState != 2 && dispositivo.wearState != 3 && filtroScelto!=='smartbed'">
                            Non Indossato</div>
                          <div *ngIf="dispositivo.wearState == 3" class="c-red">Offline</div>
                        </strong><div class="nascosto">a</div></div>
                        <div class="flex">
                          <div class="progress">
                            <div class="selettore" *ngIf="movementsStats==0"></div>
                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div class="progress">
                            <div class="selettore" *ngIf="movementsStats==1"></div>
                            <div class="progress-bar progress-bar-striped bg-color-198754" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div class="progress">
                            <div class="selettore left-38p" *ngIf="movementsStats==2"></div>
                            <div class="progress-bar progress-bar-striped bg-color-FFC107" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="selettore left-63p" *ngIf="movementsStats==3"></div>
                          </div>
                          <div class="progress">
                            <div class="selettore" *ngIf="movementsStats==4"></div>
                            <div class="progress-bar progress-bar-striped bg-color-DC3454" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div>
                            <div class="selettore" *ngIf="movementsStats==5"></div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div> -->
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ======================= -->
      <!-- = STORICO + Grafici -->
      <!-- ======================= -->
      <div class="row">
        <div class="col-md-12 ">
          <div class="card">
            <div class="card-content">
              <h4 class="card-title display-inline">Storico Allarmi e Grafici</h4>

              <span><small class="text-muted"> {{dispositivo.utente?.nome}} {{dispositivo.utente?.cognome}} -
                  {{dispositivo.deviceName}} </small></span>
              <!-- ======================= -->
              <!-- = INTRO STORICO -->
              <!-- ======================= -->
              <!-- <div class="dropdown z-1 float-right">
                <span>Seleziona una data: </span>
                <a class="collapsed" id="dropdownMenu4" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="true">
                  {{date | date: 'dd/MM/yyyy'}}
                  <span class="caret"></span>
                </a>
                <ul class="dropdown-menu scrollable-menu ml--11-per" role="menu" aria-labelledby="dropdownMenu4">
                  <li>
                    <p-calendar [maxDate]="today" [(ngModel)]="date" (click)="cambiaGrafici()" [inline]="true"></p-calendar>
                  </li>
                </ul>
              </div> -->
              <!-- ======================= -->
              <!-- = Lista STORICO -->
              <!-- ======================= -->
              <div class="row">
                <div class="col-sm-12">
                  <div class="card bg-color-f1f1f1 h-300">
                    <div class="card-header card-header-text bg-color-2196F3 w-100 h-40">
                      <span class="card-title c-white">
                        <span class="fw-bold">Storico del {{ date | date: 'dd/MM/yyyy'}} </span>
                      </span>
                    </div>
                    <div class="card-content of-auto maxh-250">
                      <h5 *ngIf="dispositivo?.storico?.length < 1"> Nessuno storico disponibile</h5>
                      <div class="streamline" *ngIf="dispositivo?.storico?.length > 0 ">
                        <!-- <div class="sl-item" *ngFor="let allarme of dispositivo.storico; let i = index"
                                      [ngClass]="{'sl-primary': (i%4)==0, 'sl-danger': ((i+1)%4)==0, 'sl-success': ((i+2)%4)==0, 'sl-warning': ((i+3)%4)==0}"> -->
                        <ng-container *ngFor="let allarme of dispositivo.storico; let i = index">
                          <div class="sl-item" *ngIf="(allarme.time | date:'dd/MM/yyyy') == (date | date:'dd/MM/yyyy')"
                            [ngClass]="{'colorPink': allarme.telemetria == 'HeartRate' ,
                                      'colorBlue': allarme.telemetria == 'DBP' ,
                                      'colorBlue2': allarme.telemetria == 'SBP' ,
                                      'colorYellow': allarme.telemetria == 'Power',
                                      'colorGreen': allarme.telemetria == 'BodyTemperature',
                                       'colorCyan': allarme.telemetria == 'StepCounter',
                                      'colorRed': (allarme.telemetria == 'emergenza') || (allarme.telemetria !== 'BreathingRate' && allarme.telemetria !== 'emergenza batteria' &&
                                      allarme.telemetria !== 'emergenza' && allarme.telemetria !== 'StepCounter' &&
                                      allarme.telemetria !== 'BodyTemperature' && allarme.telemetria !== 'Power' &&
                                      allarme.telemetria !== 'SBP' && allarme.telemetria !== 'DBP' && allarme.telemetria !== 'HeartRate'),
                                       'testColor': allarme.telemetria == 'BreathingRate'}">

                            <span class="icon icon-info absolute-important l--12">
                              <i *ngIf="allarme.telemetria == 'HeartRate'"
                                class="material-icons c-ea4c89 ">favorite_border</i>
                              <i *ngIf="allarme.telemetria == 'DBP'" class="material-icons c-2196F3">bloodtype</i>
                              <i *ngIf="allarme.telemetria == 'SBP'" class="material-icons c-2196F3">bloodtype</i>
                              <i *ngIf="allarme.telemetria == 'Power'"
                                class="material-icons c-FFC107">battery_charging_full</i>
                              <i *ngIf="allarme.telemetria == 'BodyTemperature'"
                                class="material-icons c-4CAF50">thermostat</i>
                              <i *ngIf="allarme.telemetria == 'StepCounter'"
                                class="material-icons c-03A9F4">directions_walk</i>
                              <i *ngIf="allarme.telemetria == 'emergenza'"
                                class="material-icons c-F44336">report_problem</i>
                              <i *ngIf="allarme.telemetria == 'emergenza batteria'"
                                class="material-icons c-F44336">battery_alert</i>
                              <i *ngIf="allarme.telemetria == 'BreathingRate'" class="material-icons c-03A9F4">co2</i>
                              <i *ngIf="(allarme.telemetria !== 'BreathingRate' && allarme.telemetria !== 'emergenza batteria' &&
                            allarme.telemetria !== 'emergenza' && allarme.telemetria !== 'StepCounter' &&
                            allarme.telemetria !== 'BodyTemperature' && allarme.telemetria !== 'Power' &&
                            allarme.telemetria !== 'SBP' && allarme.telemetria !== 'DBP' && allarme.telemetria !== 'HeartRate')"
                                class="material-icons c-F44336">report_problem</i>

                            </span>
                            <div class="sl-content">
                              <small class="text-muted">{{allarme.time | date: 'dd/MM/yyyy HH:mm'}}</small>
                              <p>{{allarme.messaggio}}</p>
                              <button *ngIf="allarme.nota" (click)="salvaAllarme(allarme,1)" type="button"
                                data-toggle="modal" data-target="#exampleModal" aria-hidden="true"
                                class="close fs-20 bottom-16 absolute right-10">
                                <i class="material-icons notranslate fs-20 c-00bcd4">event_note</i>
                              </button>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ======================= -->
              <!-- = GRAFICI -->
              <!-- ======================= -->
              <ng-container *ngIf="vuoto">
                <!-- ======================= -->
                <!-- = GRAFICO PRESSIONE -->

             <div *ngIf="mostraGrafico('DBP')" class="row">
          <!--        <app-grafico-iot [hasLegend]='true' [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartPressioneIot" [nomeTelemetria]="'Pressione'" [graficoWidthConst]="graficoPressioneWidthConst" [dataIot]="dataPressioneIot" [dataIot2]="dataPressioneIot2" [options]="optionsPressione">
                    <div class="chart-legend pressione-min"></div>
                    <span [ngStyle]="{'text-decoration': pressioneMinimaMarked ? 'line-through' : null}">Pressione minima</span>
                    <div class="chart-legend pressione-max"></div>
                    <span [ngStyle]="{'text-decoration': pressioneMassimaMarked ? 'line-through' : null}">Pressione massima</span>
                     <app-zoombar class="zoom-grafico" *ngIf="showZoom" (currentZoomEmitter)="graficoPressioneWidth=modificaZoomGrafico(graficoPressioneWidthConst, selezionaDivZoom('DBP'), graficoPressioneWidth, $event)"></app-zoombar> 
                  </app-grafico-iot>  -->
                </div> 

                <div *ngIf="mostraGrafico('DBP')" class="row">
                  <app-grafico-iot-v2 [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartPressioneIot" [nomeTelemetria]="'Pressione'" [dataIot]="dataPressioneIot">
                    
                    <!-- <app-zoombar class="zoom-grafico" *ngIf="showZoom" (currentZoomEmitter)="graficoPressioneWidth=modificaZoomGrafico(graficoPressioneWidthConst, selezionaDivZoom('DBP'), graficoPressioneWidth, $event)"></app-zoombar> -->
                  </app-grafico-iot-v2>
                </div>
            <!-- <div *ngIf="mostraGrafico('DBP')" class="row">
              <div class="col-xs-12 col-sm-12">
                <div class="card min-max-h-370">
                  <div class="card-header card-header-text w-100">
                    <h4 class="card-title display-inline">Grafico Pressione del {{ date | date: 'dd/MM/yyyy'}}</h4>
                    <span><small class="text-muted"> {{dispositivo.utente?.nome}} {{dispositivo.utente?.cognome}} - {{dispositivo.deviceName}} </small></span>
                  </div>
                  <div class="legend-wrapper">
                    <div class="chart-legend pressione-min"></div>
                    <span [ngStyle]="{'text-decoration': pressioneMinimaMarked ? 'line-through' : null}" (click)="onClickLabelPressione(true)">Pressione minima</span>
                    <div class="chart-legend pressione-max"></div>
                    <span [ngStyle]="{'text-decoration': pressioneMassimaMarked ? 'line-through' : null}" (click)="onClickLabelPressione(false)">Pressione massima</span>
                    <app-zoombar class="zoom-grafico" *ngIf="showZoom" (currentZoomEmitter)="graficoPressioneWidth=modificaZoomGrafico(graficoPressioneWidthConst, selezionaDivZoom('DBP'), graficoPressioneWidth, $event)"></app-zoombar>
                  </div>
                  <div class="card-content table-responsive delete-overflow">
                    <div *ngIf="!mostraChartPressioneIot" class="m-20 centered">
                      <span>
                        <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                      </span>
                    </div>
                    <div class="absolute">
                      <p-chart *ngIf="mostraChartPressioneIot" type="line" [options]="optionsBPM2" [height]="300" [data]="dataPressioneIot2"></p-chart>
                    </div>
                    <div class="w-95 of-x-scroll relative bg-white ml-50 z-1" #zoomDBPDiv>
                      <div [ngStyle]="{'width.px': graficoPressioneWidth, 'overflow-x': 'auto'}">
                        <p-chart *ngIf="mostraChartPressioneIot" type="line" [options]="optionsBPM" [height]="300" [data]="dataPressioneIot" (onDataSelect)="selectData($event, 0)" #chartPressione></p-chart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->


                <!-- ======================= -->
                <!-- = GRAFICO BATTITO CARDIACO -->
                <!-- ======================= -->
                <div *ngIf="mostraGrafico('HeartRate')" class="row">
                  <!-- <app-grafico-iot [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartBattitoCardiacoIot" [nomeTelemetria]="'Battito Cardiaco'" [graficoWidthConst]="graficoBattitoWidthConst" [dataIot]="dataBattitoCardiacoIot" [dataIot2]="dataBattitoCardiacoIot2" [options]="optionsBPM" [options2]="optionsBPM2">
                  </app-grafico-iot> -->
                  
                  <app-grafico-iot-v2 [nomeTelemetria]="'Battito Cardiaco'" [dataIot]="dataBattitoCardiacoIot" [mostraChartIot]="mostraChartBattitoCardiacoIot" [date]="date" [dispositivo]="dispositivo"></app-grafico-iot-v2>

                </div>
                <!-- ======================= -->
                <!-- = GRAFICO TEMPERATURA -->
                <!-- ======================= -->
                <div *ngIf="mostraGrafico('BodyTemperature')" class="row">
                  <app-grafico-iot [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartTemperaturaIot" [nomeTelemetria]="'Temperatura'" [graficoWidthConst]="graficoTemperaturaWidthConst" [dataIot]="dataTemperaturaIot" [dataIot2]="dataTemperaturaIot2" [options]="optionsTemp"></app-grafico-iot>
                  <!-- <div class="col-xs-12 col-sm-12">
                    <div class="card">
                      <div class="card-header card-header-text">
                        <h4 class="card-title display-inline">Grafico Temperatura del {{ date | date:
                          'dd/MM/yyyy'}}</h4>

                        <span><small class="text-muted"> {{dispositivo.utente?.nome}} {{dispositivo.utente?.cognome}} -
                            {{dispositivo.deviceName}} </small></span>
                      </div>
                      <div class="card-content table-responsive delete-overflow">
                        <div *ngIf="!mostraChartTemperaturaIot" class="m-20 centered">
                          <span>
                            <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                              styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                          </span>
                        </div>
                        <div class="absolute">
                          <p-chart *ngIf="mostraChartTemperaturaIot" type="line" [options]="optionsTemp2" [height]="300"
                            [data]="dataTemperaturaIot2"></p-chart>
                        </div>
                        <div class="w-95 of-x-scroll relative bg-white ml-50 z-1">
                          <div [ngStyle]="{'width.px': graficoTemperaturaWidth}">
                            <p-chart *ngIf="mostraChartTemperaturaIot" type="line" [options]="optionsTemp"
                              [height]="300" [data]="dataTemperaturaIot" (onDataSelect)="selectData($event, 2)"
                              #chartTemp></p-chart>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
                <!-- ======================= -->
                <!-- = GRAFICO TEMPERATURA -->
                <!-- ======================= -->
                <div *ngIf="mostraGrafico('BloodOxygen')" class="row">
                  <!-- <app-grafico-iot [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartBloodOxygenIot" [nomeTelemetria]="'Ossigenazione del Sangue'" [graficoWidthConst]="graficoBloodOxygenWidthConst" [dataIot]="dataBloodOxygenIot" [dataIot2]="dataBloodOxygenIot2" [options]="optionsSPO2"></app-grafico-iot> -->
                  <app-grafico-iot-v2 [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartBloodOxygenIot" [nomeTelemetria]="'Ossigenazione del Sangue'" [dataIot]="dataBloodOxygenIot"></app-grafico-iot-v2>
                  <!-- <div class="col-xs-12 col-sm-12">
                    <div class="card min-max-h-370">
                      <div class="card-header card-header-text w-100">
                        <h4 class="card-title display-inline">Grafico Ossigenazione del Sangue del {{ date | date:
                          'dd/MM/yyyy'}}</h4>

                        <span><small class="text-muted"> {{dispositivo.utente?.nome}} {{dispositivo.utente?.cognome}} -
                            {{dispositivo.deviceName}} </small></span>
                            <app-zoombar *ngIf="showZoom" (currentZoomEmitter)="graficoBloodOxygenWidth=modificaZoomGrafico(graficoBloodOxygenWidthConst, selezionaDivZoom('BloodyOxygen'), graficoBloodOxygenWidth, $event)"></app-zoombar>
                          </div>
                      <div class="card-content table-responsive delete-overflow">
                        <div *ngIf="!mostraChartBloodOxygenIot" class="m-20 centered">
                          <span>
                            <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                              styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                          </span>
                        </div>
                        <div class="absolute">
                          <p-chart *ngIf="mostraChartBloodOxygenIot" type="line" [options]="optionsRespirazione2"
                            [height]="300" [data]="dataBloodOxygenIot2"></p-chart>
                        </div>
                        <div class="w-95 of-x-scroll relative bg-white ml-50 z-1" #zoomBODiv>
                          <div [ngStyle]="{'width.px': graficoBloodOxygenWidth}">
                            <p-chart *ngIf="mostraChartBloodOxygenIot" type="line" [options]="optionsRespirazione"
                              [height]="300" [data]="dataBloodOxygenIot" (onDataSelect)="selectData($event, 1)"
                              #chartBO></p-chart>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
                <!-- ======================= -->
                <!-- = GRAFICO CONTAPASSI -->
                <!-- ======================= -->
                <div *ngIf="mostraGrafico('StepCounter')" class="row">
                  <!-- <app-grafico-iot [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartContaPassiIot" [nomeTelemetria]="'Contapassi'" [graficoWidthConst]="graficoPassiWidthConst" [dataIot]="dataContaPassiIot" [dataIot2]="dataContaPassiIot2" [options]="optionsPassi"></app-grafico-iot> -->
                  <app-grafico-iot-v2 [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartContaPassiIot" [nomeTelemetria]="'Contapassi'" [dataIot]="dataContaPassiIot"></app-grafico-iot-v2>
                  <!-- <div class="col-xs-12 col-sm-12">
                    <div class="card min-max-h-370">
                      <div class="card-header card-header-text w-100">
                        <h4 class="card-title display-inline">Grafico Contapassi del {{ date | date: 'dd/MM/yyyy'}}</h4>
                        <span><small class="text-muted"> {{dispositivo.utente?.nome}} {{dispositivo.utente?.cognome}} - {{dispositivo.deviceName}} </small></span>
                        <app-zoombar *ngIf="showZoom" (currentZoomEmitter)="graficoPassiWidth=modificaZoomGrafico(graficoPassiWidthConst, selezionaDivZoom('StepCounter'), graficoPassiWidth, $event)"></app-zoombar>
                      </div>
                      <div class="card-content table-responsive delete-overflow">
                        <div *ngIf="!mostraChartContaPassiIot" class="m-20 centered">
                          <span>
                            <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                          </span>
                        </div>
                        <div class="absolute">
                          <p-chart *ngIf="mostraChartContaPassiIot" type="line" [options]="optionsPassi2" [height]="300" [data]="dataContaPassiIot2"></p-chart>
                        </div>
                        <div class="w-95 of-x-scroll relative bg-white ml-50 z-1" #zoomSCDiv>
                          <div [ngStyle]="{'width.px': graficoPassiWidth}">
                            <p-chart *ngIf="mostraChartContaPassiIot" type="line" [options]="optionsPassi" [height]="300" [data]="dataContaPassiIot" (onDataSelect)="selectData($event, 3)" #chartContapassi></p-chart>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
                

                <!-- Area SmartBed Grafici -->

                <!-- ======================= -->
                <!-- = GRAFICO BreatingHeart smartbed -->
                <!-- ======================= -->
                <div *ngIf="mostraGrafico('BreathingRate')" class="row">
                  <!-- <app-grafico-iot [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartRespirazioneIot" [nomeTelemetria]="'Respirazione'" [graficoWidthConst]="graficoRespirazioneWidthConst" [dataIot]="dataRespirazioneIot" [dataIot2]="dataRespirazioneIot2" [options]="optionsRespirazione"></app-grafico-iot> -->
                  <app-grafico-iot-v2 [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartRespirazioneIot" [nomeTelemetria]="'Respirazione'" [dataIot]="dataRespirazioneIot"></app-grafico-iot-v2>
                  <!-- <button (click)="printDataInConsole()">Print</button> -->
                  <!-- <div class="col-xs-12 col-sm-12">
                    <div class="card min-max-h-370">
                      <div class="card-header card-header-text w-100">
                        <h4 class="card-title display-inline">Grafico Respirazione del {{ date | date:
                          'dd/MM/yyyy'}}</h4>

                        <span><small class="text-muted"> {{dispositivo.utente?.nome}} {{dispositivo.utente?.cognome}} -
                            {{dispositivo.deviceName}} </small></span>

                        <app-zoombar *ngIf="showZoom" (currentZoomEmitter)="zoomRespirazione($event)"></app-zoombar>
                      </div>
                      <div class="card-content table-responsive delete-overflow">
                        <div *ngIf="!mostraChartRespirazioneIot" class="m-20 centered">
                          <span>
                            <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                              styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                          </span>
                        </div>
                        <div class="absolute">
                          <p-chart *ngIf="mostraChartRespirazioneIot" type="line" [options]="optionsRespirazione2"
                            [height]="300" [data]="dataRespirazioneIot2"></p-chart>
                        </div>
                        <div class="w-95 of-x-scroll relative bg-white ml-50 z-1" #zoomRESDiv>
                          <div [ngStyle]="{'width.px': graficoRespirazioneWidth}">
                            <p-chart *ngIf="mostraChartRespirazioneIot" type="line" [options]="optionsRespirazione"
                              [height]="300" [data]="dataRespirazioneIot" (onDataSelect)="selectData($event, 4)"
                              #chartRespirazione></p-chart>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>

              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div [ngStyle]="{'display': (vuoto && filtroScelto!=='smartbed') ? '' : 'none'}" class="row">
        <div class="col-md-12 ">
          <div class="card">
            <div class="card-content">
              <ng-container *ngIf="!errorMap">
                <!-- -----mappa------ -->
                <h4 class="card-title display-inline">Mappa </h4>

                <div class="map3" id="map" ref="mapElement" [ngClass]="{notMapFullScreen: !fullScreenMap}">
                </div>
                <leaflet-fullscreen-control [map]="map" [hidden]="!caricato && vuoto">
                </leaflet-fullscreen-control>
                <!-- ------fine mappa----- -->
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>



<p-dialog [draggable]="false" [resizable]="false" [(visible)]="selectedAllarmi" [closeOnEscape]="false" modal="true">
  <h5 class="centered">Sei sicuro di voler eliminare tutti gli Allarmi?</h5>

  <div class="flex">
    <button class="btn btn-secondary" (click)="selectedAllarmi = false">Indietro</button>
    <button class="btn btn-primary ml-auto" (click)="eliminateAllAllarms()">Conferma</button>
  </div>
</p-dialog>

<ng-template #tooltipChoicesUp>

  <div class="dropdown-menu menu-azioni" aria-labelledby="dropdownMenuButton">

    <div class="dropdown-item azione" (click)="inviaComandoJ2032('FIND_DEVICE')"><div class="azione-testo">Trova dispositivo</div></div>
    <div class="dropdown-item azione" (click)="inviaComandoJ2032('START_HR')"><div class="azione-testo">Avvia misurazione HR</div></div>
    <div class="dropdown-item azione" (click)="inviaComandoJ2032('START_SPO2')"><div class="azione-testo">Avvia misurazione Spo2</div></div>
    <div class="dropdown-item azione" (click)="inviaComandoJ2032('START_BP')"><div class="azione-testo">Avvia misurazione pressione</div></div>
  </div>
</ng-template>