<div id="container">
    <div *ngIf="session" id="session" style="z-index: 10;">
        <opv-session #ovSessionComponent [ovSettings]="ovSettings" [sessionName]="mySessionId" [user]="myUserName" [tokens]="tokens" (leaveSession)="close($event)"
          (sessionCreated)="handlerSessionCreatedEvent($event)" (publisherCreated)="handlerPublisherCreatedEvent($event)" (error)="handlerErrorEvent($event)"
          [isControllRoom]="true" [url]="urlEnv">
        </opv-session>

        <!-- <opv-session [sessionName]="mySessionId" [user]="myUserName" [openviduServerUrl]="'https://localhost:4443'" [openviduSecret]="'MY_SECRET'"></opv-session> -->
      </div>
    </div>
