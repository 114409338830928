import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectItem, PrimeNGConfig, MessageService } from 'primeng/api';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-schedule-v2',
  templateUrl: './schedule-v2.component.html',
  styleUrls: ['./schedule-v2.component.scss'],
  changeDetection:ChangeDetectionStrategy.Default
})
export class ScheduleV2Component implements OnInit {

  aulaInfo: any;
  @Input() idGruppoLezioni;

  @Output() indietro = new EventEmitter<boolean>();

  createForm: FormGroup;
  createFormDue: FormGroup;
  submitted = false;
  loadingProva = false;
  opzioni: SelectItem[];
  selectedOption: string;
  giorni: SelectItem[];
  selectedGiorni= [];
  listaInsegnanti = [];
  loading = false;
  ita = {
    firstDayOfWeek: 1,
    dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
    monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
    monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk'
  };
  ambiente
  modifica = false;
  showPage=true;
  erroreData=false;
  erroreOra=false;
  constructor(
    private formBuilder: FormBuilder,
    private classroomService: ClassroomService,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig,
    private service: ShareService,
    private cd: ChangeDetectorRef,
    private shareService: ShareService,
  ) {
    this.alertService.clear;
    this.ambiente = environment
    this.createForm = this.formBuilder.group({
      nome: ['', Validators.required],
      docente: ['', Validators.required],
    });
    this.createFormDue = this.formBuilder.group({
      dataDa: ['', Validators.required],
      dataA: ['', Validators.required],
      oraDa: ['', Validators.required],
      oraA: ['', Validators.required],
    })
    this.shareService.scegliAula$.subscribe((res) => {
      this.aulaInfo = res;
    });
  }

  ngOnInit() {
    this.showPage = false;
    this.opzioni = [{ label: "No", value: "0" }, { label: "Sì", value: "1" }];
    this.giorni = [{ label: "Lunedì", value: "2" }, { label: "Martedì", value: "3" }, { label: "Mercoledì", value: "4" }, { label: "Giovedì", value: "5" }, { label: "Venerdì", value: "6" }, { label: "Sabato", value: "7" }, { label: "Domenica", value: "1" }];
    this.classroomService.lista_insegnanti(this.aulaInfo.idAula).subscribe(res => {
      this.listaInsegnanti = res.data
      this.loading = true;
      if (!this.idGruppoLezioni) {
        this.showPage = true;
        this.cd.markForCheck();
      }
      else {
        this.modifica = true;
        this.classroomService.recupera_schedule_gruppo(this.idGruppoLezioni).subscribe((res) => {
          this.createForm = this.formBuilder.group({
            nome: [res.data.lezioniGruppo[0].nome, Validators.required],
            docente: [res.data.lezioniGruppo[0].docente, Validators.required],
          })
         let start =res.data.lezioniGruppo[0].start.split(":")
          let oraDa :Date = new Date()
          oraDa.setHours(Number(start[0]))
          oraDa.setMinutes(Number(start[1]))
          oraDa.setSeconds(Number(start[2]))
          let end =res.data.lezioniGruppo[0].end.split(":")
          let oraA :Date = new Date()
          oraA.setHours(Number(end[0]))
          oraA.setMinutes(Number(end[1]))
          oraA.setSeconds(Number(end[2]))
          this.createFormDue =this.formBuilder.group({
            dataDa: [new Date(res.data.dataInizio), Validators.required],
            dataA: [new Date(res.data.dataFine), Validators.required],
            oraDa: [oraDa, Validators.required],
            oraA: [oraA, Validators.required],
          })
          if(res.data.lezioniGruppo[0].ripetizione == 0){
           this.selectedOption="0";
          }else{
            this.selectedOption="1";
          let listaGiorni:[String] = res.data.listaGiorni
          listaGiorni.forEach(element => {
            if(element.toLowerCase() =="lunedì"){
              this.selectedGiorni.push("2")
            }if(element.toLowerCase()=="martedì"){
              this.selectedGiorni.push("3")
            }if(element.toLowerCase()=="mercoledì"){
              this.selectedGiorni.push("4")
            }if(element.toLowerCase()=="giovedì"){
              this.selectedGiorni.push("5")
            }if(element.toLowerCase()=="venerdì"){
              this.selectedGiorni.push("6")
            }if(element.toLowerCase()=="sabato"){
              this.selectedGiorni.push("7")
            }if(element.toLowerCase()=="domenica"){
              this.selectedGiorni.push("1")
            }
          });
          }
          this.showPage = true;
          this.cd.markForCheck();
        })
      }
    })
  }

  get f() { return this.createForm.controls; }
  get h() { return this.createFormDue.controls; }


  salvaForm() {
      this.submitted = true;
      this.loadingProva = true;
      this.erroreData=false;
      this.erroreOra=false;
      if (this.selectedOption === "1") {
        if (this.selectedGiorni?.length < 1) {
          this.loadingProva = false;
          return;
        }
      }
      if (this.createFormDue.invalid) {
        this.loadingProva = false;
        return;
      }
      if (this.createForm.invalid) {
        this.loadingProva = false;
        return;
      }
      if(this.createFormDue.value.dataA <this.createFormDue.value.dataDa){
        // this.alertService.error("errore nell'inserimento dei giorni")
        this.loadingProva = false
        this.erroreData=true;
        this.cd.markForCheck();
        return
      }
      if(this.createFormDue.value.oraA <this.createFormDue.value.oraDa){
        //this.alertService.error("errore nell inserimento delle ore")
        this.erroreOra=true;
        this.loadingProva = false
        this.cd.markForCheck();
        return
      }

      //TMS 7-10-2021 Bug Fix GAP-446
      if (this.createForm.value.docente === undefined ||
        this.createForm.value.docente === null ||
        this.createForm.value.docente == '') {
        this.loadingProva = false

      return
    }
    let ripetizione
    if (this.selectedOption === '1' && this.selectedGiorni.length > 0) {
      ripetizione = 1
    } else {
      ripetizione = 0
      this.selectedGiorni = []
    }
    let minutiDa
    if (this.createFormDue.value.oraDa.getMinutes().toString() == 0) {
      minutiDa = "00";
    } else {
      minutiDa = this.createFormDue.value.oraDa.getMinutes()
    }
    let minutiA
    if (this.createFormDue.value.oraA.getMinutes().toString() == 0) {
      minutiA = "00"
    } else {
      minutiA = this.createFormDue.value.oraA.getMinutes()
    }
    let lezione = {
      nome: this.createForm.value.nome,
      docente: this.createForm.value.docente,
      dataDa: this.createFormDue.value.dataDa.getDate().toString() + '-' + (parseInt(this.createFormDue.value.dataDa.getMonth()) + 1).toString() + '-' + this.createFormDue.value.dataDa.getFullYear().toString(),
      dataA: this.createFormDue.value.dataA.getDate().toString() + '-' + (parseInt(this.createFormDue.value.dataA.getMonth()) + 1).toString() + '-' + this.createFormDue.value.dataA.getFullYear().toString(),
      oraDa: this.createFormDue.value.oraDa.getHours().toString() + ':' + minutiDa + ':00',
      oraA: this.createFormDue.value.oraA.getHours().toString() + ':' + minutiA + ':00',
      giorni: this.selectedGiorni,
      ripetizione: ripetizione,
    }
    if (this.modifica) {
      this.classroomService.modifica_schedule(this.aulaInfo.idAula, this.idGruppoLezioni, lezione).subscribe((res) => {
        this.loadingProva = false;
        this.service.changeSchedule(true);
        this.alertService.success(res.status.description)
        this.indietro.emit(true)
      }, (error) => {
        this.loadingProva = false;
        this.cd.markForCheck();

      })
    } else {
      this.classroomService.createLezione(this.aulaInfo.idAula, lezione).subscribe((res) => {
        this.loadingProva = false;
        this.service.changeSchedule(true);
        this.alertService.success(res.status.description)
        this.indietro.emit(true)
      }, (error) => {
        this.loadingProva = false;
        this.cd.markForCheck();

        })
      }
  }

  clear() {
    this.indietro.emit(true);
  }

}

