import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/HELPERS/auth.guard';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ModificaAulaComponent } from './configurazione/aula-virtuale-config/modifica-aula/modifica-aula.component';
import { ConfigurazioneComponent } from './configurazione/configurazione.component';
import { CreaUtenteComponent } from './crea-utente/crea-utente.component';
import { GestioneClientiComponent } from './gestione-clienti/gestione-clienti.component';

import { GestioneGruppiComponent } from './gestione-gruppi/gestione-gruppi.component';
import { GestioneProfiliComponent } from './gestione-profili/gestione-profili.component';
import { GestioneTecniciComponent } from './gestione-tecnici/gestione-tecnici.component';
import { GestioneUtenteComponent } from './gestione-utente/gestione-utente.component';
import { LayoutComponentPannello } from './layout.component';

import { ReportComponent } from './report/report.component';
import {GestioneMediciComponent} from "./gestione-medici/gestione-medici.component";

const routes: Routes = [
    {
       path: '', component: LayoutComponentPannello, canActivate: [AuthGuard],
        children: [
            { path: 'creautente', component: CreaUtenteComponent, canActivate: [AuthGuard] },
            { path: 'gestioneutenti', component: GestioneUtenteComponent, canActivate: [AuthGuard] },
            { path: 'gestionemedici', component: GestioneMediciComponent, canActivate: [AuthGuard] },
            { path: 'gestioneassistiti', component: GestioneClientiComponent, canActivate: [AuthGuard] },
            { path: 'gestioneprofili', component: GestioneProfiliComponent, canActivate: [AuthGuard]},
            { path: 'gestionegruppi', component: GestioneGruppiComponent, canActivate: [AuthGuard]},
            { path: 'gestionetecnici', component: GestioneTecniciComponent, canActivate: [AuthGuard]},
            { path: 'report', component: ReportComponent, canActivate: [AuthGuard]},
            { path: 'configurazione', component: ConfigurazioneComponent, canActivate: [AuthGuard]},
            { path: 'editAula/:id', component: ModificaAulaComponent, canActivate: [AuthGuard]},
            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class PannelloControlloRoutingModule { }
