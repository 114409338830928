

    <p-confirmDialog key="ritorno" #cd  icon="pi pi-exclamation-triangle" [style]="{width:  responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
        <p-footer>
           
            <button [disabled]="loading"   type="button" pButton icon="pi pi-check" label="Sì" (click)="goBackScorm()">
                <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
            </button>
            <button [disabled]="loading" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
    
        </p-footer>
    </p-confirmDialog>
   <div class="card-title">
        <button (click)="ritorno()" id="back-button" class="btn btn-round  btn-just-icon"
            style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
            <i class="material-icons notranslate">arrow_back</i>
        </button>
        <h4  style="display: inline;">{{scorms.nomeCorso}}</h4>
        </div>
       
    <div *ngIf="caricato" class="app-container bg-light" style="height: 90vh;  margin-bottom: 50px;" >
    
        <ng-scorm-player [urlDirSco]="scorms.path" [scormResult]=""></ng-scorm-player>
        
        <!-- <ng-scorm-player [openInNewWindow]="'/Users/luca/scorm/Scorm 1.2 Edition'" [scormResult]=""></ng-scorm-player> -->
        <!-- <ng-scorm-player [urlLaunchPage]="'/assets/scorm/ScormDefinitivo'" [scormResult]=""></ng-scorm-player> -->
       </div>  
    
