import {
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import {
  OpenviduSessionComponent,
  StreamEvent,
  Session,
  UserModel,
  OpenViduLayout,
  OvSettings,
  OpenViduLayoutOptions,
  SessionDisconnectedEvent,
  Publisher,
  OpenVidu,
  OpenviduSessionModule,
  SignalOptions,
  SignalEvent,
  Connection,
} from 'openvidu-angular';
import { Utente } from 'src/app/MODELS/USER/utente';
import { ViewChild } from '@angular/core';
import { ruolo } from 'src/app/MODELS/USER/ruolo';
import { OperatorService } from 'src/app/SERVICES/ASSISTENZA/operator.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { DOCUMENT } from '@angular/common';
import { encode } from 'punycode';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { LavagnaService } from 'src/app/SERVICES/CLASSROOM/lavagna.service';
import { UserStream } from 'src/app/MODELS/ASSISTENZA/user_stream.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvService } from 'src/app/SERVICES/GENERAL/..';
import { AlertService } from 'src/app/SERVICES/GENERAL/..';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { AccountService } from 'src/app/SERVICES/index';
import { Sondaggio } from 'src/app/MODELS/CLASSROOM/sondaggio';
import { CallRateService } from 'src/app/SERVICES/CLASSROOM/callRate.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-classroom-lezione-in-corso',
  templateUrl: './classroom-lezione-in-corso.component.html',
  styleUrls: ['./classroom-lezione-in-corso.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in')),
    ]),
    trigger('rotatedStateMicrophone', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in')),
    ]),
  ],
})
export class ClassroomLezioneInCorsoComponent implements OnInit, OnDestroy {
  aulaInfo: any;
  @Output() indietro = new EventEmitter<boolean>();
  @Output() feedbackDialog = new EventEmitter<boolean>();
  listPublisher: any = [];
  isModerator = false;
  permessi = false;
  stream;
  searchform: FormGroup;
  listaUtenti = [];
  allUser = [];
  isOpenvidu = false;
  showCameraSettings = false;
  idAula;
  ruoloUser;
  showAlertRelatori = true;
  intervallo1: any;
  intervallo2: any;
  aulaChiusa = false;
  //Variabili openvidu
  openviduSessionModule: OpenviduSessionModule;
  mediaDevice: OpenVidu = new OpenVidu();
  mySessionId = '';
  myUserName = '';
  recording = false;
  idRecording;
  user: Utente = new Utente();
  tokens = [];
  session = false;
  ovSession: Session;
  ovLocalUsers: UserModel[];
  ovLayout: OpenViduLayout;
  ovLayoutOptions: OpenViduLayoutOptions;
  ovSettings: OvSettings;
  ovPublisher: Publisher;
  chiudi = false;
  connectionId: string;
  appello = true;
  counterIphone = 0;
  @ViewChild('ovSessionComponent')
  public ovSessionComponent: OpenviduSessionComponent;
  //Fine Variabili openvidu
  isFullScreen: boolean;
  @ViewChild('fullScreen') divRef;
  msgs: Message[] = [];
  subscription: Subscription;
  subscription2: Subscription;
  //smartVision inizio
  openLavagna = false;
  smartVision = false;
  fullScreen = false;
  screenHeight;
  //smartVision fine
  //#region var Lavagna start
  videoElement: HTMLVideoElement;
  openedByOtherUser = false;
  userStreams: UserStream[] = []; //email e idHTMLVideoElement
  /**
   * Caso in cui tutti hanno lo stream spento
   */
  messaggioDefault: UserStream[] = [
    new UserStream('', 'Nessuna istantanea disponibile'),
  ];
  userCreateCR: UserStream; //Utente che crea la CR
  immagineWhiteboard: string = '';
  /**
   * variabile che contiene la sottoiscrizione dell'immagine
   */
  subscriptionWhiteboard: Subscription = null;
  /**
   * variabile per la sottoiscrizione all'update
   */
  subscriptionUpdateWhiteboard: Subscription = null;

  /**
   * Serve per fare in modo che una volta aperta la lavagna, se
   * arriva un ulteriore chiamata dalla socket di non riaprila
   * questa variabile ritorna false dopo 5.
   *
   * Il salvataggio nella cache impiega del tempo per aggiornarsi
   * questo causa un apertura di molte pagine, con questa variabile
   * si evita
   */
  lavagnaActiveFix = false;
  envLavagna;
  isFirstTimeRelatore = true;

  /**
   * Se vero il componente lavagna è attivo in Desktop
   */
  isActiveLavagnaComponent = false;
  //#endregion

  //#region Sondaggio
  /**
   * popolato dalla socket in base a cio' che scrive il docente
   */
  messaggioDocente: string = '';
  /**
   * ricezione dei dati riguardo il sondaggio dalla socket
   */
  subscriptionSondaggio: Subscription = null;
  /**
   * Se il docente vuole un sondaggio verrà visualizzato un popup
   * con si e no, altrimenti un semplice chiuditi
   */
  isSondaggio = false;

  /**
   * Utile per prelevare i dati del form
   */
  sondaggioForm: FormGroup;

  /**
   * per aprire il popUp lato docente
   *
   * Vero aperto
   *
   * False chiuso
   */
  popupInsegnante = false;

  /**
   * per aprire il popUp lato studente.
   *
   * Vero aperto
   *
   * False chiuso
   */
  popupStudente = false;

  /**
   * un interval per calcolare il tempo di inattività
   */
  timerSondaggio: NodeJS.Timeout;

  /**
   * tempo in secondi dell'inattività
   */
  tempoInattivita: number = 0;

  /**
   * Vogliamo che la lista degli utenti abbia la stessa altezza di openVidu.
   *
   * Questa variabile ci permette di ottenere l'altezza del componente di openVidu, che la salviamo
   * in un altra variabile
   * @see {@link altezzaOpenVidu}
   */
  @ViewChild('containerOpenvidu') containerOpenVidu: ElementRef;

  /**
   * Altezza del componente di openVidu
   */
  altezzaOpenVidu: number;

  isRecording = false;
  nomeFile = '';

  screenSize;
  showMenu = false;
  isRelatore = false;
  isRelatoreInsegnante = false;
  isFirstTimeSessionCreated = true;
  urlEnv = '';
  isFirstTimeActivated = true;
  isFirstTimeCreated = true;
  recordingSubscribe;
  //#endregion
  envRecording;
  isFirstTimeAttiva: boolean = true;
  registrazioneOver = false;
  utenteOriginario;
  formVideo: FormGroup;
  eventLavagna;
  lavagnaIntervall;

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    private operatorService: OperatorService,
    private formBuilder: FormBuilder,
    private classroomService: ClassroomService,
    @Inject(DOCUMENT) private document: any,
    private shareService: ShareService,
    private lavagnaService: LavagnaService,
    private elementRef: ElementRef,
    private envService: EnvService,
    private crService: CallRateService,
    //smartVision inizio
    private deviceService: DeviceDetectorService // smartVision fine
  ) {
    this.screenSize = window.innerWidth;
    this.ruoloUser = this.accountService.ruolo;
    this.utenteOriginario = this.accountService;
    this.envLavagna = this.envService.LAVAGNA;
    this.envRecording = this.envService.RECORDING;
    //smartVision inizio
    this.shareService.scegliAula$.subscribe((res) => {
      this.aulaInfo = res;
    });

    if (this.deviceService.isMobile()) {
      this.smartVision = true;
      this.screenHeight = window.innerHeight;
    }
    let thisFunction = this;
    window.addEventListener('resize', function (event) {
      thisFunction.refreshVideo();
      thisFunction.screenSize = window.innerWidth;
    });
    this.document.addEventListener('fullscreenchange', () => {
      thisFunction.refreshVideo();
    });
    this.formVideo = this.formBuilder.group({
      nome: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
        ],
      ],
    });
    //smartVision fine
    let constraints = {
      video: true,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((res) => {
        this.stream = res;
        this.permessi = true;

        this.ovSettings = {
          chat: true,
          autopublish: false,
          toolbarButtons: {
            audio: true,
            video: true,
            screenShare: true,
            fullscreen: false,
            layoutSpeaking: false,
            exit: false,
          },
        };

        this.joinSession();
      })
      .catch((err) => this.reload());

    this.searchform = this.formBuilder.group({
      search: ['', Validators.required],
    });
    //#region Sondaggio
    //cattura del valori nel formcontrol html
    this.sondaggioForm = this.formBuilder.group({
      messaggio: [''],
      sondaggio: [false],
    });
    //#endregion
  }
  get f() {
    return this.formVideo.controls;
  }
  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    if (!this.aulaChiusa && !this.notOpenFeedbackDialog) {
      this.feedbackDialog.emit();
    }
    if (this.recordingSubscribe) this.recordingSubscribe.unsubscribe();
    clearInterval(this.intervallo1);
    clearInterval(this.intervallo2);
    clearInterval(this.lavagnaIntervall);
    this.appello = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.user?.email) {
      if (!this.aulaChiusa) {
        this.classroomService
          .confermaAssenzaStudente(
            this.aulaInfo.idAula,
            this.aulaInfo,
            this.user.email
          )
          .subscribe((res) => {
            this.listaUtenti.forEach((utente) => {
              if (utente.email === this.user.email) {
                utente.relatore = false;
              }
            });
          });
      }
    }

    localStorage.removeItem('classroom');
    if (!this.chiudi) {
    }
    localStorage.removeItem('openviduCallNickname');
    if (this.stream) {
      const track = this.stream.getTracks();
      track[0].stop();
      track[1].stop();
    }
    //lavagna start ngOndestroy
    this.lavagnaService.changeBackgroundImageWhiteboard(null);
    this.subscriptionUpdateWhiteboard.unsubscribe();
    this.lavagnaService.changeidAula(-1);
    if (this.lavagnaService.isDocente()) {
      //mando un messaggio agli studenti di chiusura lavagna
      this.lavagnaService
        .updateBackgroundCanvas('END', this.aulaInfo.idAula)
        .subscribe((res) => { });
      //per chiudure la subscribe
      this.lavagnaService
        .imageBackgroundCanvas(null, this.aulaInfo.idAula)
        .subscribe((res) => { });
    } else {
      this.subscriptionWhiteboard.unsubscribe();
    }
    localStorage.removeItem('backgroundWhiteboard');
    localStorage.removeItem('idAula');
    localStorage.removeItem('enableWhiteboard');
    //lavagna end

    //#region sondaggio
    if (this.subscriptionSondaggio) {
      if (!this.subscriptionSondaggio.closed) {
        this.subscriptionSondaggio.unsubscribe();
      }
    }

    if (this.lavagnaService.isDocente()) {
      this.classroomService.saveDBPool(this.aulaInfo.idAula).subscribe((res) => { });
    }
    this.notOpenFeedbackDialog = false;
    //#endregion
    this.indietro.emit(true);
  }

  ngOnInit(): void {
    if (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform)
    ) {
      this.counterIphone = 2;
    }
    this.urlEnv = this.envService.apiUrl;
    let utenteAttuale;
    this.idAula = (
      this.aulaInfo.nomeAula +
      '_QlXXh-g43S_' +
      this.aulaInfo.idAula
    ).replace(/\s/g, '');
    this.classroomService.checkRec(this.aulaInfo.idAula).subscribe((res) => {
      if (res && res.data === true) {
        this.isRecording = true;
        this.idRecording = res.data;
      } else {
        this.isRecording = false;
      }
    });
    this.listaUtenti = this.aulaInfo.listaUtenti;
    this.allUser = this.listaUtenti;
    this.listaUtenti.forEach((utente) => {
      if (utente.email === this.user.email) {
        utente.relatore = false;
      }
    });
    //#region LAVAGNA start
    //se sono un docente
    if (this.lavagnaService.isDocente()) {
      //apro il canale nel BE
      this.lavagnaService
        .creaCanaleLavagna(this.aulaInfo.idAula)
        .subscribe((res) => { });
    }
    //#endregion
    if (this.ruoloUser == 1 || this.ruoloUser == 4) {
      this.recordingSubscribe = this.shareService.registrazione$.subscribe(
        (res) => {
          if (res) {
            if (
              res.registrazione.id == this.idAula &&
              this.accountService.userValue.data.id == res.idUtente
            ) {
              this.isRecording = false;
              this.registrazioneOver = true;
            }
          }
        }
      );
    }
    //#region Sondaggio
    this.shareService.sondaggio$.subscribe((res: Sondaggio) => {
      if (res) {
        //res not null
        this.isSondaggio = res.sondaggio;
        this.messaggioDocente = res.messaggio;
        if (this.lavagnaService.mioId() != res.idMittende) {
          //non sono il mittende del sondaggio
          // if (this.ruoloUser != 4 && this.ruoloUser != 1) {
          //non sono nè un docente nè un admin
          //apro il popupstudente
          this.closeFullscreen();
          this.popupStudente = true;
          this.timerSondaggio = setInterval(() => {
            this.tempoInattivita++;
          }, 1000); //5min 300s*1000=300000 ms
          //TMS 29-09-2021 FIX GAP-412
          this.shareService.changeSondaggio(null);
          // }
        }
      }
    });
    //#endregion

    this.intervallo1 = setInterval(() => {
      if (this.appello) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.subscription = this.classroomService
          .listaStudentiPresenti(this.aulaInfo.idAula, this.idAula)
          .subscribe((res) => {
            this.listaUtenti.forEach((utenteAula) => {
              utenteAula.presenza = 0;
              // .studentiAttesa
              res.data.studentiAttesa.forEach((utentePresente) => {
                if (utenteAula.id === utentePresente.studente.id) {
                  utenteAula.presenza = 2;
                  if (utentePresente.relatore === 1) {
                    utenteAula.relatore = true;
                  } else if (utentePresente.relatore === 0) {
                    utenteAula.relatore = false;
                  }
                }
              });
              res.data.studentiPresenti.forEach((utentePresente) => {
                if (utenteAula.id === utentePresente.studente.id) {
                  utenteAula.presenza = 1;
                  if (utentePresente.relatore === 1) {
                    utenteAula.relatore = true;
                  } else if (utentePresente.relatore === 0) {
                    utenteAula.relatore = false;
                  }
                }
              });
            });
            this.listaUtenti = this.listaUtenti.sort((a, b) =>
              a.presenza > b.presenza ? -1 : a.presenza < b.presenza ? 1 : 0
            );

            this.allUser = this.listaUtenti;
          });
      }
    }, 5000);

    setTimeout(() => {
      this.intervallo2 = setInterval(() => {
        if (this.appello) {
          this.subscription2 = this.classroomService
            .contatorePresenza(this.aulaInfo.idAula)
            .subscribe((res) => { });
        }
      }, 60000);
    }, 10000);
    //#region LAVAGNA start
    //appena accedo alla classroom cambio il valore di idAula
    //in modo che appcomponent esegue l'iscrizione al sottocanale della socket
    //ma solo dopo un po, giusto il tempo che il BE avvii il canale
    setTimeout(() => {
      this.lavagnaService.changeidAula(this.aulaInfo.idAula);
    }, 5000);
    //rimango in ascolto del backgroundImage
    //quando il docente apre la lavagna la manda sulla socket
    //questo è l'unico caso che la var backgroundImage avrà valore
    // if (!this.lavagnaService.isDocente()) {
    //se sono uno studente rimango in ascolto in background
    this.subscriptionWhiteboard =
      this.lavagnaService.backgroundImage$.subscribe((imageString) => {
        if (imageString) {
          let attivo = localStorage.getItem('enableWhiteboard') || null;
          //Se attivo non è null allora la lavagna è presente
          if (attivo != null) {
            return;
          }
          //salvo in memoria cache lo stato di apertura del canale
          localStorage.setItem('enableWhiteboard', 'ON');
          //non è null quando:
          //Il docente apre la lavagna la prima volta e gli utenti stanno in ascolto
          //se l'utente ancora deve entrare nella lavagna apre la finestra
          //apro la finestra
          setTimeout(() => {
            //salvo idAula per aprire la socket
            localStorage.setItem('idAula', JSON.stringify(this.aulaInfo.idAula));
            localStorage.setItem('backgroundWhiteboard', imageString);
            this.openedByOtherUser = true;
            //apro una seconda finestra dopo 2s
            if (this.smartVision && !this.fullScreen) {
              this.apriLavagnaSmart();
              this.isActiveLavagnaComponent = true;
              this.closedLavagna = true;
            } else {
              // window.open("/#/lavagna", "", "width=600,height=400,left=200,top=200")
              this.isActiveLavagnaComponent = true;
              this.closedLavagna = true;
              this.openFullscreen();
            }
            this.refreshVideo();
          }, 2000);
        } else {
          //se imageString è null non è detto che la lavagna non sia attiva
        }
      });
    // }
    //Questa riceve ogni aggiornamento della lavagna, qualora
    //lo studente chiude per errore la lavagna potrà sempre recuperarla
    //Sta qui anche qualora ci sono 2 docenti e 1 sbaglia a chiudere
    this.subscriptionUpdateWhiteboard =
      this.lavagnaService.updateCanvas$.subscribe((res: string) => {
        if (res === 'END') {
          // if (!this.lavagnaService.isDocente()) {
          //lato studente chiude la lavagna
          this.isActiveLavagnaComponent = false;
          this.refreshVideo(); //riadatta il video alla chiusura della lavagna
          // }
        }
        //INIZIO Fix dell'apertura molteplice delle finestre
        //dovute alla velocità di scrittura lenta della cache
        if (this.lavagnaActiveFix) {
          return;
        }
        this.lavagnaActiveFix = true;
        setTimeout(() => {
          this.lavagnaActiveFix = false;
        }, 5000);
        //FINE FIX

        let attivo = localStorage.getItem('enableWhiteboard') || null;
        //Se attivo non è null allora la lavagna è presente
        if (attivo != null) {
          return;
        }
        //chiamo il BE e prelevo immagine
        this.lavagnaService
          .findImageWhiteboard(this.aulaInfo.idAula)
          .subscribe((res: any) => {
            let imageString = res.data.imageString;
            if (imageString) {
              //se la lavagna non è attiva imageString è null, se è attiva
              //apro la finestra
              // if (!this.lavagnaService.isDocente()) {
              localStorage.setItem('enableWhiteboard', 'ON');
              setTimeout(() => {
                //salvo idAula per aprire la socket
                localStorage.setItem(
                  'idAula',
                  JSON.stringify(this.aulaInfo.idAula)
                );
                localStorage.setItem('backgroundWhiteboard', imageString);
                //apro una seconda finestra dopo 2s
                if (this.smartVision && !this.fullScreen) {
                  this.apriLavagnaSmart();
                } else {
                  // window.open("/#/lavagna", "", "width=600,height=400,left=200,top=200")
                  this.isActiveLavagnaComponent = true;
                  this.openFullscreen();
                }
              }, 2000);
              // }
            } else {
              localStorage.removeItem('idAula');
              localStorage.removeItem('backgroundWhiteboard');
              localStorage.removeItem('enableWhiteboard');
            }
          });
      });
    //#endregion
    //smartVision inizio
    this.inzializzaSmartVision();
    //smartVision fine
  }
  notOpenFeedbackDialog = false;
  reload() {
    this.notOpenFeedbackDialog = true;
    this.alertService.error('Per continuare, è necessario fornire i permessi');
    this.chiudi = true;
    this.session = false;
    this.tokens = [];
    this.indietro.emit(true);
  }

  searchInUser() {
    if (this.listaUtenti !== null) {
      let valore: string = this.searchform.value.search;
      this.listaUtenti = this.allUser;
      if (valore) {
        let users = [];
        this.listaUtenti.forEach((user) => {
          let nomeCognome = user.nome + ' ' + user.cognome;
          let cognomeNome = user.cognome + ' ' + user.nome;
          if (
            cognomeNome
              .toLocaleLowerCase()
              .includes(valore.toLocaleLowerCase()) ||
            nomeCognome
              .toLocaleLowerCase()
              .includes(valore.toLocaleLowerCase()) ||
            user.nome.toLowerCase().includes(valore.toLowerCase()) ||
            user.cognome.toLowerCase().includes(valore.toLowerCase())
          ) {
            if (!users.find((x) => x === user)) {
              users.push(user);
            }
          }
        });
        this.listaUtenti = users;
      } else if (this.listaUtenti !== this.allUser) {
        this.listaUtenti = this.allUser;
      }
    }
  }

  //////////////////////////////////Metodi Openvidu

  joinSession(isFromChangeCam = false) {
    let token1;
    let token2;

    let ruoloUser: ruolo = new ruolo();
    ruoloUser.id = this.accountService.ruolo;
    ruoloUser.nome_ruolo = this.accountService.userValue.data.ruolo.nome_ruolo;
    this.user.id = this.accountService.userValue.data.id;
    this.user.nome = this.accountService.userValue.data.nome;
    this.user.cognome = this.accountService.userValue.data.cognome;
    this.user.ruolo = ruoloUser;
    this.user.email = this.accountService.userValue.data.email;
    this.mySessionId = this.user.email;
    this.myUserName = this.user.email;
    this.classroomService
      .joinAula(this.idAula, this.user, isFromChangeCam || false)
      .subscribe(
        (res) => {
          if (res.data[0].indexOf('MODERATOR') !== -1) {
            this.isModerator = true;
            this.isRelatoreInsegnante = true;
            this.user.relatore = true;
          }
          localStorage.setItem('classroom', JSON.stringify(this.aulaInfo.idAula));
          token1 = res.data[0];
          token2 = res.data[1];
          this.tokens.push(token1);
          this.tokens.push(token2);
          this.session = true;
          if (!isFromChangeCam) {
            this.confermaPresenza();
          }
        },
        (error) => {
          this.crService.feedBack = false;
          this.aulaChiusa = true;
          this.indietro.emit(true);
        }
      );
  }
  rememberEvent;
  async cambiaEvidenza(event, changedCam = false) {
    if (this.ruoloUser == 1 || this.ruoloUser == 4 || changedCam) {
      let signal: SignalOptions = {};
      signal.data = event;
      signal.type = 'changeEvidence';
      await this.ovSession.signal(signal);
    }
    // else {
    //   let signal: SignalOptions = {};
    //   signal.data = event;
    //   signal.type = 'changeEvidence';
    //   signal.to = [this.ovSession.connection]
    //   this.ovSession.signal(signal);
    // }
  }

  confermaPresenza() {
    if (this.accountService.ruolo !== 1 || this.accountService.ruolo !== 4) {
      this.classroomService
        .confermaPresenzaStudente(this.aulaInfo.idAula, this.user.email)
        .subscribe((res) => { });
    }
  }

  handlerSessionCreatedEvent(session: Session): void {
    this.ovSession = session;
    this.shareService.changeCloseLateralMenu(new Date());
    let footer = document.getElementById('footer');
    footer.style.minWidth = 'initial !important';
    //lavagna start per visualizzare la drop up solo degli utenti attivi

    session.on('streamPropertyChanged', (event: StreamEvent) => {
      // if(!this.isModerator) {
      //   try {
      //     this.ovPublisher.session.unpublish(this.ovPublisher);
      //   } catch (error) {
      //     console.error(error);
      //   }
      // }
      let utente = JSON.parse(event.stream.connection.data);
      let email = utente.clientData;
      //scorro array e cerco quell'utente
      let i = 0;
      for (; i < this.userStreams.length; i++) {
        if (email === this.userStreams[i].email) {
          //trovato l'utente cambio la proprietà
          this.userStreams[i].videoActive = event.stream.videoActive;
        }
      }
    });
    //lavagna end
    session.on('streamCreated', (event: StreamEvent) => {
      this.ovSession = session;
      let utente = JSON.parse(event.stream.connection.data);
      let email = utente.clientData;
      //
      if (this.isFirstTimeCreated) {
        if (!this.isModerator) {
          this.isFirstTimeCreated = false;
          let signal: SignalOptions = {};
          signal.data = 'disattivaWebMic';
          signal.type = 'disattivaWebMic';
          signal.to = [this.ovSession.connection];
          this.ovSession.signal(signal);
        }
      } else {
        if (
          !this.isModerator &&
          email === this.accountService.userValue.data.email
        ) {
          let signal: SignalOptions = {};
          signal.data = 'disattivaWebMic';
          signal.type = 'disattivaWebMic';
          signal.to = [this.ovSession.connection];
          this.ovSession.signal(signal);
        }
      }
      //LAVAGNA START
      //mi salvo id del tag che contiene il video
      let idStream = '#container-' + event.stream.streamId;
      //salvo le info dentro array
      let userStream = new UserStream(idStream, email);
      userStream.videoActive = event.stream.videoActive;
      this.userStreams.push(userStream);
      //LAVAGNA STOP
      if (!email.includes('_SCREEN')) {
        this.classroomService
          .confermaPresenzaStudente(this.aulaInfo.idAula, email)
          .subscribe((res) => { });
      }
    });

    this.ovSession.on('signal:changeEvidence', (event: any) => {
      if (this.rememberEvent === event.data)  {this.rememberEvent = undefined;}
      else {this.rememberEvent = event.data;}
    });
    session.on('signal:disattivaWebMic', (event: SignalEvent) => {
      this.listaUtenti.forEach((utente) => {
        if (
          utente.id === this.accountService.userValue.data.id &&
          !this.isFirstTimeRelatore
        ) {
          // if(utente.relatore){
          this.isRelatore = true;
          if (this.showAlertRelatori) {
            if (this.counterIphone === 0) {
              this.alertService.warn('Sei stato rimosso dai relatori');
            } else {
              this.counterIphone = this.counterIphone - 1;
            }
          }
          // }
        }
      });
      this.isFirstTimeRelatore = false;
    });

    session.on('signal:attivaWebMic', (event: SignalEvent) => {
      this.listaUtenti.forEach((utente) => {
        if (utente.id === this.accountService.userValue.data.id) {
          if (
            (!utente.relatore && this.isRelatore) ||
            this.isFirstTimeActivated
          ) {
            this.isFirstTimeActivated = false;
            this.isRelatore = false;
            if (this.showAlertRelatori) {
              this.showAlertRelatori = false;
              if (this.counterIphone === 0) {
                this.alertService.warn('Sei stato aggiunto ai relatori');
              } else {
                this.counterIphone = this.counterIphone - 1;
              }
              setTimeout(() => {
                this.showAlertRelatori = true;
              }, 3000);
            }
            utente.relatore = true;
          }
        }
      });
    });

    session.on('signal:manoAlzata' + this.idAula, (event: SignalEvent) => {
      let messaggioPresente = false;
      this.msgs.forEach((messaggio) => {
        if (messaggio.summary === event.data) {
          messaggioPresente = true;
        }
      });
      if (!messaggioPresente) {
        this.msgs.unshift({
          severity: 'info',
          summary: event.data,
          detail: 'ha alzato la mano',
        });
        if (this.msgs.length > 5) {
          this.msgs.pop();
        }
      }
    });

    session.on('streamDestroyed', (event: StreamEvent) => {
      //LAVAGNA START
      //Cancello nell'array l'utente che esce dalla sessione, per farlo mi prendo email
      let utente = JSON.parse(event.stream.connection.data);
      let email = utente.clientData;
      //scorro array e cerco quell'utente
      for (var i = 0; i < this.userStreams.length; i++) {
        if (email === this.userStreams[i].email) {
          //trovato l'utente lo cancello
          let a = this.userStreams.splice(i, 1);
        }
      }
      //LAVAGNA STOP
    });

    session.on('sessionDisconnected', (event: SessionDisconnectedEvent) => {
      if (!this.user.email.includes('_SCREEN') && this.user.email) {
        if (!this.aulaChiusa) {
          this.classroomService
            .confermaAssenzaStudente(
              this.aulaInfo.idAula,
              this.idAula,
              this.user.email
            )
            .subscribe((res) => {});
        }
      }
      if (
        this.isActiveLavagnaComponent &&
        (this.ruoloUser == 1 || this.ruoloUser == 4)
      ) {
        this.lavagnaService.richiestaFormData(true);
      }

      this.chiudi = true;
      this.session = false;
      this.tokens = [];
      //     setInterval(()=>{
      // if(this.ev){
      //   this.indietro.emit(true);
      // }
      //     },100)
    });
    session.on('signal:muteMicrophone', (event: SignalEvent) => {
      let obj = JSON.parse(event.data);
      if (obj.id === this.user.id) {
        try {
          let a = document.getElementById('navMicrophoneButton');
          a.click();
        } catch (error) {}
      }
    });
    this.myMethod();
  }

  handlerPublisherCreatedEvent(publisher: Publisher) {
    this.ovPublisher = publisher;
    this.crService.feedBack = true;

    publisher.on('streamCreated', (e) => {
      if (this.isFirstTimeSessionCreated) {
        this.isFirstTimeSessionCreated = false;
        if (!this.isModerator) {
          const listPublisher = publisher;
          this.listPublisher = listPublisher;

          let signal: SignalOptions = {};
          signal.data = 'disattivaWebMic';
          signal.type = 'disattivaWebMic';
          signal.to = [this.ovSession.connection];
          this.ovSession.signal(signal);
          // this.ovPublisher.session.unpublish(this.ovPublisher);
        }
        this.ovPublisher = publisher;

        if (
          this.accountService.ruolo !== 1 &&
          this.accountService.ruolo !== 4
        ) {
          this.listaUtenti.forEach((utente) => {
            if (utente.id === this.accountService.userValue.data.id) {
              if (!utente.relatore && !this.isModerator) {
                let signal: SignalOptions = {};
                signal.data = 'disattivaWebMic';
                signal.type = 'disattivaWebMic';
                signal.to = [this.ovSession.connection];
                this.ovSession.signal(signal);
              }
            }
          });
        }
      }
    });
  }

  handlerErrorEvent(event): void {}

  myMethod() {
    this.ovLocalUsers = this.ovSessionComponent.getLocalUsers();
    this.ovLayout = this.ovSessionComponent.getOpenviduLayout();
    this.ovLayoutOptions = this.ovSessionComponent.getOpenviduLayoutOptions();
  }

  alzaLaMano() {
    //servizio conteggio alzate di mano
    this.classroomService
      .update_mani_alzate(
        this.aulaInfo.idAula,
        this.accountService.userValue.data.id
      )
      .subscribe(
        (res) => {
          let signal: SignalOptions = {};
          signal.data =
            this.accountService.userValue.data.nome +
            ' ' +
            this.accountService.userValue.data.cognome;
          signal.type = 'manoAlzata' + this.idAula;
          this.ovSession.signal(signal);
        },
        (error) => {}
      );
  }
  isStillLoading = false;
  modificaRelatore(utente, isSelf?) {
    // this.ovSession.publish(this.listPublisher)
    if (!this.isStillLoading) {
      setTimeout(() => {
        this.isStillLoading = false;
      }, 5000);
      this.isStillLoading = true;
      if (isSelf) this.isRelatoreInsegnante = !this.isRelatoreInsegnante;
      let connessioni: any = this.ovSession.remoteConnections;

      this.classroomService
        .recuperaConnessioni(this.idAula)
        .subscribe((res) => {
          res.data.forEach((connessione) => {
            let obj = JSON.parse(connessione.clientData);
            if (obj.clientData === utente.email) {
              this.connectionId = connessione.connectionId;
            }
          });
          let connessione: Connection = connessioni[this.connectionId];
          if (connessione === undefined) {
            try {
              connessioni.forEach((element) => {
                if (element.connectionId === this.connectionId) {
                  connessione = element;
                }
              });
            } catch (error) {
              connessione = this.ovPublisher.session.connection;
            }
          }
          if (!utente.relatore) {
            //disattivo webcam e microfono
            this.classroomService
              .editRelatori(this.idAula, utente.id, 1, this.aulaInfo.idAula)
              .subscribe((res) => {
                let signal: SignalOptions = {};
                signal.data = 'attivaWebMic';
                signal.type = 'attivaWebMic';
                signal.to = [connessione];
                this.ovSession.signal(signal).then(() => {
                  // if (this.isFirstTimeAttiva) {
                  // signal.data = 'disattivaWebMic';
                  // signal.type = 'disattivaWebMic';
                  // this.ovSession.signal(signal).then(()=> {
                  //   signal.data = 'attivaWebMic';
                  //   signal.type = 'attivaWebMic';
                  setTimeout(() => {
                    this.ovSession.signal(signal).then(() => {
                      this.isStillLoading = false;
                    });
                  }, 2000);
                  // });
                  // this.isFirstTimeAttiva = false
                  // }
                });

                this.classroomService
                  .listaStudentiPresenti(this.aulaInfo.idAula, this.idAula)
                  .subscribe((res) => {
                    this.listaUtenti.forEach((utenteAula) => {
                      utenteAula.presenza = 0;
                      utenteAula.relatore = false;
                      utente.relatore = true;
                      res.data.studentiAttesa.forEach((utentePresente) => {
                        if (utenteAula.id === utentePresente.studente.id) {
                          utenteAula.presenza = 2;
                          if (utentePresente.relatore === 1) {
                            utenteAula.relatore = true;
                          } else if (utentePresente.relatore === 0) {
                            utenteAula.relatore = false;
                          }
                        }
                      });
                      res.data.studentiPresenti.forEach((utentePresente) => {
                        if (utenteAula.id === utentePresente.studente.id) {
                          utenteAula.presenza = 1;
                          if (utentePresente.relatore === 1) {
                            utenteAula.relatore = true;
                          }
                        }
                      });
                    });
                    this.allUser = this.listaUtenti;
                  });
              });
          } else {
            //attivo webcam e microfono
            this.classroomService
              .editRelatori(this.idAula, utente.id, 0, this.aulaInfo.idAula)
              .subscribe(async (res) => {
                // this.listaUtenti.forEach(x=>{
                //   if(x.id === utente.id){
                //     x.relatore = true;
                //   }
                // })
                // this.allUser = this.listaUtenti;
                if (this.rememberEvent === utente.email) {

                  let signal: SignalOptions = {};
                  signal.data = this.rememberEvent;
                  signal.type = 'changeEvidence';
                  this.ovSession.signal(signal).finally(()=>{
                    let signal: SignalOptions = {};
                    utente.relatore = false;
                    signal.data = 'disattivaWebMic';
                    signal.type = 'disattivaWebMic';
                    signal.to = [connessione];
                    this.ovSession.signal(signal).then(() => {
                      this.isStillLoading = false;
                    });
                  });
                } else {
                  let signal: SignalOptions = {};
                  utente.relatore = false;
                  signal.data = 'disattivaWebMic';
                  signal.type = 'disattivaWebMic';
                  signal.to = [connessione];
                  this.ovSession.signal(signal).then(() => {
                    this.isStillLoading = false;
                  });
                }
              });
          }
        });
    }
  }

  ////////////////////////////////////////Fine metodi openvidu

  ///////////////////////////////////////Inizio metodi fullscreen
  openFullscreen() {
    //se la lista è aperta chiudiamola in modo cha alla riapertura
    //si riadatta al componente di openvidu
    // this.showUserLavagna = false
    // //smartVision inizio
    // const fullscreen = this.divRef.nativeElement;
    // this.fullScreen = true;
    // if (this.smartVision) {
    //   this.chiudiLavagna()
    // }
    // //smartVision fine

    // if (fullscreen.requestFullscreen) {
    //   fullscreen.requestFullscreen();
    // } else if (fullscreen.mozRequestFullScreen) {
    //   /* Firefox */
    //   fullscreen.mozRequestFullScreen();
    // } else if (fullscreen.webkitRequestFullscreen) {
    //   /* Chrome, Safari and Opera */
    //   fullscreen.webkitRequestFullscreen();
    // } else if (fullscreen.msRequestFullscreen) {
    //   /* IE/Edge */
    //   fullscreen.msRequestFullscreen();

    // }
    // this.riadattamentoLista()
    this.refreshVideo();
  }
  /* Close fullscreen */
  closeFullscreen() {
    //se la lista è aperta chiudiamola in modo cha alla riapertura
    //si riadatta al componente di openvidu
    this.showUserLavagna = false;
    this.fullScreen = false;
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
    this.riadattamentoLista();
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreenmodes(event) {
    this.chkScreenMode();
  }
  chkScreenMode() {
    if (document.fullscreenElement) {
      //fullscreen
      this.isFullScreen = true;
    } else {
      //not in full screen
      this.isFullScreen = false;
    }
  }
  //////////////////////////////////////Fine metodi fullscreen

  cancellaMessaggio(messaggio) {
    this.msgs = this.msgs.filter((x) => x.summary !== messaggio.summary);
  }
  //////////////////////////////////////LAVAGNA
  //#region LAVAGNA metodi
  ngAfterViewInit(): void {
    //lavagna, salviamoci lo stream del docente
    this.userCreateCR = new UserStream(
      '#video-undefined',
      this.accountService.userValue.data.email
    );
    if (this.lavagnaService.isDocente()) {
      this.userCreateCR.videoActive = true;
    }
    this.userStreams.push(this.userCreateCR);
  }

  /**
   * Il metodo crea l'evento di ascolto video e acquisizione dell'immagine.
   * Visto che il video è uno stream, prima di acquisire l'immagine occorre ascoltare lo stream
   * @param index indice del vettore, quale schermata acquisire
   */
  apriFinestraConLavagna(index: number) {
    //punto all'elemento HTML contenuto nell'array,
    //di posizione scelta dal creatore, che clicca. Al suo interno è contenuto il video
    this.videoElement = document.querySelector(
      this.userStreams[index].idHTMLVideo
    );

    //variabile utilizzata per gestire il caso [0] cioè il creatore della CR
    let tmp = this.videoElement;

    //punto al video se non è il creatore, altrimenti null e gestisco il creatore con if
    this.videoElement = this.videoElement.querySelector('video') || null;

    //gestisco come caso particolare lo stream del creatore della CR:
    //poichè al momento non sono in grado di catturare HTMLVideoElement di chi crea la CR dal componente openVidu
    //per gestirla punto all'elemento idHTML figlio, generico, che contiene il video - #video-undefined
    //Generico poiché tutti gli stream hanno questo id, l'unico elemento univico è dato da un id che ha questa forma
    //#container-str etc... se puntiamo al generico, in automatico angular prende il primo che trova cioè a quello del
    //Creatore della CR
    if (index === 0) {
      //creatore della CR
      //attraverso offsetParent, recupero il tag container, che contiene, come tag figlio, lo stream video univico
      let idElement = tmp.offsetParent.id;
      //infine seleziono lo stream del creatore della CR, puntando al tag video
      tmp = this.elementRef.nativeElement.querySelector('#' + idElement); //lo seleziono
      this.videoElement = tmp.querySelector('video'); //e punto al video
    }
    //mi metto in ascolto di quel video
    let funzione = function () {}; //la variabile funzione temporanea, mi serve per rimuovere l'ascolto a fine acquisizione
    this.videoElement.addEventListener('loadedmetadata', funzione, false);
    //acquisizione screen e download
    this.inviaImmagineALavagna(this.videoElement, index);
    //rimuovo l'ascolto
    this.videoElement.removeEventListener('loadedmetadata', funzione, false);
    this.closedLavagna = true;
  }

  /**
   * Metodo per creare l'evento di apertura finestra, cioè si apre una finestra del browser dove il
   * docente può disegnare
   * @param index utente
   */
  inviaImmagineALavagna(htmlVideo: HTMLVideoElement, index: number) {
    //creo un oggetto che conterrà la mia immagine
    var canvas = document.createElement('canvas'),
      //velocizzo il metodo getContext, che serve per disegnare l'immagine
      ctx = canvas.getContext('2d');
    //fornisce le dimensioni all'immagine
    canvas.width = htmlVideo.offsetWidth;
    canvas.height = htmlVideo.offsetHeight;
    //l'effetto specchio nasce nel momento che cerco di catturare lo schermo, visto che
    //il creatore non ne è affetto quando provo a catturarlo, genero il problema.
    //Mentre per gli altri il loro stream è specchiato pertanto quando provo a catturarlo
    //i difetti si annullano
    //Elimino l'effetto a specchio solo per il CR
    if (index == 0) {
      //creatore della CR
      ctx.translate(canvas.width, 0); //elimino effetto a specchio
      ctx.scale(-1, 1);
    }
    //disegno l'immagine
    ctx.drawImage(htmlVideo, 0, 0, canvas.width, canvas.height);
    //converto immagine in una stringa
    this.immagineWhiteboard = canvas.toDataURL('image/png');
    //se non sono un docente non posso attivare la lavagna
    if (!this.lavagnaService.isDocente()) {
      return;
    }
    let attivo = localStorage.getItem('enableWhiteboard') || null;
    if (attivo) {
      return;
    }
    //abilito la variabile onde evitare che il docente
    //apre 2 volte la finestra
    localStorage.setItem('enableWhiteboard', 'ON');
    //REST
    //Mando immagine al BE in modo che quest'ultimo lo propaghi
    //tramite socket agli altri
    this.lavagnaService
      .imageBackgroundCanvas(this.immagineWhiteboard, this.aulaInfo.idAula)
      .subscribe((res) => { });
    //apro la finestra
    setTimeout(() => {
      //salvo nella cache immagine
      localStorage.setItem('backgroundWhiteboard', this.immagineWhiteboard);
      //salvo idAula per aprire la socket
      localStorage.setItem('idAula', JSON.stringify(this.aulaInfo.idAula));
      //apro una seconda finestra dopo 2s
      if (this.smartVision && !this.fullScreen) {
        this.apriLavagnaSmart();
      } else {
        // window.open("/#/lavagna", "", "width=600,height=400,left=200,top=200")
        this.isActiveLavagnaComponent = true;
        this.openFullscreen();
      }
    });
  }

  //nascondiamo la lavagna a chi non è docente
  isDocente(): boolean {
    return this.lavagnaService.isDocente();
  }

  accettaRifiutaUser(user, stato = false) {
    this.classroomService
      .impostaStato(this.aulaInfo.idAula, stato, [user.id])
      .subscribe((res) => { });
  }

  /**
   * Il metodo chiude la lavagna component, riutilizza i metodi per la chiusura dello schermo attivo e il refresh
   * adattivo degli stream di openvidu.
   * @see {@link closeFullscreen(closeFullscreen)}
   * @see {@link refreshVideo(refreshVideo)}
   */

  chiudiLavagnaComponent(event: FormData) {
    //resetto la variabile, onde evitare che all'apertura della lavagna
    //e alla sub risalvi l'immagine
    this.eventLavagna = event;
    this.lavagnaService.richiestaFormData(null);
    this.lavagnaService.sendFile(event).subscribe((res) => {});
    if (this.fullScreen) {
      this.closeFullscreen();
    }
    this.refreshVideo();
    this.isActiveLavagnaComponent = false;
  }

  /**
   * Il padre chiede al figlio l'immagine, per poi chiuderlo
   */
  closedLavagna = true;
  lavagnaOpenable = true;
  richiediChiusuraLavagna() {
    this.lavagnaOpenable = false;
    this.closedLavagna = false;
    localStorage.removeItem('backgroundWhiteboard');
    this.lavagnaService.chiudiLavagna(this.aulaInfo.idAula).subscribe((res) => { });
    this.refreshVideo();
    setTimeout(() => {
      localStorage.removeItem('enableWhiteboard');
      localStorage.removeItem('backgroundWhiteboard');
      this.lavagnaOpenable = true;
    }, 3000);
  }

  //#endregion

  /**
   *
   * @returns Vero se c'è almeno un utente con stream attivo
   */
  verificaUsersAttivi(): boolean {
    for (var i = 0; i < this.userStreams.length; i++) {
      if (this.userStreams[i].videoActive) {
        return true;
      }
    }
    return false;
  }

  /**
   * In base al tipo di componente fornisce, l'incognita 'x' di una grid (col-xs-'x') per la disposizione del contenuto,
   * attraverso ngClass
   * @param componente lavagna, openVidu, listaUtenti
   * @returns una stringa che corrisponde al grid, ad esempio 'col-xs-9'
   */
  formattazioneSchermo(componente: string): string {
    switch (componente) {
      case 'lavagna': {
        if (
          this.isActiveLavagnaComponent &&
          this.closedLavagna &&
          this.fullScreen
        ) {
          //lavagna e schermo intero attivo
          //lavagna: xs-6 | openVidu: xs-4 | listaUtenti: xs-2
          return 'col-xs-6';
        } else if (
          this.isActiveLavagnaComponent &&
          this.closedLavagna &&
          !this.fullScreen
        ) {
          //solo lavagna attiva
          //lavagna: xs-7 | openVidu: xs-3 | ListaUtenti: xs-2
          return 'col-xs-7';
        } else if (
          !(this.isActiveLavagnaComponent && this.closedLavagna) &&
          this.fullScreen
        ) {
          //solo schermo intero attivo
          //lavagna: OFF | openVidu: xs-10 | ListaUtenti: xs-2
          return '';
        } else {
          //lavagna e schermo intero disattivato
          //lavagna: OFF | openVidu: xs-9 | ListaUtenti: xs-3
          return '';
        }
      }

      case 'openVidu': {
        if (this.screenSize > 800) {
          if (
            this.isActiveLavagnaComponent &&
            this.closedLavagna &&
            this.fullScreen
          ) {
            return 'col-xs-5';
          } else if (
            this.isActiveLavagnaComponent &&
            this.closedLavagna &&
            !this.fullScreen
          ) {
            return 'col-xs-5';
          } else if (
            !(this.isActiveLavagnaComponent && this.closedLavagna) &&
            this.fullScreen
          ) {
            return 'col-xs-10';
          } else {
            return 'col-xs-9';
          }
        } else {
          if (
            this.isActiveLavagnaComponent &&
            this.closedLavagna &&
            this.fullScreen
          ) {
            return 'col-xs-5 w-100';
          } else if (
            this.isActiveLavagnaComponent &&
            this.closedLavagna &&
            !this.fullScreen
          ) {
            return 'col-xs-3 w-100';
          } else if (
            !(this.isActiveLavagnaComponent && this.closedLavagna) &&
            this.fullScreen
          ) {
            return 'col-xs-10 w-100';
          } else {
            return 'col-xs-9 w-100';
          }
        }
      }
      case 'listaUtenti': {
        if (
          this.isActiveLavagnaComponent &&
          this.closedLavagna &&
          this.fullScreen
        ) {
          return 'col-xs-2';
        } else if (
          this.isActiveLavagnaComponent &&
          this.closedLavagna &&
          !this.fullScreen
        ) {
          return 'col-xs-2';
        } else if (
          !(this.isActiveLavagnaComponent && this.closedLavagna) &&
          this.fullScreen
        ) {
          return 'col-xs-2';
        } else {
          return 'col-xs-3';
        }
      }
      default: {
        return '';
      }
    }
  }

  /**
   * Il metodo riadatta la grandezza della lista utenti al componente di openVidu
   */
  riadattamentoLista() {
    //otteniamo l'altezza del componente
    if (
      this.containerOpenVidu &&
      this.containerOpenVidu.nativeElement &&
      this.containerOpenVidu.nativeElement.clientHeight
    ) {
      this.altezzaOpenVidu = this.containerOpenVidu.nativeElement.clientHeight;
    } else {
      this.altezzaOpenVidu = 300;
    }
    let offset = 25; //togliamolo un pò
    this.altezzaOpenVidu -= offset;
    //prendiamo il componente html listaUtenti
    let listaUtenti = document.getElementById('listaUtenti');
    //è settiamogli l'altezza

    if (listaUtenti && listaUtenti.style) {
      if (!this.isActiveLavagnaComponent) {
        //disattivo abilitando la class
        listaUtenti.style.height = null;
      } else {
        //attivo disabilitando la class
        listaUtenti.style.height = this.altezzaOpenVidu + 'px';
      }
    }
  }

  refreshVideo() {
    if (this.ovLayout) {
      this.ovLayout.updateLayout();
    }
  }
  inzializzaSmartVision() {
    setTimeout(() => {
      if (this.screenHeight > 800 && this.screenHeight < 1024) {
        this.screenHeight = this.screenHeight - 120;
      }
      if (this.screenHeight >= 1024) {
        this.screenHeight = this.screenHeight - 400;
      }
      let grandezzaDinamicaOV = document.getElementById('grandezzaDinamicaOV');
      if (grandezzaDinamicaOV === undefined || grandezzaDinamicaOV === null) {
        return;
      }
      grandezzaDinamicaOV.style.height = this.screenHeight - 90 + 'px';
      setTimeout(() => {
        this.refreshVideo();
      }, 2000);
    }, 6000);
  }
  apriLavagnaSmart() {
    setTimeout(() => {
      let grandezzaDinamicaOV = document.getElementById('grandezzaDinamicaOV');
      grandezzaDinamicaOV.style.height = this.screenHeight / 2 - 90 + 'px';
      this.openLavagna = true;
      setTimeout(() => {
        let grandezzaDinamica = document.getElementById('grandezzaDinamica');
        grandezzaDinamica.style.height = this.screenHeight / 2 - 90 + 'px';

        this.refreshVideo();
      }, 4000);
    }, 3000);
  }
  chiudiLavagna() {
    localStorage.removeItem('backgroundWhiteboard');
    this.openLavagna = false;
    let grandezzaDinamicaOV = document.getElementById('grandezzaDinamicaOV');
    grandezzaDinamicaOV.style.height = this.screenHeight - 90 + 'px';
    this.refreshVideo();
  }
  //smartVision fine

  //#region Sondaggio
  /**
   * Salvataggio nel report dell'opinione studente
   * @param isPositivo 'true' studente contento
   */
  sondaggio(isPositivo: string) {
    clearInterval(this.timerSondaggio);
    //chiusura della finestra
    this.popupStudente = false;
    this.classroomService
      .answerPool(
        this.aulaInfo.idAula,
        this.messaggioDocente,
        isPositivo,
        this.tempoInattivita
      )
      .subscribe((res) => {
        //chiudo il popup dopo la conferma del BE
        this.tempoInattivita = 0;
      });
  }

  /**
   * Lato docente per la creazione del sondaggio
   * @param shouldSend vero inviare al BE, falso no
   */
  inviaSondaggio(shouldSend: boolean) {
    if (!shouldSend) {
      this.popupInsegnante = false;
      return;
    }

    //chiusura della finestra
    if (this.sondaggioForm.invalid) {
      this.alertService.error('Messaggio invalido');
      return;
    }
    let messaggio = this.sondaggioForm.controls.messaggio.value;
    let sondaggioBox = this.sondaggioForm.controls.sondaggio.value;
    this.classroomService
      .sendPool(
        messaggio,
        sondaggioBox,
        this.lavagnaService.mioId(),
        this.aulaInfo.idAula
      )
      .subscribe((res) => {
        this.sondaggioForm.reset();
        this.popupInsegnante = false;
      });
  }
  /**
   * In base al tipo di utenza, verrà mostrato una diversa struttura
   */
  apriPopUpSondaggio() {
    this.closeFullscreen();
    this.popupInsegnante = true;
  }

  stopRecording = false;

  toggleRecording() {
    // this.closeFullscreen();
    this.envRecording = false;
    if (!this.isRecording) {
      this.recording = !this.recording;
    } else {
      this.stopRecording = !this.stopRecording;
    }
  }
  showUserLavagna = false;
  showUserLavagnaActivated() {
    this.showUserLavagna = !this.showUserLavagna;
    if (this.showUserLavagna) {
      //se la lavagna è attiva la lista diventa
      //un pannello e va riadattata
      this.riadattamentoLista();
    }
  }

  erroreNomeVideo = false;
  recordingService() {
    if (this.formVideo.invalid && !this.isRecording) {
      this.erroreNomeVideo = true;
      return;
    } else {
      this.erroreNomeVideo = false;
    }
    let endpoint = '';

    if (!this.isRecording) {
      this.nomeFile = this.formVideo.value.nome;
      endpoint =
        '/avviaRegistrazioneAula?nomeFile=' + this.formVideo.value.nome + '&';
      this.isRecording = true;
    } else if (this.idRecording) {
      endpoint = '/stopRegistrazioneAula?id=' + this.idRecording + '&';
      this.isRecording = false;
    }
    this.recording = false;
    this.stopRecording = false;
    this.envRecording = false;
    this.classroomService
      .toggle_Registrazione(endpoint + 'idAula=' + this.aulaInfo.idAula)
      .subscribe(
        (res) => {
          if (res.data.id) {
            this.idRecording = res.data.id;
            if (this.isRecording) {
              this.classroomService
                .sendPool(
                  'Registrazione avviata',
                  false,
                  this.lavagnaService.mioId(),
                  this.aulaInfo.idAula
                )
                .subscribe((res) => {});
            }
            setTimeout(() => {
              this.envRecording = true;
            }, 3000);
          }
        },
        (error) => {
          this.isRecording = !this.isRecording;
          setTimeout(() => {
            this.envRecording = true;
          }, 3000);
        }
      );
  }
  //#endregion

  close(event) {
    // if(this.isActiveLavagnaComponent){
    //   this.lavagnaService.richiestaFormData(true)
    // }

    if (!event) {
      this.alertService.error(
        'Per continuare, è necessario fornire i permessi'
      );
      this.notOpenFeedbackDialog = true;
    } else {
    }
    this.chiudi = true;
    this.session = false;
    this.tokens = [];
    this.indietro.emit(true);
  }

  imageHeight() {
    let height = document.getElementById('openviduAltezza').clientHeight;
    return height - 50;
  }

  changeMenu() {
    this.showMenu = !this.showMenu;
  }

  checkButtonInvisible() {
    return this.screenSize <= 800 || this.isActiveLavagnaComponent;
  }
  toggleRecordingOver() {
    this.registrazioneOver = false;
  }
  //   altezzaOpenViduTab() {
  //     if(this.ovSessionComponent) {
  //       let h:any = this.ovSessionComponent
  //       if(this.fullScreen) {
  //         return h.oVLayout.openviduLayout.layoutContainer.clientHeight - 330;
  //       } else {
  //         return h.oVLayout.openviduLayout.layoutContainer.clientHeight - 130;
  //       }
  //   }
  // }
  responsiveWidth() {}
  videoDevices;
  audioDevices;
  selectedCamera;
  selectedMicrophone;
  settingCamButtonClicked() {
    let OV = new OpenVidu();
    OV.getDevices().then((devices) => {
      this.videoDevices = devices.filter(
        (device) => device.kind === 'videoinput'
      );
      this.audioDevices = devices.filter(
        (device) => device.kind !== 'videoinput'
      );
      if (this.videoDevices && this.videoDevices[0]) {
        this.selectedCamera = this.videoDevices[0].label;
      }
      if (this.audioDevices && this.audioDevices[0]) {
        this.selectedMicrophone = this.audioDevices[0].label;
      }
    });
    this.showCameraSettings = true;
  }
  modified = undefined;
  camera;
  microphone;
  isOpenMicrophone;
  state = 'default';
  stateMicrophone = 'default';
  isOpen = false;
  rotateMicrophone() {
    this.isOpenMicrophone = !this.isOpenMicrophone;
    this.stateMicrophone =
      this.stateMicrophone === 'default' ? 'rotated' : 'default';
    this.isOpen = false;
    this.state = 'default';
  }
  rotate() {
    this.isOpen = !this.isOpen;
    this.state = this.state === 'default' ? 'rotated' : 'default';
    this.isOpenMicrophone = false;
    this.stateMicrophone = 'default';
  }
  publishNewUser() {
    if (!this.checkNumUtenti(this.listaUtenti)) {
      this.recordingService();
    }
    let el = document.getElementById('navScreenButtonNo');
    if (el) el.click();
    setTimeout(() => {
      this.modified = false;
      this.camera = this.videoDevices.filter(
        (device) => device.label === this.selectedCamera
      );
      this.microphone = this.audioDevices.filter(
        (device) => device.label === this.selectedMicrophone
      );
      this.showCameraSettings = false;
      setTimeout(() => {
        this.modified = true;
        this.joinSession(true);
      }, 100);
    }, 100);
  }

  checkNumUtenti(listaUtenti) {
    let response = false;
    this.listaUtenti.forEach((res) => {
      if (res.presenza === 1) response = true;
    });
    return response;
  }
  muteMicrophone(user) {
    let signal: SignalOptions = {};
    signal.data = JSON.stringify(user);
    signal.type = 'muteMicrophone';
    this.ovSession.signal(signal);
  }
}
