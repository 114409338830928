<div id="container">
  <div *ngIf="session" id="session" style="z-index: 10">
    <opv-session
      (cambiaEvidenzaEmit)="cambiaEvidenza($event)"
      #ovSessionComponent
      [ovSettings]="ovSettings"
      [sessionName]="mySessionId"
      [user]="myUserName"
      [url]="urlEnv"
      [tokens]="tokens"
      (leaveSession)="close($event)"
      (sessionCreated)="handlerSessionCreatedEvent($event)"
      (participantCreated)="handlerPublisherCreatedEvent($event)"
      (error)="handlerErrorEvent($event)"
      [isControllRoom]="true"
      [hide]="true"
    >
    </opv-session>
  </div>
</div>
