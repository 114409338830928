import {Component, OnInit, Output, EventEmitter, Input, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utente } from 'src/app/MODELS/USER/utente';
import { AccountService, AlertService, EnvService, UsersService } from '../../../../../SERVICES';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { ConfirmationService, PrimeNGConfig, SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import { ListaEdificiComponent } from 'src/app/COMPONENTS/planimetria/lista-edifici/lista-edifici.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {FormFilterComponent} from "../../../gestione-medici/form-filter/form-filter.component";
import {Doctor} from "../../../../../MODELS/USER/doctor";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modifica-aula',
  templateUrl: './modifica-aula.component.html',
  styleUrls: ['./modifica-aula.component.scss'],
  providers: [ConfirmationService]

})
export class ModificaAulaComponent implements OnInit {

  //VAR PARTE MODIFICA
  @Input() aula;
  disableButton = false;
  @Output() tornaLista = new EventEmitter<void>();
  listaDettagli = [];
  i = 0;
  modificaRouting = false;
  modifica = true;//mostra form di modifica
  aulaModForm: FormGroup;//form per modificare nome e descrizione aula
  submitted = false;//submitted per nome e descrizione
  searchDocentiForm: FormGroup;//form per filtrare docenti
  docenti: Utente[] = [];//lista docenti appartenenti all'aula
  searchStudentiForm: FormGroup;//form per filtrare studenti
  //studenti: Utente[] = [];//lista studenti appartenenti all'aula nicoletta old
  studenti: Doctor[] = [];//lista studenti appartenenti all'aula
  //allStudenti: Utente[] = []; nicoletta old
  allStudenti: Doctor[] = [];
  allDocenti: Utente[] = [];
  docenteId;
  cancellaDocente = false;
  loading = false;
  studenteId;
  cancellaStudente = false;
  caricato = false;
  listaTemplate;
  //VAR AGGIUNGI DOCENTE
  //utilizza searchDocentiForm sopra
  addDocente = false;
  searchDocentiForm2: FormGroup;
  listaTotaleDocenti: Utente[] = [];//ritorna la lista di tutti i docenti
  selectedAddDocenti;//indica i docenti selezionati da aggiungere
  listaTotaleDocentiAppoggio: Utente[] = []
  //VAR AGGIUNGI STUDENTE
  //utilizza searchStudentiForm sopra
  addStudente = false;
  searchStudentiForm2: FormGroup;
  //listaTotaleStudenti: Utente[] = [];//ritorna la lista di tutti gli studenti
  listaTotaleStudenti: Doctor[] = [];//ritorna la lista di tutti i dottori
  selectedAddStudenti; //indica gli studenti selezionati da aggiungere
  listaTotaleStudentiAppoggio: Doctor[] = []
  docenteEdit = false;
  studenteEdit = false;
  templateEdit = false;
  id;
  opzioniInd: SelectItem[];
  selectedOptionInd;
  privateOption: any[] = [
    { label: 'Si', value: 'true' },
    { label: 'No', value: 'false' }
  ];
  selectedOptionPrivate: boolean;
  ita = {
    firstDayOfWeek: 1,
    dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
    monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
    monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Wk'
  };
  loadingSave = false;
  check;
  ambiente;
  afi
  notificheEdit: boolean;
  chekAllNotifications: any;
  formNotifiche: FormGroup;

  filtersVisible = false;
  removeFilters = false;
  filters: any = {};
  @ViewChild(FormFilterComponent) formFilterComponent: FormFilterComponent;
  refreshView = true;
  numeroMaxPagine: number;
  items;
  maxItems;
  isClickable = true;
  pagina;
  size = 10;
  pagination = 0;
  selectedValue;
  subscriptionlistaTotaleStudenti: Subscription;
  alllistaTotaleStudenti: Doctor[];
  carica: boolean;
  isClickableCancellati: boolean;
  paginatorCancellati: boolean;
  paginator: boolean;
  isSearchingOn: boolean;
  isSelectingValue: boolean;
  isSelectingValueCancellati: boolean;
  showTable: boolean;
  valoreRicerca;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService,
    private classService: ClassroomService,
    private _Activatedroute: ActivatedRoute,
    private routing: Router,
    private accountService: AccountService,
    private env: EnvService,
  ) {
    this.ambiente = environment;
    this.afi = env.alfaintes
    this.opzioniInd = [{ label: "Sì", value: "1" }, { label: "No", value: "0" }];
    if (this.accountService.ruolo !== 1) {
      this.routing.navigate([''])
    }
    this.id = this._Activatedroute.snapshot.paramMap.get("id");
    this.aulaModForm = this.formBuilder.group({
      nomeMod: ['', Validators.required],
      descrizioneMod: ['', Validators.required],
    });
    this.searchDocentiForm = this.formBuilder.group({
      search: ['', Validators.required]
    });
    this.searchStudentiForm = this.formBuilder.group({
      search: ['', Validators.required]
    })
    this.searchDocentiForm2 = this.formBuilder.group({
      search: ['', Validators.required]
    });
    this.searchStudentiForm2 = this.formBuilder.group({
      search: ['', Validators.required]
    })
    this.formNotifiche = new FormGroup({
      newsletters: new FormControl(false),
      social: new FormControl(false),
      whatsapp: new FormControl(false),
    });
    this.pagina = 0;
    this.selectedValue = 10;
  }

  ngOnInit(): void {
    if (this.afi) {
      this.classService.lista_attestati().subscribe((res) => {
        this.listaTemplate = res.data;
        this.listaTemplate.push({
          id: -1,
          titolo: 'Nessuno'
        })
      })
    }
    if (this.id) {
      this.modificaRouting = true;
      this.classService.lista_classroom_leggera().subscribe((res) => {
        if (!res.data.content) {
          res.data.forEach((aula) => {
            if (aula.id.toString() === this.id) {
              this.aula = aula;
            }
          });
        }
        else {
          res.data.content.forEach((aula) => {
            if (aula.id.toString() === this.id) {
              this.aula = aula;
            }
          });
        }

        if (this.aula.start) {
          this.selectedOptionInd = "1";
        }
        else {
          this.selectedOptionInd = "0";
        }
        this.initAula();
        if (!this.aula.attestato) {
          this.aula.attestato = {
            id: -1,
            titolo: 'Nessuno'
          }
        }
      })
    }
    else {
      if (this.aula.start) {
        this.selectedOptionInd = "1";
      }
      else {
        this.selectedOptionInd = "0";
      }
      this.initAula();
    }
    this.primengConfig.ripple = true;

    if (this.aula && !this.aula.attestato)
      this.aula.attestato = {
        id: -1,
        titolo: 'Nessuno'
      }
  }

  initAula() {
    this.getList(this.pagina, this.size); // get lista studenti (medici)
    this.classService.lista_dettagli_classroom(this.aula.token, true).subscribe((res) => {
      this.caricato = true;
      this.docenti = res.data.listaDocenti;
      this.studenti = res.data.listaStudenti;
      this.allDocenti = this.docenti;
      this.allStudenti = this.studenti;
      this.listaDettagli = this.aula.dettagli;
      this.userService.getAll().subscribe((res) => {
        this.listaTotaleDocenti = res.data.listaUtentiAttivi;
        this.listaTotaleDocenti.forEach(docente => {
          if (docente.ruolo.id !== 4) {
            this.listaTotaleDocenti = this.listaTotaleDocenti.filter(x => x !== docente)
          }
        })
        this.listaTotaleDocenti.forEach(docenteAggiuntivo => {
          this.docenti.forEach(docentePresente => {
            if (docenteAggiuntivo.id === docentePresente.id) {
              this.listaTotaleDocenti = this.listaTotaleDocenti.filter(x => x !== docenteAggiuntivo)
            }
            /*this.studenti.forEach(studentePresente => {
              if (docenteAggiuntivo.id === studentePresente.id) {
                this.listaTotaleDocenti = this.listaTotaleDocenti.filter(x => x !== docenteAggiuntivo)
              }
            })*/
          })
        })
        this.listaTotaleDocentiAppoggio = this.listaTotaleDocenti;
        /* ++++++++++++++++++++++++++ */
        /*
        this.listaTotaleStudenti = res.data.listaUtentiAttivi;
        this.listaTotaleStudenti.forEach(studente => {
          if (studente.ruolo.id === 4) {
            this.listaTotaleStudenti = this.listaTotaleStudenti.filter(x => x !== studente)
          }
        })
        this.listaTotaleStudenti.forEach(studenteAggiuntivo => {
          this.docenti.forEach(docentePresente => {
            if (studenteAggiuntivo.id === docentePresente.id) {
              this.listaTotaleStudenti = this.listaTotaleStudenti.filter(x => x !== studenteAggiuntivo)
            }
            this.studenti.forEach(studentePresente => {
              if (studenteAggiuntivo.id === studentePresente.id) {
                this.listaTotaleStudenti = this.listaTotaleStudenti.filter(x => x !== studenteAggiuntivo)
              }
            })
          })
        })
        this.listaTotaleStudentiAppoggio = this.listaTotaleStudenti
          */
        /* ++++++++++++++++++++++++++ */
        this.listaTotaleStudentiAppoggio = this.listaTotaleStudenti;
      })
      this.disableButton = true;
    })

    if (this.selectedOptionInd == 1) {
      this.aulaModForm = this.formBuilder.group({
        nomeMod: [this.aula.nomeAula, [Validators.required, Validators.pattern(`^[^!?@|\/'"$£%=€\s][A-Ú a-ù0-9]+$`)]],
        descrizioneMod: [this.aula.descrizioneAula, Validators.required],
        dataA: [new Date(this.aula.end)],
        dataDa: [new Date(this.aula.start)],
        selectedOptionPrivate: [this.aula.isPrivate],
      });
    }
    else {
      this.aulaModForm = this.formBuilder.group({
        nomeMod: [this.aula.nomeAula, [Validators.required, Validators.pattern(`^[^!?@|\/'"$£%=€\s][A-Ú a-ù0-9]+$`)]],
        descrizioneMod: [this.aula.descrizioneAula, Validators.required],
        dataA: [''],
        dataDa: [''],
        selectedOptionPrivate: [this.aula.isPrivate],
      });
    }
    this.caricato = true;
  }

  //METODI PARTE MODIFICA

  get l() { return this.aulaModForm.controls }
  //  erroreListaDocenti=false;
  //salvaAula modifica il nome e la descrione dell'aula
  salvaAula() {
    this.loadingSave = true;
    this.submitted = true;
    this.searchDocentiForm.value.search = '';
    this.searchStudentiForm.value.search = '';
    this.searchInDocenti();
    this.searchInStudenti();
    if (this.docenti.length == 0) {
      this.loadingSave = false;
      this.alertService.error('Seleziona almeno un docente')
      // console.log("sei Qui")
      // this.erroreListaDocenti=true;
      return
    }
    this.loading = true;
    this.check = true;
    this.controlloValidators();
    if (this.check) {
      this.aula.nomeAula = this.aulaModForm.value.nomeMod.trim();
      this.aula.descrizioneAula = this.aulaModForm.value.descrizioneMod;
      this.aula.listaDocenti = this.docenti;
      this.aula.listaUtenti = this.studenti;
      this.aula.dettagli = this.listaDettagli;
      this.aula.isPrivate = this.aulaModForm.value.selectedOptionPrivate;
      if (this.selectedOptionInd == 1) {
        this.aula.start = this.aulaModForm.value.dataDa.toString();
        this.aula.end = this.aulaModForm.value.dataA.toString();
      }
      else {
        this.aula.start = null;
        this.aula.end = null;
      }
      let date = new Date();
      let idAula = this.aula.attestato.id
      if (this.aula.attestato) {
        if (this.aula.attestato.id !== -1) {
          this.aula.idAttestato = this.aula.attestato.id;
        } else {
          this.templateEdit = false;
          this.aula.attestato = null;
        }
      }
      this.classService.editAula(this.aula, idAula).subscribe((res) => {
        if (this.selectedFileCopertina) {
          const uploadFileCopertina = new FormData();
          uploadFileCopertina.append('imageFile', this.selectedFileCopertina, this.selectedFileCopertina.name)
          this.classService.modifica_img_afi(this.aula.id, uploadFileCopertina).subscribe((res) => {
            if (this.id) {
              setTimeout(() => {
                this.loadingSave = false;
                this.alertService.success(res.status.description);

                this.goBackLista();
              }, 7000);

            }
            else {
              setTimeout(() => {
                this.alertService.success(res.status.description);

                this.loadingSave = false;
                this.tornaLista.emit();
              }, 7000);

            }

          }, (error) => {
            this.loading = false;
            this.loadingSave = false;
          })
        } else {
          this.alertService.success(res.status.description);
          if (this.id) {
            this.loadingSave = false;
            this.goBackLista();
          }
          else {
            this.loadingSave = false;
            this.tornaLista.emit();
          }
        }
      }, (error) => {
        this.loading = false;
        this.loadingSave = false;
      })
    }
  }

  controlloValidators() {
    if (this.aulaModForm.invalid) {
      this.loadingSave = false;
      this.loading = false;
      this.check = false;
      return;
    }
    this.listaDettagli.forEach(dettaglio => {
      if (!dettaglio.attributo || !dettaglio.valore) {
        this.loadingSave = false;
        this.loading = false;
        this.check = false;
        return;
      }
    })
    if (this.selectedOptionInd == 1) {
      if (!this.aulaModForm.value.dataDa || !this.aulaModForm.value.dataA) {
        this.loadingSave = false;
        this.loading = false;
        this.check = false;
        return;
      }
      if (this.aulaModForm.value.dataDa >= this.aulaModForm.value.dataA) {
        this.loadingSave = false;
        this.loading = false;
        this.check = false;
        return;
      }
    }
  }
  //searchInDocenti filtra nella lista di docenti
  //NB: stesso metodo usato anche nel filtro di aggiunta docente
  searchInDocenti() {
    let valore: string = this.searchDocentiForm.value.search;
    this.docenti = this.allDocenti;
    if (valore) {
      let users = []
      this.docenti.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;
        if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!(users.find(x => x === user))) {
            users.push(user);
          }
        }
      });
      this.docenti = users;

    } else if (!valore) {
      this.docenti;
    }
  }

  //eliminaDocente fa comparire il popup di conferma e si occupa del reject
  eliminaDocente(docenteId, i) {

    this.docenteId = docenteId
    this.cancellaDocente = true;
    this.confirmationService.confirm({
      message: 'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',

      icon: 'pi pi-exclamation-triangle',

      reject: () => {
        this.cancellaDocente = false;
        this.loading = false;
        this.confirmationService.close();

      }
    });
  }
  //accettaCancellaDocente si occupa di accept e cancella docente
  accettaCancellaDocente() {
    this.loading = true;
    this.docenti.forEach(docente => {
      if (docente.id === this.docenteId) {
        this.docenti = this.docenti.filter(x => x !== docente)
        this.allDocenti = this.allDocenti.filter(x => x !== docente)
        this.listaTotaleDocenti.push(docente);
        this.listaTotaleDocentiAppoggio = this.listaTotaleDocenti;
       // this.listaTotaleStudenti.push(docente); nicoletta
        this.listaTotaleStudentiAppoggio = this.listaTotaleStudenti;
      }
    })
    this.cancellaDocente = false;
    this.loading = false;
    this.confirmationService.close();
  }

  //aggiungiDocente nasconde parte modifica e mostra form per aggiungere nuovo docente
  aggiungiDocente() {
    this.modifica = false;
    this.addDocente = true;
  }

  //searchInStudenti filtra nella lista di docenti
  //NB: stesso metodo usato anche nel filtro di aggiunta studente
  searchInStudenti() {
    let valore: string = this.searchStudentiForm.value.search;


    this.studenti = this.allStudenti;

    if (valore) {
      let users = []
      this.studenti.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;



        if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email_privata.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!(users.find(x => x === user))) {
            users.push(user);
          }
        }
      });
      this.studenti = users;

    } else if (!valore) {
      this.studenti = this.allStudenti;
    }
  }

  //eliminaStudente elimina un docente dall'aula
  eliminaStudente(studenteId, i) {

    this.studenteId = studenteId
    this.cancellaStudente = true;
    this.confirmationService.confirm({
      message: 'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',

      icon: 'pi pi-exclamation-triangle',

      reject: () => {
        this.cancellaStudente = false;
        this.loading = false;
        this.confirmationService.close();

      }
    });
  }
  accettaCancellaStudente() {
    this.loading = true;
    this.studenti.forEach(studente => {
      if (studente.id === this.studenteId) {
        this.studenti = this.studenti.filter(x => x !== studente);
        this.allStudenti = this.allStudenti.filter(x => x !== studente);
        //this.listaTotaleDocenti.push(studente); nicoletta
        //this.listaTotaleDocentiAppoggio = this.listaTotaleDocenti; nicoletta
        this.listaTotaleStudenti.push(studente);
        this.listaTotaleStudentiAppoggio = this.listaTotaleStudenti;
      }
    })
    this.cancellaStudente = false;
    this.loading = false;
    this.confirmationService.close();
  }
  //aggiungiStudente nasconde parte modifica e mostra form per aggiungere nuovo studente
  aggiungiStudente() {
    this.addStudente = true;
    this.modifica = false;
  }

  //goBack torna ad elenco aule virtuali
  goBack() {
    this.tornaLista.emit();
  }

  goBackLista() {
    this.routing.navigate(['./education'])
  }


  //METODI PARTE ADD DOCENTE


  //backToModifica nascone addDocente e mostra parte modifica; usato anche per addStudente
  backToModifica() {
    this.searchDocentiForm2.reset();
    this.searchStudentiForm2.reset();
    this.listaTotaleDocenti = this.listaTotaleDocentiAppoggio;
    this.listaTotaleStudenti = this.listaTotaleStudentiAppoggio;
    this.addStudente = false;
    this.addDocente = false;
    this.modifica = true;
  }


  //searchInDocenti2 filtra docenti in lista totale
  searchInDocenti2() {
    let valore: string = this.searchDocentiForm2.value.search;
    this.listaTotaleDocenti = this.listaTotaleDocentiAppoggio;
    if (valore) {
      let users = []
      this.listaTotaleDocenti.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;
        if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!(users.find(x => x === user))) {
            users.push(user);
          }
        }
      });
      this.listaTotaleDocenti = users;

    } else if (!valore) {
      this.listaTotaleDocenti;
    }
  }

  //inserisciDocente finalizza aggiunta del docente selezioanto all'aula
  inserisciDocente() {
    if (!this.selectedAddDocenti || this.selectedAddDocenti.length < 1) {
      this.alertService.error('Seleziona almeno un docente')
      return
    }
    this.selectedAddDocenti.forEach(docente => {
      this.docenti.push(docente);
      this.listaTotaleDocenti = this.listaTotaleDocentiAppoggio;
      this.listaTotaleStudenti = this.listaTotaleStudentiAppoggio
      this.listaTotaleStudenti = this.listaTotaleStudenti.filter(x => x != docente);
      this.listaTotaleStudentiAppoggio = this.listaTotaleStudenti;
      this.listaTotaleDocenti = this.listaTotaleDocenti.filter(x => x != docente);
      this.listaTotaleDocentiAppoggio = this.listaTotaleDocenti;
    });
    this.allDocenti = this.docenti;
    this.modifica = true;
    this.addDocente = false;
    this.selectedAddDocenti = []
    this.searchDocentiForm2.reset();
  }

  //METODI PARTE ADD STUDENTE
  //searchInStudenti2 filtra ricerca in totale studenti
  searchInStudenti2() {
    let valore: string = this.searchStudentiForm2.value.search;
    this.listaTotaleStudenti = this.listaTotaleStudentiAppoggio;
    if (valore) {
      let users = []
      this.listaTotaleStudenti.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;



        if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          //user.email.toLowerCase().includes(valore.toLowerCase()) nicoletta
          user.email_privata.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!(users.find(x => x === user))) {
            users.push(user);
          }
        }
      });
      this.listaTotaleStudenti = users;

    } else if (!valore) {
      this.listaTotaleStudenti;
    }
  }
  //inserisciStudente finalizza aggiunta degli studenti selezioanti all'aula
  inserisciStudente() {
    if (!this.selectedAddStudenti || this.selectedAddStudenti.length < 1) {
      this.alertService.error('Seleziona almeno uno studente')
      return
    }
    this.selectedAddStudenti.forEach(studente => {
      this.studenti.push(studente);
      this.listaTotaleStudenti = this.listaTotaleStudentiAppoggio;
      this.listaTotaleStudenti = this.listaTotaleStudenti.filter(x => x != studente);
      this.listaTotaleStudentiAppoggio = this.listaTotaleStudenti;
      /*this.listaTotaleDocenti = this.listaTotaleDocenti.filter(x => x != studente);
      this.listaTotaleDocentiAppoggio = this.listaTotaleDocenti; nicoletta */
    });
    this.allStudenti = this.studenti;
    this.modifica = true;
    this.addStudente = false;
    this.selectedAddStudenti = [];
    this.searchStudentiForm2.reset();

  }


  editDocente() {
    this.docenteEdit = !this.docenteEdit;
    this.studenteEdit = false;
    this.templateEdit = false;
  }

  editStudente() {
    this.docenteEdit = false;
    this.templateEdit = false;
    this.studenteEdit = !this.studenteEdit;
  }

  editNotifiche() {
    this.docenteEdit = false;
    this.templateEdit = false;
    this.studenteEdit = false;
    this.notificheEdit = !this.notificheEdit
  }

  editTemplate() {
    this.docenteEdit = false;
    this.studenteEdit = false;
    this.templateEdit = !this.templateEdit;
  }

  aggiungiDettaglio() {
    let dettaglio = {
      id: this.i,
      attributo: '',
      valore: ''
    }
    this.listaDettagli.push(dettaglio)
    this.i++
  }

  deleteDettaglio(dettaglio) {
    this.listaDettagli = this.listaDettagli.filter(x => x.id != dettaglio.id)
  }

  checkAll(evt, type?) {
    if (this.chekAllNotifications) {
      this.chekAllNotifications = false
      this.formNotifiche.setValue(
        {
          newsletters: false,
          social: false,
          whatsapp: false
        }
      )
    } else {
      this.chekAllNotifications = true
      this.formNotifiche.setValue(
        {
          newsletters: true,
          social: true,
          whatsapp: true
        }
      )

    }
  }

  erroreIMG
  caricaCopertinaChanged(event) {

    switch (event.target.files[0].type) {
      case "image/png": {
        this.erroreIMG = this.controlloValiditàImmagine(event);
        break;
      }
      case "image/jpeg": {
        this.erroreIMG = this.controlloValiditàImmagine(event);
        break;
      }
      default: {
        this.alertService.error("Il file che stai caricando non ha un formato valido")
        this.selectedFileCopertina = null;
        break;
      }

    }
  }
  nomeFileCopertina
  selectedFileCopertina: File = null;
  path
  controlloValiditàImmagine(event) {
    this.selectedFileCopertina = null;
    this.nomeFileCopertina = event.target.files[0].name;
    this.selectedFileCopertina = event.target.files[0];

    if (event.target.files && this.selectedFileCopertina) {
      // Size Filter Bytes
      const max_size = 1000000;
      //const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 600;
      const max_width = 600;
      if (this.selectedFileCopertina.size > max_size) {
        this.alertService.warn('Peso massimo deve essere di 1MB')
        this.selectedFileCopertina = null;
        this.nomeFileCopertina = null;

        return true;
      }
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFileCopertina)
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        this.path = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height !== max_height && img_width !== max_width) {
            this.alertService.warn('L\'immagine deve avere dimensioni 600x600')
            this.selectedFileCopertina = null;
            this.nomeFileCopertina = null;
            return true;
          }
        }
      }
    }
  }
  responsiveWidth() { }


  getList(page?, size?, filters?): void {
    this.refreshView = false;
    this.userService.getDoctors(page, size, filters).subscribe(
      (response: any) => {
        this.listaTotaleStudenti = response.data.content;
        this.numeroMaxPagine = Number(response.data.totalPages);
        this.maxItems = response.data.totalElements;
        this.items = response.data.numberOfElements;
        this.isClickable = true;
        this.refreshView = true;
      },
      (error) => {
        console.error('Errore durante il recupero dei dati:', error);
      }
    );
  }

  openFilters(): void {
    this.filtersVisible = !this.filtersVisible;
  }

  deleteFilters(): void {
    this.removeFilters = true;
    this.filters = {};
    this.pagina = 0;
    this.getList(this.pagina, this.size, this.filters);
  }

  submitFilters(): void {
    this.pagina = 0;
    this.formFilterComponent.onSubmitFilters();
    this.filtersVisible = false;
    
  }
  updateList(filtersObj: any): void {
    this.removeFilters = false;
    Object.keys(filtersObj).reduce((acc, k) => {
      if (!filtersObj[k]) {
        delete acc[k];
      }
      return acc;
    }, filtersObj);

    this.filters = { ...filtersObj };
    this.getList(this.pagina, this.size, this.filters);
  }

  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)

    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }

  chiamataPaginata(pagina, quantità, attivo, ricerca?) {
    this.refreshView = false;
    /*if(attivo)
    {*/
      this.subscriptionlistaTotaleStudenti = this.userService.getDoctors(pagina, quantità).subscribe({
      next: (res) => {

      if (attivo == true) {
        if (res?.data?.content?.length > 0) {
          this.listaTotaleStudenti = res.data.content;
        } else {
          this.listaTotaleStudenti = []
        }
        this.alllistaTotaleStudenti = this.listaTotaleStudenti;
        this.numeroMaxPagine = Number(res.data.totalPages);
        this.maxItems = res.data.totalElements;
        this.items = res.data.numberOfElements

        this.carica = true;
        this.caricato = true;
        this.isClickable = true;
        this.isClickableCancellati = true;
        this.paginatorCancellati = true;
        this.paginator = true;
        this.isSearchingOn = false;
        this.isSelectingValue = false;
        this.isSelectingValueCancellati = false;
        this.refreshView = true;
        this.showTable = true;
      }
    },
    error: (err) => {
      console.log(err)
    }})
  }
  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.refreshView = false;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, true, this.valoreRicerca)
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue, true)
    }
  }
}
