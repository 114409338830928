import {
  Component,
  OnInit,
  AfterViewInit,
  DoCheck,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import 'leaflet-sidebar-v2';
import * as L from 'leaflet';
import 'leaflet-fullscreen';
import { Control, Map, TileLayer } from 'leaflet';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Utente } from 'src/app/MODELS/USER/utente';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { EnvService, UsersService, WebSocketService } from 'src/app/SERVICES/index';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';
import { ColorArray1, ColorArray2, DateAliasIta } from './gelocalizzzazione.const';
/* ES5 */
var htmlToImage = require('html-to-image');

@Component({
  selector: 'app-geo3',
  templateUrl: './geolocalizzazione3.component.html',
  styleUrls: ['./geolocalizzazione3.component.less'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('80ms ease-out')),
      transition('default => rotated', animate('80ms ease-in')),
    ]),
  ],
})


export class Geolocalizzazione3Component implements AfterViewInit, OnDestroy {


  //VARS --------------------------

  @Output() testEmitter = new EventEmitter<boolean>();

  public map!: Map;
  public sidebar!: Control.Sidebar;

  //Panel
  selectedPanel = 'home';

  //Websocket
  stompClient;
  riconnessioneWs = false;
  intervalReconnectionWs;

  //Allarmistica
  alarmLat;
  alarmLong;
  modaleAllarme = false;
  modaleGestioneAllarme = false;
  allarme;
  listaDispositiviConAllarme = [];
  selectedDevice;
  testoNota;
  //Fine aggiornamento a nuova versione

  today = new Date();
  dateToShow = new Date();
  @ViewChild('myCalendar') private myCalendar: any;
  @Output() mappaCaricata = new EventEmitter<boolean>();
  //map: Map;

  users = [];
  intervallo: any;
  allUser;
  cords = [];
  list = [];
  showToolTip = false;
  open = true;
  traccia = false;
  allcords;
  rangeDates: Date[];
  showToggle = false;
  listToolTip = [];
  //fullScreenMap: boolean = false;
  i = 0;
  mainLayer;
  markers = [];
  firstTime = false;
  subscription: Subscription;
  nome;
  showUser = false;
  colorArray = ColorArray1;
  usersStatic = [];
  listaBeacons = [];
  //Controllo
  devices = false;
  searchform: FormGroup;

  // definisicie il locale utilizzato dalla mappa?
  ita;
  overlayVisib: string = 'none'
  markerHtmlStyles = `
  color: ${this.colorArray[this.i]};
  font-size: xx-large;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  -webkit-text-stroke: 1px black;
  `;

  smallIcon = L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 24],
    popupAnchor: [0, -36],
    html: `<i class="material-icons notranslate" style="${this.markerHtmlStyles}">place</i>`,
  });

  tracker;
  utenteAssociato;
  isExporting = false;
  state: string[] = [];
  spinner = false;
  debug;
  idUserOpen;
  toCallTrackerInUser;
  intervalBeacon;
  map2;
  fromDay;
  fromMonth;
  fromYear;
  toDay;
  toMonth;
  toYear;
  batteryLevel = -1;
  powerMode = -1;
  outletYes = "Charging"
  cerchio;


  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
    private router: Router,
    private fileService: FileService,
    private envService: EnvService,
    private excelService: ExportExcelService,
    private wsService: WebSocketService,
    private iotService: IotService,
    private shareService: ShareService
  ) {
    this.ita = JSON.stringify(DateAliasIta);
    this.tracker = this.envService.TRACKING;
    if (this.envService.debug) {
      this.debug = "flex";
    } else {
      this.debug = "none";
    }
    this.mainLayer = L.tileLayer(
      'https://{s}.tile.openStreetMap.org/{z}/{x}/{y}.png',
      {
        minZoom: 6,
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }
    );
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required],
    });

    this.fetchData();
    this.intervallo = setInterval(() => {
      if (this.open) {
        this.fetchData();
      }
    }, 10000);
  }

  fetchData() {
    if (this.tracker && !this.showUser) {
      let toReset = true;
      this.listToolTip.forEach((element) => {
        if (element) toReset = false;
      });
      if (toReset)
        this.subscription = this.userService
          .getCordTracker(6, true)
          .subscribe((res) => {
            this.listToolTip = [];
            if (res.data.length === 0) this.tracker = false;
            res.data.forEach((element) => {
              element.tracker = true;
              this.listToolTip.push(false);
            });
            this.cords = res.data;
            this.chiamataUtenti(res);
            // if(this.myCalendar)
            // this.myCalendar.updateInputfield();
          });
    } else {
      this.cords = [];
      this.chiamataUtenti(null);
    }
  }


  chiamataUtenti(res2: any) {
    this.userService.getCord().subscribe((res) => {

      this.i = 0;
      //showUser è false  (definito per la casistica devices)
      //toCallTrackerInUser è undefined
      if (this.showUser && this.toCallTrackerInUser) {
       
        //TODO: Indagare su questo 6----v (tipologia device???)
        if (res2 != null) {
          this.cords = [...this.cords, ...res.data.utentiOnline];
          this.allcords = this.cords;
          this.setLista();
          this.clearMarkers();
          res2.data.forEach((element, i) => {

            element.tracker = true;
            res2.data[i].counter = i + this.cords.length
            this.addMarker2(
              element.ultimaPosizione.latitudine,
              element.ultimaPosizione.longitudine,
              element.counter
            );
          });
          this.listaBeacons = res2.data;
          // if(this.myCalendar)
          // this.myCalendar.updateInputfield();
          this.cords.forEach((user, i) => {
            this.state.push('default');

            if (
              false //TODO RIMETTERE
              // user.ultimaPosizione &&
              // user.ultimaPosizione.timestamp &&
              // user.ultimaPosizione.timestamp < new Date().getTime() - 18000000
            ) {
              this.cords[i].counter = -1;
              this.usersStatic[i].counter = -1;
            } else {
              this.cords[i].counter = this.i;
              this.usersStatic[i].counter = this.i;
            }
            //L.marker(user.latitudine, user.longitudine).removeFrom(this.map)
            this.addMarker2(
              user.ultimaPosizione.latitudine,
              user.ultimaPosizione.longitudine,
              user.counter
            );
            if (user.counter != -1) this.i += 1;
          });
          this.showToggle = true;
        }
      } else {
        
        if (this.selectedPanel != 'devices') {
          this.cords = [...this.cords, ...res.data.utentiOnline];
        }

        this.allcords = this.cords;
        this.setLista();
        this.clearMarkers();
        this.cords.forEach((user, i) => {
          if (
            //TODO: RIMETTERE
            false
            // user.ultimaPosizione &&
            // user.ultimaPosizione.timestamp &&
            // user.ultimaPosizione.timestamp < new Date().getTime() - 18000000
          ) {
            this.cords[i].counter = -1;
            this.usersStatic[i].counter = -1;
          } else {
            this.cords[i].counter = this.i;
            this.usersStatic[i].counter = this.i;
          }
          //L.marker(user.latitudine, user.longitudine).removeFrom(this.map)
          this.addMarker2(
            user.ultimaPosizione.latitudine,
            user.ultimaPosizione.longitudine,
            user.counter
          );
          if (user.counter != -1) this.i += 1;
        });
        setTimeout(() => {
          this.spinner = false;
          this.showToggle = true;
        }, 1000)
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervallo);
    clearInterval(this.intervalBeacon);
    this.open = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.shareService.changePaginaTraker(false)
  }

  ngOnInit(): void {
    this.iscrizioneTopicWebSocket();
    this.initMap();
    this.shareService.changePaginaTraker(true)
  }

  searchInUser() {
    if (this.usersStatic !== null) {
      let valore: string = this.searchform.value.search;
      this.usersStatic = this.allUser;
      if (valore) {
        let users = [];
        this.usersStatic.forEach((user) => {
          let nomeCognome = user.nome + ' ' + user.cognome;
          let cognomeNome = user.cognome + ' ' + user.nome;
          user.gruppos.forEach((gruppo) => {
            if (
              gruppo.nomeGruppo.includes(valore) ||
              gruppo.nomeGruppo.toLowerCase().includes(valore.toLowerCase()) ||
              gruppo.nomeGruppo.toUpperCase().includes(valore)
            ) {
              if (!users.find((x) => x === user)) {
                users.push(user);
              }
            }
          });

          if (
            cognomeNome
              .toLocaleLowerCase()
              .includes(valore.toLocaleLowerCase()) ||
            nomeCognome
              .toLocaleLowerCase()
              .includes(valore.toLocaleLowerCase()) ||
            user.nome.toLowerCase().includes(valore.toLowerCase()) ||
            user.cognome.toLowerCase().includes(valore.toLowerCase())
          ) {
            if (!users.find((x) => x === user)) {
              users.push(user);
            }
          }

          if (
            user.user &&
            (user.user.nome
              .toLocaleLowerCase()
              .includes(valore.toLocaleLowerCase()) ||
              user.user.cognome
                .toLocaleLowerCase()
                .includes(valore.toLocaleLowerCase()))
          ) {
            if (!users.find((x) => x === user)) {
              users.push(user);
            }
          }
        });
        this.usersStatic = users;
      } else if (this.users !== this.allUser) {
        this.usersStatic = this.allUser;
      }
    }
  }

  ngAfterViewInit(): void {
    // this.getLista();
    this.creaMappa();
    this.spinner = true;

  }

  changeMonth(event) {
    this.dateToShow = new Date(event.year, event.month, 0);
  }

  changeStatusCalendar(valueListToolTip, j) {
    this.listToolTip.forEach((element) => {
      element = false;
    });
    this.listToolTip[j] = !valueListToolTip;
  }

  changeStatusToFalseCalendar() {
    this.listToolTip.forEach((element, i) => {
      this.listToolTip[i] = false;
    });
  }

  getLista() {
    if (this.tracker) {
      this.subscription = this.userService
        .getCordTracker(6, true)
        .subscribe((res) => {
          res.data.forEach((element) => {
            this.state.push('default');
            element.tracker = true;
          });
          this.cords = res.data;
          this.userService.getCord().subscribe(
            (res) => {
              this.cords = [...this.cords, ...res.data.utentiOnline];
              this.allcords = this.cords;
              this.setLista();
              this.creaMappa();
            },
            (error) => { }
          );
        });
    } else {
      this.userService.getCord().subscribe(
        (res) => {
          this.cords = res.data.utentiOnline;
          this.allcords = this.cords;
          this.setLista();
          this.creaMappa();
        },
        (error) => { }
      );
    }
  }





  stampa(beacon) {
    return beacon.nome
  }

  selezionaData() {
    if (this.rangeDates !== undefined && this.rangeDates[0] !== null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.fromDay = dd;
      this.fromMonth = mm;
      this.fromYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] !== null) {
      var dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[1].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] === null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates == undefined) {
      this.fromDay = '01';
      this.fromMonth = '01';
      this.fromYear = '1990';
      this.toDay = this.today.getDate();
      this.toMonth = this.today.getMonth() + 1;
      this.toYear = this.today.getFullYear();
    }
  }


  getBeacons(id) {
    this.idUserOpen = id;
    if (!this.showUser) {
      this.userService.getBeaconsTracker(id).subscribe((res) => {
        this.listaBeacons = [];
        this.utenteAssociato = '';
        this.utenteAssociato = res.data.utente;
        this.listaBeacons = res.data;
      });
    } else {
      this.toCallTrackerInUser = true;
    }
  }


  getBattery(id) {
    this.userService.getBatteryMode(id).subscribe((res) => {
      if (res != null) {
        if (res.data[0].telemetryData == 1) {
          this.powerMode = 1;
          this.userService.getBatteryTracker(id).subscribe((res2) => {
            if (res2.data.length === 0) {
              this.batteryLevel = -1;
            } else {
              this.batteryLevel = res2.data[0].telemetryData;
            }
          });
        } else {
          this.powerMode = 0;
        }
      }
    });


  }

  setLista() {
    let i = 0;
    this.usersStatic = [];
    this.cords.forEach((user) => {
      let utente: Utente = new Utente();
      utente.id = user.id;
      utente.nome = user.nome;
      utente.cognome = user.cognome ? user.cognome : '';
      utente.gruppos = user.gruppos ? user.gruppos : [];
      utente.latitudine = user.ultimaPosizione.latitudine;
      utente.longitudine = user.ultimaPosizione.longitudine;
      utente.capcomune = user.ultimaPosizione.capComune;
      utente.user = user.utente;
      let tmp = utente.capcomune;
      utente.capcomune = utente.capcomune.split(',', 2);
      utente.token = i.toString();
      utente.counter = i;
      utente.tracker = user.tracker;
      if (utente.capcomune.length > 1) {
        utente.capcomune[1] = tmp;
      }
      this.usersStatic.push(utente);
      i++;
    });
    this.allUser = this.usersStatic;
  }

  countUsers() {
    if (!this.usersStatic) {
      return 0;
    }
    return this.usersStatic.filter((user) => !user.tracker).length;
  }

  creaMappa() {
    let cordinate = {
      lat: 40.86,
      lng: 14.28,
    };
    navigator.geolocation.getCurrentPosition(
      (res) => {
        cordinate.lat = res.coords.latitude;
        cordinate.lng = res.coords.longitude;
        let a = 30; //PoC, originale: 14
        let zoomLevel = a;
        this.map = new Map('map', {
          center: [39.8282, -98.5795],
          zoom: zoomLevel,
          dragging: true
        });

        const tiles = new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 18,
          minZoom: 3,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });

        //  const fullscreenControl = new L.Control.Fullscreen();
        //  this.map.addControl(fullscreenControl);


        if (Object.keys(L.control).includes("fullscreen")) {
          L.control["fullscreen"](null).addTo(this.map);
        }



        this.sidebar = new Control.Sidebar({ container: 'sidebar', position: "right" })
          .addTo(this.map)
          .open('home');


        tiles.addTo(this.map);

        // this.map2 = new L.map('map', {
        //   center: [cordinate.lat, cordinate.lng],
        //   zoom: zoomLevel,
        //   dragging: true,
        // });

        if (this.mainLayer) this.mainLayer.addTo(this.map);
        // if (this.mainLayer) this.mainLayer.addTo(this.map2);
        if (this.usersStatic)
          this.usersStatic.forEach((user, i) => {
            if (
              //TODO: Rimettere
              false
              //user.ultimaPosizione &&
              //user.ultimaPosizione.timestamp &&
              //user.ultimaPosizione.timestamp < new Date().getTime() - 18000000
            ) {
              this.usersStatic[i].counter = -1;
            } else {
              this.usersStatic[i].counter = this.i;
            }
            this.addMarker(user.latitudine, user.longitudine, user.counter);
          });
        this.firstTime = true;
        this.mappaCaricata.emit(true);
      },
      (error) => {
        this.mappaCaricata.emit(true);
        this.alertService.error(
          'Per continuare, è necessario fornire i permessi di geolocalizzazione'
        );
        this.router.navigate(['']);
      }
    );
  }

  addMarker(cordinate1, cordinate2, contatore, option?, lunghezza?) {
    if (lunghezza === 1) {
      this.i = 99;
    }
    let markerHtmlStyles;
    if (contatore >= 0) {
      markerHtmlStyles = this.generateMarker(this.colorArray[contatore])
    } else {
      markerHtmlStyles = this.generateMarker();
    }

    let smallIcon = L.divIcon({
      className: 'my-custom-pin',
      iconAnchor: [0, 24],
      popupAnchor: [0, -36],
      html: `<i class="material-icons notranslate" style="${markerHtmlStyles}">place</i>`,
    });

    let marker;
    if (option) {
      marker = L.marker([cordinate1, cordinate2], { icon: smallIcon })
        .addTo(this.map)
        .bindPopup(option)
        .openPopup();
    } else {
      marker = L.marker([cordinate1, cordinate2], {
        icon: smallIcon,
      });
      if (marker) marker.addTo(this.map);
    }
    this.markers.push(marker);
    if (lunghezza) {
      this.i = this.i + (Math.floor(100 / lunghezza) + 1);
      if (lunghezza === 1) {
        this.i = 99;
      }
      if (this.i > 99) {
        this.i = 99;
      }
    }
  }


  addMarker2(cordinate1, cordinate2, contatore) {
    if (this.open) {
      let markerHtmlStyles;
      if (contatore >= 0) {
        markerHtmlStyles = this.generateMarker(this.colorArray[contatore]);
      } else {
        //TODO: RIPORTARE A GRAY
        markerHtmlStyles = this.generateMarker();
      }

      let smallIcon = L.divIcon({
        className: 'my-custom-pin',
        iconAnchor: [0, 24],
        popupAnchor: [0, -36],
        html: `<i class="material-icons notranslate" style="${markerHtmlStyles}">place</i>`,
      });

      let marker = L.marker([cordinate1, cordinate2], {
        icon: smallIcon,
      });

      if (marker) marker.addTo(this.map);

      if (this.idUserOpen && this.listaBeacons) {
        let utente = this.usersStatic.filter((res) => {
          if (this.idUserOpen === res.id) return res;
        });
        if (this.cerchio) {
          this.map.removeLayer(this.cerchio);
        }
        this.cerchio = undefined;
        if (this.map.getZoom() > 12 && !this.showUser)
          this.cerchio = L.circle(
            [utente[0].latitudine, utente[0].longitudine],
            100
          ).addTo(this.map);
      }
      this.markers.push(marker);
    }
  }

  getRandomColor() {
    var color = this.colorArray[this.i];
    return color;
  }

  zoom(lat, lng) {
    // var latlng = L.latLng(lat, lng);
    // this.map.setView(latlng, this.map.getZoom());
    this.map.flyTo([lat, lng], 18);
  }

  iscrizioneTopicWebSocket() {
    this.stompClient = this.wsService.connect();
    
    this.stompClient.connect({}, (frame) => {
      this.riconnessioneWs = false;
      this.stompClient.subscribe('/topic/device/allarmi', (res) => { //In attesa di allarmi
        this.allarme = JSON.parse(res.body.toString());
        // console.log("Ricevuto dal WS: " + res + "\n alarm.monitoring.device: " + this.allarme.monitoring.device);

        if (this.allarme.monitoring.tipologia == "tracciamento_asset") { //Considero solo gli allarmi della tipologia interessata
          this.listaDispositiviConAllarme.push(this.allarme.monitoring.device); //Aggiungo l'ID del dispositivo alla lista di dispositivi con un allarme attivo
          this.userService.getCordDevice(this.allarme.monitoring.device).subscribe((res) => { //Ottengo le coordinate del dispositivo che ha sollevato l'allarme
            
            this.modaleAllarme = true;
            this.alarmLat = res.data.latitudine;
            this.alarmLong = res.data.longitudine;

            this.testEmitter.emit(true);
          });
        }
      });

    });
  }

  checkForAlarms(deviceId) {
    if (this.listaDispositiviConAllarme.indexOf(deviceId) == -1) {
      return false;
    }
    return true;
  }

  raggiungiAllarme() {
    this.zoom(this.alarmLat, this.alarmLong);
    this.modaleAllarme = false;
  }

  apriGestioneAllarme(idDevice) {
    this.modaleGestioneAllarme = true;
    this.selectedDevice = idDevice;
  }

  //1: Falso allarme | 2: Risolto
  getConfermaAllarme(id, stato) {
    this.iotService.getConfermaAllarme(id, stato).subscribe((res) => {
      this.alertService.success("Operazione effettuata con successo.")
      this.listaDispositiviConAllarme.splice(this.listaDispositiviConAllarme.indexOf(id), 1);
    })
    this.modaleGestioneAllarme = false;
    this.iotService.getConfermaAllarme(id, stato).subscribe((res) => { })
  }

  notificaOperatore() {
    this.iotService.inviaAllarmeAdOperatoreAssociato(this.selectedDevice).subscribe((res) => {
      this.alertService.success("Operazione effettuata con successo.");
    })
  }


  deleteCerchio() {
    if (this.cerchio) {
      this.map.removeLayer(this.cerchio);
    }
    this.cerchio = undefined;
  }
  onMapReady(map: Map) {
    this.map = map;
  }

  listaChiamateTracciamento;

  tracciamento(id, nome, device?) {
    this.deleteCerchio()
    if (!device) {
      this.nome = nome;
      this.open = false;
      let lista: string[] = new Array();
      lista.push(id);
      let date = new Date();
      let giorno = date.getDate().toString();
      if (Number(giorno) < 10) {
        giorno = '0' + giorno
      }
      let mese = (date.getMonth() + 1).toString();
      if (Number(mese) < 10) {
        mese = '0' + mese;
      }
      let anno = date.getFullYear();
      this.fileService
        .inviaDatiReport2(lista, giorno, mese, anno, giorno, mese, anno)
        .subscribe(
          (res) => {
            this.traccia = true;
            this.colorArray = ColorArray2;
            this.i = 0;
            this.markers.forEach((marker) => {
              this.map.removeLayer(marker);
            });
            this.markers = [];
            let primoRisultato = true;

            res.data.forEach((posizione, index) => {
              if (primoRisultato) {
                this.zoom(posizione.latitudine, posizione.longitudine);
              }
              primoRisultato = false;
              let ora = posizione.createdAt.split(' ');
              let option =
                'Indirizzo: ' +
                posizione.indirizzo +
                ', ' +
                posizione.capComune +
                ', Ora: ' +
                ora[1];
              this.addMarker(
                posizione.latitudine,
                posizione.longitudine,
                index,
                option,
                res.data.length
              );
            });
          },
          (error) => {
            this.open = true;
            this.alertService.warn('Tracciamento non disponibile');
          }
        );
    } else {
      this.nome = nome;
      this.open = false;
      let today = new Date();
      let startOfToday = new Date(
        today.getTime() - (today.getTime() % 86400000)
      );
      this.fileService
        .downloadGeoLocation(
          id,
          Math.trunc(startOfToday.getTime() / 1000),
          Math.trunc(today.getTime() / 1000)
        )
        .subscribe(
          (res) => {
            this.listaChiamateTracciamento = res.data;
            this.traccia = true;
            this.colorArray = ColorArray2;
            this.i = 0;
            this.markers.forEach((marker) => {
              this.map.removeLayer(marker);
            });
            this.markers = [];
            let primoRisultato = true;
            res.data.forEach((posizione, index) => {
              if (primoRisultato) {
                this.zoom(posizione.latitudine, posizione.longitudine);
              }
              primoRisultato = false;
              let ora = posizione.timestamp.split(' ');
              let option =
                'Indirizzo: ' +
                posizione.indirizzo +
                ', ' +
                posizione.capComune +
                ', Ora: ' +
                ora[1];
              this.addMarker(
                posizione.latitudine,
                posizione.longitudine,
                index,
                option,
                res.data.length
              );
            });
          },
          (error) => {
            this.open = true;
            this.alertService.warn('Tracciamento non disponibile');
          }
        );
    }
  }

  resetStateTooltip() {
    this.state.forEach((element, i) => {
      this.state[i] = 'default';
    });
  }

  stopTracciamento() {
    this.colorArray = ColorArray1;
    this.i = 0;
    this.markers.forEach((marker) => {
      this.map.removeLayer(marker);
    });
    this.markers = [];
    let primaVolta = true;
    this.cords.forEach((user) => {
      user.counter = this.i;
      if (primaVolta) {
        this.zoom(
          user.ultimaPosizione.latitudine,
          user.ultimaPosizione.longitudine
        );
      }
      primaVolta = false;
      this.addMarker(
        user.ultimaPosizione.latitudine,
        user.ultimaPosizione.longitudine,
        user.counter
      );
    });
    this.open = true;
    this.traccia = false;
  }

  formatBeaconData(beacons) {
    let returnList = [];
    beacons.forEach((res) => {
      let returnObj: any = {};
      returnObj.Nome = res.device.deviceName ? res.device.deviceName : '';
      returnObj.attivo = res.active ? 'Si' : 'No';
      returnObj.Precisione = res.rssi ? res.rssi : '';
      returnList.push(returnObj);
    });
    return returnList;
  }

  getFullDate(d: Date) {
    return (
      d.getDate() +
      '/' +
      (d.getMonth() + 1) +
      '/' +
      d.getFullYear() +
      ' ' +
      d.getHours() +
      ':' +
      d.getMinutes() +
      ':' +
      d.getSeconds()
    );
  }

  formatLocationData(beacons) {
    let returnList = [];
    beacons.forEach((res) => {
      let returnObj: any = {};
      returnObj.Indirizzo = res.indirizzo ? res.indirizzo : '';
      returnObj.Latitudine = res.latitudine ? res.latitudine : '';
      returnObj.Longitudine = res.longitudine ? res.longitudine : '';
      returnObj.CAP = res.capComune ? res.capComune : '';
      returnObj.Accuratezza = res.accuracy ? res.accuracy : '';
      returnObj.Extra = '';
      if (res.extra) returnObj.Extra = res.extra.LocationType;
      returnObj.Data = this.getFullDate(new Date(res.timestamp));
      returnList.push(returnObj);
    });
    return returnList;
  }

  exportToExcel(user) {
    let dataForExcel: any = {};

    this.userService.getBeaconsTracker(user.id).subscribe((res) => {
      dataForExcel.Beacons = this.formatBeaconData(res.data);
      let start = new Date();
      let end = new Date();
      if (this.fromDay === this.toDay && this.fromMonth === this.toMonth && this.fromYear === this.toYear) {
        start.setDate(Number(this.fromDay));
        start.setMonth(Number(this.fromMonth) - 1);
        start.setFullYear(Number(this.fromYear));
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(1);
        end.setDate(Number(this.toDay));
        end.setMonth(Number(this.toMonth) - 1);
        end.setFullYear(Number(this.toYear));
      } else {
        start.setDate(Number(this.fromDay));
        start.setMonth(Number(this.fromMonth) - 1);
        start.setFullYear(Number(this.fromYear));
        end.setDate(Number(this.toDay) + 1);
        end.setMonth(Number(this.toMonth) - 1);
        end.setFullYear(Number(this.toYear));
      }

      this.fileService
        .downloadGeoLocation(
          user.id,
          Math.trunc(start.getTime() / 1000),
          Math.trunc(end.getTime() / 1000)
        )
        .subscribe(
          async (res2) => {
            this.list.push(user.id)
            let body: any = {};
            body.id = this.list;
            this.list = ["Alert"]
            body.telemetryName = this.list
            this.fileService.inviaDatiReportDispositivo(body, this.fromDay, this.fromMonth, this.fromYear, this.toDay, this.toMonth, this.toYear)
              .subscribe(async (res3) => {

                this.isExporting = true
                this.switchExportingOverlay()
                this.sidebar.close()
                await this.delay(1000);
                this.map.once('zoomend', () => {
                  this.clearMarkers();
                  if (this.cerchio) {
                    this.map.removeLayer(this.cerchio);
                  }
                  this.myCalendar.overlayVisible = false;
                  this.cerchio = undefined;
                  this.addMarker2(user.latitudine, user.longitudine, 0);
                  let node = document.getElementById('map');
                  let context = this;
                  htmlToImage.toJpeg(node).then(function (dataUrl) {
                    dataForExcel.dataUrl = dataUrl;
                    dataForExcel.location = context.formatLocationData(res2.data);
                    dataForExcel.Alert = res3.data;
                    let reportData = {
                      title: 'Report Dispositivo: ' + user.nome,
                      data: dataForExcel,
                      headers: [
                        'Indirizzo',
                        'Latitudine',
                        'Longitudine',
                        'CAP',
                        'Accuratezza',
                        'Extra',
                        'Data',
                      ],
                      user: user,
                    };
                    context.excelService.exportExcel(reportData, [
                      'Tracker',
                      'location',
                      'Alert'
                    ], null, null, true);

                    context.changeStatusToFalseCalendar();

                  });
                  this.isExporting = false;
                  this.list = [];
                  this.sidebar.open('home');
                  this.switchExportingOverlay()
                });

                this.map.flyTo([user.latitudine, user.longitudine], 18);
              }


              )


          },
          (error) => {
            this.alertService.error('Inserisci delle date valide');
          }
        );

    });

  }

  clearMarkers() {
    if (this.open) {
      this.i = 0;
      this.markers.forEach((marker) => {
        this.map.removeLayer(marker);
      });
      this.markers = [];
    }
  }

  //UTILS METHODS ----------------------

  private switchExportingOverlay() {
    if (this.isExporting) {
      this.overlayVisib = 'flex';
    } else {
      this.overlayVisib = 'none';
    }
  }

  private initMap(): void {
    this.map = new Map('map', {
      center: [41.884174, 12.506103],
      zoom: 6
    });
    const tiles = new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    this.sidebar = new Control.Sidebar({ container: 'sidebar', position: "right" })
      .addTo(this.map)
      .open('home');
    tiles.addTo(this.map);
  }


  flyTo() {
    this.map.flyTo([40.865901, 14.285809]);
  }

  rotate(j, state, id, isUser = false) {
    this.idUserOpen = null;
    if (this.intervalBeacon) {
      clearInterval(this.intervalBeacon);
    }
    this.toCallTrackerInUser = false;
    this.state.forEach((element, i) => {
      this.state[i] = 'default';
    });
    this.state[j] = state === 'default' ? 'rotated' : 'default';
    if (this.state[j] === 'rotated') {
      this.listaBeacons = [];
      this.utenteAssociato = '';
      this.getBeacons(id);
      this.getBattery(id);
      this.intervalBeacon = setInterval(() => {
        this.getBeacons(id);
        if (!this.showUser) this.getBattery(id);
      }, 5000);
    }
  }

  addMarker3() {
    const redMarker = L.divIcon({
      className: 'material-icons red-marker',
      html: 'place',
      iconSize: [24, 24],
      iconAnchor: [12, 12]
    });

    const marker = L.marker([40.865901, 14.285809], { icon: redMarker }).addTo(this.map);
  }

  debugUserlist() {
    
  }

  changeRenderedList(id) //usato per alleggerire la pagina
  {
    
    if (id != this.selectedPanel) {
      if (id != "home" && this.traccia) {
        if ((this.selectedPanel == "devices" && id == "users") || (this.selectedPanel == "users" && id == "devices")) {
          this.stopTracciamento();
        }
      }
      this.selectedPanel = id;
      this.spinner = true;
      this.clearMarkers();
      this.resetStateTooltip();
      this.changeStatusToFalseCalendar();
      this.deleteCerchio();
      this.cords = [];
      this.usersStatic = [];

      clearInterval(this.intervallo);
      if (id == 'home') {
        this.showUser = false;
        this.fetchData();
      }

      else if (id == 'users') {
        this.showUser = true;
        this.fetchData();
      }

      else if (id == 'devices') {
        this.showUser = false;
        this.fetchData();
      }
      this.intervallo = null;
      this.intervallo = setInterval(() => {
        if (this.open) {
          this.fetchData();
        }
      }, 10000);
    }
  }

  generateMarker(color: string = 'gray') {
    let markerStyle: string = `
      color: ${color};
      font-size: xx-large;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      position: relative;
      -webkit-text-stroke: 1px black;
      `;

    return markerStyle
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


}
