import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { NgScormPlayerService, ScormResult } from 'ng-scorm-player';
import { timer } from 'rxjs';
import { compileNgModuleFromRender2 } from '@angular/compiler/src/render3/r3_module_compiler';
import { ConfirmationService } from 'primeng/api';
import { Scorm } from 'src/app/MODELS/CLASSROOM/Scorm';
import { ScormInvio } from 'src/app/MODELS/CLASSROOM/ScormInvioDati';
import { ScormService } from 'src/app/SERVICES/CLASSROOM/scorm.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { AccountService } from 'src/app/SERVICES/index';
@Component({
  selector: 'app-corsi-player',
  templateUrl: './corsi-player.component.html',
  styleUrls: ['./corsi-player.component.scss']
})

export class CorsiPlayerComponent implements OnInit,OnDestroy {
  @Input() scorms;
  @Output() chiudiPlayer= new EventEmitter<boolean>();
  oggettoPassato=new Scorm;
  idModulo;
  idAula;
 stringa;
  get;
  set;
  sub;
  invioDati=new ScormInvio
  caricato=false;
  idUtente;
  ruolo;
  finito;
  idCorso;
  path;
  primaVolta=true;
  intervallo: any;
  tempoInviato;
  tempo=0;
  contatore;
  loading=false;

  constructor(
     private alertService: AlertService,
     private player: NgScormPlayerService,
     private accountService: AccountService,
     private scormService: ScormService,
     private confirmationService:ConfirmationService,
     ) {
      this.invioDati.idUtente = this.accountService.userValue.data.id;

      this.intervallo = setInterval(() => {
this.scormService.aggiungi_dati_modulo_utente(this.invioDati).subscribe((res) => {


}, (error) => {

})
      },60000)

     this.contatore= timer(0, 1000).subscribe(ellapsedCycles => {

        this.tempo++;



    });


}

  ngOnDestroy(): void {
    this.invioDati.tempo = this.tempo;
    this.scormService.aggiungi_dati_modulo_utente(this.invioDati).subscribe((res) => {


    }, (error) => {
    })
   this.get.unsubscribe();
    this.set.unsubscribe();
    this.sub.unsubscribe();
    this.finito.unsubscribe();
    this.contatore.unsubscribe();
    clearInterval(this.intervallo);
  }

  ngOnInit(): void {
    if(this.scorms.tempo){
    this.tempo=this.scorms.tempo}
    this.caricato=true;

    this.invioDati.idCorso = this.scorms.idCorso;
    this.invioDati.idModulo = this.scorms.idModulo;
  this.invioDati.aulaId = this.scorms.idAula;

   this.path=this.scorms.path;
   this.stringa=this.scorms.stringa;

   this.ruolo = this.accountService.ruolo;

   this.player.initializeEvent.subscribe(val => {


 });
   this.get = this.player.getValueEvent.subscribe(val => {
 this.invioDati.statoModulo= this.player.MyGetValue('cmi.core.lesson_status'),
 this.invioDati.tempo = this.tempo;
 this.invioDati.recuperoPercorso =this.player.GetValue('cmi.suspend_data'),
 this.invioDati.voto =this.player.GetValue('cmi.core.score.raw')

});
this.set  = this.player.setValueEvent.subscribe(val => {

if(this.stringa && this.primaVolta){
  this.player.SetValue('cmi.suspend_data',this.stringa)
this.primaVolta=false;
   }

});

this.sub= this.player.commitEvent.subscribe(val => {


 this.invioDati.statoModulo= this.player.MyGetValue('cmi.core.lesson_status'),
 this.invioDati.tempo = this.tempo;
 this.invioDati.recuperoPercorso =this.player.GetValue('cmi.suspend_data'),
 this.invioDati.voto =this.player.GetValue('cmi.core.score.raw'),

 this.scormService.aggiungi_dati_modulo_utente(this.invioDati).subscribe((res) => {


 }, (error) => {

 })
});

this.finito=this.player.finishEvent.subscribe(val => {

});
  }
goBackScorm(){
  this.chiudiPlayer.emit(true);

  }

  gettempoInviato(tempo: number) {
    var ore = '' + Math.floor(tempo / 3600);
    var minuti = '' + Math.floor(tempo % 3600 / 60);
    var secondi = '' + Math.floor(tempo % 3600 % 60);

    if (Number(ore) < 10) {
      ore = '0' + ore;
    } else {
      ore = '' + ore;
    }
    if (Number(minuti) < 10) {
      minuti = '0' + minuti;
    } else {
      minuti = '' + minuti;
    }
    if (Number(secondi) < 10) {
      secondi = '0' + secondi;
    } else {
      secondi = '' + secondi;
    }

    this.tempoInviato = ore + ':' + minuti + ':' + secondi;
  }
  ritorno(){

    this.confirmationService.confirm({
      key:"ritorno",
      message: 'Sicuro di voler continuare? '+ '<br>' +' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {

        this.loading=false;
        this.confirmationService.close();
      }
    });
  }
  responsiveWidth() {    }

}
