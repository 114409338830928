import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { Posizione } from 'src/app/MODELS/ASSISTENZA/posizione';
import { Sessione } from 'src/app/MODELS/ASSISTENZA/sessione';
import { Utente } from 'src/app/MODELS/USER/utente';
import { UsersService, AccountService, AlertService } from 'src/app/SERVICES';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
// import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report-assistenza',
  templateUrl: './report-assistenza.component.html',
  styleUrls: ['./report-assistenza.component.scss'],
})
export class ReportAssistenzaComponent implements OnInit {
  hoveredDate: Date | null = null;
  tutti;
  fromDate;

  newDate: Date;

  toDate;

  dayDate: Date;

  cols: any[];

  users: Utente[];

  allUser: Utente[];

  searchform: FormGroup;

  selectedUsers3: Utente[] = [];

  selezionaUtente: boolean = true;

  utentiSelezionati: Utente[];

  idUtentiSelezionati: String[];
  today = new Date();
  oggi;
  dd = String(this.today.getDate()).padStart(2, '0');
  mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  yyyy = this.today.getFullYear();
  todayDay = this.dd;
  todayMonth = this.mm;
  todayYear = this.yyyy;
  //this.oggi = this.mm + '/' + dd + '/' + yyyy;
  //dataOk : boolean = false;
  calendar: FormGroup;
  toDay;
  toMonth;
  toYear;
  fromDay;
  fromMonth;
  fromYear;
  usersVideo;
  usersGeo;
  dataOK: boolean = true;
  value1: Date;
  value2: Date;
  dates: Date[];
  rangeDates: Date[];
  date1: string;
  report: boolean = false;
  loading = false;
  loading2 = false;
  ita;
  constructor(
    private userService: UsersService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    // private calendar: NgbCalendar,
    private alertService: AlertService,
    private fileService: FileService,
    private excelService: ExportExcelService,
    private datePipe: DatePipe
  ) {
    //  this.fromDate = calendar.getToday();
    //  this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit() {
    this.ita = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domenica',
        'Lunedi',
        'Martedi',
        'Mercoledi',
        'Giovedi',
        'Venerdi',
        'Sabato',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
      monthNamesShort: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk',
    };

    this.buildForm();
    this.userService.getAllReport().subscribe(
      (res) => {
        this.users = res.data.listaUtentiAttivi;
        res.data.listaUtentiCancellati.forEach((element) => {
          this.users.push(element);
        });
        this.allUser = this.users;
        this.report = true;
      },
      (error) => {}
    );

    this.cols = [
      { field: 'email', header: 'Email' },
      { field: 'nome', header: 'Nome' },
      { field: 'cognome', header: 'Cognome' },
      { field: 'gruppos[0].nomeGruppo', header: 'Gruppo' },
    ];

    this.searchform = this.formBuilder.group({
      search: ['', Validators.required],
    });
  }
  buildForm(): void {
    this.calendar = this.formBuilder.group({
      calendario: new FormControl('', Validators.required),
    });
  }
  selectAll() {}

  searchInUser() {
    //metti search in user in gestione utenti
    let valore: string = this.searchform.value.search;
    this.users = this.allUser;
    if (valore) {
      let users = [];
      this.users.forEach((user) => {
        user.gruppos.forEach((gruppo) => {
          if (
            gruppo.nomeGruppo.includes(valore) ||
            gruppo.nomeGruppo.toLowerCase().includes(valore) ||
            gruppo.nomeGruppo.toUpperCase().includes(valore)
          ) {
            if (!users.find((x) => x === user)) {
              users.push(user);
            }
          }
        });

        if (
          user.nome.includes(valore) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.includes(valore) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toUpperCase().includes(valore) ||
          user.cognome.toUpperCase().includes(valore) ||
          user.email.includes(valore) ||
          user.email.toLowerCase().includes(valore.toLowerCase()) ||
          user.email.toUpperCase().includes(valore) ||
          (user.nome + ' ' + user.cognome)
            .toLocaleLowerCase()
            .includes(valore.toLowerCase()) ||
          (user.cognome + ' ' + user.nome)
            .toLocaleLowerCase()
            .includes(valore.toLowerCase())
        ) {
          if (!users.find((x) => x === user)) {
            users.push(user);
          }
        }
      });
      this.users = users;
    } else if (this.users !== this.allUser) {
      this.users = this.allUser;
    }
  }

  avanti() {
    this.alertService.clear();
    if (this.selectedUsers3.length > 0) {
      this.utentiSelezionati = this.selectedUsers3;
      this.selezionaUtente = false;
    } else {
      this.alertService.error('Seleziona uno o più utenti');
    }
  }

  indietro() {
    this.selezionaUtente = true;
    this.selectedUsers3 = [];
  }
  selezionaData() {
    if (this.rangeDates !== undefined && this.rangeDates[0] !== null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.fromDay = dd;
      this.fromMonth = mm;
      this.fromYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] !== null) {
      var dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[1].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] === null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates === undefined) {
      this.fromDay = '01';
      this.fromMonth = '01';
      this.fromYear = '1990';

      this.toDay = this.todayDay;
      this.toMonth = this.todayMonth;
      this.toYear = this.todayYear;
    }
  }

  exportExcelVideo() {
    let usersVideo;
    usersVideo = this.usersVideo.data;
    let usersVid: Sessione[] = new Array();

    usersVideo.forEach((user) => {
      let sessione: Sessione = new Sessione();
      this.users.forEach((utente) => {
        if (utente.id === user.sessione.idCreatore) {
          sessione.operatore = utente.nome + ' ' + utente.cognome;
        }
      });

      sessione.nome = user.utente.nome + ' ' + user.utente.cognome;
      sessione.dettaglio = user.utente.dettaglio[0]?.dettaglioAggiuntivo;
      sessione.gruppo = '';
      user.utente.gruppos.forEach((gruppo) => {
        sessione.gruppo = sessione.gruppo + ' ' + gruppo.nomeGruppo;
      });
      sessione.latitudine = user.latitudine;
      sessione.longitudine = user.longitudine;
      sessione.motivo_chiamata = user.motivoChiamata;
      sessione.durata =
        'da ' +
        this.datePipe.transform(user.createdAt, 'dd/MM/yy HH:mm:ss') +
        ' a ' +
        this.datePipe.transform(user.deletedAt, 'dd/MM/yy HH:mm:ss');
      usersVid.push(sessione);
    });

    if (usersVid.length >= 1) {
      this.loading = false;
      this.exportToExcel(usersVid);
      //   import("xlsx").then(xlsx => {
      //     const worksheet = xlsx.utils.json_to_sheet(usersVid);
      //     const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      //     const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      //     this.saveAsExcelFile(excelBuffer, "Report_Videochiamata");
      // });
    } else {
      this.loading = false;
      this.alertService.clear;
      if (this.dataOK) {
        this.alertService.info(
          'Nessun report presente per gli utenti e/o le date selezionate'
        );
      }
    }
  }

  // saveAsExcelFile(buffer: any, fileName: string): void {
  //     import("file-saver").then(FileSaver => {
  //         let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //         let EXCEL_EXTENSION = '.xlsx';
  //         const data: Blob = new Blob([buffer], {
  //             type: EXCEL_TYPE
  //         });
  //         FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  //     });
  // }

  inviaDati() {
    this.loading = true;
    this.selezionaData();

    this.idUtentiSelezionati = new Array();
    this.utentiSelezionati.forEach((user) => {
      let id: string;
      id = user.id;
      this.idUtentiSelezionati.push(id);
    });

    this.fileService
      .inviaDatiReport(
        this.idUtentiSelezionati,
        this.fromDay,
        this.fromMonth,
        this.fromYear,
        this.toDay,
        this.toMonth,
        this.toYear
      )
      .subscribe(
        (res) => {
          this.usersVideo = res;
          this.exportExcelVideo();
        },
        (error) => (this.loading = false)
      );
  }

  dataForExcel = [];

  exportToExcel(usersVid) {
    this.dataForExcel = [];
    usersVid.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row));
    });

    let reportData = {
      title: 'Report Assistenza',
      data: this.dataForExcel,
      headers: Object.keys(usersVid[0]),
    };

    this.excelService.exportExcel(reportData);
  }
}
