<div class="container-fluid" *ngIf="gestioneFile">
    <div *ngIf="gestione" class="content">

        <div class="row">
            <div class="col-md-12 ">
                <div class="card">
                    <div class="card-content">

                        <h4 class="card-title" *ngIf="!visualizzaEsercitazioni">{{ambiente.DOCUMENTAZIONE}}</h4>
                        <h4 class="card-title" *ngIf="visualizzaEsercitazioni">
                            <button *ngIf="visualizzaEsercitazioni" (click)="indietroVisualizzaEsercitazioni()"
                                id="back-button" class="btn btn-round  btn-just-icon"
                                style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
                                <i class="material-icons notranslate">arrow_back</i>
                            </button>
                            {{nomeEsercitazione}}
                        </h4>
                        <ul class="nav nav-pills nav-pills-primary" *ngIf="!visualizzaEsercitazioni">
                            <li [ngClass]="{'active': pil8}">
                                <a (click)="cambiaPil('file')" data-toggle="tab">File</a>
                            </li>
                            <li [ngClass]="{'active': pil9}" *ngIf="((userRole.toString() === '1') || (userRole.toString() === '4'))">
                                <a (click)="cambiaPil('cestino')" data-toggle="tab">Cestino</a>
                            </li>

                            <div *ngIf="!visualizzaEsercitazioni && ((userRole.toString() === '1') || (userRole.toString() === '4'))"
                                class="pull-right">
                                <div class="btn btn-primary btn-round" style="padding: 8px 8px 8px 8px;"
                                    (click)="addFile()">
                                    <i class="material-icons notranslate">add</i>
                                </div>
                            </div>
                        </ul>

                        <hr>
                        <div class="tab-content">
                            <div *ngIf="pil8">
                                <div class="material-datatables">

                                    <div class="row">
                                        <div class="col-sm-3 col-xs-3">
                                            <div class="dataTables_length" id="datatables_length">
                                                <label class="form-group">Limita Risultati
                                                    <select [(ngModel)]="selectedValue" (ngModelChange)="cambiaValue()"
                                                        aria-controls="datatables" class="form-control input-sm">
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>

                                                    </select> </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-9 col-xs-9">

                                            <div class="pull-right">
                                                <div id="datatables_filter" class="dataTables_filter">
                                                    <form [formGroup]="searchform">
                                                        <label class="form-group ">
                                                            Esegui una ricerca
                                                            <p class="hiddenVisibility" *ngIf="!isDesktop">a</p>
                                                            <div class="row">
                                                                <div class="col-sm-10"><input type="text"
                                                                        formControlName="search" class="form-control"
                                                                        placeholder="Cerca..." maxlength="30"
                                                                        style="width: 40vw;">
                                                                </div>

                                                            </div>

                                                        </label>
                                                    </form>
                                                    <!-- <a (click)="vaiRicerca()" class="pull-right" style="margin-top: -10px;">Ricerca Avanzata</a> -->
                                                    <div *ngIf="searchInFile()"></div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="scrollable">
                                        <p-confirmDialog key="esercitazioni" #cd icon="pi pi-exclamation-triangle"
                                            [style]="{width:  responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
                                            <p-footer>

                                                <button [disabled]="loading" *ngIf="cancellaFile" type="button" pButton
                                                    icon="pi pi-check" label="Sì" (click)="accettaCancella()">
                                                    <span *ngIf="loading">
                                                        <p-progressSpinner [style]="{width: '10px', height: '10px'}"
                                                            strokeWidth="8" styleClass="custom-spinner"
                                                            animationDuration=".5s"></p-progressSpinner>
                                                    </span>
                                                </button>
                                                <button *ngIf="!cancellaFile" type="button" pButton icon="pi pi-check"
                                                    label="Sì" (click)="cd.accept()"></button>
                                                <button [disabled]="loading && cancellaFile" type="button" pButton
                                                    icon="pi pi-times" label="No" (click)="cd.reject()"></button>

                                            </p-footer>
                                        </p-confirmDialog>
                                        <div class="table-responsive">
                                            <table id="datatables"
                                                class="table table-striped table-no-bordered table-hover table-responsive"
                                                cellspacing="0" width="100%" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th (click)="ordinaArray('nome')" style="cursor: pointer;">Nome
                                                            File <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i></th>
                                                        <th *ngIf="!visualizzaEsercitazioni"
                                                            (click)="ordinaArray('categoria')" style="cursor: pointer;">
                                                            Categoria <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i></th>
                                                        <th *ngIf="!visualizzaEsercitazioni"
                                                            (click)="ordinaArray('gruppo')" style="cursor: pointer;">
                                                            Gruppo <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i></th>
                                                        <th (click)="ordinaArray('data')" style="cursor: pointer;">Data
                                                            Upload <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i></th>
                                                        <th (click)="ordinaArray('estensione')"
                                                            style="cursor: pointer;">Estensione <i
                                                                class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i></th>

                                                        <th class="text-right">Azioni</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th style="width: 20px;">#</th>
                                                        <th>Nome File</th>
                                                        <th *ngIf="!visualizzaEsercitazioni">Categoria</th>
                                                        <th *ngIf="!visualizzaEsercitazioni">Gruppo</th>
                                                        <th>Data Upload</th>
                                                        <th>Estensione</th>

                                                        <th class="text-right">Azioni</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr *ngFor="let file of listaFile;let i =index;">

                                                        <ng-container
                                                            *ngIf="i>=(selectedValue*(pagina-1)) && i<(selectedValue*pagina)">
                                                            <td style="font-size: xx-small;">

                                                                <i class="material-icons notranslate"
                                                                style="font-size: 22px; color: #aaaaaa;"
                                                                *ngIf="(file.extension ==='docx') || (file.extension ==='txt') || (file.extension ==='doc') || (file.extension ==='pdf') || (file.extension ==='odt') || (file.extension ==='ods') || (file.extension ==='odp') || (file.extension ==='odb') || (file.extension ==='pdf') || (file.extension ==='xls') || (file.extension ==='xlsx')">library_books</i>
                                                            <i class="material-icons notranslate"
                                                                style="font-size: 22px; color: #aaaaaa;"
                                                                *ngIf="(file.extension === 'mp3') || ( file.extension === 'ogg') || ( file.extension === 'wav')">music_video</i>
                                                            <i class="material-icons notranslate"
                                                                style="font-size: 22px; color: #aaaaaa;"
                                                                *ngIf="(file.extension === 'png') || (file.extension === 'jpg') || (file.extension === 'bmp') || (file.extension === 'gif') || (file.extension === 'psd')">insert_photo</i>
                                                            <i class="material-icons notranslate"
                                                                style="font-size: 22px; color: #aaaaaa;"
                                                                *ngIf="(file.extension === 'avi') || (file.extension === 'mpeg') || (file.extension ===  'wmv') || (file.extension ===  'mp4') || (file.extension ===  '3gp') || (file.extension ===  'flv') || (file.extension ===  'mkv')">videocam</i>
                                                            <i class="material-icons notranslate"
                                                                style="font-size: 22px; color: #aaaaaa;"
                                                                *ngIf="(file.extension === 'exe') || (file.extension === 'com') || (file.extension === 'bat') || (file.extension === 'cmd')">computer</i>
                                                            <i class="material-icons notranslate"
                                                                style="font-size: 22px; color: #aaaaaa;"
                                                                *ngIf="(file.extension ===  'htm') || (file.extension ===  'html') || (file.extension ===  'shtml') || (file.extension ===  'shtm') || (file.extension ===  'stm') || (file.extension ===  'asp') || (file.extension ===  'aspx') || (file.extension === 'php') || (file.extension === 'dtw') || (file.extension === 'xml') || (file.extension === 'xps')">code</i>
                                                            <i class="material-icons notranslate"
                                                                style="font-size: 22px; color: #aaaaaa;"
                                                                *ngIf="(file.extension === 'zip') || (file.extension === 'rar')">folder</i>
                                                            <i class="material-icons notranslate"
                                                                style="font-size: 22px; color: #aaaaaa;"
                                                                *ngIf="(file.extension !== 'zip') && (file.extension !== 'rar') && (file.extension !=='exe') && (file.extension !=='com') && (file.extension !=='bat') && (file.extension !=='cmd') && (file.extension !=='png') && (file.extension !=='jpg') && (file.extension !=='bmp') && (file.extension !=='gif') && (file.extension !=='psd') && (file.extension !=='mp3') && (file.extension !=='ogg') && (file.extension !=='wav') && (file.extension !=='htm') && (file.extension !=='html') && (file.extension !=='shtml') && (file.extension !=='shtm') && (file.extension !=='stm') && (file.extension !=='asp') && (file.extension !=='aspx') && (file.extension !=='php') && (file.extension !=='dtw') && (file.extension !=='xml') && (file.extension !=='xps') && (file.extension !=='pdf') && (file.extension !=='txt') && (file.extension !=='doc') && (file.extension !=='docx') && (file.extension !=='odt') && (file.extension !=='ods') && (file.extension !=='odp') && (file.extension !=='odb') && (file.extension !=='pdf') && (file.extension !=='xls') && (file.extension !=='xlsx') && (file.extension !=='mp4') && (file.extension !=='mkv')">insert_drive_file</i>

                                                            </td>
                                                            <td style="font-size: medium;">{{file.title}} </td>
                                                            <td *ngIf="!visualizzaEsercitazioni"
                                                                style="font-size: medium;">{{file.category}}</td>
                                                            <td *ngIf="!visualizzaEsercitazioni"
                                                                style="font-size: medium;">{{file.group}}</td>
                                                            <td style="font-size: medium;">{{file.uploadDate | date:
                                                                'dd/MM/yyyy HH:mm'}}</td>
                                                            <td style="font-size: medium;">{{file.extension}}</td>

                                                            <td class="text-right">



                                                                <a rel="tooltip" (click)="scaricaFile(link + file.id)"
                                                                    title="Download" class="btn btn-primary btn-simple"
                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                        class="material-icons notranslate pointer"
                                                                        style="font-size: 20px;">get_app</i></a>
                                                                <input style="display: none" type="file"
                                                                    (change)="onFileChanged($event, file.id)"
                                                                    #fileInput>
                                                                <a *ngIf="!visualizzaEsercitazioni" rel="tooltip"
                                                                    (click)="fileInput.click()"
                                                                    title="Aggiungi Risposta"
                                                                    class="btn btn-info btn-simple"
                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                        class="material-icons notranslate pointer"
                                                                        style="font-size: 20px;">post_add</i></a>
                                                                <a *ngIf="(file.owner?.includes(userID.toString()) || userRole === 1 || userRole === 4) && !visualizzaEsercitazioni"
                                                                    (click)="getEsercitazioni(file)" rel="tooltip"
                                                                    title="Visualizza Risposte"
                                                                    style="padding: 2px 2px 2px 2px;"
                                                                    class="btn btn-warning btn-simple"><i
                                                                        class="material-icons notranslate pointer"
                                                                        style="font-size: 20px;">library_books</i></a>

                                                                <a *ngIf="(file.owner?.includes(userID.toString()) || userRole === 1 || userRole === 4) && !visualizzaEsercitazioni"
                                                                    (click)="editFile(file)" rel="tooltip"
                                                                    title="Modifica File"
                                                                    class="btn btn-success btn-simple"
                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                        class="material-icons notranslate pointer"
                                                                        style="font-size: 20px;">edit</i></a>

                                                                <a *ngIf="(file.owner?.includes(userID.toString()) || userRole === 1 || userRole === 4) && !visualizzaEsercitazioni"
                                                                    (click)="cancella(file.id,i)" rel="tooltip"
                                                                    title="Elimina File"
                                                                    class="btn btn-simple btn-danger btn-icon remove"
                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                        class="material-icons notranslate pointer"
                                                                        style="font-size: 20px;">close</i></a>

                                                            </td>
                                                        </ng-container>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-5">

                                            <div class="dataTables_info" *ngIf="selectedValue < numeroFile" id="datatables_info" role="status"
                                            aria-live="polite" style="margin-top: 5%;">
                                            {{selectedValue*pagina <=
                                                numeroFile? selectedValue :
                                                numeroFile.toString()[numeroFile.toString().length - 1]}} di
                                                {{numeroFile}} file
                                            </div>

                                                <div class="dataTables_info" *ngIf="selectedValue >= numeroFile" id="datatables_info" role="status"
                                                    aria-live="polite" style="margin-top: 5%;">
                                                    {{numeroFile}} di {{numeroFile}} file</div>
                                                 </div>
                                            <div class="col-sm-7 text-right" [ngClass]="{'pd': !isDesktop}">
                                                <div class="dataTables_paginate paging_full_numbers"
                                                    id="datatables_paginate">
                                                    <ul class="pagination" >
                                                        <li class="paginate_button pointer " id="datatables_first">
                                                            <a (click)="paginaUno()" aria-controls="datatables"
                                                                data-dt-idx="0" tabindex="0" [ngClass]="{'pd': !isDesktop}">Inizio</a>
                                                        </li>

                                                        <!-- VISUAL DESKTOP -->

                                                        <ng-container *ngIf="isDesktop">
                                                            <li class="paginate_button pointer " id="datatables_previous">
                                                                <a (click)="back()" aria-controls="datatables"
                                                                    data-dt-idx="1" tabindex="0">Indietro</a>
                                                            </li>
                                                            <li *ngFor="let pagina of pagine; let i=index"
                                                                class="paginate_button pointer"
                                                                [class]="{active:pagina === appoggioclick}">
                                                                <a (click)="cambiaPagina(i)" aria-controls="datatables"
                                                                    data-dt-idx="2" tabindex="0">{{pagina}}</a>
                                                            </li>
                                                            <li class="paginate_button pointer" id="datatables_next">
                                                                <a (click)="next()" aria-controls="datatables"
                                                                    data-dt-idx="6" tabindex="0">Avanti</a>
                                                            </li>

                                                        </ng-container>
                                                        <!--END VISUAL DESKTOP -->

                                                        <!-- PHONE VIEW -->
                                                        <ng-container *ngIf="!isDesktop">
                                                            <ng-container  *ngFor="let pagina of pagine; let i=index">
                                                                <li
                                                                    class="paginate_button pointer " *ngIf="appoggioclick===pagina && pagina > 2">
                                                                            <a (click)="paginaUno()" aria-controls="datatables" class="pd pdr"
                                                                            data-dt-idx="2" tabindex="0">1 ...</a>
                                                                </li>
                                                                <li *ngIf="appoggioclick===pagina"
                                                                    class="paginate_button pointer">
                                                                    <ng-container *ngIf="appoggioclick===pagina">
                                                                        <a (click)="cambiaPagina(pagina-2)" aria-controls="datatables" *ngIf="pagina-1"
                                                                            data-dt-idx="2" tabindex="0">{{pagina-1}}</a>
                                                                        </ng-container>
                                                                </li>
                                                                <li class="paginate_button pointer" [class]="{active:pagina === appoggioclick}" *ngIf="appoggioclick===pagina">
                                                                            <ng-container class="active" >
                                                                        <a  aria-controls="datatables"
                                                                            data-dt-idx="2" tabindex="0">{{pagina}}</a>
                                                                        </ng-container>
                                                                </li>
                                                                <li
                                                                    class="paginate_button pointer" *ngIf="appoggioclick===pagina">
                                                                            <a (click)="cambiaPagina(pagina)" aria-controls="datatables" *ngIf="pagine[i+1]"
                                                                            data-dt-idx="2" tabindex="0">{{pagina+1}}</a>
                                                                </li>
                                                                <li
                                                                    class="paginate_button pointer" *ngIf="appoggioclick===pagina && pagina < pagine.length-1">
                                                                            <a (click)="fine()" aria-controls="datatables"
                                                                            data-dt-idx="2" tabindex="0">...{{pagine.length}}</a>
                                                                </li>
                                                            </ng-container>
                                                        </ng-container>
                                                        <!-- END PHONE VIEW -->

                                                        <li class="paginate_button pointer " id="datatables_last">
                                                            <a (click)="fine()" aria-controls="datatables"
                                                                data-dt-idx="7" tabindex="0">Fine</a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!--FILE CANCELLATI-->
                                <div *ngIf="pil9">
                                    <div class="material-datatables">

                                        <div class="row">
                                            <div class="col-sm-3 col-xs-3">
                                                <div class="dataTables_length" id="datatables_length">
                                                    <label class="form-group">Limita Risultati
                                                        <select [(ngModel)]="selectedValueCancellati"
                                                            (ngModelChange)="cambiaValueCancellati()"
                                                            aria-controls="datatables" class="form-control input-sm">
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>

                                                        </select> </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-9 col-xs-9">
                                                <div id="datatables_filter" class="dataTables_filter">
                                                    <form [formGroup]="searchformCancellati">
                                                        <label class="form-group pull-right">Esegui una ricerca
                                                            <input type="text" formControlName="searchCancellati"
                                                                class="form-control" placeholder="Cerca..."
                                                                maxlength="30" style="width: 40vw;">
                                                        </label>
                                                    </form>
                                                    <div *ngIf="searchInFileCancellati()"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="scrollable">
                                            <p-confirmDialog #cd icon="pi pi-exclamation-triangle"
                                                [style]="{width:  responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
                                                <p-footer>

                                                    <button [disabled]="loading" type="button" pButton
                                                        icon="pi pi-check" label="Sì" (click)="accettaRipristina()">
                                                        <span *ngIf="loading">
                                                            <p-progressSpinner [style]="{width: '10px', height: '10px'}"
                                                                strokeWidth="8" styleClass="custom-spinner"
                                                                animationDuration=".5s"></p-progressSpinner>
                                                        </span>
                                                    </button>
                                                    <button [disabled]="loading" type="button" pButton
                                                        icon="pi pi-times" label="No" (click)="cd.reject()"></button>

                                                </p-footer>
                                            </p-confirmDialog>
                                            <div class="table-responsive">
                                                <table id="datatables"
                                                    class="table table-striped table-no-bordered table-hover table-responsive"
                                                    cellspacing="0" width="100%" style="width:100%">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 20px;">#</th>
                                                            <th>Nome File</th>
                                                            <th>Categoria</th>
                                                            <th>Gruppo</th>
                                                            <th>Data Upload</th>
                                                            <th>Estensione</th>

                                                            <th class="text-right">Azioni</th>
                                                        </tr>
                                                    </thead>
                                                    <tfoot>
                                                        <tr >
                                                            <th style="width: 20px;">#</th>
                                                            <th>Nome File</th>
                                                            <th>Categoria</th>
                                                            <th>Gruppo</th>
                                                            <th>Data Upload</th>
                                                            <th>Estensione</th>

                                                            <th class="text-right">Azioni</th>
                                                        </tr>
                                                    </tfoot>
                                                    <tbody>
                                                        <tr *ngFor="let file of fileCancellati;let i =index;" [ngClass]="{'height-53': i>=(selectedValueCancellati*(paginaCancellati-1)) && i<(selectedValueCancellati*paginaCancellati)}" >

                                                            <ng-container
                                                                *ngIf="i>=(selectedValueCancellati*(paginaCancellati-1)) && i<(selectedValueCancellati*paginaCancellati)">
                                                                <td style="font-size: xx-small;">
                                                                    <i class="material-icons notranslate"
                                                                        style="font-size: 22px; color: #aaaaaa;"
                                                                        *ngIf="(file.extension ==='docx') || (file.extension ==='txt') || (file.extension ==='doc') || (file.extension ==='pdf') || (file.extension ==='odt') || (file.extension ==='ods') || (file.extension ==='odp') || (file.extension ==='odb') || (file.extension ==='pdf') || (file.extension ==='xls') || (file.extension ==='xlsx')">library_books</i>
                                                                    <i class="material-icons notranslate"
                                                                        style="font-size: 22px; color: #aaaaaa;"
                                                                        *ngIf="(file.extension === 'mp3') || ( file.extension === 'ogg') || ( file.extension === 'wav')">music_video</i>
                                                                    <i class="material-icons notranslate"
                                                                        style="font-size: 22px; color: #aaaaaa;"
                                                                        *ngIf="(file.extension === 'png') || (file.extension === 'jpg') || (file.extension === 'bmp') || (file.extension === 'gif') || (file.extension === 'psd')">insert_photo</i>
                                                                    <i class="material-icons notranslate"
                                                                        style="font-size: 22px; color: #aaaaaa;"
                                                                        *ngIf="(file.extension === 'avi') || (file.extension === 'mpeg') || (file.extension ===  'wmv') || (file.extension === 'mp4') || (file.extension ===  '3gp') || (file.extension ===  'flv') || (file.extension ===  'mkv')">videocam</i>
                                                                    <i class="material-icons notranslate"
                                                                        style="font-size: 22px; color: #aaaaaa;"
                                                                        *ngIf="(file.extension === 'exe') || (file.extension === 'com') || (file.extension === 'bat') || (file.extension === 'cmd')">computer</i>
                                                                    <i class="material-icons notranslate"
                                                                        style="font-size: 22px; color: #aaaaaa;"
                                                                        *ngIf="(file.extension ===  'htm') || (file.extension ===  'html') || (file.extension ===  'shtml') || (file.extension ===  'shtm') || (file.extension ===  'stm') || (file.extension ===  'asp') || (file.extension ===  'aspx') || (file.extension === 'php') || (file.extension === 'dtw') || (file.extension === 'xml') || (file.extension === 'xps')">code</i>
                                                                    <i class="material-icons notranslate"
                                                                        style="font-size: 22px; color: #aaaaaa;"
                                                                        *ngIf="(file.extension === 'zip') || (file.extension === 'rar')">folder</i>
                                                                    <i class="material-icons notranslate"
                                                                        style="font-size: 22px; color: #aaaaaa;"
                                                                        *ngIf="(file.extension !== 'zip') && (file.extension !== 'rar') && (file.extension !=='exe') && (file.extension !=='com') && (file.extension !=='bat') && (file.extension !=='cmd') && (file.extension !=='png') && (file.extension !=='jpg') && (file.extension !=='bmp') && (file.extension !=='gif') && (file.extension !=='psd') && (file.extension !=='mp3') && (file.extension !=='ogg') && (file.extension !=='wav') && (file.extension !=='htm') && (file.extension !=='html') && (file.extension !=='shtml') && (file.extension !=='shtm') && (file.extension !=='stm') && (file.extension !=='asp') && (file.extension !=='aspx') && (file.extension !=='php') && (file.extension !=='dtw') && (file.extension !=='xml') && (file.extension !=='xps') && (file.extension !=='pdf') && (file.extension !=='txt') && (file.extension !=='doc') && (file.extension !=='docx') && (file.extension !=='odt') && (file.extension !=='ods') && (file.extension !=='odp') && (file.extension !=='odb') && (file.extension !=='pdf') && (file.extension !=='xls') && (file.extension !=='xlsx') && (file.extension !=='mp4') && (file.extension !=='mkv')">insert_drive_file</i>

                                                                </td>
                                                                <td style="font-size: medium;">{{file.title}}</td>
                                                                <td style="font-size: medium;">{{file.category}}</td>
                                                                <td style="font-size: medium;">{{file.group}}</td>
                                                                <td style="font-size: medium;">{{file.uploadDate | date:
                                                                    'dd/MM/yyyy HH:mm'}}</td>
                                                                <td style="font-size: medium;">{{file.extension}}</td>



                                                                <td class="text-right">

                                                                    <a (click)="ripristina(file.id,i)" rel="tooltip"
                                                                        title="Ripristina file"><i
                                                                            class="material-icons notranslate pointer"
                                                                            style="color: red;">restore_from_trash</i></a>

                                                                </td>
                                                            </ng-container>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-5">
                                                <div class="dataTables_info" id="datatables_info" role="status"
                                                    aria-live="polite" style="margin-top: 5%;">{{selectedValueCancellati
                                                    < numeroFileCancellati? selectedValueCancellati:
                                                        numeroFileCancellati}} di {{numeroFileCancellati}} file</div>
                                                </div>
                                                <div class="col-sm-7 text-right" [ngClass]="{'pd': !isDesktop}">
                                                    <div class="dataTables_paginate paging_full_numbers"
                                                        id="datatables_paginate">
                                                        <ul class="pagination">
                                                            <li class="paginate_button pointer " id="datatables_first">
                                                                <a (click)="paginaUnoCancellati()" [ngClass]="{'pd': !isDesktop}"
                                                                    aria-controls="datatables" data-dt-idx="0"
                                                                    tabindex="0">Inizio</a>
                                                            </li>
                                                            <li class="paginate_button pointer "
                                                                id="datatables_previous">
                                                                <a (click)="backCancellati()" aria-controls="datatables"
                                                                    data-dt-idx="1" tabindex="0">Indietro</a>
                                                            </li>
                                                            <ng-container *ngIf="isDesktop">
                                                                <li *ngFor="let pagina of pagineCancellati; let i=index"
                                                                    class="paginate_button pointer"
                                                                    [class]="{active:pagina === appoggioclickCancellati}">
                                                                    <a (click)="cambiaPaginaCancellati(i)"
                                                                        aria-controls="datatables" data-dt-idx="2"
                                                                        tabindex="0">{{pagina}}</a>

                                                                </li>
                                                            </ng-container>

                                                        <!-- PHONE VIEW -->
                                                        <ng-container *ngIf="!isDesktop">
                                                            <ng-container  *ngFor="let pagina of pagineCancellati; let i=index">
                                                                <li
                                                                    class="paginate_button pointer " *ngIf="appoggioclick===pagina && pagina > 2">
                                                                            <a (click)="paginaUno()" aria-controls="datatables" class="pd pdr"
                                                                            data-dt-idx="2" tabindex="0">1 ...</a>
                                                                </li>
                                                                <li *ngIf="appoggioclick===pagina"
                                                                    class="paginate_button pointer">
                                                                    <ng-container *ngIf="appoggioclick===pagina">
                                                                        <a (click)="cambiaPagina(pagina-2)" aria-controls="datatables" *ngIf="pagina-1"
                                                                            data-dt-idx="2" tabindex="0">{{pagina-1}}</a>
                                                                        </ng-container>
                                                                </li>
                                                                <li class="paginate_button pointer" [class]="{active:pagina === appoggioclick}" *ngIf="appoggioclick===pagina">
                                                                            <ng-container class="active" >
                                                                        <a  aria-controls="datatables"
                                                                            data-dt-idx="2" tabindex="0">{{pagina}}</a>
                                                                        </ng-container>
                                                                </li>
                                                                <li
                                                                    class="paginate_button pointer" *ngIf="appoggioclick===pagina">
                                                                            <a (click)="cambiaPagina(pagina)" aria-controls="datatables" *ngIf="pagineCancellati[i+1]"
                                                                            data-dt-idx="2" tabindex="0">{{pagina+1}}</a>
                                                                </li>
                                                                <li
                                                                    class="paginate_button pointer" *ngIf="appoggioclick===pagina && pagina < pagineCancellati.length-1">
                                                                            <a (click)="fine()" aria-controls="datatables"
                                                                            data-dt-idx="2" tabindex="0">...{{pagineCancellati.length}}</a>
                                                                </li>
                                                            </ng-container>
                                                        </ng-container>
                                                        <!-- END PHONE VIEW -->

                                                            <li class="paginate_button pointer" id="datatables_next">
                                                                <a (click)="nextCancellati()" aria-controls="datatables"
                                                                    data-dt-idx="6" tabindex="0">Avanti</a>
                                                            </li>
                                                            <li class="paginate_button pointer" id="datatables_last">
                                                                <a (click)="fineCancellati()" aria-controls="datatables"
                                                                    data-dt-idx="7" tabindex="0">Fine</a>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>



                            </div>

                        </div>

                    </div>

                </div>
            </div>

            <div *ngIf="modifica">
                <app-add-edit-esercitazione [fileId]="idFile" (tornaGestione)="indietro()">
                </app-add-edit-esercitazione>
            </div>
