import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import { Map } from 'leaflet';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from 'src/app/SERVICES/index';
import { AccountService } from 'src/app/SERVICES/index';
import { OperatorService } from 'src/app/SERVICES/ASSISTENZA/operator.service';
import { Utente } from 'src/app/MODELS/USER/utente';
import { Router } from '@angular/router';
import { runInThisContext } from 'vm';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-geo',
  templateUrl: './geolocalizzazione.component.html',
  styleUrls: ['./geolocalizzazione.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('80ms ease-out')),
      transition('default => rotated', animate('80ms ease-in')),
    ]),
  ],
})
export class GeolocalizzazioneComponent implements AfterViewInit, OnDestroy {
  map: Map;
  public fullscreenOptions: { [key: string]: any } = {
    position: 'topleft',
    title: 'View Fullscreen',
    titleCancel: 'Exit Fullscreen',
  };
  users = [];
  allUser;
  usersOffline = [];
  allUserOffline;
  cords = [];
  allcords;
  intervallo: any;
  open = true;
  markers = [];
  firstTime = false;
  fullScreenMap: boolean = false;
  i = 0;
  whiteListUsers = [];
  subscription: Subscription;
  colorArray = [
    '#FF0000',
    '#FF8B00',
    '#0800FF',
    '#70FF00',
    '#00FFC9',
    '#0080FF',
    '#F0D218',
    '#9E00FF',
    '#FF00B9',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
  ];
  usersStatic = [];
  searchform: FormGroup;
  markerHtmlStyles = `
  color: ${this.colorArray[this.i]};
  font-size: xx-large;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  -webkit-text-stroke: 1px black;
  `;
  smallIcon = L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 24],
    popupAnchor: [0, -36],
    html: `<i [class]="material-icons notranslate" style="${this.markerHtmlStyles}">place</i>`,
  });
  spinner = true;
  spinnerOffline = true;
  stateOnline: string[] = [];
  stateOffline: string[] = [];
  listaDevices = [];
  listaDispositiviUser = [];
  isSearchingOn = false;
  spinnerDispositivi = false;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private operatorService: OperatorService,
    private accountService: AccountService,
    private alertService: AlertService,
    private router: Router,
    private service: ShareService
  ) {
    this.service.listaGeolocalizzazione$.subscribe((res) => {
      this.whiteListUsers = res;
    });
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required],
    });

    this.intervallo = setInterval(() => {
      if (this.open) {
        this.subscription = this.userService
          .getCordinateInCr()
          .subscribe((res) => {
            this.i = 0;
            this.markers.forEach((marker) => {
              this.map.removeLayer(marker);
            });
            this.cords = res.data.utentiOnline;
            this.usersOffline = res.data.utentiOffline;
            this.allUserOffline = this.usersOffline;
            if (this.whiteListUsers) {
              this.cords.forEach((element) => {
                if (this.whiteListUsers.includes(element.email)) {
                  element.attivoOnMap = 0;
                }
              });
            }
            this.allcords = this.cords;
            this.setLista();
            this.markers = [];
            this.usersStatic.forEach((user) => {
              //L.marker(user.latitudine, user.longitudine).removeFrom(this.map)
              this.addMarker(user.latitudine, user.longitudine);
            });
          });
      }
    }, 6000);
  }
  ngOnDestroy(): void {
    clearInterval(this.intervallo);
    this.open = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  timeOut;
  hideUser() {
    this.isSearchingOn = true;
    if (this.timeOut) clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.isSearchingOn = false;
    }, 1000);
  }

  searchInUser() {
    if (this.usersStatic !== null) {
      let valore: string = this.searchform.value.search;
      this.usersStatic = this.allUser;
      if (valore) {
        let users = [];
        this.usersStatic.forEach((user) => {
          let nomeCognome = user.nome + ' ' + user.cognome;
          let cognomeNome = user.cognome + ' ' + user.nome;
          user.gruppos.forEach((gruppo) => {
            if (
              gruppo.nomeGruppo.includes(valore) ||
              gruppo.nomeGruppo.toLowerCase().includes(valore.toLowerCase()) ||
              gruppo.nomeGruppo.toUpperCase().includes(valore)
            ) {
              if (!users.find((x) => x === user)) {
                users.push(user);
              }
            }
          });

          if (
            cognomeNome
              .toLocaleLowerCase()
              .includes(valore.toLocaleLowerCase()) ||
            nomeCognome
              .toLocaleLowerCase()
              .includes(valore.toLocaleLowerCase()) ||
            user.nome.toLowerCase().includes(valore.toLowerCase()) ||
            user.cognome.toLowerCase().includes(valore.toLowerCase())
          ) {
            if (!users.find((x) => x === user)) {
              users.push(user);
            }
          }
        });
        this.usersStatic = users;
      } else if (this.users !== this.allUser) {
        this.usersStatic = this.allUser;
      }
    }

    if (this.usersOffline !== null) {
      let valore2: string = this.searchform.value.search;
      this.usersOffline = this.allUserOffline;
      if (valore2) {
        let users2 = [];
        if (this.usersOffline)
          this.usersOffline.forEach((user) => {
            let nomeCognome2 = user.nome + ' ' + user.cognome;
            let cognomeNome2 = user.cognome + ' ' + user.nome;
            user.gruppos.forEach((gruppo) => {
              if (
                gruppo.nomeGruppo.includes(valore2) ||
                gruppo.nomeGruppo
                  .toLowerCase()
                  .includes(valore2.toLowerCase()) ||
                gruppo.nomeGruppo.toUpperCase().includes(valore2)
              ) {
                if (!users2.find((x) => x === user)) {
                  users2.push(user);
                }
              }
            });

            if (
              cognomeNome2
                .toLocaleLowerCase()
                .includes(valore2.toLocaleLowerCase()) ||
              nomeCognome2
                .toLocaleLowerCase()
                .includes(valore2.toLocaleLowerCase()) ||
              user.nome.toLowerCase().includes(valore2.toLowerCase()) ||
              user.cognome.toLowerCase().includes(valore2.toLowerCase())
            ) {
              if (!users2.find((x) => x === user)) {
                users2.push(user);
              }
            }
          });
        this.usersOffline = users2;
      } else if (this.usersOffline !== this.allUserOffline) {
        this.usersOffline = this.allUserOffline;
      }
    }
  }

  ngAfterViewInit(): void {
    this.getLista();
  }

  getLista() {
    this.userService.getCordinateInCr().subscribe(
      (res) => {
        this.cords = res.data.utentiOnline;
        this.usersOffline = res.data.utentiOffline;
        this.allUserOffline = this.usersOffline;
        this.allcords = this.cords;
        this.setLista();
        this.creaMappa();
        this.spinnerOffline = false;
      },
      (error) => {}
    );
  }

  setLista() {
    this.usersStatic = [];
    this.cords.forEach((user) => {
      let utente: Utente = new Utente();
      utente.id = user.id;
      utente.nome = user.nome;
      utente.cognome = user.cognome;
      utente.gruppos = user.gruppos;
      utente.id = user.id;
      utente.latitudine = user.localizzazione.latitudine;
      utente.longitudine = user.localizzazione.longitudine;
      utente.capcomune = user.localizzazione.capComune;
      utente.userNonInCr = user.utenteCR;
      let tmp = utente.capcomune;
      utente.capcomune = utente.capcomune.split(',', 2);
      if (utente.capcomune.length > 1) {
        utente.capcomune[1] = tmp;
      }
      utente.attivo = user.attivoOnMap;
      if (utente.attivo === 1) {
        this.usersStatic.push(utente);
      } else if (utente.attivo === 0) {
        this.usersStatic.unshift(utente);
      }
    });
    this.allUser = this.usersStatic;
    this.spinner = false;
  }
  creaMappa() {
    let cordinate = {
      lat: 40.86,
      lng: 14.28,
    };
    navigator.geolocation.getCurrentPosition(
      (res) => {
        cordinate.lat = res.coords.latitude;
        cordinate.lng = res.coords.longitude;
        let a = 14;
        let zoomLevel = a;
        this.map = L.map('map', {
          center: [cordinate.lat, cordinate.lng],
          zoom: zoomLevel,
          dragging: true,
        });
        let mainLayer = L.tileLayer(
          'https://{s}.tile.openStreetMap.org/{z}/{x}/{y}.png',
          {
            minZoom: 10,
            maxZoom: 19,
            attribution:
              '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          }
        );
        mainLayer.addTo(this.map);
        this.usersStatic.forEach((user) => {
          this.addMarker(user.latitudine, user.longitudine);
        });
        this.firstTime = true;
      },
      (error) => {
        this.alertService.error(
          'Per visualizzare la mappa, è necessario fornire i permessi di geolocalizzazione'
        );
      }
    );
  }

  addMarker(cordinate1, cordinate2) {
    let markerHtmlStyles = `
    color: ${this.colorArray[this.i]};
    font-size: xx-large;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    -webkit-text-stroke: 1px black;
    `;
    let smallIcon = L.divIcon({
      className: 'my-custom-pin',
      iconAnchor: [0, 24],
      popupAnchor: [0, -36],
      html: `<i class="material-icons notranslate" style="${markerHtmlStyles}">place</i>`,
    });
    let marker = L.marker([cordinate1, cordinate2], { icon: smallIcon }).addTo(
      this.map
    );
    this.markers.push(marker);
    this.i = this.i + 1;
  }

  getRandomColor() {
    var color = this.colorArray[this.i];

    return color;
  }

  zoom(lat, lng) {
    var latlng = L.latLng(lat, lng);
    this.map.setView(latlng, 19);
  }

  onMapReady(map: Map) {
    this.map = map;
  }

  call(id, dispositivo?) {
    if (dispositivo) {
      this.operatorService
        .callUserDevice(id, this.accountService.userValue.data.id, dispositivo)
        .subscribe((res) => {
          this.alertService.success('Chiamata inoltrata');
        });
      return;
    }
    this.operatorService
      .callUser(id, this.accountService.userValue.data.id)
      .subscribe((res) => {
        this.alertService.success('Chiamata inoltrata');
      });
  }

  rotate(listaState, j, state, id) {
    this.stateOnline = [];
    this.stateOffline = [];
    this.usersOffline.forEach((element, i) => {
      this.stateOffline.push('default');
    });
    this.usersStatic.forEach((element, i) => {
      this.stateOnline.push('default');
    });
    this[listaState][j] = state === 'default' ? 'rotated' : 'default';
    if (this[listaState][j] === 'rotated') {
      this.spinnerDispositivi = true;
      this.getListaDispositivi(id);
    }
  }

  getListaDispositivi(id) {
    this.listaDispositiviUser = [];
    this.userService.lista_dispositivi(id).subscribe((res) => {
      res.data.forEach((device) => {
        this.listaDispositiviUser.push(device);
      });
    });
    this.spinnerDispositivi = false;
  }
}
