import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { KonvaService } from '../konva.service';
import Konva from 'konva';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { Stage } from 'konva/lib/Stage';
import { Layer } from 'konva/lib/Layer';
import { Transformer } from 'konva/lib/shapes/Transformer';
import { LavagnaService } from 'src/app/SERVICES/CLASSROOM/lavagna.service';
import { WhiteBoardService } from 'src/app/SERVICES/CLASSROOM/WhiteBoard.service';
import { Subscription } from 'rxjs';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
// import * as html2canvas from "html2canvas";
declare let html2canvas: any;

@Component({
  selector: 'app-whiteboard-page',
  templateUrl: './whiteboard-page.component.html',
  styleUrls: ['./whiteboard-page.component.scss'],
})
export class WhiteboardPageComponent implements OnInit, OnDestroy {
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @Input() openedByOtherUser;
  @Input() ruoloUser;
  idAula;

  tr = new Konva.Transformer();

  shapes: any = [];
  stage!: Stage;
  changedBrush = false;
  layer!: Layer;
  interval;
  aulaInfo;
  image;
  showImage = false;
  scale: any;
  inkColor: string = '#000000';
  selectedButton: any = {
    line: false,
    eraser: false,
  };
  eraser: boolean = false;
  transformers: Transformer[] = [];
  brushSize: number = 3;
  brushOpacity: number = 1.0;
  subscriber: Subscription[] = [];
  constructor(
    private _bottomSheet: MatBottomSheet,
    private konvaService: KonvaService,
    private lavagnaService: LavagnaService,
    private whiteBoardService: WhiteBoardService,
    private classroomService: ClassroomService
  ) {
    this.creaPalettaImmagini();
  }
  ngOnDestroy(): void {
    localStorage.removeItem('enableWhiteboard');
    clearInterval(this.interval);
    if (!this.openedByOtherUser) {
      this.whiteBoardService
        .saveLavagna(this.stage.toJSON(), this.idAula)
        .subscribe((res) => {
          this.whiteBoardService
            .deleteLavagna(this.idAula)
            .subscribe((res) => {});
        });
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.idAula = localStorage.getItem('classroom');

      this.classroomService.lista_classroom_leggera().subscribe((res) => {
        let aule = res.data as [any];
        for (let i = 0; i < aule.length; i++) {
          let idAula = aule[i].id as number;
          if (this.idAula == idAula) {
            this.aulaInfo = aule[i];
            break;
          }
        }
      });
      let thisClass = this;
      this.image = localStorage.getItem('backgroundWhiteboard') as string;
      this.stage = new Stage({
        container: 'lavagna',
        width: window.innerWidth,
        height: window.innerHeight,
      });
      let sub = this.whiteBoardService
        .getStatus(this.idAula)
        .subscribe((res) => {
          this.whiteBoardService
            .getLavagna(this.idAula)
            .subscribe((whiteBoard) => {
              if (whiteBoard.data && whiteBoard.data.datiLavagna) {
                this.stage = Konva.Node.create(
                  JSON.parse(whiteBoard.data.datiLavagna),
                  'lavagna'
                );
              }
              this.layer = new Layer();
              this.stage.add(this.layer);
              this.stage.find('Image').forEach((imageNode: any) => {
                const src = imageNode.getAttr('src');
                const image = new Image();
                image.onload = () => {
                  imageNode.image(image);
                  imageNode.getLayer().batchDraw();
                };
                image.src = src;
              });
              if (!(this.ruoloUser === 1 || this.ruoloUser === 4)) {
                this.interval = setInterval(() => {
                  this.whiteBoardService
                    .getLavagna(this.idAula)
                    .subscribe((whiteBoard) => {
                      this.stage = Konva.Node.create(
                        JSON.parse(whiteBoard.data.datiLavagna),
                        'lavagna'
                      );
                      this.layer = new Layer();
                      this.stage.add(this.layer);
                      this.stage.find('Image').forEach((imageNode: any) => {
                        const src = imageNode.getAttr('src');
                        const image = new Image();
                        image.onload = () => {
                          imageNode.image(image);
                          imageNode.getLayer().batchDraw();
                        };
                        image.src = src;
                      });

                      // let imageObj = new Image();
                      // imageObj.src = this.image;
                      // imageObj.onload = function () {
                      //   let map = new Konva.Image({
                      //     x: 0,
                      //     y: 0,
                      //     image: imageObj,
                      //     width: thisClass.stage.container().clientWidth,
                      //     height: thisClass.stage.container().clientHeight,
                      //   });
                      //   // add the image to the layer
                      //   thisClass.layer.add(map);
                      //   // add the layer to the stage
                      //   thisClass.stage.add(thisClass.layer);
                      // };

                      // this.image = localStorage.getItem('backgroundWhiteboard') as string;
                      // let imageObj = new Image();
                      // imageObj.src = this.image;
                      // imageObj.onload = function () {
                      //   let map = new Konva.Image({
                      //     x: 0,
                      //     y: 0,
                      //     image: imageObj,
                      //     width: thisClass.stage.container().clientWidth,
                      //     height: thisClass.stage.container().clientHeight,
                      //   });
                      //   // add the image to the layer
                      //   thisClass.layer.add(map);
                      //   // add the layer to the stage
                      //   thisClass.stage.add(thisClass.layer);
                      // };
                    });
                }, 5000);

                // });
              } else {
                if (this.openedByOtherUser){
                this.interval = setInterval(() => {
                  this.whiteBoardService
                    .getLavagna(this.idAula)
                    .subscribe((whiteBoard) => {
                      this.stage = Konva.Node.create(
                        JSON.parse(whiteBoard.data.datiLavagna),
                        'lavagna'
                      );
                      this.layer = new Layer();
                      this.stage.add(this.layer);
                      this.stage.find('Image').forEach((imageNode: any) => {
                        const src = imageNode.getAttr('src');
                        const image = new Image();
                        image.onload = () => {
                          imageNode.image(image);
                          imageNode.getLayer().batchDraw();
                        };
                        image.src = src;
                      });
                    });
                }, 5000);}
                this.layer = new Layer();
                this.stage.add(this.layer);

                this.image = localStorage.getItem(
                  'backgroundWhiteboard'
                ) as string;
                // let imageObj = new Image();
                // imageObj.src = this.image;
                // imageObj.onload = function () {
                //   let map = new Konva.Image({
                //     x: 0,
                //     y: 0,
                //     image: imageObj,
                //     width: thisClass.stage.container().clientWidth,
                //     height: thisClass.stage.container().clientHeight,
                //   });
                //   thisClass.layer.add(map);
                //   thisClass.stage.add(thisClass.layer);
                thisClass.interval = setInterval(() => {
                  if (!this.openedByOtherUser) {
                    thisClass.whiteBoardService
                      .saveLavagna(thisClass.stage.toJSON(), thisClass.idAula)
                      .subscribe((res) => {});
                  }
                }, 5000);
                thisClass.setSelection('brush');
                thisClass.addLineListeners();
                // };
              }

              // this.image = localStorage.getItem('backgroundWhiteboard') as string;
              // let imageObj = new Image();
              // imageObj.src = this.image;
              // imageObj.onload = function () {
              //   let map = new Konva.Image({
              //     x: 0,
              //     y: 0,
              //     image: imageObj,
              //     width: thisClass.stage.container().clientWidth,
              //     height: thisClass.stage.container().clientHeight,
              //   });
              //   // add the image to the layer
              //   thisClass.layer.add(map);
              //   // add the layer to the stage
              //   thisClass.stage.add(thisClass.layer);
              // };
            });

          // this.whiteBoardService.getImage(this.idAula).subscribe((image) => {
          // this.stage = Konva.Node.create(this.json, 'lavagna');
        });
      this.subscriber.push(sub);
      // this.layer = this.stage.children[0];

      //per le immagini
      //Occorre caricare le immagine in gruppi se abbiamo più di una immagine
      //questo è caso singolo.
      //si recupera il numero di oggetti

      // for (let pos = 0; pos < thisClass.immagini.length; pos++) {
      //   let idImmagine = thisClass.immagini[pos].id;
      //   let imageObj = new Image();
      //   imageObj.onload = function () {
      //     thisClass.stage.getLayer();
      //     //il findOne serve # per la ricerca tramite id, . per la ricerca con name
      //     // la differenza tra findOne e find e che l'ultimo fornisce un array,
      //     //vuoto se non trova mentre findOne undefined
      //     //Conviene usare il find per cercare tutto e poi caricare le forme
      //     let forme = thisClass.stage.find('#' + idImmagine) as Konva.Image[];
      //     for (let i = 0; i < forme.length; i++) {
      //       //verifico se l'immagine è già caricata
      //       let img = forme[i].image();
      //       if (img === undefined || img === null) {
      //         //se non lo è la carico
      //         forme[i].image(imageObj);
      //       }
      //     }
      //   };
      //   //fase in cui carico l'immagine
      //   imageObj.src = this.immagini[pos].src;
      // }

      // if (this.stage.children.length > 0) {
      //   let figureStudente: Shape[] =
      //     (this.stage.children[0].children as Shape[]) || [];
      //   for (let i = 2; i < figureStudente.length; i++) {
      //   }
      // }
      //#endregion

      //     this.stage.find('Image').forEach((imageNode: any) => {
      //     const src = imageNode.getAttr('src');
      //     const image = new Image();
      //     image.onload = () => {
      //         imageNode.image(image);
      //         imageNode.getLayer().batchDraw();
      //     }
      //     image.src = src;
      // });
    }, 100);
  }
  showImageKonva() {
    this.showImage = !this.showImage;
    setTimeout(() => {
      this.initImageKonva();
    }, 100);
  }

  clearSelection(): void {
    this.selectedButton = {
      brush: false,
      eraser: false,
    };
  }
  initImageKonva() {
    // what is url of dragging element?
    let itemURL = '';
    let thisClass = this;
    document
      .getElementById('drag-items')
      .addEventListener('dragstart', function (e: any) {
        itemURL = e.target.src;
      });

    let con = this.stage.container();
    con.addEventListener('dragover', function (e) {
      e.preventDefault(); // !important
    });

    con.addEventListener('drop', function (e) {
      e.preventDefault();
      // now we need to find pointer position
      // we can't use stage.getPointerPosition() here, because that event
      // is not registered by Konva.Stage
      // we can register it manually:
      thisClass.stage.setPointersPositions(e);
      Konva.Image.fromURL(itemURL, function (image) {
        thisClass.shapes.push(image);
        thisClass.layer.add(image);

        image.position(thisClass.stage.getPointerPosition());
        image.draggable(true);
        // let tr = new Konva.Transformer();
        thisClass.layer.add(thisClass.tr);

        // by default select all shapes
        thisClass.tr.nodes([image]);

        // add a new feature, lets add ability to draw selection rectangle
        let selectionRectangle = new Konva.Rect({
          fill: 'rgba(0,0,255,0.5)',
          visible: false,
        });
        thisClass.layer.add(selectionRectangle);
        image.on('dragstart', function () {
          this.moveToTop();
        });
        image.setAttr('src', itemURL);
        image.on('dragmove', function () {
          document.body.style.cursor = 'pointer';
        });

        image.on('mouseover', function () {
          document.body.style.cursor = 'pointer';
        });
        image.on('mouseout', function () {
          document.body.style.cursor = 'default';
        });
        let x1: any, y1: any, x2: any, y2: any;
        thisClass.stage.on('mousedown touchstart', (e) => {
          // do nothing if we mousedown on any shape
          if (e.target !== thisClass.stage) {
            return;
          }
          e.evt.preventDefault();
          // @ts-ignore: Object is possibly 'null'.
          x1 = thisClass.stage.getPointerPosition().x;
          // @ts-ignore: Object is possibly 'null'.
          y1 = thisClass.stage.getPointerPosition().y;
          // @ts-ignore: Object is possibly 'null'.
          x2 = thisClass.stage.getPointerPosition().x;
          // @ts-ignore: Object is possibly 'null'.
          y2 = thisClass.stage.getPointerPosition().y;

          selectionRectangle.visible(false);
          selectionRectangle.width(0);
          selectionRectangle.height(0);
        });

        thisClass.stage.on('mousemove touchmove', (e) => {
          // do nothing if we didn't start selection
          if (!selectionRectangle.visible()) {
            return;
          }
          e.evt.preventDefault();
          // @ts-ignore: Object is possibly 'null'.
          x2 = thisClass.stage.getPointerPosition().x;
          // @ts-ignore: Object is possibly 'null'.
          y2 = thisClass.stage.getPointerPosition().y;

          selectionRectangle.setAttrs({
            x: Math.min(x1, x2),
            y: Math.min(y1, y2),
            width: Math.abs(x2 - x1),
            height: Math.abs(y2 - y1),
          });
        });

        thisClass.stage.on('mouseup touchend', (e) => {
          // do nothing if we didn't start selection
          if (!selectionRectangle.visible()) {
            return;
          }
          e.evt.preventDefault();
          // update visibility in timeout, so we can check it in click event
          setTimeout(() => {
            selectionRectangle.visible(false);
          });

          let shapes = thisClass.stage.find('.rect');
          let box = selectionRectangle.getClientRect();
          let selected = shapes.filter((shape) =>
            Konva.Util.haveIntersection(box, shape.getClientRect())
          );
          thisClass.tr.nodes(selected);
        });

        // clicks should select/deselect shapes
        thisClass.stage.on('click tap', function (e) {
          // if we are selecting with rect, do nothing
          if (selectionRectangle.visible()) {
            return;
          }

          // if click on empty area - remove all selections
          if (e.target === thisClass.stage) {
            thisClass.tr.nodes([]);
            return;
          }

          // do nothing if clicked NOT on our rectangles
          if (!e.target.hasName('rect')) {
            return;
          }

          // do we pressed shift or ctrl?
          const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
          const isSelected = thisClass.tr.nodes().indexOf(e.target) >= 0;

          if (!metaPressed && !isSelected) {
            // if no key pressed and the node is not selected
            // select just one
            thisClass.tr.nodes([e.target]);
          } else if (metaPressed && isSelected) {
            // if we pressed keys and node was selected
            // we need to remove it from selection:
            const nodes = thisClass.tr.nodes().slice(); // use slice to have new copy of array
            // remove node from array
            nodes.splice(nodes.indexOf(e.target), 1);
            thisClass.tr.nodes(nodes);
          } else if (metaPressed && !isSelected) {
            // add the node into selection
            const nodes = thisClass.tr.nodes().concat([e.target]);
            thisClass.tr.nodes(nodes);
          }
        });
      });
    });
  }
  openBottomSheet(): void {
    const bottomSheetRef = this._bottomSheet.open(BottomSheet);
    this.changedBrush = true;
    bottomSheetRef.afterDismissed().subscribe((result: any) => {
      if (result) {
        this.brushSize = result.brushSize;
        this.brushOpacity = result.brushOpacity;
      }
      setTimeout(() => {
        this.changedBrush = false;
      }, 100);
    });
  }

  setSelection(type: string) {
    this.clearSelection();
    this.selectedButton[type] = true;

    this.tr.nodes([]);
    this.tr = new Konva.Transformer();
    if (!(type === 'brush')) this.selectedButton['brush'] = false;
    switch (type) {
      case 'text':
        this.clearSelection();
        this.eraser = false;
        this.addText();
        break;
      case 'square':
        this.addSquare();
        break;
      case 'circle':
        this.addCircle();
        break;
      case 'arrow':
        this.addArrow();
        break;
      case 'eraser':
        this.tr.nodes([]);
        this.eraser = true;
        break;
      case 'image':
        this.selectedButton['brush'] = false;
        this.showImageKonva();
        break;
      case 'brush':
        this.eraser = false;
        this.selectedButton['brush'] = true;
        break;
      default:
        this.eraser = false;
        break;
    }
  }
  addSquare() {
    let thisClass = this;
    let rect1 = new Konva.Rect({
      x: 20,
      y: 20,
      width: 100,
      height: 100,
      draggable: true,
      stroke: 'black',
      strokeWidth: 1,
    });
    this.tr.nodes([]);
    // add the shape to the layer
    this.layer.add(this.tr);
    this.shapes.push(rect1);
    // by default select all shapes
    this.tr.nodes([rect1]);

    // add a new feature, lets add ability to draw selection rectangle
    let selectionRectangle = new Konva.Rect({
      fill: 'rgba(0,0,255,0.5)',
      visible: false,
    });
    this.layer.add(selectionRectangle);

    this.layer.add(rect1);
    rect1.on('dragstart', function () {
      this.moveToTop();
    });

    rect1.on('dragmove', function () {
      document.body.style.cursor = 'pointer';
    });

    rect1.on('mouseover', function () {
      document.body.style.cursor = 'pointer';
    });
    rect1.on('mouseout', function () {
      document.body.style.cursor = 'default';
    });
    let x1: any, y1: any, x2: any, y2: any;
    this.stage.on('mousedown touchstart', (e) => {
      // do nothing if we mousedown on any shape
      if (e.target !== this.stage) {
        return;
      }
      e.evt.preventDefault();
      // @ts-ignore: Object is possibly 'null'.
      x1 = this.stage.getPointerPosition().x;
      // @ts-ignore: Object is possibly 'null'.
      y1 = this.stage.getPointerPosition().y;
      // @ts-ignore: Object is possibly 'null'.
      x2 = this.stage.getPointerPosition().x;
      // @ts-ignore: Object is possibly 'null'.
      y2 = this.stage.getPointerPosition().y;

      selectionRectangle.visible(false);
      selectionRectangle.width(0);
      selectionRectangle.height(0);
    });

    this.stage.on('mousemove touchmove', (e) => {
      // do nothing if we didn't start selection
      if (!selectionRectangle.visible()) {
        return;
      }
      e.evt.preventDefault();
      // @ts-ignore: Object is possibly 'null'.
      x2 = this.stage.getPointerPosition().x;
      // @ts-ignore: Object is possibly 'null'.
      y2 = this.stage.getPointerPosition().y;

      selectionRectangle.setAttrs({
        x: Math.min(x1, x2),
        y: Math.min(y1, y2),
        width: Math.abs(x2 - x1),
        height: Math.abs(y2 - y1),
      });
    });

    this.stage.on('mouseup touchend', (e) => {
      // do nothing if we didn't start selection
      if (!selectionRectangle.visible()) {
        return;
      }
      e.evt.preventDefault();
      // update visibility in timeout, so we can check it in click event
      setTimeout(() => {
        selectionRectangle.visible(false);
      });

      let shapes = this.stage.find('.rect');
      let box = selectionRectangle.getClientRect();
      let selected = shapes.filter((shape) =>
        Konva.Util.haveIntersection(box, shape.getClientRect())
      );
      this.tr.nodes(selected);
    });

    // clicks should select/deselect shapes
    this.stage.on('click tap', function (e) {
      // if we are selecting with rect, do nothing
      if (selectionRectangle.visible()) {
        return;
      }

      // if click on empty area - remove all selections
      if (e.target === thisClass.stage) {
        thisClass.tr.nodes([]);
        return;
      }

      // do nothing if clicked NOT on our rectangles
      if (!e.target.hasName('rect')) {
        return;
      }

      // do we pressed shift or ctrl?
      const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
      const isSelected = thisClass.tr.nodes().indexOf(e.target) >= 0;

      if (!metaPressed && !isSelected) {
        // if no key pressed and the node is not selected
        // select just one
        thisClass.tr.nodes([e.target]);
      } else if (metaPressed && isSelected) {
        // if we pressed keys and node was selected
        // we need to remove it from selection:
        const nodes = thisClass.tr.nodes().slice(); // use slice to have new copy of array
        // remove node from array
        nodes.splice(nodes.indexOf(e.target), 1);
        thisClass.tr.nodes(nodes);
      } else if (metaPressed && !isSelected) {
        // add the node into selection
        const nodes = thisClass.tr.nodes().concat([e.target]);
        thisClass.tr.nodes(nodes);
      }
    });
    // this.stage.add(this.layer);
  }
  addCircle() {
    let thisClass = this;
    let rect1 = new Konva.Circle({
      x: 20,
      y: 20,
      width: 100,
      height: 100,
      draggable: true,
      stroke: 'black',
      strokeWidth: 1,
    });
    this.tr.nodes([]);
    this.shapes.push(rect1);
    // add the shape to the layer
    this.layer.add(this.tr);

    // by default select all shapes
    this.tr.nodes([rect1]);

    // add a new feature, lets add ability to draw selection rectangle
    let selectionRectangle = new Konva.Rect({
      fill: 'rgba(0,0,255,0.5)',
      visible: false,
    });
    this.layer.add(selectionRectangle);

    this.layer.add(rect1);
    rect1.on('dragstart', function () {
      this.moveToTop();
    });

    rect1.on('dragmove', function () {
      document.body.style.cursor = 'pointer';
    });

    rect1.on('mouseover', function () {
      document.body.style.cursor = 'pointer';
    });
    rect1.on('mouseout', function () {
      document.body.style.cursor = 'default';
    });
    let x1: any, y1: any, x2: any, y2: any;
    this.stage.on('mousedown touchstart', (e) => {
      // do nothing if we mousedown on any shape
      if (e.target !== this.stage) {
        return;
      }
      e.evt.preventDefault();
      // @ts-ignore: Object is possibly 'null'.
      x1 = this.stage.getPointerPosition().x;
      // @ts-ignore: Object is possibly 'null'.
      y1 = this.stage.getPointerPosition().y;
      // @ts-ignore: Object is possibly 'null'.
      x2 = this.stage.getPointerPosition().x;
      // @ts-ignore: Object is possibly 'null'.
      y2 = this.stage.getPointerPosition().y;

      selectionRectangle.visible(false);
      selectionRectangle.width(0);
      selectionRectangle.height(0);
    });

    this.stage.on('mousemove touchmove', (e) => {
      // do nothing if we didn't start selection
      if (!selectionRectangle.visible()) {
        return;
      }
      e.evt.preventDefault();
      // @ts-ignore: Object is possibly 'null'.
      x2 = this.stage.getPointerPosition().x;
      // @ts-ignore: Object is possibly 'null'.
      y2 = this.stage.getPointerPosition().y;

      selectionRectangle.setAttrs({
        x: Math.min(x1, x2),
        y: Math.min(y1, y2),
        width: Math.abs(x2 - x1),
        height: Math.abs(y2 - y1),
      });
    });

    this.stage.on('mouseup touchend', (e) => {
      // do nothing if we didn't start selection
      if (!selectionRectangle.visible()) {
        return;
      }
      e.evt.preventDefault();
      // update visibility in timeout, so we can check it in click event
      setTimeout(() => {
        selectionRectangle.visible(false);
      });

      let shapes = this.stage.find('.rect');
      let box = selectionRectangle.getClientRect();
      let selected = shapes.filter((shape) =>
        Konva.Util.haveIntersection(box, shape.getClientRect())
      );
      this.tr.nodes(selected);
    });

    // clicks should select/deselect shapes
    this.stage.on('click tap', function (e) {
      // if we are selecting with rect, do nothing
      if (selectionRectangle.visible()) {
        return;
      }

      // if click on empty area - remove all selections
      if (e.target === thisClass.stage) {
        thisClass.tr.nodes([]);
        return;
      }

      // do nothing if clicked NOT on our rectangles
      if (!e.target.hasName('rect')) {
        return;
      }

      // do we pressed shift or ctrl?
      const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
      const isSelected = thisClass.tr.nodes().indexOf(e.target) >= 0;

      if (!metaPressed && !isSelected) {
        // if no key pressed and the node is not selected
        // select just one
        thisClass.tr.nodes([e.target]);
      } else if (metaPressed && isSelected) {
        // if we pressed keys and node was selected
        // we need to remove it from selection:
        const nodes = thisClass.tr.nodes().slice(); // use slice to have new copy of array
        // remove node from array
        nodes.splice(nodes.indexOf(e.target), 1);
        thisClass.tr.nodes(nodes);
      } else if (metaPressed && !isSelected) {
        // add the node into selection
        const nodes = thisClass.tr.nodes().concat([e.target]);
        thisClass.tr.nodes(nodes);
      }
    });
    // this.stage.add(this.layer);
  }
  addArrow() {
    let thisClass = this;
    let rect1 = new Konva.Arrow({
      x: 0,
      y: 200,
      points: [0, 0, 620 / 4, 465 / 4],
      pointerLength: 20,
      pointerWidth: 20,
      draggable: true,
      fill: 'black',
      stroke: 'black',
      name: 'figura',
    });

    this.tr.nodes([]);
    this.shapes.push(rect1);
    // add the shape to the layer
    this.layer.add(this.tr);

    // by default select all shapes
    this.tr.nodes([rect1]);

    // add a new feature, lets add ability to draw selection rectangle
    let selectionRectangle = new Konva.Rect({
      fill: 'rgba(0,0,255,0.5)',
      visible: false,
    });
    this.layer.add(selectionRectangle);

    this.layer.add(rect1);
    rect1.on('dragstart', function () {
      this.moveToTop();
    });

    rect1.on('dragmove', function () {
      document.body.style.cursor = 'pointer';
    });

    rect1.on('mouseover', function () {
      document.body.style.cursor = 'pointer';
    });
    rect1.on('mouseout', function () {
      document.body.style.cursor = 'default';
    });
    let x1: any, y1: any, x2: any, y2: any;
    this.stage.on('mousedown touchstart', (e) => {
      // do nothing if we mousedown on any shape
      if (e.target !== this.stage) {
        return;
      }
      e.evt.preventDefault();
      // @ts-ignore: Object is possibly 'null'.
      x1 = this.stage.getPointerPosition().x;
      // @ts-ignore: Object is possibly 'null'.
      y1 = this.stage.getPointerPosition().y;
      // @ts-ignore: Object is possibly 'null'.
      x2 = this.stage.getPointerPosition().x;
      // @ts-ignore: Object is possibly 'null'.
      y2 = this.stage.getPointerPosition().y;

      selectionRectangle.visible(false);
      selectionRectangle.width(0);
      selectionRectangle.height(0);
    });

    this.stage.on('mousemove touchmove', (e) => {
      // do nothing if we didn't start selection
      if (!selectionRectangle.visible()) {
        return;
      }
      e.evt.preventDefault();
      // @ts-ignore: Object is possibly 'null'.
      x2 = this.stage.getPointerPosition().x;
      // @ts-ignore: Object is possibly 'null'.
      y2 = this.stage.getPointerPosition().y;

      selectionRectangle.setAttrs({
        x: Math.min(x1, x2),
        y: Math.min(y1, y2),
        width: Math.abs(x2 - x1),
        height: Math.abs(y2 - y1),
      });
    });

    this.stage.on('mouseup touchend', (e) => {
      // do nothing if we didn't start selection
      if (!selectionRectangle.visible()) {
        return;
      }
      e.evt.preventDefault();
      // update visibility in timeout, so we can check it in click event
      setTimeout(() => {
        selectionRectangle.visible(false);
      });

      let shapes = this.stage.find('.rect');
      let box = selectionRectangle.getClientRect();
      let selected = shapes.filter((shape) =>
        Konva.Util.haveIntersection(box, shape.getClientRect())
      );
      this.tr.nodes(selected);
    });

    // clicks should select/deselect shapes
    this.stage.on('click tap', function (e) {
      // if we are selecting with rect, do nothing
      if (selectionRectangle.visible()) {
        return;
      }

      // if click on empty area - remove all selections
      if (e.target === thisClass.stage) {
        thisClass.tr.nodes([]);
        return;
      }

      // do nothing if clicked NOT on our rectangles
      if (!e.target.hasName('rect')) {
        return;
      }

      // do we pressed shift or ctrl?
      const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
      const isSelected = thisClass.tr.nodes().indexOf(e.target) >= 0;

      if (!metaPressed && !isSelected) {
        // if no key pressed and the node is not selected
        // select just one
        thisClass.tr.nodes([e.target]);
      } else if (metaPressed && isSelected) {
        // if we pressed keys and node was selected
        // we need to remove it from selection:
        const nodes = thisClass.tr.nodes().slice(); // use slice to have new copy of array
        // remove node from array
        nodes.splice(nodes.indexOf(e.target), 1);
        thisClass.tr.nodes(nodes);
      } else if (metaPressed && !isSelected) {
        // add the node into selection
        const nodes = thisClass.tr.nodes().concat([e.target]);
        thisClass.tr.nodes(nodes);
      }
    });
    // this.stage.add(this.layer);
  }
  addText() {
    let textNode: any = new Konva.Text({
      text: 'Scrivi qualcosa qui',
      x: 50,
      y: 80,
      fontSize: 20,
      draggable: true,
      width: 200,
    });
    this.shapes.push(textNode);
    this.layer.add(textNode);

    let thisClass = this;
    thisClass.tr.nodes([]);
    thisClass.tr = new Konva.Transformer({
      node: textNode,
      enabledAnchors: ['middle-left', 'middle-right'],
      // set minimum width of text
      boundBoxFunc: function (oldBox, newBox) {
        newBox.width = Math.max(30, newBox.width);
        return newBox;
      },
    });

    textNode.on('transform', function () {
      // reset scale, so only with is changing by transformer
      textNode.setAttrs({
        width: textNode.width() * textNode.scaleX(),
        scaleX: 1,
      });
    });

    this.layer.add(thisClass.tr);
    textNode.on('dblclick dbltap', () => {
      // hide text node and transformer:
      textNode.hide();
      thisClass.tr.hide();

      // create textarea over canvas with absolute position
      // first we need to find position for textarea
      // how to find it?

      // at first lets find position of text node relative to the stage:
      let textPosition = textNode.absolutePosition();

      // so position of textarea will be the sum of positions above:
      let areaPosition = {
        x: this.stage.container().offsetLeft + textPosition.x,
        y: this.stage.container().offsetTop + textPosition.y,
      };

      // create textarea and style it
      let textarea = document.createElement('textarea');
      let lavagnaElement = document.getElementById('lavagna');
      lavagnaElement.appendChild(textarea);
      textarea.value = textNode.text();
      textarea.style.position = 'absolute';
      textarea.style.top = areaPosition.y + 'px';
      textarea.style.left = areaPosition.x + 'px';
      textarea.style.width = textNode.width() - textNode.padding() * 2 + 'px';
      textarea.style.height =
        textNode.height() - textNode.padding() * 2 + 5 + 'px';
      textarea.style.fontSize = textNode.fontSize() + 'px';
      textarea.style.border = 'none';
      textarea.style.padding = '0px';
      textarea.style.margin = '0px';
      textarea.style.overflow = 'hidden';
      textarea.style.background = 'none';
      textarea.style.outline = 'none';
      textarea.style.resize = 'none';
      textarea.style.lineHeight = textNode.lineHeight();
      textarea.style.fontFamily = textNode.fontFamily();
      textarea.style.transformOrigin = 'left top';
      textarea.style.textAlign = textNode.align();
      textarea.style.color = textNode.fill();
      let rotation = textNode.rotation();
      let transform = '';
      if (rotation) {
        transform += 'rotateZ(' + rotation + 'deg)';
      }

      let px = 0;
      // also we need to slightly move textarea on firefox
      // because it jumps a bit
      let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isFirefox) {
        px += 2 + Math.round(textNode.fontSize() / 20);
      }
      transform += 'translateY(-' + px + 'px)';

      textarea.style.transform = transform;

      // reset height
      textarea.style.height = 'auto';
      // after browsers resized it we can set actual value
      textarea.style.height = 23 + 'px';

      textarea.focus();

      function removeTextarea() {
        // @ts-ignore: Object is possibly 'null'.
        textarea.parentNode.removeChild(textarea);
        window.removeEventListener('click', handleOutsideClick);
        textNode.show();
        // tr.show();
        thisClass.tr.forceUpdate();
      }

      function setTextareaWidth(newWidth: any) {
        if (!newWidth) {
          // set width for placeholder
          newWidth = textNode.placeholder.length * textNode.fontSize();
        }
        // some extra fixes on different browsers
        let isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        let isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isSafari || isFirefox) {
          newWidth = Math.ceil(newWidth);
        }

        let isEdge = document.DOCUMENT_NODE || /Edge/.test(navigator.userAgent);
        if (isEdge) {
          newWidth += 1;
        }
        textarea.style.width = newWidth + 'px';
      }

      textarea.addEventListener('keydown', function (e) {
        // hide on enter
        // but don't hide on shift + enter
        if (e.keyCode === 13 && !e.shiftKey) {
          textNode.text(textarea.value);
          removeTextarea();
        }
        // on esc do not set value back to node
        if (e.keyCode === 27) {
          removeTextarea();
        }
      });

      textarea.addEventListener('keydown', function (e) {
        thisClass.scale = textNode.getAbsoluteScale().x;
        setTextareaWidth(textNode.width() * thisClass.scale);
        textarea.style.height = 'auto';
        textarea.style.height =
          textarea.scrollHeight + textNode.fontSize() + 'px';
      });

      function handleOutsideClick(e: any) {
        if (e.target !== textarea) {
          textNode.text(textarea.value);
          removeTextarea();
        }
      }
      setTimeout(() => {
        window.addEventListener('click', handleOutsideClick);
      });
    });
  }

  addLineListeners(): void {
    const component = this;
    let lastLine: any;
    let isPaint: boolean = false;
    const control_container = document.getElementById('control_container');

    this.stage.on('mousedown touchstart', function () {
      if (!component.selectedButton['brush'] && !component.eraser) {
        return;
      }
      isPaint = true;
      let pos = component.stage.getPointerPosition();
      lastLine = component.eraser
        ? component.konvaService.erase(pos, 30)
        : component.konvaService.brush(
            pos,
            component.brushSize,
            component.inkColor,
            component.brushOpacity
          );
      component.shapes.push(lastLine);
      component.layer.add(lastLine);
      control_container?.classList.add('hide_palette');
    });

    this.stage.on('mouseup touchend', function () {
      isPaint = false;
      control_container?.classList.remove('hide_palette');
    });

    this.stage.on('mousemove touchmove', function (e) {
      if (!isPaint) {
        return;
      }
      e.evt.preventDefault();
      const position: any = component.stage.getPointerPosition();
      const newPoints = lastLine.points().concat([position.x, position.y]);
      lastLine.points(newPoints);
      component.layer.batchDraw();
    });
  }

  undo(): void {
    this.tr.nodes([]);
    this.stage.remove();
    const removedShape = this.shapes.pop();

    this.transformers.forEach((t) => {
      t.detach();
    });

    if (removedShape) {
      removedShape.remove();
    }

    this.layer.draw();
  }

  clearBoard(): void {
    const length = this.shapes.length;
    for (let i = 0; i < length; i++) {
      this.undo();
    }
  }

  saveAsImage(): void {
    html2canvas(this.screen.nativeElement).then((canvas) => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'marble-diagram.png';
      this.downloadLink.nativeElement.click();
      this.lavagnaService.sendFileToTutorials(canvas.toDataURL(),this.idAula,this.aulaInfo.nomeAula).subscribe((res)=>{})
    });

    // let json = this.stage.toJSON();
    // var element = document.createElement('a');
    // element.setAttribute(
    //   'href',
    //   'data:text/json;charset=UTF-8,' + encodeURIComponent(json)
    // );
    // element.setAttribute('download', 'primer-server-task.json');
    // element.style.display = 'none';
    // document.body.appendChild(element);
    // element.click(); // simulate click
    // document.body.removeChild(element);
    // const dataUrl: string = this.stage.toDataURL({
    //   mimeType: 'image/png',
    //   quality: 1,
    //   pixelRatio: 1,
    // });

    // const link = document.createElement('a');
    // link.download = 'board_image.png';
    // link.href = dataUrl;
    // link.click();
  }

  getCursorClass(): string {
    if (this.selectedButton['brush'] || this.selectedButton['eraser']) {
      return 'pointer_cursor';
    } else {
      return 'default';
    }
  }

  pathAbsolute: string = '';
  immagini = [];
  mappaImmagineToId: Map<string, string> = new Map<string, string>();
  creaPalettaImmagini() {
    this.pathAbsolute = document.location.origin;
    const pathRelative = '/assets/img/lavagna/';
    let pathImage = '';
    let id = '';
    for (let i = 0; i < this.lavagnaService.nImmagini(); i++) {
      let img = new Image();
      pathImage = this.pathAbsolute + pathRelative + i + '.png';
      id = '' + i + '.png';
      img.src = pathImage;
      this.immagini.push({
        src: pathImage,
        id: id,
      });
      this.mappaImmagineToId.set(pathImage, '' + id);
    }
  }
}

@Component({
  selector: 'bottom-sheet',
  templateUrl: 'bottom-sheet.html',
  styleUrls: ['./whiteboard-page.component.scss'],
})
export class BottomSheet {
  brushSize!: number;
  brushOpacity!: number;
  constructor(
    private bottomSheet: MatBottomSheetRef<BottomSheet>,
    private konvaService: KonvaService
  ) {
    this.brushSize = this.konvaService.brushSize;
    this.brushOpacity = this.konvaService.brushOpacity;
  }

  ngOnDestroy(): void {
    const data = {
      brushSize: this.brushSize,
      brushOpacity: this.brushOpacity,
    };
    this.konvaService.brushSize = this.brushSize;
    this.konvaService.brushOpacity = this.brushOpacity;
    this.bottomSheet.dismiss(data);
  }
}
