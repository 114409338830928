import { DOCUMENT, formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from 'src/app/HELPERS/candeactivate-guard.service';
import { Forma } from 'src/app/MODELS/ASSISTENZA/forma';
import { UserStream } from 'src/app/MODELS/ASSISTENZA/user_stream.model';
import { Utente } from 'src/app/MODELS/USER/utente';
import { ArService } from 'src/app/SERVICES/ASSISTENZA/ar.service';
import { OperatorService } from 'src/app/SERVICES/ASSISTENZA/operator.service';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { AccountService, WebSocketService } from 'src/app/SERVICES/index';

@Component({
  selector: 'app-home-control-room',
  templateUrl: './home-control-room.component.html',
  styleUrls: ['./home-control-room.component.scss'],
  providers: [ConfirmationService],
})
export class HomeControlRoomComponent
  implements OnInit, OnDestroy, CanComponentDeactivate, AfterViewInit
{
  //#region variabili per il funzionamento della wishList, di openVidu e della mappa
  intervalReconnectionWs;
  showDialog = false;
  display: boolean = false;
  animationDisplay: boolean = false;
  newAnimation: boolean = false;
  access: boolean = false;
  fileInputShow = true;
  hideModal = undefined;
  stato: boolean = true;
  whitelistView: boolean = false;
  title = 'geo';
  screenWidth;
  screenHeight;
  showMap = true;
  whitelistMails = [];
  whitelistUsers: Utente[] = [];
  usersInt = 0;
  firstTime = false;
  mappa = false;
  whiteListChange: any = {};
  permessi = false;
  stream;
  cambiaCol = false;
  caricato = false;
  selectedUsers: Utente[];
  url;
  statoLuce = false;
  subscription;
  saveScreenshotOnCloud;
  //#endregion

  //#region var AR
  statoAR: boolean = false; //Attivo/disattivo  stato AR
  AR; //Abilito AR EndPoint
  elementHTML; //è il contenitore del flusso stream, attraverso il tag video
  //.bind() crea di volta in volta un nuovo riferimento della funzione
  //quindi nel costuttore lo creo una volta assegnandolo in questa variabile
  handleFunctionClickAR = this.clickCoord.bind(this);
  //#endregion

  //#region  var screenshot
  videoElement: HTMLVideoElement;
  userStreams: UserStream[] = []; //email e idHTMLVideoElement
  userCreateCR: UserStream; //Utente che crea la CR
  //#endregion

  //#region  var AR new
  formeView: boolean = false;
  forme: Forma[] = []; //oggetto del p-menu di primeng, permette la visualizzazione degli elementi
  selectedShape: string = 'cerchio'; //forma di default, cambiato in base al click #{{@onClickShape}}
  selectedColor: string = '0000FF'; //colore di default, cambiato in base al click #{{@onClickColor}}
  items: MenuItem[] = []; //menu toogle per i vari utenti, di primeng
  azioneScelta: string; //se aggiungere forma, cancellarla oppure eliminare tutte le forme
  utenteScelto: string; //email utente dell'utente il quale vogliamo interagire
  selectedForma: Forma;
  //#endregion

  //#region fullscreen
  isFullScreen: boolean;
  @ViewChild('fullScreen') divRef;
  //#endregion
  prova;
  email;
  entrato;
  //#region  Funzionalià e inizializzazione del p-menu

  //#region Visore
  immagineRicevuta = false; //settata a true per fini di test, da settare a false
  stompClient;
  riconnessioneWs = false;
  vrConnesso = false;
  uploadDialog = false;
  nomeFile;
  selectedFile;
  fileLink;
  caricamentoUpload = false;
  streams = 0;
  vrStreams = 0;
  uploadFromCloud;
  fileImg;
  uploadFileVisore;
  showCR = true;
  //#endregion
  isChime = false;

  constructor(
    private operatorService: OperatorService,
    private alertService: AlertService,
    private accountService: AccountService,
    private service: ShareService,
    private router: Router,
    protected arService: ArService,
    private elementRef: ElementRef,
    private env: EnvService,
    private confirmationService: ConfirmationService,
    private wsService: WebSocketService,
    private fileService: FileService,
    private accountStorage: AccountService,
    @Inject(DOCUMENT) private document: any
  ) {
    if (!this.env.CR) {
      this.router.navigate(['']);
    }
    this.AR = this.env.AR; //EndPoint AR
    this.isChime = this.env.chime;
    //#region p-menu
    this.forme.push(new Forma('cerchio'));
    this.forme.push(new Forma('quadrato'));
    this.forme.push(new Forma('freccia'));
    //#endregion

    let constraints = {
      video: true,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((res) => {
        this.stream = res;
        this.permessi = true;
      })
      .catch((err) => {
        this.reload();
        this.alertService.error(
          'Per continuare, è necessario fornire i permessi'
        );
      });

    this.giveAccess();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.access && this.permessi == true) {
      this.confirmationService.confirm({
        message: 'Sei sicuro di voler uscire?',

        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.service.navigateAwaySelection$.next(true);
        },
        reject: () => {
          this.service.navigateAwaySelection$.next(false);
        },
      });
      return this.service.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    clearInterval(this.intervalReconnectionWs);
    if (!this.accountService.userValue.data) {
      this.router.navigate['logout'];
    }
    this.subscription.unsubscribe();
    this.access = false;
    if (this.stream) {
      const track = this.stream.getTracks();
      track[0].stop();
      track[1].stop();
    }
    this.operatorService
      .pulisciWhiteList(this.accountService.userValue.data.id)
      .subscribe((res) => {});
  }

  ngOnInit(): void {
    //la linea seguente è solo per testare la lavagna, commentare prima di pushare
    //this.fileLink = "https://image.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600w-1037719192.jpg";
    this.firstTime = true;

    if (this.accountService.ruolo !== 1 && this.accountService.ruolo !== 2) {
      this.router.navigate(['']);
    }
    setTimeout(() => {
      this.caricato = true;
    }, 10000);

    if (this.firstTime) {
      this.subscription = this.service.whiteListChange$.subscribe((res) => {
        if (res) {
          this.prova = JSON.parse(res);

          this.email = this.prova.email;
          this.entrato = this.prova.entrato;
          if (this.entrato === 1) {
            if (!this.whitelistMails.includes(this.email)) {
              if (this.firstTime) {
                let utente = new Utente();
                utente.email = this.email;
                this.whitelistUsers = [...this.whitelistUsers, utente];
                // this.whitelistUsers.push(utente);
                this.whitelistMails.push(this.email);
                this.alertService.info(this.email + ' richiede assistenza');
                this.usersInt = this.whitelistUsers.length;
                this.service.changeListaGeolocalizzazione(this.whitelistMails);
              }
            }
          } else if (this.entrato === 0) {
            if (this.whitelistMails.includes(this.email)) {
              if (this.firstTime) {
                let utente = new Utente();
                utente.email = this.email;
                let index = this.whitelistUsers.indexOf(utente);
                this.whitelistUsers.splice(index, 1);
                if (this.selectedUsers) {
                  let index2 = this.selectedUsers.indexOf(utente);
                  this.selectedUsers.splice(index2, 1);
                }
                this.whitelistMails = this.whitelistMails.filter(
                  (trovamail) => trovamail !== this.email
                );
                this.usersInt = this.whitelistUsers.length;
                this.service.changeListaGeolocalizzazione(this.whitelistMails);
                if (this.usersInt === 0) {
                  this.whitelistView = false;
                }
              }
            }
          }
        }
      });
    }
    this.service.changeWhiteList(null);
    this.iscrizioneTopicWebSocket();
  }

  cambia() {
    this.cambiaCol = !this.cambiaCol;
  }

  visualizzaMappa() {
    this.mappa = !this.mappa;
  }

  reload() {
    // this.alertService.error("Per continuare, è necessario fornire i permessi")
    setTimeout(() => {
      this.router.navigate(['']);
    }, 3000);
  }

  giveAccess() {
    this.operatorService
      .limitAccess(this.accountService.userValue.data.email)
      .subscribe(
        (res) => {
          this.access = true;
        },
        (error) => {
          setTimeout(() => {
            this.router.navigate(['']);
          }, 3000);
        }
      );
  }

  onSelectChange() {
    this.operatorService
      .updateWhitelist(
        this.accountService.userValue.data.id,
        this.selectedUsers
      )
      .subscribe((res) => {});
  }

  cambiaStato() {
    let status = 1;
    if (!this.stato) {
      status = 0;
    }
    this.operatorService
      .changeStatus(this.accountService.userValue.data.id, status)
      .subscribe((res) => {
        if (!this.stato) {
          this.service.changeWhiteList(null);
          this.whitelistMails = [];
          this.whitelistUsers = [];
          this.selectedUsers = [];
          this.usersInt = 0;
          this.vrConnesso = false;
          this.service.changeListaGeolocalizzazione(this.whitelistMails);
        }
        this.stato = !this.stato;
      });
  }

  visualizzaWhitelist() {
    if (this.usersInt > 0) {
      this.whitelistView = !this.whitelistView;
    } else if (this.usersInt === 0) {
      this.whitelistView = false;
    }
  }

  displayMap() {
    this.newAnimation = true;
    this.animationDisplay = !this.animationDisplay;
    if (!this.display) {
      this.display = !this.display;
    } else if (this.display) {
      setTimeout(() => {
        this.display = !this.display;
      }, 800);
    }
  }

  cambiaStatoAuto($event) {
    if (!$event) {
      this.access = false;
    }
    if ($event) {
      if (this.stato) {
        this.cambiaStato();
      }
    }
  }
  //#endregion

  //#region inizializzazione Screenshot+AR

  /**
   * Il metodo viene richiamato solo dopo che la View viene renderizzata, quando questo avviene
   * mi salvo nella variabile @param handleFunctionClickAR
   * il riferimento della funzione, che poi verrà richiamata in un EventListener
   * Inoltre mi salvo i dati di chi ha creato la CR in @param userCreateCR
   */
  ngAfterViewInit(): void {
    this.intervalReconnectionWs = setInterval(() => {
      if (!this.riconnessioneWs) {
        if (this.stompClient && !this.stompClient?.connected) {
          this.wsService.attivo = false;
        }
      }
    }, 20000);
    if (!this.wsService.attivo) {
      this.riconnessioneWs = true;
      this.iscrizioneTopicWebSocket();
    }
    //screenshot
    this.userCreateCR = new UserStream(
      '#video-undefined',
      this.accountService.userValue.data.email
    );

    this.userStreams.push(this.userCreateCR);
    this.setZoom(this.userStreams);

    setTimeout(() => {
      if (!this.riconnessioneWs) {
        if (this.stompClient && !this.stompClient?.connected) {
          this.wsService.attivo = false;
        }
      }
    }, 20000);
    if (!this.wsService.attivo) {
      this.riconnessioneWs = true;
      this.iscrizioneTopicWebSocket();
    }
  }
  /**
   * Con questo metodo recupero id html della seconda finestra utente
   * @param event inviato dal figlio, ogni qualvolta si crea una sessione
   */
  updateUserStreams(event: UserStream[]) {
    //recupero da event id HTML
    //ScreenSHOT
    this.userStreams = event; //assegno l'array di stream nelle variabile di istanza,
    // inviatomi dal component - open-vidu
    if (this.userStreams.indexOf(this.userCreateCR) === -1) {
      //-1 viene restituito se all'interno dell'array, non c'è utente che crea la CR
      this.userStreams.splice(0, 0, this.userCreateCR);
      //se non c'è, lo inserisco in posizione 0
    }
    this.eliminaAscolti();
    this.creaMenu(); //creo e aggiorno il menu, in base al numero di utenti
  }
  //#endregion

  //#region Screenshot

  /**
   * Il metodo crea l'evento di ascolto video e acquisizione dell'immagine.
   * Visto che il video è uno stream, prima di acquisire l'immagine occorre ascoltare lo stream
   * @param index indice del vettore, quale schermata acquisire
   */
  screenShot(index: number) {
    //disabilito eventuali tasti AR
    if (this.statoAR) {
      this.eliminaAscolti();
    }

    //punto all'elemento HTML contenuto nell'array,
    //di posizione scelta dal creatore, che clicca. Al suo interno è contenuto il video
    this.videoElement = document.querySelector(
      this.userStreams[index].idHTMLVideo
    );

    //variabile utilizzata per gestire il caso [0] cioè il creatore della CR
    let tmp = this.videoElement;

    //punto al video se non è il creatore, altrimenti null e gestisco il creatore con if
    this.videoElement = this.videoElement.querySelector('video') || null;

    //gestisco come caso particolare lo stream del creatore della CR:
    //poichè al momento non sono in grado di catturare HTMLVideoElement di chi crea la CR dal componente openVidu
    //per gestirla punto all'elemento idHTML figlio, generico, che contiene il video - #video-undefined
    //Generico poiché tutti gli stream hanno questo id, l'unico elemento univico è dato da un id che ha questa forma
    //#container-str etc... se puntiamo al generico, in automatico angular prende il primo che trova cioè a quello del
    //Creatore della CR
    if (index === 0) {
      //creatore della CR
      //attraverso offsetParent, recupero il tag container, che contiene, come tag figlio, lo stream video univico
      let idElement = tmp.offsetParent.id;

      //infine seleziono lo stream del creatore della CR, puntando al tag video
      tmp = this.elementRef.nativeElement.querySelector('#' + idElement); //lo seleziono
      this.videoElement = tmp.querySelector('video'); //e punto al video
    }
    //mi metto in ascolto di quel video
    let funzione = function () {}; //la variabile funzione temporanea, mi serve per rimuovere l'ascolto a fine acquisizione
    this.videoElement.addEventListener('loadedmetadata', funzione, false);
    //acquisizione screen e download
    this.downloadImage(this.videoElement, index, this.saveScreenshotOnCloud);
    //rimuovo l'ascolto
    this.videoElement.removeEventListener('loadedmetadata', funzione, false);
  }

  /**
   * Metodo per creare l'evento di download dell'immagine, cioè si apre una finestra del OS che
   * chiede all'utente dove salvare l'immagine
   * @todo questo metodo può essere inserito in un servizio
   * @param index utente
   */
  downloadImage(htmlVideo: HTMLVideoElement, index: number, saveOnCloud) {
    //creo un oggetto che conterrà la mia immagine
    var canvas = document.createElement('canvas'),
      //velocizzo il metodo getContext, che serve per disegnare l'immagine
      ctx = canvas.getContext('2d');
    //fornisce le dimensioni all'immagine
    canvas.width = htmlVideo.offsetWidth;
    canvas.height = htmlVideo.offsetHeight;

    //l'effetto specchio nasce nel momento che cerco di catturare lo schermo, visto che
    //il creatore non ne è affetto quando provo a catturarlo genero il problema
    //mentre per gli altri il loro stream è specchiato pertanto quando provo a catturarlo
    //i difetti si annullano

    //Elimino l'effetto a specchio solo per il CR
    if (index == 0) {
      //creatore della CR
      ctx.translate(canvas.width, 0); //elimino effetto a specchio
      ctx.scale(-1, 1);
    }
    //disegno l'immagine
    ctx.drawImage(htmlVideo, 0, 0, canvas.width, canvas.height);

    //creo un ancora
    var a = document.createElement('a');
    //formatto timeZone per l'ora locale e il formato data, +1 è UTC: https://www.timeanddate.com/time/zone/italy
    //per info: https://angular.io/api/common/formatDate
    let ora = formatDate(new Date(), 'dd-MM-yyyy-hh_mm_ss', 'en-US', '+1');
    //setto il download
    (a.download =
      'snap-' + canvas.width + 'x' + canvas.height + '-' + ora + '.jpg'),
      (a.href = canvas.toDataURL('image/jpeg')),
      //Chiedo il download a windows
      document.body.appendChild(a).click(),
      //rimuovo ancora
      a.remove();
    if (saveOnCloud) {
      //Metodo per salvare sul cloud
    }
  }

  //#endregion

  //#region Menu

  /**
   * Creo il menu toogle con primeng
   * @param userStreams Gli utenti in stream
   * @param items oggetto MenuItem per costruire il menu
   */
  creaMenu() {
    //cancello tutti gli elementi, qualora il menu gìà esistesse
    this.items.splice(0, this.items.length);
    //e poi lo popolo
    this.creaMenuPersone();
    this.creaMenuAzioni();
  }

  /**
   * email->Azione
   * Quindi la prima tendina è una lista di tutti gli utenti
   */
  creaMenuPersone() {
    //comincio da 1, poiché 0 è il creatore e non ha senso interagire sullo stesso schermo
    for (let persona = 1; persona < this.userStreams.length; persona++) {
      //aggiungo email
      let emailSplit = this.userStreams[persona].email.split('@', 1);
      this.items.push({
        label: emailSplit[0], //mostro email
        icon: 'pi pi-fw pi-user',
        items: [], //istanzio la seconda tendina che conterrà le azioni: Aggiungi Forma->Cerchio
        escape: false,
      });
    }
  }

  /**
   * La tendina con tutte le azioni
   * ->Azione
   */
  creaMenuAzioni() {
    //istanzio azioni (aggiungi forma, cancella etc...)
    //creo il menu Azioni (aggiungi forma)
    for (let persona = 0; persona < this.items.length; persona++) {
      for (
        let azione = 0;
        azione < this.userStreams[persona].azioni.length - 1;
        azione++
      ) {
        //nome dell'azione
        this.items[persona].items.push({
          label: this.userStreams[persona].azioni[azione], //mostro l'azione
          icon:
            this.userStreams[persona].azioni[azione] === 'aggiungi forma'
              ? 'pi pi-fw pi-plus'
              : 'pi pi-fw pi-minus',
          command: (event) => {
            let azioneScelta = this.userStreams[persona].azioni[azione];
            let indexUserStream = persona + 1;
            this.controllerEvent(event, azioneScelta, indexUserStream);
          },
        });
      }
      //Tranne l'ultima azione che è il cancella tutto
      let ultimaAzione =
        this.userStreams[persona].azioni[
          this.userStreams[persona].azioni.length - 1
        ];
      //persona               //ultimaAzione (cancellaTutto)
      this.items[persona].items.push({
        label: ultimaAzione,
        icon: 'pi pi-fw pi-trash',
        command: (event) => {
          let indexUserStream = persona + 1;
          this.controllerEvent(event, ultimaAzione, indexUserStream);
        },
      });
    }
  }
  //#endregion

  //#region Codice AR
  /**
   * In base a quello che si clicca sul menu, innesco determinate azioni
   * @param event evento che lo ha generato
   * @param azione che l'utente ha selezionato, ad esempio aggiungi forma etc...
   * @param indexUserStream l'utente sul quale ha scelto di eseguire quella determinata azione
   */
  controllerEvent(event, azione: string, indexUserStream: number) {
    //In base all'azione il giusto metodo
    if (azione === 'aggiungi forma' || azione === 'elimina forma') {
      this.azioneScelta = azione; //mi salvo l'azione e
      this.utenteScelto = this.userStreams[indexUserStream].email; //l'evento, utilizzato dal metodo #{{@clickCoord}} per scegliere cosa fare
      this.generaAscolto(indexUserStream);
    } else {
      this.azioneScelta = azione;
      this.utenteScelto = this.userStreams[indexUserStream].email;
      this.cancellaTutto();
    }
  }

  /**
   * Genero l'ascolto su un determinato stream video
   * @param indexUserStream l'utente selezionato
   */
  generaAscolto(indexUserStream: number) {
    //elimino eventuali ascolti precedenti
    this.eliminaAscolti();
    // this.formeView = !this.formeView; //Chiedi a serena

    //cattura il tag ideo dell'utente
    let idHTMLVideo = this.userStreams[indexUserStream].idHTMLVideo;
    //per selezionare quella finestra
    this.elementHTML = this.elementRef.nativeElement.querySelector(idHTMLVideo);

    if (
      this.elementHTML === undefined || //elementHTML non deve essere undefined
      this.elementHTML === null
    ) {
      return;
    }

    //creo un ascolto che si basa sul click di quella sola finestra,
    //che attiva un metodo. #{@clickCoord()}
    //il metodo bind() indica che il metodo è costantamente in ascolto
    //Attenzione a .bind() che crea più riferimenti dello stesso metodo,
    //meglio crearlo una sola volta, attraverso l'uso di una var di istanza.
    //Inoltre va rimosso quando non serve
    this.elementHTML.addEventListener('click', this.handleFunctionClickAR);
    this.statoAR = true;
  }
  /**
   * Elimino l'ascolto precedente dello stream disattivanto lo statoAR
   */
  eliminaAscolti() {
    this.formeView = false; //Chiudo la finestra dei colori
    if (this.elementHTML === undefined) return;
    this.elementHTML.removeEventListener('click', this.handleFunctionClickAR);
    this.statoAR = false;
  }

  /**
   * Il metodo entra in gioco solo quando l'utente clicca
   * su quella determinata finestra, pertanto l'unico controllo sul
   * target è verificare se l'utente clicca sul microfono
   * @param event cattura il click sullo stream, mi permette di catturare le coordinate relative
   * dello stream, la sua dimensione
   */
  clickCoord(event) {
    //Con questo controllo, mi assicuro che l'utente clicca
    //su uno schermo
    if (event.target.id !== 'video-undefined') {
      return;
    }

    if (this.elementHTML === null || this.elementHTML === undefined) {
      return;
    }

    //prendo le coordinate relative, utilizzando jQuery
    var rect = event.target.getBoundingClientRect();
    let x = event.clientX - rect.left;
    let y = event.clientY - rect.top;
    //Recupero la dimensione relativa della finestra stream
    let altezza = this.elementHTML.offsetHeight;
    let larghezza = this.elementHTML.offsetWidth;

    //stampo le info
    //operazione finale in base a chi è attivo

    if (this.azioneScelta === 'aggiungi forma') {
      this.arService
        .aggiungi_forma(
          x,
          y,
          larghezza,
          altezza,
          this.utenteScelto,
          this.selectedColor,
          this.selectedShape
        )
        .subscribe(
          (res) => {},
          (error) => {}
        );
    } else {
      this.arService
        .elimina_forma(x, y, larghezza, altezza, this.utenteScelto)
        .subscribe(
          (res) => {},
          (error) => {}
        );
    }
  }

  /**
   * Elimino tutte le figure 3D aggiunte
   */
  cancellaTutto() {
    //elimino eventuali ascolti precedenti
    this.eliminaAscolti();
    if (this.userStreams.length === 1) return;
    this.arService.cancella_tutto(this.utenteScelto).subscribe(
      (res) => {},
      (err) => {}
    );
  }

  //#endregion

  //#region style per html

  /**
   * Metodo che mostra all'utente se AR è ancora attivo, rende il pulsante 3D verde e se premuto lo disabilta, attraverso
   * @see eliminaAscolti
   */
  statoColor(): string {
    if (this.statoAR) {
      return 'rgb(0, 255, 0)';
    } else {
      return 'white';
    }
  }

  /**
   * Si attiva quando l'utente clicca sul bottone colorato, permettendo il cambio del colore
   * @param color nel file html, ad ogni click sul colore viene dato come stringa il colore cliccato
   */
  onClickColor(color: string) {
    if (color === 'rosso') {
      this.selectedColor = 'FF0000';
    } else if (color === 'blu') {
      this.selectedColor = '0000FF';
    } else {
      this.selectedColor = 'FFFF00';
    }
  }

  /**
   * Si attiva quando l'utente clicca sul tipo di forma che vuole inviare, permettendogli il cambio del colore
   * @param shape nel file html, ad ogni click sul colore viene dato come stringa la forma cliccata
   */
  onClickShape(shape: string) {
    this.selectedShape = shape;
  }

  /**
   * quando l'utente clicca sul bottone a forma di paletta dei colori, si apre la tendina con le varie voci:
   * Forma e colore che l'utente può scegliere
   */
  apreTendina() {
    this.formeView = !this.formeView; //apre la tendina
  }

  //#endregion
  accendiLuce() {
    if (this.url) {
      this.operatorService.accendi_luci(this.url).subscribe((res) => {
        this.statoLuce = true;
        this.alertService.success('Luce Accesa');
      });
    }
  }
  spegniLuce() {
    if (this.url) {
      this.operatorService.spegni_luci(this.url).subscribe((res) => {
        this.statoLuce = false;
        this.alertService.success('Luce Spenta');
      });
    }
  }

  ///////////////////////////////////////Inizio metodi fullscreen
  openFullscreen() {
    const fullscreen = this.divRef.nativeElement;
    if (fullscreen.requestFullscreen) {
      fullscreen.requestFullscreen();
    } else if (fullscreen.mozRequestFullScreen) {
      /* Firefox */
      fullscreen.mozRequestFullScreen();
    } else if (fullscreen.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      fullscreen.webkitRequestFullscreen();
    } else if (fullscreen.msRequestFullscreen) {
      /* IE/Edge */
      fullscreen.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreenmodes(event) {
    this.chkScreenMode();
  }
  chkScreenMode() {
    if (document.fullscreenElement) {
      //fullscreen
      this.isFullScreen = true;
    } else {
      //not in full screen
      this.isFullScreen = false;
    }
  }
  //////////////////////////////////////Fine metodi fullscreen

  editAccess() {
    this.access = false;
    this.router.navigate(['']);
  }

  responsiveWidth() {}

  test() {
    //console.log("Lavagna cliccata!");
  }

  handleMessage(msg) {
    //console.log("Ricevuto un messaggio: " + msg);
  }

  iscrizioneTopicWebSocket() {
    this.stompClient = this.wsService.connect();
    this.stompClient.connect({}, (frame) => {
      this.riconnessioneWs = false;
      this.stompClient.subscribe('/topic/cr', (res) => {
        // console.log("Ricevuto messaggio:\n" + res);
        let messaggio = JSON.parse(res.body.toString());
        // console.log("res.body.toString() = " + res.body.toString());
        //console.log("res.eventType: " + messaggio.eventType);
        this.streams += 1;
        if (messaggio.eventType == 'VR_JOINED') {
          this.vrConnesso = true;
          this.vrStreams += 1;
        }
        if (messaggio.eventType == 'FILE_UPLOAD') {
          if (messaggio.senderIdentity == 'CR_USER') {
            this.fileLink = messaggio.body.fileLink;
            this.showDialog = true;
            this.hideModal = false;
          }
        }
        if (messaggio.eventType == 'SCREEN_CAPTURE_UPLOAD') {
          if (messaggio.senderIdentity == 'CR_USER') {
            this.fileLink = messaggio.body.fileLink;
            this.showDialog = true;
            this.hideModal = false;
          }
        }
      });
      //console.log("Iscritto ai topic del WebSocket!")
    });
  }

  public onFileChange(event) {
    this.selectedFile = null;
    this.nomeFile = null;

    this.nomeFile = event.target.files[0].name;
    this.selectedFile = event.target.files[0];
  }

  public openUploadDialog(isVisore = false) {
    this.uploadFileVisore = isVisore;
    this.uploadDialog = true;
    //console.log("Pulsante di download cliccato!");
  }

  public uploadFile() {
    this.fileInputShow = false;
    this.caricamentoUpload = true;

    this.fileService
      .condividiFileCr(this.accountStorage.userValue.data.id, this.selectedFile)
      .subscribe((res) => {
        this.caricamentoUpload = false;
        this.uploadDialog = false;
        this.fileInputShow = true;
        this.nomeFile = null;
        this.selectedFile = null;
        this.alertService.success(res.status.description);
      });
  }

  // allowSameFileToBeSelected() {
  //   this.fileInputShow=false;
  //   setTimeout(()=>{this.fileInputShow=true;},10);
  // }

  public downloadFile() {
    window.open(this.fileLink, '_blank');
    this.immagineRicevuta = false;
  }

  keepOpenDropdown(event) {
    event.stopPropagation();
  }

  setZoom(users) {
    users.forEach((user) => {
      user.zoom = 0;
    });
  }

  sendZoom(email, zoom) {
    this.arService.sendZoom(email, zoom).subscribe(
      (res) => {
        this.alertService.success(res.status.description);
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  openInEditor() {
    let reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = () => {
      this.fileLink = reader.result;
      this.showDialog = true;
      this.uploadDialog = false;
    };
  }

  goBack() {
    this.uploadFromCloud = false;
    this.showCR = true;
  }

  checkUsers(user) {
    let email = JSON.parse(localStorage.getItem('user')).data.email;
    if (user.email === email) {
      return false;
    }
    return true;
  }
}
