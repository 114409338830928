import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  AccountService,
  AlertService,
  EnvService,
  UsersService,
} from 'src/app/SERVICES';
import { SelectItem, PrimeNGConfig, ConfirmationService } from 'primeng/api';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';
import { Dispositivo, TipoDevice, FirstLocation } from 'src/app/MODELS/IOT/dispositivo';
import { configurazioneKetron } from 'src/app/MODELS/IOT/configurazioneKetron';
import { Utente } from 'src/app/MODELS/USER/utente';
import { IotAllarme } from 'src/app/MODELS/IOT/iot-allarme';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceTelemetryMetadata, DeviceType } from 'src/app/MODELS/IOT/device';
import { ConfigurazioneSmartbedClass } from 'src/app/MODELS/IOT/configurazioneSmartbed';
import { ConfigurazionePT460 } from 'src/app/MODELS/IOT/ConfigurazionePT460';
import { ConfigurazioneJ2032 } from 'src/app/MODELS/IOT/configurazione-j2032';
import { MatTabGroup } from '@angular/material/tabs';
import { analyzeAndValidateNgModules } from '@angular/compiler';


@Component({
  selector: 'app-dispositivo',
  templateUrl: './dispositivo.component.html',
  styleUrls: ['./dispositivo.component.scss'],
})
export class DispositivoComponent implements OnInit   {
  @Input() dispositivo;
  @Input() info;
  @Input() edit;
  @Input() crea;
  @Input() visible = false;

  toggleSoglia = false;
  toggleLetto = false;
  toggleMovimenti = false;
  testoMovimenti =
    "Permette di impostare un'allarme temporizzato che scatta in caso lo stato corrispondente perduri più del tempo indicato";
  testoPresenza =
    "Permette di impostare un'allarme temporizzato che scatta in caso lo stato corrispondente perduri più del tempo indicato.";

  numberImmobilePercentuale: any = 10;
  numberLievePercentuale: any = 10;
  numberMedioPercentuale: any = 10;
  numberCriticoPercentuale: any = 10;
  idUser;
  idTracker;
  idTrigger;
  numberImmobile: any = 30;
  numberMedio: any = 30;
  numberLieve: any = 30;
  numberAlto: any = 10;
  numberBasso: any = 10;
  numberCritico: any = 30;
  numberALetto: any = 0;
  movimenti = false;
  step3Error = false;
  numberNonALetto = 0;
  soglieToNotShow = [
    'SleepTime',
    'BedAlarm',
    'MovementsNone',
    'MovementsSlight',
    'MovementsLow',
    'MovementsMedium',
    'MovementsHigh',
    'MovementsCritical',
    'BedAlarmIn',
    'BedAlarmOut',
  ];
  minSleepTime = '';
  maxSleepTime = '';
  sleepTime = false;
  sosNumbersEnabled = false;
  aLetto = false;
  nonALetto = false;
  formDispositivo: FormGroup;
  formTrackers: FormGroup;
  searchPazientiForm: FormGroup; //cerca in lista Pazienti
  selectedPazienti = [];
  listaTipi: DeviceType[] = [];
  modSmartbed = false;
  configurazioneFormSmartbed: FormGroup;
  buttonFormSesso: any[];
  confSmartbed: ConfigurazioneSmartbedClass;
  pazienteEdit = false;
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  listaPazienti = [];
  confGenerale: configurazioneKetron;
  allListaPazienti: Utente[];
  opzioniInd: SelectItem[];
  selectedOptionInd: number;
  configurazioneform: FormGroup;
  configurazionePT460form: FormGroup;
  confPT460: ConfigurazionePT460;
  configurazioneJ2032form: FormGroup;
  configurazioneJ2032: ConfigurazioneJ2032;
  submittedStep1 = false;
  submittedStep2 = false;
  submittedStep3 = false;
  submittedStep4 = false;
  caricaPazienti = false;
  listaSelected = [];
  listaSelectedPaziente = [];
  pazienti = false; //mostra al click la lista Pazienti
  dispositivoDaform = new Dispositivo();
  valoreRadio = null;
  valoreRadioPaziente = null;
  valoreRadioWorkingMode = 1;
  valoreConfiguredShellyButton1 = false;
  caricato = false;
  listaconf;
  loading = false;
  familiari = false;
  firstLocation= new FirstLocation();
  paziente;
  allUser: Utente[];
  // --------------aggiunte
  listaSoglie = [];
  NomeRequired = false;

  SogliaMinRequired = false;
  SogliaMinNeg = false;
  controlloRange = false;

  SogliaMaxrequired = false;
  SogliaMaxNeg = false;
  listaTipoSoglia: DeviceTelemetryMetadata[];
  i = 0;
  trovato;
  valoreBpm = 1;
  valoreBpmJ2032 = 5;
  valoreTemp = 1;
  valoreSpo2 = 5;
  valoreVolume = 0;
  validato = false;
  step4Return = true;
  tipo;
  isMobile = false;
  isDesktop = true;
  passwordType = "password";
  visibility="visibility"
  visibleCollpase = false;
  passwordTypeB=true;
  @Output() tornaLista = new EventEmitter<void>(); //torna ad elenco aula virtuale

  /**
   * Salvo la tipologia precedente qualora venga modificata
   */
  tipologiaPrecedente: number = -1;
  /**
   * Mi salvo le soglie della tipologia precedente qualora venga salvata
   */
  backup: DatiTipologiaSalvatiPrecedente;
  tracking;
  valorePing = 0;
  labelTm(value: number): string {
    switch (value) {
      case 1:
        return '10 min';
      case 2:
        return '30 min';
      case 3:
        return '1 ora';
      case 4:
        return '2 ore';
    }
  }
  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
    private dispositiviService: IotService,
    private deviceService: DeviceDetectorService,
    private env: EnvService,
    private confirmationService: ConfirmationService
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.opzioniInd = [
      { label: 'Sì', value: '1' },
      { label: 'No', value: '0' },
    ];
    this.isDesktop = deviceService.isDesktop();
    this.buttonFormSesso = [
      { label: 'Uomo', value: false },
      { label: 'Donna', value: true },
    ];
    this.backup = new DatiTipologiaSalvatiPrecedente();
    this.tracking = this.env.TRACKING;
  }

  ngOnInit(): void {
    if (this.dispositivo && this.dispositivo.utente)  this.idUser = this.dispositivo.utente.id
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    if (this.dispositivo) {
      this.dispositiviService
        .getTelemetryMetadata(this.dispositivo.deviceType.id)
        .subscribe((res) => {
          this.listaTipoSoglia = <[DeviceTelemetryMetadata]>res.data;
          this.eliminaSogliaDaLista('Stato Dispositivo');
        });
      if (this.dispositivo.utente !== null && this.info) {
        this.paziente =
          this.dispositivo.utente.nome + ' ' + this.dispositivo.utente.cognome;
      } else {
        this.paziente = 'nessun paziente configurato';
      }

      if (this.dispositivo.utente !== null && this.edit) {
        this.valoreRadioPaziente = this.dispositivo.utente.id;
        this.listaSelectedPaziente.push(this.dispositivo.utente.id);
      }
      if (this.dispositivo.deviceType) {
        this.valoreRadio = this.dispositivo.deviceType.id;
        this.listaSelected.push(this.dispositivo.deviceType);
        this.tipologiaPrecedente = this.valoreRadio;
        this.backup.idTipologiaPrecedenti = this.valoreRadio;

        // cambiare valore in base a dove si trova il valore del paziente

        // this.valoreRadioPaziente = this.dispositivo.deviceType.id;
        // this.listaSelected.push(this.dispositivo.deviceType);
      }
      if (this.dispositivo.workingMode !== null) {
        this.valoreRadioWorkingMode = this.dispositivo.workingMode;
        if (!this.valoreRadioWorkingMode) {
          this.valoreRadioWorkingMode = 1;
        }
      }
    }

    this.configurazioneFormSmartbed = this.formBuilder.group({
      sesso: [false], //falso donna
      eta: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]+$/),
          Validators.max(120),
          Validators.min(1),
        ],
      ], //eta
      peso: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?!0\d)\d*(\.\d+)?$/),
          Validators.max(255),
          Validators.min(1),
        ],
      ], //peso
      altezza: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]+$/),
          Validators.max(255),
          Validators.min(40),
        ],
      ], //altezza
      sleepTime: [''],
    });

    this.configurazioneform = this.formBuilder.group({
      redNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      yellowNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      greenNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      whiteList: ['', [Validators.required, Validators.pattern('[- ,0-9]+')]],
      sleepTime: [''],
    });

    this.configurazionePT460form = this.formBuilder.group({
      sosNumber1: ['', [Validators.pattern('^[0-9]+$')]],
      sosNumber2: ['', [Validators.pattern('^[0-9]+$')]],
      sosNumber3: ['', [Validators.pattern('^[0-9]+$')]],
      workingMode: [''],
    });
    this.configurazioneJ2032form = this.formBuilder.group({
      //heartRateInterval: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      //spo02Interval: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      sosNumber: ['', [Validators.pattern('^[0-9]+$')]],
      wifiName: [''],
      wifiPassword: ['']
    });

    this.formTrackers = this.formBuilder.group({
      //heartRateInterval: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      //spo02Interval: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      latitudine: ['', [Validators.required],],
      longitudine: ['', [Validators.required]],
      batteriaMax:['',[Validators.pattern('^[0-9]+$')]],
      batteriaMin:['',[Validators.pattern('^[0-9]+$')]]
    });

    if (this.edit || this.info) {
      this.caricato = false;
      this.dispositiviService
        .trovaConfigurazione(this.dispositivo.id)
        .subscribe(
          (res) => {
            this.tipo = this.dispositivo.deviceType.id;
            if (res.data) {
              this.listaconf = res.data;
              this.valoreConfiguredShellyButton1 = this.listaconf.configured;
              this.idTracker = this.listaconf.trackerId;
              this.idTrigger = this.listaconf.idTrigger;
              this.valorePing = this.fromTimeToPing(this.listaconf.pingInterval);
              if (
                this.listaconf.type == 'ketronConfiguration' ||
                this.listaconf.type == 'thinkraceConfiguration' ||
                this.listaconf.type == "j2032Configuration"
              ) {
                //normal
                this.modSmartbed = false;
              } else {
                //smartbed
                this.modSmartbed = true;
              }

              let whitelist = '';
              if (this.listaconf.whitelist?.length > 0) {
                this.listaconf.whitelist.forEach((element) => {
                  whitelist = whitelist + ',' + element;
                });
              }
              this.listaconf.whiteList = whitelist.substring(
                1,
                whitelist.length
              );
              this.initConf();
              this.listaSoglie = this.dispositivo.allarme;

              this.listaSoglie.forEach((element) => {
                if (element.telemetria === 'SleepTime') {
                  this.minSleepTime = element.minimo;
                  this.maxSleepTime = element.massimo;
                  this.sleepTime = true;
                } else if (element.telemetria === 'BedAlarmIn') {
                  this.numberALetto = element.minimo / 60;
                  this.toggleLetto = element.sleepEnable;
                  this.aLetto = true;
                } else if (element.telemetria === 'BedAlarmOut') {
                  this.numberNonALetto = element.minimo / 60;
                  this.toggleLetto = element.sleepEnable;
                  this.nonALetto = true;
                } else if (element.telemetria === 'MovementsNone') {
                  this.numberImmobile = element.minimo;
                  this.numberImmobilePercentuale = element.massimo;
                  this.toggleMovimenti = element.sleepEnable;
                  this.movimenti = true;
                } else if (element.telemetria === 'MovementsSlight') {
                  this.numberLieve = element.minimo;
                  this.numberLievePercentuale = element.massimo;
                  this.toggleMovimenti = element.sleepEnable;
                  this.movimenti = true;
                } else if (element.telemetria === 'MovementsLow') {
                  this.numberBasso = element.minimo;
                  this.toggleMovimenti = element.sleepEnable;
                  this.movimenti = true;
                } else if (element.telemetria === 'MovementsMedium') {
                  this.numberMedio = element.minimo;
                  this.numberMedioPercentuale = element.massimo;
                  this.toggleMovimenti = element.sleepEnable;
                  this.movimenti = true;
                } else if (element.telemetria === 'MovementsHigh') {
                  this.numberAlto = element.minimo;
                  this.toggleMovimenti = element.sleepEnable;
                  this.movimenti = true;
                } else if (element.telemetria === 'MovementsCritical') {
                  this.numberCritico = element.minimo;
                  this.numberCriticoPercentuale = element.massimo;
                  this.toggleMovimenti = element.sleepEnable;
                  this.movimenti = true;
                } else {
                  this.toggleSoglia = element.sleepEnable;
                }
                element.idMio = this.i;
                this.i++;
              });
            }
          },
          (error) => {}
        );
    } else {
    }

    // questa e la crezione della lista dei tipi
    this.dispositiviService.getTipoDevice().subscribe((res) => {
      this.listaTipi = <[DeviceType]>res.data;
      this.caricato = true;
    });
    // __________

    if (this.edit || this.crea) {
      this.userService.getAll().subscribe((res) => {
        this.listaPazienti = res.data.listaUtentiAttivi;
        this.allUser = this.listaPazienti;
        this.caricaPazienti = true;
        this.listaPazienti.forEach((paziente) => {
          if (paziente.ruolo.id === 4) {
            this.listaPazienti = this.listaPazienti.filter(
              (x) => x !== paziente
            );
          }
        });
        this.allListaPazienti = this.listaPazienti;
      });
    }
    // ------------form di creazione--------

    this.formDispositivo = this.formBuilder.group({
      nome: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z A-Z0-9!@#-/$%^&*()]+$'),
        ],
      ],
      idDevice: ['', Validators.required],
    });

    this.searchPazientiForm = this.formBuilder.group({
      search: ['', Validators.required],
    });

    // -------form di modifica e edit--------

    if (this.edit || this.info) {
      this.formDispositivo = this.formBuilder.group({
        nome: [
          this.dispositivo.deviceName,
          [
            Validators.required,
            Validators.pattern('^[a-z A-Z0-9!@#-/$%^&*()]+$'),
          ],
        ],
        idDevice: [this.dispositivo.deviceId, Validators.required],
        paziente: [this.paziente, Validators.required],
      });
      if(this.dispositivo.deviceType.id === 6){
        this.formTrackers = this.formBuilder.group({
          latitudine: ['', [Validators.required],],
          longitudine: ['', [Validators.required]],
          batteriaMax:[this.dispositivo.allarme[0].massimo,[Validators.pattern('^[0-9]+$')]],
          batteriaMin:[this.dispositivo.allarme[0].minimo,[Validators.pattern('^[0-9]+$')]]
        });
        
      } 
    }
  }
 
  responsiveWidth() {}

  initConf() {
    // console.log("Valore radio: ", this.valoreRadio)
    // console.log("Modalità Smartbed: ", this.modSmartbed);
    if (
      !this.modSmartbed &&
      (this.edit || this.info) &&
      (this.valoreRadio != 5 && this.valoreRadio != 8)
    )
    {
      this.valoreBpm = this.listaconf.heartRateInterval;
      this.valoreTemp = this.listaconf.temperatureInterval;
      this.valoreVolume = this.listaconf.volume;
      this.h.redNumber.setValue(this.listaconf.redNumber);
      this.h.yellowNumber.setValue(this.listaconf.yellowNumber);
      this.h.greenNumber.setValue(this.listaconf.greenNumber);
      this.h.whiteList.setValue(this.listaconf.whiteList);
      this.h.sleepTime.setValue(this.listaconf.sleepTime);
      if (this.listaconf.temperatureInterval == 0) {
        this.valoreTemp = 1;
      }
    } else if (this.modSmartbed && (this.edit || this.info)) {
      this.fSmartbed.sesso.setValue(this.listaconf.userSex);
      this.fSmartbed.eta.setValue(this.listaconf.userAge);
      this.fSmartbed.peso.setValue(this.listaconf.userWeight);
      this.fSmartbed.altezza.setValue(this.listaconf.userHeight);
      this.fSmartbed.sleepTime.setValue(this.listaconf.sleepTime);
    } else if (
      !this.modSmartbed &&
      (this.edit || this.info) &&
      this.valoreRadio == 5
    ) {
      if (this.listaconf.sosNumber.length > 0) {
        this.p.sosNumber1.setValue(this.listaconf.sosNumber[0]);
        this.p.sosNumber2.setValue(this.listaconf.sosNumber[1]);
        this.p.sosNumber3.setValue(this.listaconf.sosNumber[2]);
        this.sosNumbersEnabled = true;
      }
      this.p.workingMode.setValue(this.listaconf.mode);
      this.valoreRadioWorkingMode = this.p.workingMode.value;
    } else if (
      !this.modSmartbed &&
      (this.edit || this.info) &&
      this.valoreRadio == 7
    ) {
      // da modificare
      // this.valorePing = this.fromTimeToPing(this.listaconf.pingInterval);
    }
    else if(
      !this.modSmartbed &&
      (this.edit || this.info) &&
      this.valoreRadio == 8)
    {
      // console.log("---Inizializzazione J2032---")
      this.valoreBpmJ2032 = this.listaconf.heartRateInterval;
      this.valoreSpo2 = this.listaconf.spo2Interval;
      // this.configurazioneJ2032.sosPhone.setValue(this.listaconf.sosNumber);
      // this.configurazioneJ2032.wifiName.setValue(this.listaconf.wifiName);
      // this.configurazioneJ2032.wifiPassword.setValue(this.listaconf.wifiPassword);
      this.sleepTime = this.listaconf.sleepTime;
    }
  }

  get h() {
    return this.configurazioneform.controls;
  }
  get f() {
    return this.formDispositivo.controls;
  }

  get t(){
    return this.formTrackers.controls;
  }

  get p() {
    return this.configurazionePT460form.controls;
  }
  get fJ2032(){
    return this.configurazioneJ2032form.controls;
  }
  get fSmartbed() {
    return this.configurazioneFormSmartbed.controls;
  }

  getValueRadio(valoreRadio) {
    this.valoreRadio = valoreRadio;
  }
  getValueRadioPaziente(valoreRadio) {
    if (this.valoreRadioPaziente === valoreRadio) {
      this.valoreRadioPaziente = null;
    } else {
      this.valoreRadioPaziente = valoreRadio;
    }
  }
  searchInPazienti() {
    let valore: string = this.searchPazientiForm.value.search;
    this.listaPazienti = this.allUser;

    if (valore) {
      let users = [];
      this.listaPazienti.forEach((user) => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;

        if (
          cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!users.find((x) => x === user)) {
            users.push(user);
          }
        }
      });
      this.listaPazienti = users;
    } else if (!valore) {
      this.listaPazienti = this.allUser;
    }
  }

  aggiungiStudente() {}
  // logica step consequenziali precendete/successivo
  scrollTop() {
    document.body.scrollTop = 0;
    // setTimeout(()=>{
    //   window.scroll({
    //     top:0,
    //     behavior: 'smooth'
    //   })
    // }, 50)
  }


  nextStep(fromDialog:Boolean=false) {
    if (
      this.step1 &&
      this.f.nome.value.trim().length !== 0 &&
      this.f.idDevice.value.trim().length !== 0
    ) {
      this.salvaFormStep1();
      if (!this.formDispositivo.invalid) {
        this.step2 = true;
        this.step1 = false;
        this.step3 = false;
        this.scrollTop();
        return;
      }
    }
    if (
      (this.f.nome.value.trim().length === 0 ||
        this.f.idDevice.value.trim().length === 0) &&
      this.step1
    ) {
      this.alertService.error('Inserire un nome e/o id valido');
    }
    if (this.step2) {
      this.salvaFormStep2();
      if (this.valoreRadio) {
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
        this.scrollTop();
        return;
      }
    }
    if (this.step3) {
      if(!fromDialog && this.valoreRadio == 8 && !this.fJ2032.sosNumber.value && this.listaconf?.sosNumber && this.edit)
      {
        this.confirmationService.confirm({
          key: 'cancelSosNumber',
          message:
            ' Non è stato inserito alcun numero SOS. Salvando, verrà eliminato quello inserito in precedenza, se presente.' +
            '<br>' +
            'Continuare?',
          icon: 'pi pi-exclamation-triangle',
          reject: () => {
            this.confirmationService.close();
          },
        });
      }
      else
      { 
        this.confirmationService.close();      
        this.salvaFormStep3();
        if (
          !this.modSmartbed &&
          this.valoreRadio != 5 &&
          !this.configurazioneform.invalid
        ) {
          this.step1 = false;
          this.step2 = false;
          this.step3 = false;
          this.step4 = true;
          this.scrollTop();
          return;
        }
        if (
          this.modSmartbed &&
          this.valoreRadio != 5 &&
          !this.configurazioneFormSmartbed.invalid
        ) {
          this.step1 = false;
          this.step2 = false;
          this.step3 = false;
          this.step4 = true;
          this.scrollTop();
          return;
        }
        if (
          !this.modSmartbed &&
          this.valoreRadio == 5 &&
          !this.configurazionePT460form.invalid
        ) {
          this.step1 = false;
          this.step2 = false;
          this.step3 = false;
          this.step4 = true;
          this.scrollTop();
          return;
        }
        if (
          !this.modSmartbed &&
          this.valoreRadio == 8 &&
          !this.configurazioneJ2032form.invalid
        ) {
          this.step1 = false;
          this.step2 = false;
          this.step3 = false;
          this.step4 = true;
          this.scrollTop();
          return;
        }
        this.submittedStep3=true;
      }
    }
  }

  previusStep() {
    if (this.step2) {
      this.step1 = true;
      this.step2 = false;
      this.step3 = false;
    } else if (this.step3) {
      this.step2 = true;
      this.step3 = false;
      this.step1 = false;
    } else if (this.step4) {
      this.submittedStep3=false;
      this.step2 = false;
      this.step3 = true;
      this.step1 = false;
      this.step4 = false;
    }
    this.scrollTop();
  }
  // logica swtich pulsanti in alto step randomici
  vaiCreadisp() {
    if (this.step2) {
      this.salvaFormStep2();
    }
    if (this.step3) {
      this.salvaFormStep3();
    }
    if (this.step4) {
      this.salvaFormStep4();
    }
    this.step2 = false;
    this.step1 = true;
    this.step3 = false;
    this.step4 = false;
    this.scrollTop();
  }
  vaiTipo() {
    if (this.step1) {
      this.salvaFormStep1();
    }
    if (this.step3) {
      this.salvaFormStep3();
    }
    if (this.step4) {
      this.salvaFormStep4();
    }
    if (!this.formDispositivo.invalid) {
      this.step2 = true;
      this.step1 = false;
      this.step3 = false;
      this.step4 = false;
    }
    this.scrollTop();
  }
  vaiConf() {
    if (this.step1) {
      this.salvaFormStep1();
      if(this.tracking) {
        this.vaiTipo();
        return;
      }
    }
    if (this.step2) {
      this.salvaFormStep2();
    }
    if (this.step4) {
      this.salvaFormStep4();
    }
    if (!this.formDispositivo.invalid && this.valoreRadio) {
      this.step2 = false;
      this.step1 = false;
      this.step3 = true;
      this.step4 = false;
    }
    this.scrollTop();
  }
  vaiSoglie() {
    if (this.step1) {
      this.salvaFormStep1();
    }
    if (this.step2) {
      this.salvaFormStep2();

      if (!this.salvaFormStep3() && this.dispositivo) {
        this.vaiConf();
        return false;
      }
    }
    if (this.step3) {
      this.salvaFormStep3();
      if (!this.salvaFormStep3() && this.dispositivo) {
        this.vaiConf();
        return false;
      }
      this.scrollTop();
    }
    this.inserisciNomeSoglia();
    if (
      (!this.formDispositivo.invalid &&
        this.valoreRadio &&
        (!this.configurazioneform.invalid ||
          !this.configurazioneFormSmartbed.invalid)) ||
      (this.valoreRadio == 5 && !this.configurazionePT460form.invalid)
    ) {
      this.step2 = false;
      this.step1 = false;
      this.step3 = false;
      this.step4 = true;
    }
  }
  // -----------------SUBMIT DEI FORM
  salvaFormStep1() {
    this.submittedStep1 = true;
    if (this.formDispositivo.invalid) {
      return;
    }
    if (this.valoreRadioPaziente) {
      this.dispositivoDaform = {
        device_name: this.formDispositivo.value.nome,
        active: true,
        device_id: this.formDispositivo.value.idDevice,
        utente: {
          id: this.valoreRadioPaziente,
        },
      };
    } else {
      this.dispositivoDaform = {
        device_name: this.formDispositivo.value.nome,
        active: true,
        device_id: this.formDispositivo.value.idDevice,
      };
    }
    this.submittedStep1 = false;
  }
  salvaFormStep2() {
    this.submittedStep2 = true;
    if (!this.valoreRadio) {
      return;
    }
    this.dispositivoDaform.device_type = new TipoDevice();
    this.dispositivoDaform.device_type.id = this.valoreRadio;
    this.submittedStep3 = false;
    //il tipo scelto dall'utente è quello precedente alla modifica
    if (
      this.backup.idTipologiaPrecedenti == this.valoreRadio &&
      this.backup.listaSoglie != undefined
    ) {
      //rimettiamo le precedenti soglie
      this.listaSoglie = this.backup.listaSoglie;
    }
    //il tipo scelto dall'utente è diverso dal tipo iniziale
    if (this.tipologiaPrecedente != this.valoreRadio) {
      this.backup.idTipologiaPrecedenti = this.tipologiaPrecedente;
      this.backup.listaSoglie = this.listaSoglie.slice(
        0,
        this.listaSoglie.length
      );
      //cancelliamo i valori salvati
      this.listaSoglie.splice(0, this.listaSoglie.length);
    }
    this.dispositiviService
      .getTelemetryMetadata(this.valoreRadio)
      .subscribe((res) => {
        //telemetrie disponibili per quel tipo di dispositivo
        this.listaTipoSoglia = <[DeviceTelemetryMetadata]>res.data;
        this.eliminaSogliaDaLista('Stato Dispositivo');
      });

    //Qui si cambia configurazione in base al tipo
    let isSmarbed = false;
    this.listaTipi.forEach((deviceType) => {
      if (this.valoreRadio == deviceType.id) {
        //se il valore selezioniato nel form corrisponde al device type
        if (
          deviceType.tipologia != null &&
          deviceType.tipologia == 'smartbed'
        ) {
          //e se il device type è smartbend, cambia impostazioni
          isSmarbed = true;
        }
      }
    });

    if (isSmarbed) {
      //set var
      this.modSmartbed = true;
    } else {
      //reset var
      this.modSmartbed = false;
    }
  }


  salvaFormStep3() {
    this.submittedStep3 = true;

    if (this.modSmartbed) {
      if (this.configurazioneFormSmartbed.invalid) {
        return false;
      }
      if (
        this.configurazioneFormSmartbed.value.sesso === undefined ||
        this.configurazioneFormSmartbed.value.eta === undefined ||
        this.configurazioneFormSmartbed.value.peso === undefined ||
        this.configurazioneFormSmartbed.value.altezza === undefined ||
        this.configurazioneFormSmartbed.value.sesso === null ||
        this.configurazioneFormSmartbed.value.eta === null ||
        this.configurazioneFormSmartbed.value.peso === null ||
        this.configurazioneFormSmartbed.value.altezza === null
        // this.configurazioneFormSmartbed.value.eta < 1 ||
        // this.configurazioneFormSmartbed.value.eta > 99 ||
        // this.configurazioneFormSmartbed.value.peso < 1 ||
        // this.configurazioneFormSmartbed.value.peso > 250 ||
        // this.configurazioneFormSmartbed.value.altezza < 1 ||
        // this.configurazioneFormSmartbed.value.altezza > 250
      ) {
        this.alertService.error('Il form è errato');
        return false;
      }

      this.confSmartbed = new ConfigurazioneSmartbedClass(
        'smartbedConfiguration',
        this.configurazioneFormSmartbed.value.sesso,
        this.configurazioneFormSmartbed.value.eta,
        this.configurazioneFormSmartbed.value.peso,
        this.configurazioneFormSmartbed.value.altezza,
        this.configurazioneFormSmartbed.value.sleepTime
      );
      return true;
    }
    if (
      this.configurazioneform.invalid &&
      this.configurazionePT460form.invalid
    ) {
      return false;
    }

    if( this.valoreRadio == 6 && ((this.t.latitudine.value.trim().length === 0 || this.t.longitudine.value.trim().length === 0) && !this.edit) || this.idUser == null) {
      if(this.idUser == null ) this.alertService.error('Associa un Utente');
      if((this.t.latitudine.value.trim().length === 0 || this.t.longitudine.value.trim().length === 0) && !this.edit )this.alertService.error('Errori in Associa Posizione');
      this.step3Error = true
      return false;
    } else {
      if (this.t.batteriaMax.value.trim() > 0 && this.t.batteriaMax.value.trim() < this.t.batteriaMin.value.trim() && !this.edit){
        this.alertService.error('Soglia Batteria errata!');
        this.step3Error = true
        return false;
      }

    }
    let numeriWhiteListDivisi =
      this.configurazioneform.value.whiteList.split(',');
    if (this.valoreRadio == 5) {
      var temp_sosNumbers: String[] = [];
      if (this.sosNumbersEnabled) {
        temp_sosNumbers = [
          this.configurazionePT460form.value.sosNumber1,
          this.configurazionePT460form.value.sosNumber2,
          this.configurazionePT460form.value.sosNumber3,
        ];
      }
      this.confPT460 = {
        type: 'thinkraceConfiguration',
        mode: this.valoreRadioWorkingMode,
        sosNumber: temp_sosNumbers,
      };
    } else if (this.valoreRadio == 6) {
      this.confGenerale = {
        type: 'trackerConfiguration',
        // pingInterval: this.convertPingTime(this.valorePing)
      };
      this.dispositivoDaform.utente = {id: this.idUser}
    } else if (this.valoreRadio == 7) {
      this.confGenerale = {
        trackerId: this.idTracker,
        type: 'beaconConfiguration',
      };
    } else if (this.valoreRadio == 8) {
      this.configurazioneJ2032 = {
        type: "j2032Configuration",
        heartRateInterval: this.valoreBpmJ2032,
        spo2Interval: this.valoreSpo2,
        sosPhone: this.fJ2032.sosNumber.value,
        wifiName: this.fJ2032.wifiName.value,
        wifiPassword: this.fJ2032.wifiPassword.value,
        sleepTime: this.sleepTime
      }
    } 
    else if (this.valoreRadio == 9) {
      this.confGenerale = {
        configured: this.valoreConfiguredShellyButton1,
        type: "shellyButton1Configuration"
      }
    }
    else if (this.valoreRadio == 10) {
      this.confGenerale = {
        triggerId: this.idTrigger,
        type: "shellyPlus1Configuration"
      }
    }
    else if (this.valoreRadio != 2) {
      this.confGenerale = {
        type: 'ketronConfiguration',
        heartRateInterval: this.valoreBpm,
        temperatureInterval: this.valoreTemp,
        volume: this.valoreVolume,
        redNumber: this.configurazioneform.value.redNumber,
        yellowNumber: this.configurazioneform.value.yellowNumber,
        greenNumber: this.configurazioneform.value.greenNumber,
        whitelist: numeriWhiteListDivisi,
        sleepTime: this.configurazioneform.value.sleepTime,
      };
    } else {
      this.confGenerale = {
        type: 'ketronConfiguration',
        heartRateInterval: this.valoreBpm,
        temperatureInterval: 1,
        volume: this.valoreVolume,
        redNumber: this.configurazioneform.value.redNumber,
        yellowNumber: this.configurazioneform.value.yellowNumber,
        greenNumber: this.configurazioneform.value.greenNumber,
        whitelist: numeriWhiteListDivisi,
        sleepTime: this.configurazioneform.value.sleepTime,
      };
    }

    if(this.valoreRadio == 6) {
      this.listaSoglie = [];
      let soglia = {
        nome: 'Batteria',
        telemetry: 'Power',
        idMio: this.i,
        telemetria: '',
        minimo: '',
        massimo: '',
        errore: false,
      };
      if( (this.t.batteriaMin.value.trim() != null || this.t.batteriaMax.value.trim() != null) && (this.t.batteriaMin.value || this.t.batteriaMax.value)){
      soglia.minimo = this.t.batteriaMin.value.trim()
      soglia.massimo = this.t.batteriaMax.value.trim()
      this.listaSoglie.push(soglia)
      }
    } else {
    this.inserisciNomeSoglia();
    }
    //this.submittedStep3 = false;
    return true;
  }

  roundToTen(number, originalNumber) {
    number = Number(number);
    if (originalNumber === '--') {
      return '--';
    }
    if (number < 10) {
      return 10;
    }
    if (number > 100) {
      return 100;
    }
    return Math.ceil(number / 10) * 10;
  }
  roundToFive(number) {
    if (number === null || number === undefined || number == 0) {
      return '--';
    }
    number = Number(number);
    if (number < 10) {
      return 10;
    }
    return Math.ceil(number / 5) * 5;
  }
  //popola nome soglia
  inserisciNomeSoglia() {
    if (
      this.info &&
      this.listaSoglie.length != 0 &&
      this.listaTipoSoglia.length != 0
    ) {
      this.listaSoglie.forEach((soglia) => {
        this.listaTipoSoglia.forEach((tipoSoglia) => {
          if (tipoSoglia.telemetry == soglia.telemetria) {
            soglia.nome = tipoSoglia.telemetryName;
          }
        });
      });
    }
  }

  salvaFormStep4() {
    this.step4Return = true;
    this.submittedStep4 = true;
    let isValidated = true;
    let primavolta = false;
    let validateToTrue = true;
    if (this.listaSoglie.length < 1) {
      this.validato = true;
    } else {
      this.listaSoglie.forEach((res) => {
        if (this.soglieToNotShow.indexOf(res.telemetria) === -1) {
          validateToTrue = false;
          if (this.step4Return) {
            //validator
            if (
              res.minimo === '' ||
              res.massimo === '' ||
              res.massimo <= res.minimo ||
              res.minimo == res.massimo ||
              !res.minimo ||
              !res.massimo
            ) {
              res.errore = true;
              this.step4Return = false;
              this.validato = false;
              return;
            } else {
              res.errore = false;
            }
            if (res.telemetria == '') {
              res.erroreTel = true;
              this.step4Return = false;
              this.validato = false;
              return;
            } else {
              res.erroreTel = false;
            }
            //controllo tipi duplicati
            let indice = 0;
            for (let index = 0; index < this.listaSoglie.length; index++) {
              if (this.listaSoglie[index].telemetria === res.telemetria) {
                indice = indice + 1;
              }
            }
            if (indice >= 2 && !primavolta) {
              this.alertService.error(
                "E' possibile selezionare un solo tipo di telemetria per le Soglie"
              );
              primavolta = true;
              this.validato = false;
              isValidated = false;
              this.step4Return = false;
              return;
            } else {
              if (isValidated) {
                this.validato = true;
              }
            }
          }
        }
      });
      if (validateToTrue) {
        this.validato = true;
      }
    }
  }
  dataMinimaSleepTimeSet(event) {
    // console.log(this.minSleepTime);
  }

  dataMassimaSleepTimeSet(event) {
    // console.log(this.maxSleepTime);
  }

  onSubmit() {
    if (this.valoreRadio !== 6 && this.valoreRadio !== 7 && this.valoreRadio !==9 && this.valoreRadio!==10) { //se il dispositivo non è un tracker/beacon/bottone/relè
      this.salvaFormStep4();
      if (this.sleepTime) {
        if (
          this.maxSleepTime === '' ||
          this.minSleepTime === '' ||
          this.maxSleepTime === this.minSleepTime
        ) {
          this.validato = false;
          this.alertService.error(
            'Selezionare delle telemetrie di sleep time valide'
          );
        }
      }
      if (this.validato) {
        this.loading = true;
        let body: any = {};
        if (this.dispositivoDaform.device_type == null) {
          this.dispositivoDaform.device_type = new TipoDevice();
          this.dispositivoDaform.device_type.id = this.valoreRadio;
        }
        body.device = this.dispositivoDaform;
        if (this.modSmartbed) {
          body.configuration = this.confSmartbed;
        } else if (this.valoreRadio == 5) {
          body.configuration = this.confPT460;
        } 
          else if (this.valoreRadio == 8) {
            body.configuration = this.configurazioneJ2032;
        }  
        else {
          body.configuration = this.confGenerale;
        }
        let listaSoglie = [];
        this.listaSoglie.forEach((element) => {
          if (this.soglieToNotShow.indexOf(element.telemetria) === -1) {
            listaSoglie.push(element);
          }
          this.listaTipoSoglia.forEach((tipoTelemetria) => {
            if (element.telemetria == tipoTelemetria.telemetry) {
              element.nome = tipoTelemetria.telemetryName;
              element.sleepEnable = this.toggleSoglia;
            }
          });
        });
        this.numberImmobile = Number(this.numberImmobile);
        this.numberLieve = Number(this.numberLieve);
        this.numberMedio = Number(this.numberMedio);
        this.numberCritico = Number(this.numberCritico);
        this.numberImmobilePercentuale = Number(this.numberImmobilePercentuale);
        this.numberLievePercentuale = Number(this.numberLievePercentuale);
        this.numberMedioPercentuale = Number(this.numberMedioPercentuale);
        this.numberCriticoPercentuale = Number(this.numberCriticoPercentuale);
        if (
          this.sleepTime &&
          this.minSleepTime !== '' &&
          this.maxSleepTime !== '' &&
          this.minSleepTime !== this.maxSleepTime
        ) {
          listaSoglie.push({
            telemetria: 'SleepTime',
            minimo: this.minSleepTime,
            massimo: this.maxSleepTime,
            nome: 'Sleep Time',
            sleepEnable: null,
          });
        }
        if (this.aLetto && this.numberALetto > 0) {
          listaSoglie.push({
            telemetria: 'BedAlarmIn',
            minimo: this.numberALetto * 60,
            massimo: null,
            nome: 'Presenza a Letto',
            sleepEnable: this.toggleLetto,
          });
        }
        if (this.nonALetto && this.numberNonALetto > 0) {
          listaSoglie.push({
            telemetria: 'BedAlarmOut',
            minimo: this.numberNonALetto * 60,
            massimo: null,
            nome: 'Presenza a Letto',
            sleepEnable: this.toggleLetto,
          });
        }
        if (this.movimenti) {
          if (this.numberImmobile >= 0) {
            listaSoglie.push({
              telemetria: 'MovementsNone',
              minimo: this.numberImmobile,
              massimo: this.numberImmobilePercentuale,
              nome: 'Movimenti Immobile',
              sleepEnable: this.toggleMovimenti,
            });
          }
          if (this.numberLieve >= 0) {
            listaSoglie.push({
              telemetria: 'MovementsSlight',
              minimo: this.numberLieve,
              massimo: this.numberLievePercentuale,
              nome: 'Movimenti Immobile',
              sleepEnable: this.toggleMovimenti,
            });
          }
          // if(this.numberBasso > 0) {
          //   listaSoglie.push({
          //     "telemetria": "MovementsLow",
          //     "minimo": this.numberBasso,
          //     "massimo": null,
          //     "nome": "Movimenti Immobile",
          //     "sleepEnable":this.toggleMovimenti
          //   })
          // }
          if (this.numberMedio >= 0) {
            listaSoglie.push({
              telemetria: 'MovementsMedium',
              minimo: this.numberMedio,
              massimo: this.numberMedioPercentuale,
              nome: 'Movimenti Immobile',
              sleepEnable: this.toggleMovimenti,
            });
          }
          // if(this.numberAlto > 0) {
          //   listaSoglie.push({
          //     "telemetria": "MovementsHigh",
          //     "minimo": this.numberAlto,
          //     "massimo": null,
          //     "nome": "Movimenti Immobile",
          //     "sleepEnable":this.toggleMovimenti
          //   })
          // }
          if (this.numberCritico >= 0) {
            listaSoglie.push({
              telemetria: 'MovementsCritical',
              minimo: this.numberCritico,
              massimo: this.numberCriticoPercentuale,
              nome: 'Movimenti Immobile',
              sleepEnable: this.toggleMovimenti,
            });
          }
        }
        body.allarms = listaSoglie;


        //---------------------crea
        if (this.crea) {
          this.dispositiviService.creaDevice(body).subscribe(
            (res) => {
              this.alertService.success(res.status.description);
              this.tornaLista.emit();
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
        }

        //---------------------Modifica

        if (this.edit) {
          this.dispositiviService
            .modificaDevice(body, this.dispositivo.id)
            .subscribe(
              (res) => {
                this.alertService.success(res.status.description);
                this.tornaLista.emit();
                this.loading = false;
              },
              (error) => {
                this.loading = false;
              }
            );
        }
      }
    } else {
      if(!this.salvaFormStep3()) return;
      let body: any = {};
      body.device = this.dispositivoDaform;
      body.configuration = this.confGenerale;
      if(this.listaSoglie.length > 0){
        body.allarms = this.listaSoglie
      }
      if(this.valoreRadio === 6){
          
        this.firstLocation.latitudine = this.t.latitudine.value,
        this.firstLocation.longitudine = this.t.longitudine.value
      
      body.firstLocation = this.firstLocation
    }

      if (this.crea) {
        this.dispositiviService.creaDevice(body).subscribe(
          (res) => {
            this.alertService.success(res.status.description);
            this.tornaLista.emit();
            this.loading = false;
          },
          (error) => {
            
            this.loading = false;
          }
        );
      }

      //---------------------Modifica

      if (this.edit) {
        this.dispositiviService
          .modificaDevice(body, this.dispositivo.id)
          .subscribe(
            (res) => {
              this.alertService.success(res.status.description);
              this.tornaLista.emit();
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
      }
    }
  }

  addPaziente() {
    //mostra al click la lista Pazienti

    this.pazienteEdit = !this.pazienteEdit;
    this.pazienti = !this.pazienti;

    //this.docenti=false;
  }
  addFamiliari() {
    //mostra al click la lista Pazienti

    this.familiari = !this.familiari;

    //this.docenti=false;
  }
  goBack() {
    //torna ad elenco aula virtuale
    this.tornaLista.emit();
  }

  aggiungiSoglia() {
    let soglia = {
      idMio: this.i,
      telemetria: '',
      minimo: '',
      massimo: '',
      errore: false,
    };

    this.listaSoglie.push(soglia);
    this.i++;
  }

  deleteSoglia(soglia) {
    this.listaSoglie = this.listaSoglie.filter((x) => x.idMio != soglia.idMio);
  }

  eliminaSogliaDaLista(nomeSoglia: string) {
    let index = -1;
    for (var i = 0; i < this.listaTipoSoglia.length; i++) {
      if (
        this.listaTipoSoglia[i].telemetryName !== undefined &&
        this.listaTipoSoglia[i].telemetryName !== null &&
        this.listaTipoSoglia[i].telemetryName == nomeSoglia
      ) {
        index = i;
      }
    }
    if (i >= 0) {
      this.listaTipoSoglia.splice(index, 1);
    }
  }

  formatLabelVolume(value: number) {
    if (value >= 11) {
      value = 11;

      return value;
    }

    return value;
  }
  formatLabel(value: number) {
    if (value >= 1) {
      return Math.round(value) + ' h';
    }

    return value;
  }

  formatLabelBpmJ2032(value: number) {
    if (value == 0) {
      return 'Off';
    }

    return value + ' m';
  }

  checkTelemetryUnit(soglia, tipo) {
    if (soglia.telemetria === tipo.telemetry) {
      return true;
    }
    return false;
  }

  showTipoSoglia(soglia) {
    let telemetria = '';
    if (this.listaTipoSoglia) {
      this.listaTipoSoglia.forEach((element) => {
        if (element.telemetry === soglia.telemetria) {
          telemetria = element.telemetryName;
        }
      });
    }
    return telemetria;
  }

  setWorkingMode(workingMode) {
    this.valoreRadioWorkingMode = workingMode;
  }

  getWorkingMode(modeId) {
    if (modeId == 1) {
      return 'Normale';
    }
    if (modeId == 2) {
      return 'Risparmio energetico';
    }
    if (modeId == 3) {
      return 'Alta precisione';
    }
  }

  convertPingTime(value) {
    switch (value) {
      case 1:
        return 10;
      case 2:
        return 30;
      case 3:
        return 60;
      case 4:
        return 120;
    }
  }

  fromTimeToPing(value) {
    switch (value) {
      case 10:
        return 1;
      case 30:
        return 2;
      case 60:
        return 3;
      case 120:
        return 4;
    }
  }

  registraId(id) {
    this.idUser = id;
  }

  registraIdTracker(id) {
    this.idTracker = id;
  }

  registraIdTrigger(id){
    this.idTrigger = id;
  }


  changeMac(event)
  {
    
    this.f.idDevice.setValue(event);
  }



  toggleCollapse(): void {
    this.visible = !this.visible;
  }

  showPassword(){
    this.passwordTypeB =!this.passwordTypeB
    if(this.passwordTypeB)
    {
      this.passwordType="password"
      this.visibility="visibility"
    }
    else
    {
      this.passwordType="text"
      this.visibility="visibility_off"
    }
  }
  

  
}



export class DatiTipologiaSalvatiPrecedente {
  idTipologiaPrecedenti: number;
  listaSoglie: any[];
}
