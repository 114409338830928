//IMPORTS
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccordionModule } from 'primeng/accordion';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PannelloControlloModule } from './COMPONENTS/pannello-controllo/pannello-controllo.module';
import { AssistitiModule } from './COMPONENTS/assistiti/assistiti.module';
import { AccountModule } from './COMPONENTS/account/account.module';
import { NgxLeafletFullscreenModule } from '@runette/ngx-leaflet-fullscreen';
import { OpenviduSessionModule } from 'openvidu-angular';
import { SidebarModule } from 'primeng/sidebar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { WebSocketService } from './SERVICES/GENERAL/socket-client.service';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { NgChatModule } from 'ng-chat';
import { ToastModule } from 'primeng/toast';
import { CanDeactivateGuard } from './HELPERS/candeactivate-guard.service';
import { EnvServiceProvider } from './SERVICES/GENERAL/env.service.provider';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ChartModule } from 'primeng/chart';
import {
  CalendarModule as CalendarModule2,
  DateAdapter,
  DateFormatterParams,
  CalendarNativeDateFormatter,
  CalendarDateFormatter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MultiSelectModule } from 'primeng/multiselect';
import { DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { NgScormPlayerModule } from 'ng-scorm-player';
import { GalleriaModule } from 'primeng/galleria';
import { TrasformatoreSecondi } from './HELPERS/PIPES/trasformatoreSecondi.pipe';
import { DateAgoPipe } from './HELPERS/PIPES/dateAgo.pipe';
import { SogliePipe } from './HELPERS/PIPES/soglie.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { KonvaService } from './COMPONENTS/classroom/konva.service';
import { MatSliderModule } from '@angular/material/slider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckboxModule } from 'primeng/checkbox';

//tabView per iOT
//import {TabViewModule} from 'primeng/tabview';
import { NewsLetterModule } from './COMPONENTS/news-letter/news-letter.module';
import { DateAgoIOTPipe } from './HELPERS/PIPES/dateAgoIOT.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ExternalComponentsModule } from 'src/app/MODELS/COMPONENTS/externalComponents.module';
//DECLARATIONS
import { DashboardComponent } from './COMPONENTS/dashboard/dashboard.component';
import { JwtInterceptor } from './HELPERS/jwt.interceptor';
import { ErrorInterceptor } from './HELPERS/error.interceptor';
import { AlertComponent } from './COMPONENTS/alert/alert.component';
import { NavbarComponent } from './COMPONENTS/navbar/navbar.component';
import { CambiaPasswordComponent } from './COMPONENTS/navbar/cambia-password/cambia-password.component';
import { LogoutComponent } from './COMPONENTS/navbar/logout';
import { OpenViduComponent } from './COMPONENTS/controlroom/open-vidu/open-vidu.component';
import { GeolocalizzazioneComponent } from './COMPONENTS/controlroom/geolocalizzazione/geolocalizzazione.component';
import { HomeControlRoomComponent } from './COMPONENTS/controlroom/home-control-room/home-control-room.component';
import { VideochiamataComponent } from './COMPONENTS/videochiamata/videochiamata.component';
import { VideochiamataOpenviduComponent } from './COMPONENTS/videochiamata/videochiamata-openvidu/videochiamata-openvidu.component';
import { ChatbotComponent } from './COMPONENTS/chatbot/chatbot.component';
import { HomeControlRoomLightComponent } from './COMPONENTS/geolocalizzazione/home-control-room/home-control-roomLight.component';
import { Geolocalizzazione3Component } from './COMPONENTS/geolocalizzazione/geolocalizzazione3/geolocalizzazione3.component';
import { ChatliveComponent } from './COMPONENTS/chatlive/chatlive.component'
import { GuidaComponent } from './COMPONENTS/navbar/guida/guida.component';
import { ProfiloComponent } from './COMPONENTS/navbar/profilo/profilo.component';
import { ClassroomHomepageComponent } from './COMPONENTS/classroom/homepageClassroom/classroom-homepage/classroom-homepage.component';
import { ClassroomLezioneInCorsoComponent } from './COMPONENTS/classroom/lezioneInCorso/classroom-lezione-in-corso/classroom-lezione-in-corso.component';
import { ClassroomListaAuleComponent } from './COMPONENTS/classroom/listaAule/classroom-lista-aule/classroom-lista-aule.component';
import { CalendarioComponent } from './COMPONENTS/classroom/calendario/calendario.component';
import { EmbedControlComponent } from './COMPONENTS/embed-control/telemetria/embed-control.component';
import { ListaFileComponent } from './COMPONENTS/Dms/lista-file/lista-file.component';
import { AddEditFileComponent } from './COMPONENTS/Dms/add-edit-file/add-edit-file.component';
import { InfoFileComponent } from './COMPONENTS/Dms/info-file/info-file.component';
import { RicercaAvanzataComponent } from './COMPONENTS/Dms/ricerca-avanzata/ricerca-avanzata.component';
import { EsercitazioniComponent } from './COMPONENTS/classroom/esercitazioni/esercitazioni.component';
import { AddEditEsercitazioneComponent } from './COMPONENTS/classroom/esercitazioni/add-edit-esercitazione/add-edit-esercitazione.component';
import { LezioniPassateComponent } from './COMPONENTS/classroom/lezioni-passate/lezioni-passate.component';
import { IotLayoutComponent } from './COMPONENTS/embed-control/iot-layout/iot-layout.component';
import { CorsiComponent } from './COMPONENTS/classroom/corsi/corsi.component';
import { CorsiPlayerComponent } from './COMPONENTS/classroom/corsi/corsi-player/corsi-player.component';
import { LavagnaComponent } from './COMPONENTS/classroom/lavagna/lavagna.component';
import { WhiteboardPageComponent } from './COMPONENTS/classroom/whiteboard-page/whiteboard-page.component';
import { MatTabsModule} from '@angular/material/tabs';
import { BottomSheet } from './COMPONENTS/classroom/whiteboard-page/whiteboard-page.component';
import { ScheduleV2Component } from './COMPONENTS/classroom/calendario/schedule-v2/schedule-v2.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IotModularComponent } from './COMPONENTS/iot/iot-modular/iot-modular.component';
import { DeviceListComponent } from './COMPONENTS/iot/device-list/device-list.component';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ListaAllarmiComponent } from './COMPONENTS/iot/lista-allarmi/lista-allarmi.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CarouselModule } from 'primeng/carousel';
import { CardListComponent } from './COMPONENTS/iot/card-list/card-list.component';
import { IOTService } from './COMPONENTS/iot/serviceIOT.service';
import { EditorModule } from 'primeng/editor';
import { PrenotazioneTecnicoComponent } from './COMPONENTS/prenotazione-tecnico/prenotazione-tecnico.component';
import { FormCreatorComponentComponent } from './COMPONENTS/prenotazione-tecnico/form-creator-component/form-creator-component.component';
import { MatStepperModule } from '@angular/material/stepper';
import { DownloadReportImageComponent } from './COMPONENTS/pannello-controllo/report/report-badge/download-report-image/download-report-image.component';
import { PrenotazioneTecnicoService } from './SERVICES/PRENOTAZIONETECNICO/prenotazione.service';
import { VisualizzazioneFormComponent } from './COMPONENTS/prenotazione-tecnico/visualizzazione-form/visualizzazione-form.component';
import { EditImageComponent } from './COMPONENTS/controlroom/edit-image/edit-image.component';
import { MatChipsModule } from '@angular/material/chips';
import { CalendarCallCenterComponent } from './COMPONENTS/calendar-call-center/calendar-call-center.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FarmacieAddComponent } from './COMPONENTS/calendar-call-center/farmacie-add/farmacie-add.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from 'src/app/SERVICES/USER/CustomPaginato';

import { MatNativeDateModule } from '@angular/material/core';
import { InfoChiamataComponent } from './COMPONENTS/calendar-call-center/info-chiamata/info-chiamata.component';
import { FarmacieListComponent } from './COMPONENTS/calendar-call-center/farmacie-list/farmacie-list.component';
//import { DashboardMonitoraggioComponent } from './components/monitoraggio-ambiente/dashboard-monitoraggio/dashboard-monitoraggio.component';
import { CreazioneEventoComponent } from './COMPONENTS/prenotazione-tecnico/creazione-evento/creazione-evento.component';
import { ClassroomListaAuleGoToComponent } from './COMPONENTS/classroom/listaAule/classroom-lista-aule-go-to/classroom-lista-aule-go-to.component';
import { AccessTokenGoToComponent } from './COMPONENTS/classroom/access-token-go-to/access-token-go-to.component';
import { BodyCodiceFiscale } from './HELPERS/bodyCodiceFiscale.interceptor';


class CustomDateFormatter extends CalendarNativeDateFormatter {
  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat('it', { hour: 'numeric' }).format(date);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    BottomSheet,
    DashboardComponent,
    AlertComponent,
    NavbarComponent,
    CambiaPasswordComponent,
    LogoutComponent,
    OpenViduComponent,
    GeolocalizzazioneComponent,
    HomeControlRoomComponent,
    VideochiamataComponent,
    VideochiamataOpenviduComponent,
    ChatbotComponent,
    Geolocalizzazione3Component,
    HomeControlRoomLightComponent,
    ChatliveComponent,
    GuidaComponent,
    ProfiloComponent,
    ClassroomHomepageComponent,
    ClassroomLezioneInCorsoComponent,
    ClassroomListaAuleComponent,
    CalendarioComponent,
    EmbedControlComponent,
    ListaFileComponent,
    FarmacieAddComponent,
    AddEditFileComponent,
    InfoFileComponent,
    RicercaAvanzataComponent,
    EsercitazioniComponent,
    AddEditEsercitazioneComponent,
    LezioniPassateComponent,
    DateAgoPipe,
    DateAgoIOTPipe,
    SogliePipe,
    IotLayoutComponent,
    CorsiComponent,
    CorsiPlayerComponent,
    TrasformatoreSecondi,
    LavagnaComponent,
    WhiteboardPageComponent,
    ScheduleV2Component,
    IotModularComponent,
    DeviceListComponent,
    ListaAllarmiComponent,
    CardListComponent,
    PrenotazioneTecnicoComponent,
    FormCreatorComponentComponent,
    DownloadReportImageComponent,
    VisualizzazioneFormComponent,
    EditImageComponent,
    CalendarCallCenterComponent,
    InfoChiamataComponent,
    FarmacieListComponent,
    //DashboardMonitoraggioComponent,
    CreazioneEventoComponent,
    ClassroomListaAuleGoToComponent,
    AccessTokenGoToComponent,
    GeolocalizzazioneComponent
  ],
  imports: [
    MatChipsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    EditorModule,
    MatStepperModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    CarouselModule,
    MatExpansionModule,
    BrowserModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    AppRoutingModule,
    FormsModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatMenuModule,
    ReactiveFormsModule,
    HttpClientModule,
    SelectButtonModule,
    BrowserAnimationsModule,
    KeyFilterModule,
    CalendarModule,
    TableModule,
    MatDialogModule,
    AccordionModule,
    ConfirmDialogModule,
    ButtonModule,
    MatTooltipModule,
    MessagesModule,
    MessageModule,
    AccountModule,
    ColorPickerModule,
    PannelloControlloModule,
    ProgressSpinnerModule,
    NgxLeafletFullscreenModule,
    OpenviduSessionModule,
    SidebarModule,
    MatSidenavModule,
    NgxAutoScrollModule,
    NgChatModule,
    ToastModule,
    SlideMenuModule,
    ChartModule,
    MultiSelectModule,
    DialogModule,
    GalleriaModule,
    CalendarModule,
    NewsLetterModule,
    ExternalComponentsModule,
    MatSelectModule,
    MatInputModule,
    CheckboxModule,
    MatCheckboxModule,
    MatFormFieldModule,
    NgScormPlayerModule.forChild({ debug: true }),
    CalendarModule2.forRoot(
      { provide: DateAdapter, useFactory: adapterFactory },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CustomDateFormatter,
        },
      }
    ),
    AssistitiModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BodyCodiceFiscale, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    [WebSocketService],
    [CanDeactivateGuard],
    [EnvServiceProvider],
    [DatePipe],
    IOTService,
    KonvaService,
    PrenotazioneTecnicoService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
