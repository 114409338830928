<div>
  <div>
    <div>Dimensioni Pennello</div>
    <mat-slider [value]="brushSize" [(ngModel)]="brushSize" thumbLabel step="1" min="1" max="100"
      aria-label="brush size">
    </mat-slider>
  </div>
  <div>
    <div>Opacità Pennello</div>
    <mat-slider [value]="brushOpacity" [(ngModel)]="brushOpacity" thumbLabel step="0.1" min="0.1" max="1"
      aria-label="brush opacity">
    </mat-slider>
  </div>
</div>
